import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer, PERSIST, PAUSE, REHYDRATE, FLUSH, PURGE, REGISTER , } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { thunk} from 'redux-thunk';

import authReducer from './slices/authSlice';
import websiteReducer from './slices/WebSiteData';
import pathReducer from './slices/headerFixedSlice';
import notificationReducer from './slices/Notification';
import instituteReducer from './slices/institute'
import inputReducer from './slices/input';
import { combineReducers } from 'redux';
import eventReducer from './slices/QuizSlice';
import formReducer from './slices/FestForm';
import userReducer from './slices/UsersSlice';

// Combine reducers
const rootReducer = combineReducers({
  auth: authReducer,
  website: websiteReducer,
  path: pathReducer,
  notification: notificationReducer,
  event: eventReducer,
  form: formReducer,
institute:instituteReducer,
user:userReducer,
add :inputReducer
});

// Persist configuration
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'website', 'path', 'notification', 'event' , "form", 'institute', 'user' , "add"], // Specify reducers to persist
};

// Wrap the root reducer with persistReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure the store
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER,],
      },
    }).concat(thunk),
});

// Persistor
export const persistor = persistStore(store);
export default store;
