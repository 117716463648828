
import { collection, doc, getDoc } from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaArrowLeft, FaFacebook, FaInstagram, FaTwitter, FaLink, FaWhatsapp } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { firestore } from "../../firebase";

const BlogDetail = () => {
  const { t } = useTranslation();
  const detailRef = useRef(null);
  console.log("jkhjhjvbjhbvmj")
  const location = useLocation()
  const { blogId } = useParams(); 
  console.log('blogId: ', blogId);
  const navigate = useNavigate()
  const [blogData, setBlogData] = useState(null);
  const [ textScale , setTextScale]= useState(1)
  const [loading, setLoading] = useState(true); 
  useEffect(() => {
    if (detailRef.current) {
      detailRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []); 
  const screenType = useSelector((state) => state.website.screenType);
  console.log(blogData,":::::::::::::::::")
  const image = blogData?.imageLink
  const styles = {
    container: {
      position: "relative",
      maxWidth: "64rem",
      margin: "2vw auto",
      padding: "2rem 1rem",
      marginTop: screenType ==="tab"||screenType==="tablet" ?"7vh" : "2vh",
      // backgroundColor:"white"
    },
    backButton: {
      display: "flex",
      alignItems: "center",
      gap: "0.5rem",
      marginLeft:"0px",
      margin:screenType === 'smallPhone' || screenType === 'phone'? "1rem": "2rem",
      padding: "0.5rem 1rem",
      color: "#4B5563",
      backgroundColor: "transparent",
      border: "none",
      cursor: "pointer",
      transition: "color 0.2s",
    },
    backIcon: {
      width: "20px",
      height: "20px",
      transition: "transform 0.2s",
    },
    backText: {
      fontWeight: "500",
    },
    mainContent: {
      backgroundColor: "#ffffff",
      borderRadius: "1rem",
      overflow: "hidden",
      boxShadow: "0 10px 25px rgba(0, 0, 0, 0.1)",
    },
    image: {
      width: "100%",
      height: screenType === 'smallPhone' || screenType === 'phone'? "": "400px",
      objectFit: "contain",
    },
    contentContainer: {
      padding: "2rem",
    },
    categoryContainer: {
      marginBottom: "1.5rem",
    },
    categoryTag: {
      display: "inline-block",
      padding: "0.5rem 1rem",
      borderRadius: "9999px",
      fontSize: "0.875rem",
      fontWeight: "500",
      backgroundColor: "#EBF5FF",
      color: "#2563EB",
    },
    title: {
      marginBottom: "1.5rem",
      color: "#111827",
      fontWeight: "bold",
    },
    content: {
      lineHeight: "1.625",
      color: "#374151",
    },
    shareContainer: {
      // position: "absolute",
      right: "-0px",
      // top: "50%",
      // transform: "translateY(-50%)",
      display: "flex",
      position:"relative",
     marginLeft:"30px",
      // flexDirection: "column",
      gap: "1rem",
      marginBottom:"20px"
    },
  };
// const post = location.state.post
const Blogid = location.state?.documentId
useEffect(() => {
  const fetchBlogData = async () => {
    try {
      const blogRef = doc(firestore, "Blogs", blogId); // Reference to the blog document
      const blogSnap = await getDoc(blogRef);

      if (blogSnap.exists()) {
        setBlogData(blogSnap.data()); // Set blog data to state
        console.log(blogRef)
      } else {
        console.error("No such document!");
      }
    } catch (error) {
      console.error("Error fetching blog data: ", error);
    } finally {
      setLoading(false); // Stop loading spinner
    }
  };

  fetchBlogData();
}, [Blogid]);
console.log(Blogid)
  const handleShare = (platform) => {
    const url = `${window.location.origin}/blogs/BlogDetailsScreen/${Blogid}`;
    const text = blogData.BlogTopic;
    const fullMessage = `${text} ${url}`;
    switch (platform) {
      case "whatsapp":
        window.open(`https://wa.me/?text=${encodeURIComponent(fullMessage)}`);
        break;
      case "twitter":
        window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(url)}`);
        break;
      case "facebook":
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`);
        break;
      case "instagram":
        alert("Instagram sharing is typically done through their app");
        break;
      case "copy":
        navigator.clipboard.writeText(url);
        alert("Link copied to clipboard!");
        break;
      default:
        break;
    }
  };
const onBack =()=>{
navigate("/blogs")
}
  return (
    <div style={styles.container} ref={detailRef}>

      <div style={styles.mainContent}>
      <button
        style={styles.backButton}
        onClick={onBack}
        onMouseOver={(e) => {
          e.currentTarget.style.color = "#111827";
          const icon = e.currentTarget.querySelector("svg");
          if (icon) icon.style.transform = "translateX(-4px)";
        }}
        onMouseOut={(e) => {
          e.currentTarget.style.color = "#4B5563";
          const icon = e.currentTarget.querySelector("svg");
          if (icon) icon.style.transform = "translateX(0)";
        }}
      >
        <FaArrowLeft style={styles.backIcon} />
        <span style={styles.backText}>{t("Back to Blogs")}</span>
      </button>
      {
      image &&(  <img src={blogData?.imageLink} alt={blogData?.title} style={styles.image} /> ) 
       


      }

        <div style={styles.contentContainer}>
          <div style={styles.categoryContainer}>
            <span style={styles.categoryTag}>{blogData?.blogcategory}</span>
          </div>

          <h1
            style={{
              ...styles.title,
              fontSize: `${2 * textScale}rem`,
            }}
          >
            {t(blogData?.blogtopic)}
          </h1>

          <div
            style={{
              ...styles.content,
              fontSize: `${1 * textScale}rem`,
            }}
          >
            {t(blogData?.blogcontent)}
          </div>
        </div>
      <div style={styles.shareContainer}>
        <button onClick={() => handleShare("whatsapp")}>
          <FaWhatsapp style={{ color: "#25D366", fontSize: "1.5rem" }} />
        </button>
        <button onClick={() => handleShare("twitter")}>
          <FaTwitter style={{ color: "#1DA1F2", fontSize: "1.5rem" }} />
        </button>
        <button onClick={() => handleShare("facebook")}>
          <FaFacebook style={{ color: "#4267B2", fontSize: "1.5rem" }} />
        </button>
        <button onClick={() => handleShare("instagram")}>
          <FaInstagram style={{ color: "#E4405F", fontSize: "1.5rem" }} />
        </button>
        <button onClick={() => handleShare("copy")}>
          <FaLink style={{ color: "#6B7280", fontSize: "1.5rem" }} />
        </button>
      </div>
      </div>

    </div>
  );
};

export default BlogDetail;
