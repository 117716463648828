import React, { useState, useRef, useEffect } from 'react';
import {

  Vibration,
 
  Keyboard,
  ActivityIndicator,
  Alert,

} from 'react-native';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ref, uploadBytes, getDownloadURL } from "../../../firebase";

import {
    setMessagesArray,
    deleteMessagesArray,
    setLastSeen,
  } from '../../../reduxStore/slices/input';
import SenderInput from '../SenderInput/senderInput';
import MessageCard from './MessageCard'
import { useDispatch, useSelector } from 'react-redux';
import LoadingSpinner from "../../../Components/loadingSpinner"; // Import the Framer Motion spinner

import CustomConfirmationModal from '../../MainFIle/confirmationModal';
import { convertToJSON } from '../../../backgroundServices/notificationService';
import { storageEvents } from '../../../backgroundServices/notificationService';

import { resizeImage } from '../../Conversation/ResizeImage';
import {
  appendPendingMessageQueue,
  deleteUserMessages,
  queueUpdateData,
  removeMessageFromQueue,
  updateUserMessagesRef,
} from '../../../reduxStore/slices/UsersSlice';
import { generateRandomDocumentID } from '../../../utils/getRandomDocumentId';
import { firestore , storage, functions } from '../../../firebase';
import { collection, documentId, where , query , getDocs, doc, getDoc, writeBatch } from 'firebase/firestore';
import { useLocation, useNavigate } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
let variable = true;
export const refreshFunction = () => {

    variable = !variable;
};
const width = window.innerWidth;

const MessageDemo = routes => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation()
  const conversationId = location.state.id;
  const userData = useSelector(state => state.user.users);
  const activeUser = useSelector(state => state.user.activeUser);
  const activeUserData = userData[activeUser];
  const messageReduxRef = activeUserData.messageRefs;
  const [messagesArrayss, setMMesagesArr] = useState(activeUserData.messages);

  const pendingMessagesQueue = useSelector(
    state => state.user.pendingMessagesQueue,
  );

  // console.log('activeUserData.conversations: ', activeUserData.conversations);
  const [conversation, setConversation] = useState(
    activeUserData?.conversations[conversationId],
  );
  const participantsUids = Object.keys(conversation['participants']);
  // const [matchedViewers,setMatchedViewers]=useState({}) // Keeps track of users whose viewerMessageId matched message.id
  // Initialize ref to track matched viewers without re-rendering
  const matchedViewersRef = useRef({});

  const filteredParticipantsUids = participantsUids.filter(
    item => item !== activeUser,
  );

  const allConversation = activeUserData?.conversations;
  const sendConversationMessage = httpsCallable( functions ,
    'sendConversationMessage',
  );
  const updateMessageDelivered = httpsCallable( functions ,
    'updateMessageDelivered',
  );
  const [message, setMessage] = useState('');
  const [selectedCards, setSelectedCards] = useState([]); // Track selected cards
  const [prevCardOfSelectedCard, setPrevCardOfSelectedCard] = useState([])
  const [selectedUserCards, setSelectedUserCards] = useState([]);
  const [selectionMode, setSelectionMode] = useState(false); // Track if selection mode is active
  const [goBottom, setGoBottom] = useState(false);
  const [keyboardOpen, setKeyboardOpen] = useState(false);
  const [messages, setMessages] = useState([]); // State for messages
  const [loading, setLoading] = useState(true); // State for loading
  const [loadingMore, setLoadingMore] = useState(false); // State for loading more messages
  const [fetching, setFetching] = useState(true); // State for loading more messages
  const [lastVisible, setLastVisible] = useState(null); // Track the last visible message
  const [addMessageWithId, setAddMessageWithId] = useState(null);
  const [showForward, setShowForward] = useState(false);
  const [messageArrived, setMesssageArrived] = useState(false);
  const activeUserid = activeUser;
  const [deletionMode, setDeletionMode] = useState(false);
  const scrollViewRef = useRef(null);
  const scrollRef = useRef(null);

  const [addMessage, setAddMessage] = useState([]);
  // Use a ref to store the previous length of addMessage
  const prevAddMessageLength = useRef(addMessage?.length); // Initialize with the current length
  const [audioPath, setAudioPath] = useState('');
  const [reply, setReply] = useState({
    replyText: '',
    replyId: null,
  });
  const [imagePath, setImagePath] = useState([]);
  const [canReply, setCanReply] = useState(false);
  
  const [selectedFiles, setSelectedFiles] = useState([]);
  const lastSeen = useSelector(state => state.add.lastSeen);
  const [isProcessingQueue, setIsProcessingQueue] = useState(false);
  const [conversationLastSeen, setConversationLastSeen] = useState('');
  const [isSendConversationCalled, setIsSendConversationCalled] =
    useState(false);
  const [referObject, setReferObject] = useState({});
  const [prevAddmessageLength, setPrevAddMessageLength] = useState();
  const [failedMessagesArray, setFailedMessagesArray] = useState(
    activeUserData.failedMessagesArray
      ? activeUserData.failedMessagesArray
      : [],
  );

  const [showTemporaryMessage, setShowTemporaryMessage] = useState(false)
  const [temporaryMessage, setTemporaryMessage] = useState(null)
  const [sortedAddMessages, setSortedAddmessage] = useState([])

 
  // useEffect(() => {

  //     async function tempFunction() {
  //         //console.log("]]]]");

  //         const asyncStorageData = await AsyncStorage.getItem('persist:input');
  //         const jsonData = JSON.parse(asyncStorageData);
  //         const newdata = convertToJSON(jsonData)
  //         if (newdata['messagesArray'][conversationId]) {

  //             setMMesagesArr(newdata['messagesArray']);
  //             const data = newdata['messagesArray'][conversationId] || []

  //             setAddMessage(data);
  //         }
  //         setLoading(false);
  //     }
  //     tempFunction();
  // }, []);

  useEffect(() => {
    if (lastSeen[conversationId]) {
      setConversationLastSeen(lastSeen[conversationId]);
    } else {
      const date = new Date();
      const dateInMilliseconds = date.getTime();
      const dateInSeconds = Math.floor(dateInMilliseconds / 1000);
      //console.log('date: -=-=-=-=-=-=-=-=-==-=-=-=-', dateInSeconds);
      setConversationLastSeen(dateInSeconds);
    }
  }, [lastSeen]);

  useEffect(()=>{
      dispatch(setLastSeen({ conversationId, timeStamp: Math.floor((new Date().getTime())/1000) }));
      //console.log(' (new Date().getTime())/1000: ',  (new Date().getTime())/1000);
  },[])

  const updationFunction = async () => {
    try {
      //console.log('isSendConversationCalled: ', isSendConversationCalled);
      const filteredMessagesObject = {};
      const sortedAddMessage = sortByTime(addMessage); // Assumes messages are sorted already
      const lastMessage = sortedAddMessage[sortedAddMessage.length - 1];
      //   console.log('lastMessage: ', lastMessage.id);
      //console.log("conversationLastSeen", conversationLastSeen);

      // // Iterate over messages in reverse
      // for (let i = sortedAddMessage.length - 1; i >= 0; i--) {
      //     const message = sortedAddMessage[i];
      //     //console.log('message: ', message.sender_id,activeUser);
      //     if (message.sender_id !== activeUser) {
      //         //console.log('message.timestamp: ', message['timestamp']['seconds']);

      //         // Filter messages based on timestamp and sender
      //         if (message['timestamp']['seconds'] > conversationLastSeen) {
      //             //console.log("=====][][][][][[][][");
      //             filteredMessagesObject[message.id] = message; // Store message with id as key
      //         }

      //         // Break loop if older message found
      //         if (message['timestamp']['seconds'] < conversationLastSeen) {
      //             //console.log(";;.;;;;;;;;;;;;;");
      //             break;
      //         }
      //     }
      // }

      // Proceed if there are filtered messages
      // console.log('Object.keys(filteredMessagesObject).length: ', (filteredMessagesObject));
      if (
        lastMessage.sender_id !== activeUser &&
        lastMessage['timestamp']['seconds'] > conversationLastSeen
      ) {
        try {
          const res = await updateMessageDelivered({
            messageId: lastMessage.id,
            userId: activeUser,
            conversationId,
            topicId: conversationId,
          });
          //   console.log(
          //     '{ messageId: lastMessage.id, usersId: activeUser, conversationId,topicId:conversationId }: ',
          //     {
          //       messageId: lastMessage.id,
          //       usersId: activeUser,
          //       conversationId,
          //       topicId: conversationId,
          //     },
          //   );

          //console.log('res:------------------------------------------------------------------- ', res);
          //console.log('Filtered messages sent: ', { messagesDataObject: filteredMessagesObject, usersId: activeUser, conversationId });
          //console.log('res:------------------------------------------------------------------- ', res);
          //console.log('Filtered messages sent: ', { messagesDataObject: filteredMessagesObject, usersId: activeUser, conversationId });

          if (res.data.status === 'success') {
          }
          dispatch(
            setLastSeen({
              conversationId,
              timeStamp: new Date().getTime() / 1000,
            }),
          );

          //console.log(filteredMessagesObject); // Log filtered messages
        } catch (error) {
          //console.error('Error in updateMessageDelivered:', error);
        }
      }
    } catch (error) {
      //console.error('Error in updationFunction:', error);
    } finally {
      setIsSendConversationCalled(false);
    }
  };

  useEffect(() => {

    // Check if the previous length is less than the current length
    if (prevAddMessageLength.current < addMessage.length) {
      setShowTemporaryMessage(false)
      setTemporaryMessage(null)

      updationFunction(); // Call the update function
    }

    // Update the ref value to the current length for the next render
    prevAddMessageLength.current = addMessage.length;
  }, [addMessage]); // Dependencies

  useEffect(() => {
    updationFunction();
  }, []);

  //   useEffect(() => {
  //     async function tempFunction() {
  //       if (messageConversationArray) {
  //         //console.log(
  //           '=======--=======--=======--=======--=======--=======--=======--=======--=======--=======--=======---',
  //           messagesArrayss[conversationId],
  //         );

  const [showFirstTimeDateContainer, setShowFirstTimeDateContainer] = useState(
    [],
  );
  let previousVariable;
  let lastMessageRenderedDate = null;

  // useEffect(() => {
  //     async function tempFunction() {
  //         const asyncStorageData = await AsyncStorage.getItem('persist:input');
  //         const jsonData = JSON.parse(asyncStorageData);
  //         const newdata = convertToJSON(jsonData)

  //         setMMesagesArr(newdata['messagesArray']);
  //         const data = newdata['messagesArray'][conversationId] || []
  //         setAddMessage(data);
  //         setLoading(false);
  //     }
  //     tempFunction();
  // }, []);

  // useEffect(() => {
  //     let lastMessageRenderedDate = null; // Track the last date

  //     const data = addMessage.map((message) => {
  //         const date = new Date(message.timestamp)// Assuming message is available or passed as a prop
  //         const onlyDate = date.getDate()

  //         const messageDate = new Date(message.timestamp);

  //         if (lastMessageRenderedDate === null) {
  //             // First message, show the date
  //             lastMessageRenderedDate = onlyDate;
  //             return 'show';
  //         }

  //         if (onlyDate > lastMessageRenderedDate) {
  //             lastMessageRenderedDate = new Date(message.timestamp).getDate()
  //             return 'show'
  //         } else {
  //             lastMessageRenderedDate = new Date(message.timestamp).getDate()
  //             return 'notShow'
  //         }
  //     }
  //     )
  //     //console.log(data);
  //     setShowFirstTimeDateContainer(data)

  // }, [addMessage]);
  //   useEffect(() => {
  //     async function tempFunction() {
  //       if (messageConversationArray) {
  //         //console.log(
  //           '=======--=======--=======--=======--=======--=======--=======--=======--=======--=======--=======---',
  //           messagesArrayss[conversationId],
  //         );

  //         setAddMessage(messagesArrayss[conversationId]);

  //         scrollViewRef.current?.scrollToEnd({animated: true});
  //       } else {
  //         setAddMessage([]);
  //       }
  //     }

  //     tempFunction();
  //   }, [messagesArrayss]);

  useEffect(() => {
    async function tempFunction({ key, value }) {
        const asyncStorageData = localStorage.getItem('persist:user');
        const jsonData = asyncStorageData ? JSON.parse(asyncStorageData) : null;
        const newdata = jsonData ? convertToJSON(jsonData) : {};
      //console.log('newdata: ', newdata['messagesArray'][conversationId]);
      if (key === 'messages') {
        setAddMessage(newdata[activeUser]['messages'][conversationId]);

        // console.log(
        //   `newdata[activeUser]['messages'][conversationId: `,
        //   newdata[activeUser]['messages'][conversationId],
        // );
        dispatch(
          queueUpdateData({
            type: 'userMessage',
            userUid: activeUser,
            updates: newdata[activeUser]['messages'][conversationId],
            conversationId,
            append: false,
          }),
        );
        setMesssageArrived(true);
      }

      // if (key === 'messagesRef') {
      //     const newValueOfVariable = value['messageRef']

      //     const messagesRefLength = (messageReduxRef[conversationId]).length
      //     const messagesArrayLength = (newdata['messagesArray'][conversationId]).length
      //     if (messagesRefLength === messagesArrayLength) {
      //         //here we will call the cloud function to set the user id in the viewer of the message doc ref
      //     }
      // }
    }

    // Listen for the storageChange event
    storageEvents.on('storageChange', tempFunction);

    // Clean up the listener when the component unmounts
    return () => {
      storageEvents.off('storageChange', tempFunction);
    };
  }, []);

  useEffect(() => {
    if (goBottom && messageArrived) {
      scrollViewRef.current?.scrollToEnd({ animated: true });
      setMesssageArrived(false);
    }
  }, [messageArrived, goBottom]);

  useEffect(() => {
    if (userData[activeUser]['messages']) {
      //    console.log(
      //        '><SLASO',
      //        userData[activeUser]['messages'][conversationId],
      //        '><<',
      //      );
      setMMesagesArr(userData[activeUser]['messages']);
      const data = userData[activeUser]['messages'][conversationId] || [];
      setConversation(userData[activeUser]['conversations'][conversationId]);
      setAddMessage(data);

      matchedViewersRef.current = {}; // Reset the ref to an empty object
      setLoading(false);
    }
  }, [userData]);

  const fetchMessagesByReferences = async (fetchMore=true) => {
    try {
      if (fetchMore) {
        setLoadingMore(false);
      } else {
        setLoading(true);
      }
      if (messageReduxRef[conversationId].length == 0) {
        return;
      }
      // Get the message references from Redux
      if (messageReduxRef && messageReduxRef[conversationId]) {
        // Array of message references
        let messageRefs = messageReduxRef[conversationId];
        messageRefs = messageRefs.filter(
          messageRef => !messageRef.deleted || !messageRef.deleted.includes(activeUser)
        );
        messageRefs = sortByTime(messageRefs);
        // messageRefs = messageRefs;

        // Get the existing messages from Redux (messageArray)
        const messagesFromRedux = messagesArrayss ? messagesArrayss[conversationId]
          ? messagesArrayss[conversationId].map(message => message.id)
          : [] : [] // Extract IDs from Redux messageArray or set empty array if conversationId doesn't exist


        // Filter messageRefs that don't exist in Redux (to avoid fetching already present messages)
        const messageRefsToFetch = messageRefs.filter(
          ref => !messagesFromRedux.includes(ref.messageRef),
        );
        // //console.log('dddddddddddd', messageRefsToFetch);
        if (messageRefsToFetch.length === 0) {
          //console.log('All messages are already fetch..');
          if (fetchMore) {
            setLoadingMore(false);
          } else {
            setLoading(false);
          }
          return; // Skip fetching since all messages are already fetched
        }

        // Calculate the messages to fetch
        const messageBatchSize = 17;
        const startIndex =
          fetchMore && lastVisible !== null
            ? Math.max(lastVisible - messageBatchSize, 0) // fetch messages before lastVisible
            : Math.max(messageRefsToFetch.length - messageBatchSize, 0); // initial or default case

        const endIndex =
          fetchMore && lastVisible !== null
            ? lastVisible
            : messageRefsToFetch.length; // fetch from the start or full list for the first time

        const extractedMessageRefs = messageRefsToFetch
          .slice(startIndex, endIndex)
          .map(ref => ref.messageRef);

        //console.log('extractedMessageRefs: ', extractedMessageRefs);
        // Fetch the messages using the references
        const messageQuery = query(
            collection(firestore, "messages"),
            where(documentId(), "in", extractedMessageRefs)
        );

        const messageFetch = await getDocs(messageQuery);

        // ✅ Map the fetched documents into a structured array
        const messageFetched = messageFetch.docs.map(doc => ({
            id: doc.id,  // Store the document ID
            ...doc.data() // Spread the document data
        }));

        // ✅ Sort messages by timestamp (Assuming you have a `sortByTime` function)
        const messageSorted = sortByTime(messageFetched);

        if (fetchMore) {
            // ✅ Reset matchedViewersRef
            matchedViewersRef.current = {};

            // ✅ Update local state (Prepend new messages)
            setAddMessage(prevMessages => [...messageSorted, ...prevMessages]);

            // ✅ Dispatch action to Redux
            dispatch(queueUpdateData({
                type: "userMessage",
                userUid: activeUser,
                updates: messageSorted,
                conversationId,
                append: true
            }));
        
        } else {
          // Set messages to local state (array format)
          //console.log('ffff');
          matchedViewersRef.current = {}; // Reset the ref to an empty object

          setAddMessage(prevMessages => [...messageSorted, ...prevMessages]);
          //console.log('fffff');

          // Dispatch to Redux with conversationId as key (object format)

          dispatch(
            queueUpdateData({
              type: 'userMessage',
              userUid: activeUser,
              updates: messageSorted,
              conversationId,
              append: false,
            }),
          );
        }

        // Update the last visible message
        setLastVisible(startIndex > 0 ? startIndex : null);
      }
    } catch (error) {
      toast.error("Error fetching messages. Please try again.", {
        position: "top-center",
        autoClose: 5000, // Closes after 5 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
    });
    }

    if (fetchMore) {
      setLoadingMore(false);
    } else {
      //   setLoading(false);
    }
  };

  const sortByTime = array => {
    // //console.log('array: ', array);
    const uniqueItems = new Map(); // Use a Map to store unique items by their `id`
    if (array.length == 0) {
      return [];
    }
    let temparr = [...array];
    const sortedArray = [...temparr]
      .map(item => {
        // Check if timestamp is a Firestore timestamp object (has `seconds` and `nanoseconds` properties)
        if (item.timestamp && typeof item.timestamp.seconds === 'number') {
          return item; // Return the item as is (already in Firestore timestamp format)
        }
        // Otherwise, handle as an ISO string or number (milliseconds)
        else {
          const date = new Date(item.timestamp);
          return {
            ...item,
            timestamp: {
              seconds: Math.floor(date.getTime() / 1000),
              nanoseconds: date.getMilliseconds() * 1e6,
            },
          };
        }
      })
      .sort(
        (a, b) =>
          a.timestamp.seconds - b.timestamp.seconds ||
          a.timestamp.nanoseconds - b.timestamp.nanoseconds,
      );

    // Loop through the sorted array and add unique items to the Map (removing duplicates by `id`)
    sortedArray.forEach(item => {
      if (item.id) {
        // If the item has an `id` field
        if (!uniqueItems.has(item.id)) {
          uniqueItems.set(item.id, item); // Add only if `id` is not already in the Map
        }
      } else {
        // If no `id`, treat it as a unique item
        uniqueItems.set(Math.random(), item); // Use a random key for items without an `id`
      }
    });

    // Convert the Map back to an array of unique items
    return Array.from(uniqueItems.values());
  };

  // Initial fetch of messages when the component mounts
  useEffect(() => {
    //console.log('=============================================================',);
    // Check if messagesArrayss[conversationId] exists and is not undefined or null
    const existingMessages = messagesArrayss
      ? messagesArrayss[conversationId]
      : [];

    // Extract IDs from Redux messageArray (if it exists)
    const messagesFromRedux =
      existingMessages?.length > 0
        ? existingMessages.map(message => message.id)
        : [];

    // Filter messageRefs that don't exist in Redux (to avoid fetching already present messages)
    const messageRefsToFetch = messageReduxRef[conversationId]
      ? messageReduxRef[conversationId].filter(
        ref => !messagesFromRedux.includes(ref.messageRef),
      )
      : [];

    if (messageRefsToFetch.length === 0) {
      //console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
      //console.log('All messages are already fetched, skipping fetch...');
      // setAddMessage(sortByTime(addMessage));
      setLoadingMore(false);
      //   setLoading(false);
    }
    setTimeout(() => {
      matchedViewersRef.current = {}; // Reset the ref to an empty object

      setFetching(false);
    }, 200);
  }, [conversationId]);

  useEffect(() => {
    fetchMessagesByReferences();
  }, []);

  useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener(
      'keyboardDidShow',
      () => {
        setKeyboardOpen(true);
      },
    );
    const keyboardDidHideListener = Keyboard.addListener(
      'keyboardDidHide',
      () => {
        setKeyboardOpen(false);
      },
    );

    // Cleanup the listeners when the component unmounts
    return () => {
      keyboardDidShowListener.remove();
      keyboardDidHideListener.remove();
    };
  }, []);
  useEffect(() => {
    // Scroll to the bottom whenever dependencies change
    scrollViewRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [imagePath, loading, fetching, messages]);

  const uploadFileToFirebase = async (file, storagePath) => {
    try {
      if (!file) throw new Error("No file selected!");
  
      const storageRef = ref(storage, storagePath);
      await uploadBytes(storageRef, file);
  
      // Get the file's download URL
      const downloadURL = await getDownloadURL(storageRef);
  
      return downloadURL; // Return the file URL
  
    } catch (error) {
      console.error("File upload failed:", error);
      throw new Error("File upload failed: " + error.message);
    }
  };

  const newMessage = async () => {
    if (
      message.trim() === '' &&
      audioPath === '' &&
      imagePath.length === 0 &&
      selectedFiles.length === 0
    )
      return;
    let resizedImages = [];
    // stat(imagePath[0])
    //               .then(info => console.log('File exists in this stat:', info))
    //               .catch(err => console.error('File does not exist:', err));
    // resizedImages = await resizeImage(imagePath);
    // console.log('resizedImages: ', resizedImages);
    // Capture the default values before clearing
    const defaultMessageData = {
      sender_id: activeUserid,
      type: 'private',
      name: 'You',
      text: message,
      audioPath: audioPath || '', // Set default audio path
      media: imagePath.length > 0 ? [...imagePath] : [], // Set default image paths
      files: selectedFiles.length > 0 ? [...selectedFiles] : [], // Set default selected files
      reply: reply,
      timestamp: Date.now(),
      viewers: [activeUser], // Empty viewers array for now
      reader: [],
      conversation_id: conversationId,
    };
  
    setShowTemporaryMessage(true)
    setTemporaryMessage({ ...defaultMessageData })
    const messageData = { ...defaultMessageData };
    
    if (imagePath.length > 0) {
      //console.log('dddd');
      resizedImages = await resizeImage(imagePath)
      //console.log('resizedImages: ', resizedImages);
    }
    
    // Handle media uploads (image, audio, files)
    const uploadTasks = [];
    const tempAudioPath = audioPath;
    const tempImagePath = resizedImages;
    const TempSelectedFiles = selectedFiles;
    
    // Clear input fields before starting uploads
    setMessage('');
    setAudioPath('');
    setImagePath([]);
    setSelectedFiles([]);
    setReply('');
    // Upload audio if it exists
    if (tempAudioPath) {
      uploadTasks.push(
        uploadFileToFirebase(
          tempAudioPath,
          `messages/audio/${Date.now()}_audio.mp3`
        ).then(fileData => {
          defaultMessageData.audioPath = fileData; // Update audio path in the message
          messageData.audioPath = fileData
        })
      );
    }
    
    // Upload images if they exist
    if (tempImagePath.length > 0) {
      const imageUploadPromises = tempImagePath.map((image, index) =>

        uploadFileToFirebase(
          image,
          `messages/media/${Date.now()}_image_${index}.jpg`
        ).then(url => url)
      );
      uploadTasks.push(
        Promise.all(imageUploadPromises).then(fileData => {
          console.log('fileData:++++++++++_____________________________++============ ', fileData);
          defaultMessageData.media = fileData; // Update image URLs in the message
          messageData.media = fileData
          console.log(' messageData.media: ',  messageData.media);
        })
      );
    }
    // console.log('  messageData.media: ',   messageData.media);
    // Upload selected files if they exist
    if (TempSelectedFiles.length > 0) {
      const fileUploadPromises = TempSelectedFiles.map(async (file, index) => {
        
        const fileUri = file.uri;
        const fileName = file.name; // Assuming 'name' exists in file object
        const fileSize = file.size; // Assuming 'size' exists in file object
        const filetype = file.type;
        

        const url = await uploadFileToFirebase(
          fileUri,
          `messages/files/${Date.now()}_file_${index}`
        ).then(url => url);
     
        return {url:url, name: fileName, size: fileSize, type: filetype };
      });
      uploadTasks.push(
        Promise.all(fileUploadPromises).then(fileData => {
          defaultMessageData.files = fileData; // Update file data in the message
          messageData.files = fileData
        })
      );
    }
    // Wait for all uploads to complete
    await Promise.all(uploadTasks);
    try {
      // Clear input fields before starting uploads
      setMessage('');
      setAudioPath('');
      setImagePath([]);
      setSelectedFiles([]);
      setReply('');
      const responseData = await generateRandomDocumentID();
      let documentId;
      if (responseData.status == 'success') {
        documentId = responseData.code;
        // const media = await moveFilesToMediaFolder(
        //   messageData.media,
        //   'image',
        // );
        // const file = await moveFilesToMediaFolder(
        //   messageData.files,
        //   'files',
        // );
        
        // messageData.media = media;
        // messageData.files = file;
        // setAddMessage(prevMessages => [
          //   ...prevMessages,
          //   {...messageData, id: documentId},
          // ]); // here we will add messageData
        dispatch(
          queueUpdateData({
            type: 'userMessage',
            userUid: activeUser,
            updates: [{ ...messageData, id: documentId }],
            conversationId,
            append: true,
          }),
        );

        dispatch(
          appendPendingMessageQueue({ ...defaultMessageData, id: documentId }),
        ); //here we will send default message data

      } else {
        throw new Error('Response status is not successful'); // This will be caught in the catch block
      }
    } catch (error) {
      console.log('error: ', error);
      Alert('error white creating document id for message');
      return;
    }
    
    // Immediately set the default message to `setAddMessage`
    //console.log("()()()()()");
  };
  
  const processMessageFromQueue = async messagePayload => {

    try {
      

      try {
        // Proceed with sending the message to Firestore
        const uploadTime = new Date().getTime();
        const documentId = messagePayload.id

        const res = await sendConversationMessage({
          senderId: activeUserid,
          conversationId: conversationId,
          messageObject: messagePayload, // Use updated local data
          timestamp: uploadTime,
          topicId: conversationId,
          messageDocumentId: documentId,
        });
      
       

        if (res.data.status === 'success') {
          const messageDocRef = res.data;
          const referObject = {
            messageRef: messageDocRef.messageId,
            timestamp: uploadTime,
          };
          dispatch(
            queueUpdateData({
              type: 'userMessageRefs',
              userUid: activeUser,
              updates: [referObject],
              conversationId,
              append: true,
            }),
          );
        } else {

          let newFailedMessageArray = [];
          const prev = [...failedMessagesArray]
          if (!prev.includes(messagePayload.id)) {
            newFailedMessageArray = [...prev, messagePayload.id]
          } else {
            newFailedMessageArray = prev
          }

          setFailedMessagesArray(
            newFailedMessageArray);
          dispatch(
            queueUpdateData({
              type: 'updateUserField',
              userUid: activeUser,
              updates: { failedMessagesArray: newFailedMessageArray },
            }),
          );
          //updatefailed Message array in the user data


          toast.error('Error sending message. Please try again.', {
            position: "top-center",
            autoClose: 5000, // Closes after 5 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
        }
      } catch (error) {
        let newFailedMessageArray = [];
        const prev = [...failedMessagesArray]
        if (!prev.includes(messagePayload.id)) {
          newFailedMessageArray = [...prev, messagePayload.id]
        } else {
          newFailedMessageArray = prev
        }

        setFailedMessagesArray(
          newFailedMessageArray);
        dispatch(
          queueUpdateData({
            type: 'updateUserField',
            userUid: activeUser,
            updates: { failedMessagesArray: newFailedMessageArray },
          }),
        );






        // await updateFailedMessage(messagePayload.id)
      }
      matchedViewersRef.current = {}; // Reset the ref to an empty object

      setLoading(false);
      return true;
    } catch (error) {
      matchedViewersRef.current = {}; // Reset the ref to an empty object

      setLoading(false);
      toast.error(  'Error sending message. Please try again.', {
        position: "top-center",
        autoClose: 5000, // Closes after 5 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
    });
      return false;
    }
  };

  
  const moveFilesToMediaFolder = async (files, type) => {
    // console.log('files: ""{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{}}}}}}}}}}}}}}}}}}}}', files);
    // try {
    //     const destinationDirectory = `StudyBuddy/${type === 'image' ? 'StudyBuddy-images' : 'StudyBuddy-files'}/sent`;

    //     const processFile = async (file) => {
    //         let fileName;
    //         let blob;

    //         console.log("Processing file:", file); // Debugging

    //         // If file is a File object (from input upload)
    //         if (file instanceof File) {
    //             fileName = file.name;
    //             blob = file;
    //         }
    //         // If file is a Blob URL (e.g., blob:http://localhost:3000/...)
    //         else if (typeof file === 'object' && file.uri?.startsWith("blob:")) {
    //             const response = await fetch(file.uri);
    //             blob = await response.blob();
    //             fileName = file.name || `downloaded_${Date.now()}.${file.type.split("/")[1] || "file"}`;
    //         }
    //         // If file is a URL (remote image)
    //         else if (typeof file === 'string' && file.startsWith("http")) {
    //             const response = await fetch(file);
    //             if (!response.ok) throw new Error(`Failed to fetch file: ${file}`);
    //             blob = await response.blob();
    //             fileName = `downloaded_${Date.now()}.${blob.type.split("/")[1] || "file"}`;
    //         }
    //         // Unsupported file format
    //         else {
    //             console.error("Unsupported file format received:", file);
    //             throw new Error("Unsupported file format.");
    //         }


    //         // Create a downloadable link (to simulate file storage)
    //         const url = blob;
    //         // const a = document.createElement("a");
    //         // a.href = url;
    //         // a.download = fileName;
    //         // document.body.appendChild(a);
    //         // a.click();
    //         // document.body.removeChild(a);
    //         // URL.revokeObjectURL(url); // Cleanup URL to free memory

    //         return url;
    //     };

    //     if (Array.isArray(files)) {
    //         return await Promise.all(files.map(processFile));
    //     } else {
    //         throw new Error("Expected an array of files or URIs.");
    //     }
    // } catch (error) {
    //     console.error("Error moving file(s):", error);
    //     return null;
    // }
};

  useEffect(() => {
    if (!isProcessingQueue && pendingMessagesQueue?.length > 0) {
      processPendingMessagesQueue();
    }
  }, [pendingMessagesQueue]); // Dependency on pendingMessagesQueue

  const processPendingMessagesQueue = async () => {
    // Prevent re-entry
    setIsProcessingQueue(true);

    const pendingQueue = [...pendingMessagesQueue]; // Copy queue
    // console.log('pendingQueue:-=-= ', pendingQueue);

    for (const message of pendingQueue) {
      const success = await processMessageFromQueue(message);
      // console.log('success:=-=-=98 ', success);

      if (success) {
        // Remove the successfully processed message from the queue
        dispatch(removeMessageFromQueue(message));
      } else {
        // Optionally, retry or mark the message as failed
      }
    }

    // Reset processing flag
    setIsProcessingQueue(false);
  };

  const onCardSelect = (index, prevMessageId) => {
    Vibration.vibrate([0, 30]);

    if (selectedCards.includes(index)) {
      // If the card is already selected, deselect it
      const updatedSelection = selectedCards.filter(i => i !== index);
      const prevUpdatedSelection = prevCardOfSelectedCard.filter(i => i !== prevMessageId)

      setPrevCardOfSelectedCard(prevUpdatedSelection)
      setSelectedCards(updatedSelection);

      if (updatedSelection.length === 0) {
        setSelectionMode(false); // Exit selection mode if no card is selected
      }
    } else {
      // Select the card
      setPrevCardOfSelectedCard([...prevCardOfSelectedCard, prevMessageId])
      setSelectedCards([...selectedCards, index]);
    }
  };

  const onCardLongPress = (index, prevMessageId) => {
    if (!selectionMode) {
      setSelectionMode(true); // Enter selection mode
      onCardSelect(index, prevMessageId); // Select the card that was long pressed
    }
  };

  const onPressTextInput = () => {
    if (scrollViewRef.current) {
        scrollViewRef.current.scrollIntoView({ behavior: 'smooth' });
    }
};

  const copyText = () => {
    let textMessage = addMessage.find(item => item.id === selectedCards[0]);
    if (!textMessage) return; // Handle case where message is not found

    const textToCopy = textMessage.text;

    // ✅ Copy to Clipboard using browser API
    navigator.clipboard.writeText(textToCopy)
        .then(() => {
            // ✅ Show success toast using a simple alert or a custom toast component
            alert('p Copied!'); 
        })
        .catch(err => console.error('Failed to copy:', err));
};

  const makeReply = () => {
    let replyMessage = addMessage.find(item => item.id === selectedCards[0]);
    let replyText = replyMessage.text;
    if (!replyText) {
      if (replyMessage.media.length != 0) {
        replyText = `${replyMessage.media.length} media`;
      } else if (replyMessage.files.length != 0) {
        replyText = `${replyMessage.files.length} files`;
      } else if (replyMessage.audioPath) {
        replyText = `audio message`;
      }
    }
    setCanReply(true);
    setReply({
      replyText: replyText,
      replyId: selectedCards[0], // Assuming you're selecting only one message for reply
    });

    setSelectionMode(false);
    setPrevCardOfSelectedCard([])
    setSelectedCards([]);
  };
  const swipeReply = id => {
    let replyMessage = addMessage.find(item => item.id === id);
    let replyText = replyMessage.text;
    if (!replyText) {
      if (replyMessage.media.length != 0) {
        replyText = `${replyMessage.media.length} media`;
      } else if (replyMessage.files.length != 0) {
        replyText = `${replyMessage.files.length} files`;
      } else if (replyMessage.audioPath) {
        replyText = `audio message`;
      }
    }
    setCanReply(true);
    setReply({
      replyText: replyText,
      replyId: id, // Assuming you're selecting only one message for reply
    });
  };
  const onReplyPress = id => {
    // scrollViewRef.current?.scrollTo({ y: id * 50, animated: true });

    setSelectionMode(true); // Enter selection mode
    onCardSelect(id); // Select the card that was long pressed

    // Remove the card after 2 seconds
    // setTimeout(() => {
    //     setSelectedCards(prevSelected => prevSelected.filter(cardId => cardId !== id));
    // }, 1200);
  };
  useEffect(() => {
    if (reply.replyText == '') {
      setCanReply(false);
    }
  }, [reply]);
  const deleteMessage = () => {
    matchedViewersRef.current = {}; // Reset the ref to an empty object


    setAddMessage(addMessage.filter(item => !selectedCards.includes(item.id)));
    addFieldToMessages();
    dispatch(
      deleteUserMessages({
        userUid: activeUser,
        conversationId,
        ids: selectedCards,
      }),
    );
    setSelectionMode(false);
    setPrevCardOfSelectedCard([])
    setSelectedCards([]);
  };





  const addFieldToMessages = async () => {
    const batch = writeBatch(firestore);
        const newFieldData = { deleted: [activeUserid] };

    // Update each selected card in the 'messages' collection
    selectedCards.forEach((id) => {
      const messageRef = doc(firestore, "messages", id); // Get Firestore document reference
      batch.update(messageRef, newFieldData); // Update each document
  });

  const conversationRef = doc(firestore, "conversations", conversationId); // Get Firestore document reference

    try {

      const conversationSnapshot = await getDoc(conversationRef); // Fetch document

      if (!conversationSnapshot.exists()) {
          console.error("❌ Conversation does not exist.");
          return null;
      }      const conversationData = conversationSnapshot.data();
      let updatedMessageViewersObject;
      if (conversationData && conversationData.messages) {
        // Copy the 'messageViewersObject' for updates
        updatedMessageViewersObject = { ...conversationData.messageViewersObject }; // Viewer object at conversation level

        // Update viewers for selected cards
        selectedCards.forEach((cardId, index) => {
          const prevId = prevCardOfSelectedCard[index] || null; // Previous ID for the selected card

          // Update 'messageViewersObject' by replacing values with prevId
          Object.keys(updatedMessageViewersObject).forEach(userId => {
            if (userId !== activeUserid && updatedMessageViewersObject[userId] === cardId && prevId) {
              updatedMessageViewersObject[userId] = prevId; // Replace with previous message ID
            }
          });
        });

        // Update 'deleted' field for messages
        const updatedMessages = conversationData.messages.map(message => {
          if (selectedCards.includes(message.messageRef)) {
            let prevDeletedMessages = message.deleted ? message.deleted : [];
            message = { ...message, deleted: [...prevDeletedMessages, activeUserid] };
          }
          return message; // Return updated message
        });

        // Update both 'messages' and 'messageViewersObject' in Firestore
        batch.update(conversationRef, {
          messages: updatedMessages,
          messageViewersObject: updatedMessageViewersObject, // Update the viewers at conversation level
        });
      }
      //   console.log('activeUserData.conversations: ', activeUserData.conversations);
      if (activeUserData.conversations && updatedMessageViewersObject) {

        const allConversationData = { ...activeUserData.conversations }
        const prevConversationData = { ...allConversationData[conversationId] }

        prevConversationData['messageViewersObject'] = updatedMessageViewersObject //updating the data in the prevConversationData
        allConversationData[conversationId] = prevConversationData //updating the conversation data in all conversations present in the redux

        dispatch(
          queueUpdateData({
            type: 'updateUserField',
            userUid: activeUser,
            updates: { 'conversations': allConversationData },
          }))

      }
      // Commit the batch write to apply all changes atomically
      await batch.commit();
      console.log('Messages and conversation updated successfully');
    } catch (error) {
      console.error('Error updating messages and conversation: ', error);
    }
  };


  useEffect(() => {
    const sorted = sortByTime(addMessage)
    setSortedAddmessage(sorted)
  }, [addMessage])

  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;

    // Check if the user has scrolled to the bottom
    const isBottom = scrollTop + clientHeight >= scrollHeight - 20;
    setGoBottom(isBottom);

    // If the user is not at the bottom, mark that a new message has not arrived
    if (!isBottom) {
        setMesssageArrived(false);
    }

    // If the user scrolls to the top and more messages can be loaded, fetch more messages
    if (scrollTop === 0 && !loadingMore && lastVisible !== null) {
        fetchMessagesByReferences(true);
    }
};

  const goDown = () => {
    setGoBottom(true);
    setMesssageArrived(false);

    scrollViewRef.current?.scrollTo({
      top: scrollViewRef.current.scrollHeight,
      behavior: "smooth", // Animated scrolling
  });  };
  const addUser = id => {
    if (selectedUserCards.includes(id)) {
      // If the card is already selected, deselect it
      const updatedSelection = selectedUserCards.filter(i => i !== id);
      setSelectedUserCards(updatedSelection);
    } else {
      // Select the card
      setSelectedUserCards([...selectedUserCards, id]);
    }
  };
  const closeModal = () => {
    setShowForward(false);
    if (deletionMode) {
      deleteMessage();
    }
  };
  return (
    <div style={{ flex: 1  ,}}>
      <div
        style={{
          width: width,
          height: 60,
          backgroundColor: '#21c063',
          zIndex: 5,
          flexDirection: 'row',
          alignItems: 'center',
          paddingHorizontal: 7,
          display: 'flex',
        }}>
        <CustomConfirmationModal
          width={'80%'}
          visible={showForward}
          onCancel={() => (setShowForward(false), setDeletionMode(false))}
          onConfirm={closeModal}>
          <>
            <p
              style={{ color: 'black', fontSize: 20, fontFamily: 'MarkerFelt' }}>
              {deletionMode ? 'Confirm Deletion' : 'Forward Message'}
            </p>
            <div
              style={{
                backgroundColor: 'lightgrey',
                maxHeight: 300,
                width: '100%',
                borderRadius: 20,
                paddingVertical: 20,
                marginBottom: 10,
              }}>
              {deletionMode ? (
                <div
                  style={{
                    padding: 10,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <p style={{ color: 'black', fontSize: 15 }}>
                    If you confirm deletion then it only delete from your side
                  </p>
                </div>
              ) : (
                <div style={{ display: "flex", flexDirection: "column", rowGap: "14px" , }}>
                  {Object.keys(allConversation).map((item, index) => {
                    const conversation = allConversation[item];
                    return (
                      <div
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          paddingHorizontal: 20,
                          display:"flex"
                        }}
                        key={index}
                        //
                        onClick={() => addUser(index)}>
                        <div
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            columnGap: 10,
                            display:"flex",
                            width: '60%',
                          }}>
                          <img
                            src={require('../../../assets/images/mehak.webp')}
                            style={{
                              width: 50,
                              height: 50,
                              borderRadius: 50,
                              borderWidth: 1,
                              borderColor: 'black',
                            }}
                          />
                          <p
                            numberOfLines={1}
                            style={{ color: 'black', fontSize: 18 }}>
                            {conversation.admins[0]}
                          </p>
                        </div>
                        <div style={{ alignItems: 'center' }}>
                          {selectedUserCards.includes(index) ? (
                            <img
                              style={{ width: 20, height: 20 }}
                              src={require('../../../assets/images/black-check-box-with-white-check.png')}
                            />
                          ) : (
                            <img
                              style={{ width: 20, height: 20 }}
                              src={require('../../../assets/images/checkBox.png')}
                            />
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </>
        </CustomConfirmationModal>
        <div  onClick={() => navigate("/conversation")}>
          <img
            style={{ width: 27, height: 27 }}
            src={require('../../../assets/images/left-arrow.png')}
          />
        </div>
        <div
          style={{ flexDirection: 'row', alignItems: 'center', columnGap: 5 , display:"flex" }}
          
          onClick={() =>
          navigate('/Whatsappcard', { id: conversationId })
          }>
          <div
            style={{
              width: 50,
              height: 50,
              borderRadius: 80,
              overflow: 'hidden',
              marginHorizontal: 5,
              borderWidth: 1,
            }}>
            <img
              style={{ width: '100%', height: '100%' }}
              src={require('../../../assets/images/mehak.webp')}
              objectfit="cover"
            />
          </div>
          <div>
            <p
              style={{ color: 'black', fontSize: 20, fontFamily: 'MarkerFelt' }}>
              Mehakdeep singh
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          // paddingTop: 60,
          overflowY: 'hidden',  
        }}>
        <img
          src={
            conversation.background_image === 'first'
              ? require('../../../assets/images/mehak.webp')
              : conversation.background_image === 'second'
                ? require('../../../assets/images/mehak.webp')
                : { uri: decodeURIComponent(conversation.background_image) }
          }
          style={{ width: '100%', height: '100%' , }}
        />
      </div>
      {!goBottom && (
        <div
          
          onClick={goDown}
          style={{
            width: 30,
            height: 30,
            backgroundColor: 'white',
            bottom: keyboardOpen ? '14%' : '8%',
            position: 'absolute',
            zIndex: 10,
            right: '4%',
            justifyContent: 'center',
            alignItems: 'center',
            display:"flex",
            borderRadius: 20,
          }}>
          {messageArrived && (
            <div
              style={{
                backgroundColor: 'green',
                width: 10,
                height: 10,
                position: 'absolute',
                top: 0,
                right: 0,
                borderRadius: 20,
              }}
            />
          )}
          <img
            src={require('../../../assets/images/double-arrows.png')}
            style={{ width: '70%', height: '70%' }}
          />
        </div>
      )}
      {selectionMode && (
        <div
          style={{
            position: 'absolute',
            flex: 1,
            display:"flex",
            flexDirection:"column",
            backgroundColor: '#21c063',
            height: 60,
            width: '100%',
            zIndex: 20,
          }}>
          <div
            style={{
              height: 60,
              flex: 1,
              display:"flex",
              flexDirection:"column",
              width: '100%',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingHorizontal: 20,
            }}>
            <div
              style={{ height: '40%', width: '10%' }}
           
              onClick={() => (setSelectionMode(false), setPrevCardOfSelectedCard([]), setSelectedCards([]))}>
              <img
                src={require('../../../assets/images/cross.png')}
                style={{ width: '100%', height: '100%', objectfit: 'contain' }}
              />
            </div>
            <div style={{ marginRight: '20%' }}>
              <p style={{ color: 'black', fontSize: 23 }}>
                {selectedCards.length}
              </p>
            </div>
            <div
              style={{
                height: '50%',
                width: '60%',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                columnGap: 20,
                display:"flex",
              }}>
              <div
               
                style={{ width: 30 }}
                onClick={() => setShowForward(true)}>
                <img
                  src={require('../../../assets/images/forward.png')}
                  style={{ width: '100%', height: '100%', objectfit: 'contain' }}
                />
              </div>
              <div
                style={{ width: 30 }}
               
                onClick={() => (setShowForward(true), setDeletionMode(true))}>
                <img
                  src={require('../../../assets/images/dustbin.png')}
                  style={{ width: '100%', height: '100%', objectfit: 'contain' }}
                />
              </div>
              {selectedCards.length < 2 && (
                <div
               
                  onClick={copyText}
                  style={{ width: 30 }}>
                  <img
                    src={require('../../../assets/images/copy.png')}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectfit: 'contain',
                    }}
                  />
                </div>
              )}
              {selectedCards.length < 2 && (
                <div
               
                  onClick={makeReply}
                  style={{ width: 30 }}>
                  <img
                    src={require('../../../assets/images/reply.png')}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectfit: 'contain',
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
        <div
      ref={scrollViewRef}
      onScroll={handleScroll}
      style={{
        marginBottom: "60px",
        marginHorizontal: "8px",
        display: "flex",
        flexDirection: "column",
        overflowY: "scroll", // Enables scrolling
        maxHeight: "500px", // Adjust as needed
        // justifyContent: "flex-end",
      
      }}
    >
         {loadingMore && (
          <div style={{ textAlign: "center", margin: "20px" }}>
            <LoadingSpinner />
          </div>
        )}
        {false && addMessage?.length ? (
       
          <ActivityIndicator
            size="large"
            color="#0000ff"
            style={{ marginTop: 20 }}
          />
        ) : (
          <>
            {
              sortedAddMessages.map((message, index) => {
                if (index == 0) {
                  matchedViewersRef.current = {};
                }

                const date = new Date(message.timestamp);
                const onlyDate = date.getDate();
                let variablett;
                const messageDate = new Date(message.timestamp);

                if (onlyDate > lastMessageRenderedDate || lastMessageRenderedDate === null) {
                  lastMessageRenderedDate = onlyDate;
                  variablett = 'show';
                } else {
                  lastMessageRenderedDate = onlyDate;
                  variablett = 'notShow';
                }

                // Track previous rendered message ID
                const prevMessageId = index > 0 ? sortedAddMessages[index - 1].id : null; // Previous message ID or null if it's the first message

                // Initialize viewers array
                const viewers = message.viewers ? [...message.viewers] : [];
                const messageViewersObject = conversation.messageViewersObject || {};
                if (messageViewersObject && typeof messageViewersObject === 'object') {
                  let newMessageViewersObject = { ...messageViewersObject };
                  delete newMessageViewersObject[activeUser];
                }
                

                Object.entries(messageViewersObject).forEach(([viewerUserId, viewerMessageId]) => {
                  if (!matchedViewersRef.current[viewerUserId] && viewerMessageId) {
                    if (!viewers.includes(viewerUserId)) {
                      viewers.push(viewerUserId);
                    }

                    if (viewerMessageId === message.id && viewerMessageId) {
                      matchedViewersRef.current[viewerUserId] = true;
                    }
                  }
                });

                //   console.log('viewers:------------- ', message.media);
                const messageCard = (
                  <MessageCard
                    key={index}
                    senderID={message.sender_id}
                    multiSender={message.type}
                    name={message.name}
                    messageText={message.text}
                    timestamp={message.timestamp}
                    viewers={viewers}
                    failedMessagesArray={failedMessagesArray}
                    audioPath={message.audioPath}
                    media={message.media}
                    files={message.files}
                    id={message.id}
                    selected={selectedCards.includes(message.id)}
                    onSelect={() => onCardSelect(message.id, prevMessageId)} // Pass current and previous message IDs
                    onLongPress={() => onCardLongPress(message.id, prevMessageId)} // Pass current and previous message IDs
                    selectionMode={selectionMode}
                    reply={message.reply}
                    onReply={swipeReply}
                    onReplyPress={onReplyPress}
                    canReply={canReply}
                    delivered={message.delivered}
                    showFirstTimeDateContainer={variablett}
                    previousIndex={previousVariable}
                    participants={participantsUids}
                  />
                );

                previousVariable = variablett;

                return !fetching && messageCard;
              })

            }
            {
              (showTemporaryMessage && temporaryMessage &&
                <MessageCard
                  key={999}
                  senderID={temporaryMessage.sender_id}
                  multiSender={temporaryMessage.type}
                  name={temporaryMessage.name}
                  messageText={temporaryMessage.text}
                  timestamp={temporaryMessage.timestamp}
                  viewers={[]} // Updated viewers array
                  failedMessagesArray={[]}
                  audioPath={temporaryMessage.audioPath}
                  media={temporaryMessage.media}
                  files={temporaryMessage.files}
                  id={temporaryMessage.id}
                  selected={selectedCards.includes(temporaryMessage.id)}
                  onSelect={() => onCardSelect(temporaryMessage.id)} // Handle single press
                  onLongPress={() => onCardLongPress(temporaryMessage.id)} // Handle long press
                  selectionMode={selectionMode}
                  reply={temporaryMessage.reply}
                  onReply={swipeReply}
                  onReplyPress={onReplyPress}
                  canReply={canReply}
                  delivered={temporaryMessage.delivered}
                  showFirstTimeDateContainer={'notShow'}
                  previousIndex={previousVariable}
                  participants={participantsUids}
                />
              )
            }
          </>

        )}
      </div>
    
      <div style={{  position: "relative",
      bottom: 0,
      width: "100%",
      background: "#fff",
      padding: "10px",
      boxShadow: "0 -2px 5px rgba(0, 0, 0, 0.1)",
      display: "flex",
      justifyContent: "center", }}>

          <SenderInput
            message={message}
            setMessage={setMessage}
            onSend={newMessage}
            onPressTextInput={onPressTextInput}
            setAudioPath={setAudioPath}
            setImagePath={setImagePath}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            reply={reply}
            setReply={setReply}
          />
        </div>

        
      
    </div>
  );
};

export default MessageDemo;

// const createConversationAndAddFriend = async (senderId, receiverId) => {
//   try {
//     const db = firebase.firestore();
//     const batch = db.batch();

//     // References to the sender and receiver documents
//     const senderRef = db.collection('users').doc(senderId);
//     const receiverRef = db.collection('users').doc(receiverId);

//     // Fetch the user documents to get FCM tokens
//     const senderDoc = await senderRef.get();
//     const receiverDoc = await receiverRef.get();

//     if (!senderDoc.exists || !receiverDoc.exists) {
//       throw new Error("Sender or receiver not found");
//     }

//     // Extract FCM tokens from the user documents and decrypt them
//     const senderFcmTokenObject = senderDoc.data().fcmTokenObject || {};
//     const senderEncryptedFcmTokenArray = Object.keys(senderFcmTokenObject) || [];
//     const senderFcmTokenArray = senderEncryptedFcmTokenArray.map(item => decryptData(item, secretKey));

//     const receiverFcmTokenObject = receiverDoc.data().fcmTokenObject || {};
//     const receiverEncryptedFcmTokenArray = Object.keys(receiverFcmTokenObject) || [];

//     const receiverFirstName = receiverDoc.data().firstName;

//     if (senderEncryptedFcmTokenArray.length === 0 || receiverEncryptedFcmTokenArray.length === 0) {
//       throw new Error("FCM token not available for one or both users");
//     }

//     // Add senderId to receiver's friends array and vice versa
//     batch.update(receiverRef, {
//       friends: firebase.firestore.FieldValue.arrayUnion(senderId),
//       pendingRequest: firebase.firestore.FieldValue.arrayRemove(senderId),
//     });

//     batch.update(senderRef, {
//       friends: firebase.firestore.FieldValue.arrayUnion(receiverId),
//       sentRequest: firebase.firestore.FieldValue.arrayRemove(receiverId),
//     });

//     // Create a new conversation document
//     const conversationRef = db.collection('conversations').doc();
//     const conversationObject = {
//       id: conversationRef.id,
//       participants: {
//         [senderId]: senderFcmTokenObject,
//         [receiverId]: receiverFcmTokenObject,
//       },
//       createdAt: firebase.firestore.FieldValue.serverTimestamp(),
//       metadata: {
//         type: 'private',
//         createdOn: firebase.firestore.FieldValue.serverTimestamp(),
//         lastTime: firebase.firestore.FieldValue.serverTimestamp(),
//         lastMessage: 'StudyBuddy',
//       },
//       admins: 'admin',
//     };

//     batch.set(conversationRef, conversationObject);

//     // Add the conversation ID to both users' conversation arrays
//     batch.update(senderRef, {
//       conversations: firebase.firestore.FieldValue.arrayUnion(conversationRef.id),
//     });

//     batch.update(receiverRef, {
//       conversations: firebase.firestore.FieldValue.arrayUnion(conversationRef.id),
//     });

//     // Commit the batch
//     await batch.commit();

//     // Prepare FCM Data
//     const FCMData = JSON.stringify({
//       sliceName: 'users',
//       userIds: [senderId],
//       variableNamesArray: ['conversations'],
//       operationTypeObject: { 'conversations': 'update' },
//       nestedKeysArray: [conversationRef.id],
//       nestedOperationTypeObject: { [conversationRef.id]: 'update' },
//       newNestedValue: { [conversationRef.id]: [] },
//       newValuesObject: { 'conversations': [conversationRef.id] },
//     });

//     // Send the FCM notification
//     const response = await messaging().sendToDevice(senderFcmTokenArray, {
//       notification: {
//         title: 'Request Accepted',
//         body: `${receiverFirstName} has accepted your friend request`,
//       },
//       data: {
//         type: 'notification',
//         notificationType: 'type1',
//         notificationTitle: 'Request Accepted',
//         notificationBody: `${receiverFirstName} has accepted your friend request`,
//         targetScreen: 'Conversations',
//         FCMData,
//       },
//       android: {
//         priority: 'high',
//       },
//       apns: {
//         headers: {
//           'apns-priority': '10',
//         },
//         payload: {
//           aps: {
//             alert: {
//               title: 'Request Accepted',
//               body: `${receiverFirstName} has accepted your friend request`,
//             },
//             sound: 'default',
//           },
//         },
//       },
//     });

//     //console.log("Successfully sent message:", response);

//     return { status: 'success', message: 'Friend added and conversation created successfully', conversationObject };
//   } catch (error) {
//     //console.error("Error creating conversation or adding friends: ", error);
//     return { status: 'failure', message: error.message };
//   }
// };