import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

const ErrorCard = ({ func, buttonText, errorDescription }) => {
    const { t } = useTranslation();
  try {

    return (
      <div style={{ backgroundColor: 'white', borderRadius: '10px', padding: '10px', textAlign: 'center' }}>
        {/* Gradient at the top of the card */}
        <div style={{
          background: 'linear-gradient(to right, red, rgba(254, 180, 150, 0.3))',
          borderRadius: '10px',
          padding: '10px'
        }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', display:"flex" }}>
            <div style={{ width: '60%', display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
              <img src="/images/oooo.gif" alt="Loading" style={{ width: '90px', height: '90px' }} />
            </div>
            <div>
              <img src="/images/error1.gif" alt="Error" style={{ width: '100px', height: '80px' }} />
            </div>
          </div>

          <div style={{ margin: '5px 0' }}>
            <p style={{ color: 'black', fontFamily: 'Lora-BoldItalic' }}>
              **{t(errorDescription || 'Something went wrong')}**
            </p>
          </div>

          <div style={{ marginBottom: '10px', marginTop: '2px' }}>
            {/* Gradient for the Try Again button */}
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={() => func()}
              style={{
                background: 'linear-gradient(to right, #d71a1f, #ff5f6d)',
                border: 'none',
                color: 'white',
                padding: '10px 20px',
                borderRadius: '15px',
                cursor: 'pointer'
              }}
            >
              {t(buttonText || 'Try Again')}
            </motion.button>
          </div>
        </div>
      </div>
    );
  } catch (error) {
    console.error("Error rendering ErrorCard:", error);
  }
};

export default ErrorCard;
