import React, { useState, useEffect } from 'react';
import ChatCard from './chatCard';
import { useSelector, useDispatch } from 'react-redux';
import { updateUserField } from '../../reduxStore/slices/UsersSlice';
import { firestore } from '../../firebase';
import { collection, documentId, getDocs, query, where } from 'firebase/firestore';

const Apps = () => {
    
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
 
    // console.log('messageConversationArray: ', messageConversationArray);
    const userData = useSelector(state => state.user.users);
    const activeUser = useSelector(state => state.user.activeUser);
    const activeUserData = userData[activeUser]
    // console.log('activeUserData: ', activeUserData);
    // console.log('messagesArrayss: ', messagesArrayss);

    //     useEffect(()=>{

    //         console.log(messageConversationArray['83vCW2qq9PT3GBJWVVbz'],'fffffffffffffffffffffffffff______-----------------')
    // },[messagesArrayss])



    // const [message, setMessage] = useState([]);

    const conversationsFromRedux = activeUserData?.conversations


    const [conversations, setConversations] = useState(conversationsFromRedux);

    const dispatch = useDispatch();

    // Utility function to compare conversations data
    const areConversationsDifferent = (newConversations, oldConversations) => {
      
        const newKeys = Object.keys(newConversations);
        const oldKeys = Object.keys(oldConversations? oldConversations : [] );
        // Check if the number of conversations (keys) is different
        if (newKeys.length !== oldKeys.length) return true;

        // Iterate over the keys and compare each conversation by its ID
        for (let key of newKeys) {
            const newConv = newConversations[key];
            const oldConv = oldConversations[key];

            // If the conversation doesn't exist in oldConversations, it's different
            if (!oldConv) return true;

            // Check if metadata objects are different
            if (!areObjectsEqual(newConv.metadata, oldConv.metadata)) return true;

            // Check if messages arrays are different
            if (!areArraysEqual(newConv.messages, oldConv.messages)) return true;
        }

        return false; // No differences found
    };

    // Helper function to check if two objects are equal (shallow comparison)
    const areObjectsEqual = (obj1, obj2) => {
        return JSON.stringify(obj1) === JSON.stringify(obj2);
    };

    // Helper function to check if two arrays are equal (shallow comparison)
    const areArraysEqual = (arr1, arr2) => {
        return JSON.stringify(arr1) === JSON.stringify(arr2);
    };
    useEffect(() => {

        function convertToTimestamp(input) {
            let date;

            // Check if input is a number (assume milliseconds)
            if (typeof input === 'number') {
                date = new Date(input);
            }
            // Otherwise, assume it's an ISO string
            else {
                date = new Date(input);
            }

            // Step 1: Get the seconds from the timestamp (Unix epoch time in seconds)
            const seconds = Math.floor(date.getTime() / 1000);

            // Step 2: Extract milliseconds and convert to nanoseconds
            const nanoseconds = date.getMilliseconds() * 1000000; // Convert ms to ns

            // Return the timestamp object
            return {
                seconds: seconds,
                nanoseconds: nanoseconds,
            };
        }
        const fetchConversationsByDocArray = async (docIds) => {
            
            try {
                // Ensure docIds is an array
                if (!Array.isArray(docIds) || docIds.length === 0) {
                    throw new Error('docIds must be a non-empty array');
                }
        
                // Fetch documents using `where` with `in` operator
                const conversationsRef = collection(firestore, 'conversations');
        
                // ✅ Firestore query using `where` with `in`
                const q = query(conversationsRef, where(documentId(), 'in', docIds));
        
                const querySnapshot = await getDocs(q);
        
                // ✅ Create objects to store data
                let messages = {};
                let conversationsData = {};
        
                // ✅ Iterate over fetched documents
                querySnapshot.docs.forEach((doc) => {
                    const conversationData = doc.data();
                 
                    // Initialize the messageData for this conversation ID
                    const messageData = conversationData.messages.map((msgRef) => ({
                        messageRef: msgRef.messageRef,
                        timestamp: convertToTimestamp(Number(msgRef.timestamp)), // Ensure timestamp conversion
                    }));
                    // Store the conversation data
                    conversationsData[doc.id] = {
                        id: doc.id,
                        participants: conversationData.participants,
                        metadata: conversationData.metadata,
                        admins: conversationData.admins,
                        background_image: 'first', // Adding your custom field here
                        messageViewersObject:conversationData.messageViewersObject
                    };
        
                    // Store messages under the current conversation's ID in the `messages` object
                    messages[doc.id] = messageData;
                });
                    setConversations(conversationsData); // Update the component state with the new structure
                    
                // Compare and update the slice if the data has changed
                if (areConversationsDifferent(conversationsData, conversationsFromRedux)) {
                    dispatch(
                        updateUserField({ userUid: activeUser, updates: { conversations: conversationsData } })
                    );
                    // dispatch(setConversation({ conversation: conversationsData })); // Update the Redux slice with the new structure
                }
                dispatch(
                    updateUserField({ userUid: activeUser, updates: { messageRefs: messages } })
                );
                // Dispatch the messages to the messages slice
                // dispatch(setMessagesRef({ messagesRefs: messages })); // Store messages in the messages slice
        
            } catch (err) {
                console.log(err);
                // Handle errors appropriately, like setting an error state or logging
            } finally {
                setLoading(false);
            }
        };
        
        

        fetchConversationsByDocArray(activeUserData?.conversationsRef);
        // dispatch(clearMessagesArray())
        /////


    }, []);


    if (loading) {
        return (
            <div style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                {/* <ActivityIndicator size="large" color="#0000ff" /> */}
            </div>
        );
    }

    if (error) {
        return (
            <div style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <p style={{ color: 'black' }}>Error: {error}</p>
            </div>
        );
    }
    const formatTimestamp = (timestamp) => {
        let date;

        // Check if the timestamp is a Firestore timestamp (object with 'seconds' and 'nanoseconds')
        if (typeof timestamp === 'object' && 'seconds' in timestamp && 'nanoseconds' in timestamp) {
            date = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
        } else if (typeof timestamp === 'number') {
            // If timestamp is a number (milliseconds), directly convert to date
            date = new Date(timestamp);
        } else {
            throw new Error('Invalid timestamp format');
        }

        const now = new Date();
        const diffTime = now - date;
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

        if (diffDays === 0) {
            return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
        } else if (diffDays === 1) {
            return 'Yesterday';
        } else {
            return `${diffDays} day${diffDays > 1 ? 's' : ''} ago`;
        }
    };
console.log('conversations: ::::::::::::::::::::::::::::::::::::::', conversations);
    return (
        <div style={{ flex: 1, padding: 10 }}>
            {conversations &&
                Object.keys(conversations).map((item, index) => {
                    const conversation = conversations[item]; // Get the conversation data using the key (item)
        

                    return (
                    
                        <ChatCard
                            key={index}
                            name={conversation.admins[0]}  // Access the first admin's name
                            id={item}  // Use the document ID as the ID
                            time={formatTimestamp(conversation.metadata.lastTime)}  // Format the timestamp
                            profilePic={require('../../assets/images/profile.webp')}  // Profile picture
                            recentText={conversation.metadata.lastMessage}  // Access the last message
                            unreadmessage={0}  // Placeholder for unread messages
                            // messages={conversation.messages}  // Pass messages if needed
                        />
                    );
                })
            }
        </div>
    );
};

export default Apps;
