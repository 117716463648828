import React, { useState, useEffect, useRef } from "react";

const ReusableModal = ({ visible, onClose, children, heightPercent = 70 }) => {
    const SCREEN_HEIGHT = window.innerHeight;
    const DEFAULT_MODAL_HEIGHT_PERCENT = 0.7;
    const SWIPE_THRESHOLD = 100; // Minimum drag distance to close modal

    heightPercent = Number(heightPercent) / 100 || DEFAULT_MODAL_HEIGHT_PERCENT;
    const modalHeight = SCREEN_HEIGHT * heightPercent;

    const [modalTranslateY, setModalTranslateY] = useState(modalHeight);
    const [isDragging, setIsDragging] = useState(false);
    const modalRef = useRef(null);
    const startY = useRef(0);

    // Handle opening and closing animation
    useEffect(() => {
        if (visible) {
            setModalTranslateY(0);
        } else {
            setModalTranslateY(modalHeight);
        }
    }, [visible, modalHeight]);

    // Handle swipe down to close
    const handleMouseDown = (e) => {
        setIsDragging(true);
        startY.current = e.clientY;
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        const diff = e.clientY - startY.current;
        if (diff > 0) {
            setModalTranslateY(diff);
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
        if (modalTranslateY > SWIPE_THRESHOLD) {
            closeModal();
        } else {
            setModalTranslateY(0);
        }
    };

    // Handle keyboard adjustments (like React Native)
    useEffect(() => {
        const handleResize = () => {
            setModalTranslateY(0);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // Handle closing the modal
    const closeModal = () => {
        setModalTranslateY(modalHeight);
        setTimeout(onClose, 300); // Delay to match animation
    };

    return (
        visible && (
            <div style={styles.modalOverlay} onMouseUp={handleMouseUp} onMouseMove={handleMouseMove}>
                <div
                    ref={modalRef}
                    style={{
                        ...styles.modalContainer,
                        transform: `translateY(${modalTranslateY}px)`,
                        height: modalHeight,
                    }}
                    onClick={(e) => e.stopPropagation()} // Prevent overlay click from closing modal
                >
                    <div
                        style={styles.modalIndicator}
                        onMouseDown={handleMouseDown} // Drag start
                    />
                    <div style={{...styles.modalContent ,}}>{children}</div>
                </div>
            </div>
        )
    );
};

export default ReusableModal;

const styles = {
    modalOverlay: {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
        transition: "all 0.3s ease-in-out",
    },
    modalContainer: {
        backgroundColor:"grey",
        width: "100%",
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        padding: 20,
        position: "relative",
        transition: "transform 0.3s ease-in-out",
    },
    modalIndicator: {
        width: 80,
        height: 5,
        backgroundColor: "lightgrey",
        borderRadius: 10,
        margin: "0 auto 10px",
        cursor: "pointer",
    },
    modalContent: {
        maxHeight: "70vh",
        overflowY: "auto",
    },
};
