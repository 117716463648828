import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import AudioPlayer from '../../Conversation/player';
import DocViewer from '../../Conversation/DocViewer';
import CardStack from '../../Conversation/stack';

const MessageCard = ({ state, onReplyPress, canReply, ...props }) => {
  const width = window.innerWidth;
  const profileLogo = require('../../../assets/images/profile.webp');
  const navigation = useNavigate();
  const [visibleReply, setVisibleReply] = useState(false);
  const [uploading, setUploading] = useState(false);
  useEffect(()=>{

  } , [uploading]);
  const [doneReply, setDoneReply] = useState(false);
  const userData = useSelector(state => state.user.users);
  const activeUser = useSelector(state => state.user.activeUser);
  const activeUserid = userData[activeUser]['userUid'];
  const [imageExist, setImageExist] = useState(true);
  const hasAllValues = props.viewers
    ? props.participants.every(uid => props.viewers.includes(uid))
    : false;
  const failedMessagesArray = props.failedMessagesArray;

  const styles = {
    container: {
      backgroundColor: '#FFF',
      borderRadius: 12,
      width: '100%',
      paddingVertical: 10,
      paddingHorizontal: 10,
      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
      marginVertical: 10,
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
    },
    innerContainer: {
      backgroundColor: 'white',
      width: '98%',
      alignSelf: 'flex-end',
      borderRadius: 12,
      paddingVertical: 10,
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
    },
    profileLogo: {
      width: 40,
      height: 40,
    },
    senderName: {
      color: '#fff',
      fontFamily: 'MarkerFelt',
    },
    messagebox: {
      backgroundColor: props.senderID == activeUserid ? '#fff' : '#eef',
      alignSelf: props.senderID == activeUserid ? 'flex-end' : 'flex-start',
      padding: 20,
      borderRadius: 12,
      paddingHorizontal: 15,
      paddingVertical: 5,
      maxWidth: width * 0.69,
      display: 'flex',
      flexDirection: 'column',
      gap: '5px',
      marginVertical: 5,
      marginHorizontal: 8,
    },
    messageText: {
      color: 'black',
      alignSelf: 'flex-end',
      marginVertical: 3,
      fontSize: 14,
    },
    messageFooter: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '4px',
      alignSelf: 'flex-end',
    },
    timestamp: {
      color: 'grey',
      fontSize: 10,
      textAlign: 'right',
    },
    tickIcon: {
      width: 15,
      height: 15,
      filter: hasAllValues ? 'brightness(0) saturate(100%) invert(74%) sepia(20%) saturate(748%) hue-rotate(176deg) brightness(97%) contrast(95%)' : 'grey',
    },
    selectedOverlay: {
      position: 'absolute',
      backgroundColor: 'rgba(88, 199, 99, 0.8)',
      width: width / 1.04,
      height: '100%',
      left: 0,
      top: 0,
      zIndex: 0,
      borderRadius: 10,
    },
  };

  const translateX = useRef(0);
  const rotation = useRef(0);

  const MAX_TRANSLATE_X = 100;

  const handlePan = (e) => {
    const clampedTranslateX = Math.max(0, Math.min(e.movementX, MAX_TRANSLATE_X));
    translateX.current = clampedTranslateX;
    rotation.current = clampedTranslateX / MAX_TRANSLATE_X;

    if (e.movementX > 70) {
      setVisibleReply(true);
    } else {
      setVisibleReply(false);
    }
  };

  const handlePanEnd = (e) => {
    if (e.movementX > 70) {
      setDoneReply(true);
    }
    translateX.current = 0;
    rotation.current = 0;
  };

  useEffect(() => {
    if (visibleReply) {
      navigator.vibrate([0, 50]);
    }
  }, [visibleReply]);

  useEffect(() => {
    if (doneReply) {
      props.onReply(props.id);
    }
  }, [doneReply]);

  useEffect(() => {
    if (props.id) {
      setUploading(false);
    } else {
      setUploading(true);
    }
  }, [props.id]);
useEffect(()=>{
} , [props.id])
  useEffect(() => {
    if (!canReply) {
      setDoneReply(false);
    }
  }, [canReply]);

  const formatTimestamp = (timestamp) => {
    let date;

    if (typeof timestamp === 'string' || typeof timestamp === 'number') {
      date = new Date(timestamp);
    } else if (timestamp && typeof timestamp === 'object' && 'seconds' in timestamp && 'nanoseconds' in timestamp) {
      date = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
    } else if (timestamp instanceof Date) {
      date = timestamp;
    } else {
      throw new Error('Invalid timestamp format');
    }

    return date.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  };

  const date = new Date(props.timestamp).toDateString();

  return (
    <div style={{ display:"flex" , justifyContent:"space-between" , }}>
      {props.showFirstTimeDateContainer === 'show' && (props.previousIndex === 'notShow' || props.previousIndex === undefined) ? (
        <div
          style={{
            width: 130,
            height: 40,
            backgroundColor: '#021',
            alignSelf: 'center',
            borderRadius: 15,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            
          }}
        >
          <p style={{ color: 'white' }}>{date}</p>
        </div>
      ) : null}
      <motion.div
        style={{
          width: 32,
          height: 32,
          position: 'absolute',
          transform: `translateX(${translateX.current}px) rotate(${rotation.current * 360}deg)`,
          left: -70,
          top: 10,
          borderRadius: 20,
          backgroundColor: 'rgba(211, 211, 211, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          src={require('../../../assets/images/reply.png')}
          style={{ width: '80%', height: '80%', filter: 'brightness(0) invert(1)' }}
          alt="reply"
        />
      </motion.div>

      <motion.div
        onPan={handlePan}
        onPanEnd={handlePanEnd}
        style={{
          transform: `translateX(${translateX.current}px)`,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '5px',
            alignItems: 'flex-start',
            justifyContent: props.senderID == activeUserid ? 'flex-end' : 'flex-start',
            marginVertical: 0,
          }}
          onClick={props.selectionMode ? props.onSelect : null}
          onDoubleClick={props.onLongPress}
        >
          {props.selected && <div style={styles.selectedOverlay} />}

          {props.multiSender === 'group' && props.senderID !== activeUserid && (
            <img src={profileLogo} style={styles.profileLogo} alt="profile" />
          )}

          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
            {props.multiSender === 'group' && props.senderID !== activeUserid && (
              <p style={styles.senderName}>{props.name ? props.name : 'hanuman ji'}</p>
            )}
            <div style={styles.messagebox}>
              {props.audioPath ? (
                <div style={{ minWidth: '100%' }}>
                  <AudioPlayer audioFilePath={props.audioPath} />
                </div>
              ) : props.media && props.media.length !== 0 ? (
                <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', justifyContent: 'center' }}>
                  {props.media.length > 1 && (
                    <div
                      style={{
                        position: 'absolute',
                        padding: 6,
                        backgroundColor: 'white',
                        zIndex: 10,
                        top: 0,
                        right: 0,
                        margin: 10,
                        borderRadius: 10,
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                      }}
                    >
                      <p style={{ color: 'black' }}>+{props.media.length - 1}</p>
                    </div>
                  )}
                  <div
                    onClick={() => navigation('ImageViewer',{ state: { images: props.media } }) }
                    disabled={uploading || !imageExist}
                    style={{
                      minWidth: '100%',
                      height: 400,
                      width:400,
                      paddingVertical: 8,
                      paddingHorizontal: props.media.length < 4 ? props.media.length : 10,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      overflow: 'hidden',
                      borderRadius: 12,
                    }}
                  >
                    <CardStack cardData={props.media} uploading={uploading} setImageExist={setImageExist} />
                  </div>

                  <p style={styles.messageText}>{props.messageText}</p>
                </div>
              ) : props.files && props.files.length !== 0 ? (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {props.files.map((item, index) => (
                    <div key={index}>
                      <DocViewer item={item} id={index} asFile uploading={uploading} />
                    </div>
                  ))}
                  <p style={styles.messageText}>{props.messageText}</p>
                </div>
              ) : props.reply && props.reply.replyText ? (
                <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                  <div
                    style={{
                      width: '100%',
                      height: 50,
                      backgroundColor: 'lightgrey',
                      borderRadius: 10,
                      display: 'flex',
                      justifyContent: 'center',
                      paddingHorizontal: 25,
                    }}
                    onClick={() => onReplyPress(props.reply.replyId)}
                  >
                    <p style={{ color: 'black' }}>{props.reply.replyText}</p>
                  </div>
                  <p style={styles.messageText}>{props.messageText}</p>
                </div>
              ) : (
                <p style={styles.messageText}>{props.messageText}</p>
              )}
              <div style={styles.messageFooter}>
                <p style={styles.timestamp}>{props.timestamp ? formatTimestamp(props.timestamp) : '11:15 AM'}</p>
                {props.senderID === activeUserid &&
                  (failedMessagesArray.includes(props.id) ? (
                    <div></div>
                  ) : uploading ? (
                    <img src={require('../../../assets/images/waiting.png')} style={styles.tickIcon} alt="waiting" />
                  ) : (
                    <img src={require('../../../assets/images/doubleTick.webp')} style={styles.tickIcon} alt="double tick" />
                  ))}
              </div>
            </div>
            {failedMessagesArray.includes(props.id) && (
              <img src={require('../../../assets/images/cross_copy.png')} style={{ width: 15, height: 15, marginBottom: 5 }} alt="cross" />
            )}
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default MessageCard;