import { createSlice } from '@reduxjs/toolkit';


const initialState = {

    LMSuser: [],
    announcement: [],
    feedback: [],
    guide: true,
    remarks: [],
    reports: [],

    conversation: [],
    messagesArray: {},
    messagesRef: [],
    text: '',
    lastSeen:{
      // conversationId:lastSeenTime
    },
    pendingTaskTracker:{},
  //   messageRefreshTrigger: true,
  //   messageConversationArray: null,
  };


const Slice = createSlice({
  name: 'input',
  initialState,
  reducers: {
    setLMSuser: (state, action) => {

      state.LMSuser = action.payload.LMSuser;
    },
    setRemarks: (state, action) => {

        
      state.remarks = action.payload.remarks;
    },
    setAnnouncement: (state, action) => {

      state.announcement = action.payload.announcement;
    },
    setFeedback: (state, action) => {

      state.feedback = action.payload.feedback;
    },
    removeAnnouncement(state, action) {

      const idToRemove = action.payload;
      state.announcement = state.announcement.filter(
        item => item.id !== idToRemove,
      );
    },
    updateAnnouncementPin(state, action) {

      const {id, isPinned} = action.payload;
      const announcement = state.announcement.find(item => item.id === id);
      if (announcement) {
        announcement.isPinned = isPinned;
      }
    },
    // resetStates: state => {
    //   // Resetting the state to initial state
    //   return initialState;
    // },
    setguide: (state, action) => {

      state.guide = action.payload.guide;
    },
    setReport: (state, action) => {

      state.reports = action.payload.reports;
    },
    setConversation: (state, action) => {

      state.conversation = action.payload.conversation;
    },
    setTest: (state, action) => {

      state.test = action.payload.test;
    },
        setMessagesArray: (state, action) => {

            const { conversationId, messagesArray, append } = action.payload;

            if (append) {


                const data = { ...state.messagesArray }
                const conversationIdsMessageArray = data[conversationId]||[]
                const newconversationIdsMessageArray = [...conversationIdsMessageArray, ...messagesArray];
                data[conversationId] = newconversationIdsMessageArray
                state.messagesArray = data
            } else {
                // Replace the messages for the specific conversationId
                state.messagesArray[conversationId] = messagesArray;
            }


        },
        deleteMessagesArray: (state, action) => {

            const { conversationId, ids } = action.payload;

            // Check if the conversation exists in the messagesArray
            if (state.messagesArray[conversationId]) {

                // Filter out messages whose id is in the ids array
                const filteredMessages = state.messagesArray[conversationId].filter(
                    message => !ids.includes(message.id)
                );

                // Update the state with the filtered messages
                state.messagesArray[conversationId] = filteredMessages;
            }
        },
        setMessagesRef: (state, action) => {
            state.messagesRef = action.payload.messagesRef;
        },
        
        clearMessagesArray: state => {
            state.messagesArray = { }; // Reset messagesArray to an empty object
            state.messagesRef = [];
            state.messageConversationArray = {};

        },
        // setMessageRefreshTrigger: (state, action) => {
        //   state.messageRefreshTrigger = action.payload;  
        // },
        // setMessageConversationArray: (state, action) => {
        //   state.messageConversationArray = action.payload;
        // },
        setLastSeen:(state,action)=>{
          console.log('setLastSeen: ', action.payload,"[][][][][][][][][][][][][][][][][][");
    
          const {conversationId,timeStamp}=action.payload
          const data={...state.lastSeen}
          data[conversationId]=timeStamp
          state.lastSeen = data
        },
        setUpdateMessageArrayCompletely:(state,action)=>{
          state.messagesArray=action.payload
        },
        setPendingTaskTracker:(state,action)=>{ //dispatch(setPendingTaskTracker({data:notification,type:'notification',priority:'high',id,timeStampToExecute}))
          const {data,type,priority,id,timeStampToExecute}=action.payload  //timestamp is in milliseconds
          console.log('data:+++ ', action.payload);
          if(!state.pendingTaskTracker[id])
          state.pendingTaskTracker[id]={data,type,priority,id,timeStampToExecute}
        },
       
    },

    // setMessageRefreshTrigger: (state, action) => {
    //   state.messageRefreshTrigger = action.payload;
    // },
    // setMessageConversationArray: (state, action) => {
    //   state.messageConversationArray = action.payload;
    // },
  
});

export const {
  setLMSuser,
  setAnnouncement,
  clearMessagesArray,
  setTest,
  removeAnnouncement,
  updateAnnouncementPin,
  resetStates,
  setFeedback,
  setguide,
  setRemarks,
  setReport,
  setConversation,
  setMessagesArray,
  setMessagesRef,
  setLastSeen,
  deleteMessagesArray,
  setUpdateMessageArrayCompletely,
  setPendingTaskTracker
 
//   setMessageRefreshTrigger,
//   setMessageConversationArray,

} = Slice.actions;

export default Slice.reducer;