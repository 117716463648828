import React,{useState,useEffect} from 'react';
import useScreenSizes from "../../utils/useScreenSize";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useTranslation } from 'react-i18next'

const ErrorCard = () => {
  const [Tab, setTab] = useState(false);
    const [isPhone, setIsPhone] = useState(false);
    const [isTablet, setIsTablet] = useState(false);
    const [miniPhone,setMiniPhone] = useState(false)
  const screen = useScreenSizes();
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation()


  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 2000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (screen >= 1200) {  // Desktop
        setTab(false);
        setIsPhone(false);
        setIsTablet(false);
        setMiniPhone(false)
    } else if (screen >= 700 && screen < 1200) { // Tablet (iPad Pro portrait)
        setTab(true);
        setIsPhone(false);
        setIsTablet(true);
        setMiniPhone(false)
    } else if (screen >= 600) {  // Other smaller tablets
        setTab(true);
        setIsPhone(false);
        setIsTablet(false);
        setMiniPhone(false)
    } else if (screen >= 350 && screen <= 550) {   // Mobile
        setTab(false);
        setIsPhone(true);
        setIsTablet(false);
        setMiniPhone(false)
    } else {
        setTab(false);
        setIsPhone(false);
        setIsTablet(false);
        setMiniPhone(true);
    }
}, [screen]);


const styles = {

  parent:{
    display:"flex", 
    backgroundColor: 'white', 
    borderRadius: 10,
    width: miniPhone || isPhone ? "93%" : isTablet ? "40%" : Tab ? "100%" : "30%",
    justifyContent:"center",
    margin:"12px" ,
    alignSelf:"center"
  },
  gradientBackground: {
    background: "linear-gradient(to right, red, rgba(254, 180, 150, 0.3))",
    padding: "20px",
    borderRadius: "15px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
},
  gradient:{ 
    display:"flex",
    borderTopLeftRadius: 10, 
    borderTopRightRadius: 10,
    borderRadius:10,
    width:"100%"
  },
  maincontainer:{
    display:"flex", 
    justifyContent: 'space-between', 
    alignItems: 'center', 
    flexDirection: 'row',
    maxWidth: "100%" 
  },
  container2:{
    display:"flex", 
    width: '60%', 
    justifyContent: 'flex-end', 
    alignItems: 'flex-end' 
  },
  image1:{ 
    width: 90, 
    height: 90 
  },
  image2:{ 
    width: 100, 
    height: 80 
  },
  container3:{
    display:"flex", 
    justifyContent: 'center', 
    alignItems: 'center', 
    margin: 5 
  },
  text1:{
    display:"flex", 
    color: 'black', 
    fontFamily: 'Lora-BoldItalic' 
  },
  container4:{
    display:"flex", 
    justifyContent: 'center', 
    alignItems: 'center', 
    marginBottom:10,
    marginTop:2 
  },
  button:{
    display:"flex", 
    padding: 5, 
    paddingHorizontal: 20, 
    borderRadius: 15,
    width:"100px",
    justifyContent:"center",
    backgroundColor:"#F95454" 
  },
  text2:{
    display:"flex", 
    color: 'white' 
  },
  skeleton:{
    borderRadius:10,
  }




}


  return (
    <div style={styles.parent}>
      
      {/* Gradient at the top of the card */}
      <div
        colors={['red', 'rgba(254, 180, 150, 0.3)']}
        start={{ x:0, y: 1 }} // Gradient starting point
        end={{ x: 1, y:0.1 }}   // Gradient ending point
        style={styles.gradient}
      >
         {loading ? (
            <Skeleton width={460} height= {150} style={styles.skeleton} />
            ) : (
            <>
        <div style={styles.maincontainer}>
              <div style={styles.container2}>
                <img src={require('../../assets/images/oooo.gif')} style={styles.image1} />
              </div>
              <div>
                <img src={require('../../assets/images/error1.gif')} style={styles.image2} />
              </div>
        </div>

      <div style={styles.container3}>
        <text style={styles.text1}>{t("**Something went wrong**")}</text>
      </div>

      <div style={styles.container4}>
        {/* Gradient for the Try Again button */}
        <div
          colors={['#d71a1f', '#ff5f6d']} // Bright red to dark 
          start={{ x: 0, y: 0 }} // Gradient starting point
          end={{ x: 1, y: 1 }}   // Gradient ending point
          style={{ borderRadius: 15 }}
        >
          <button style={styles.button}>
            <text style={styles.text2}>{t("Try Again")}</text>
          </button>
        </div>

      </div>
      </>
      )}
      </div>
    </div>
  );
};

export default ErrorCard;
