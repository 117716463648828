export const validateInput = (option, input) => {
    const optionLower = option.toLowerCase();
    switch (optionLower) {
      case 'username':
        return isValidUserName(input);
      case 'email':
        return isValidEmail(input);
      case 'mobile':
        return isValidPhoneNumber(input);
      default:
        return false; // Handles any unexpected input types
    }
  };
  
  // Validation functions
  const isValidEmail = (() => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return (email) => emailRegex.test(email);
  })();
  
  const isValidPhoneNumber = (() => {
    const phoneRegex = /^\d+$/;
    return (text) => phoneRegex.test(text) && text.length > 0;
  })();
  
  const isValidUserName = (text) => {
    const invalidCharRegex = /['"]/; // Regex to check for ' or "
    return text.length > 0 && !invalidCharRegex.test(text);
  };