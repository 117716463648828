import React, { useRef, useState, useEffect } from 'react';
import {

    Modal,
    ActivityIndicator,
    Share,
    Alert
} from 'react-native';
import { motion } from "framer-motion";

import ReusableModal from './ReusableModal'; // Ensure the correct path
import { useDispatch, useSelector } from 'react-redux';
import { updateUserField } from '../../reduxStore/slices/UsersSlice';
import CommentSearch from '../searching/commentSearch';
import ThemeButton from '../ResuableThemeComponent/ThemeButton';
import ThemeTextInput from '../ResuableThemeComponent/ThemeTextInput';
import ThemeDropdown from '../ResuableThemeComponent/ThemeDropdown';
import UserCardDropdown from './Home/UserDropDown';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from 'react-router-dom';
import { arrayRemove, arrayUnion, doc, setDoc, updateDoc, writeBatch } from 'firebase/firestore';
import { firestore } from '../../firebase';
const ITEM_HEIGHT = 470; // Base item height
const PostCard = ({ item, data }) => {
    const dispatch = useDispatch()
    const userData = useSelector(state => state.user.users);
    const activeUser = useSelector(state => state.user.activeUser);
    const activeUserData = userData[activeUser]
    const [likes, setLikes] = useState(activeUserData?.likedPosts || []);
    const [isModalVisible, setModalVisible] = useState(false);

    const location = useLocation();
    const [isOptionVisible, setOptionVisible] = useState(false);
    const [selectedPost, setSelectedPost] = useState(null);
    const [selectedPostComments, setSelectedPostComments] = useState([]);
    const [commentText, setCommentText] = useState('');
    const [likeCount, setLikeCount] = useState('');
    const [optionId, setOptionId] = useState('');
    const [selectedCards, setSelectedCards] = useState('');
    const [confirmModal, setConfirmModal] = useState(false)
    const [uploadStatus, setUploadStatus] = useState('confirm');
    const [allPosts, setAllPosts] = useState(item || [])
    const [postToDelete, setPostToDelete] = useState('');
    const [reportText, setReportText] = useState('');
    const [taggedPeople, setTaggedPeople] = useState('');
    const [selectedReport, setSelectedReport] = useState('');
    const [onlyModal, setOnlyModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState('');

    const navigation = useNavigate()

    useEffect(() => {
        setLikes(activeUserData?.likedPosts)
    }, [activeUserData])
    useEffect(() => {
        setAllPosts(item);
        if (item?.likes) {
            // setLikeCount(item.likes.length); 
        }
    }, [item]);
    



    const toggleLike = async (postId) => {
        if (!activeUser) return; // Ensure user is logged in

        const activeDocRef = doc(firestore, "SBusers", activeUser);
        const postDocRef = doc(firestore, "CommunityPosts", postId);

        let updatedLikes;
        let isLiked = activeUserData?.likedPosts.includes(postId);

        if (isLiked) {
            updatedLikes = activeUserData.likedPosts.filter((id) => id !== postId);
            await updateDoc(activeDocRef, { likedPosts: updatedLikes });
            await updateDoc(postDocRef, { likes: arrayRemove(activeUser) });
            setLikeCount((prev) => (prev > 0 ? prev - 1 : 0));
        } else {
            updatedLikes = [...activeUserData.likedPosts, postId];
            await updateDoc(activeDocRef, { likedPosts: updatedLikes });
            await updateDoc(postDocRef, { likes: arrayUnion(activeUser) });
            setLikeCount((prev) => prev + 1);
        }

        // // Update Redux state
        dispatch(updateUserField({ userUid: activeUser, updates: { likedPosts: updatedLikes } }));

        console.log("Like toggled successfully!");
    };

    useEffect(() => {
        setSelectedCards('')
    }, [])

    const openCommentsModal = (postId) => {
        // Fetch the selected post and comments based on postId
        const selectedPost = allPosts
        
        const dummyComments = [
            { id: 'c1', text: 'Great post!' },
            { id: 'c2', text: 'Thanks for sharing.' },
            { id: 'c3', text: 'Very informative.' },
        ];
        setSelectedPost(selectedPost);
        setSelectedPostComments(selectedPost.comments || []);
        setModalVisible(true);
    };
    const deleteComment = (postId, commentId) => {
        return allPosts.map(post => {
            if (post.id === postId) {
                return {
                    ...post,
                    comments: post.comments.filter(comment => comment.key !== commentId) // Remove the comment
                };
            }
            return post;
        });
    };
    function formatNumber(num) {
        num = Number(num); // Ensure it's a number
        if (num === 0) {
            return '';
        }
        if (num >= 1000000) {
            return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
        } else if (num >= 1000) {
            return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
        } else {
            return num.toString();
        }
    }
    const openOption = (id) => {
        if (allPosts.reported && activeUser != data.id) return;
        setOptionId(id)
        setOptionVisible(true);
    };

    function updatePostById(key, value) {
        const tempPost = { ...allPosts }
        tempPost[key] = value
        return tempPost
    }
    const handleAddComment = () => {
        if (commentText.trim() === '') return;

        const newComment = {
            id: activeUser, // Unique ID for the comment
            key: `${Date.now()}`,
            text: commentText,
        };
        const batch = firestore().batch()
        const userDoc = firestore().collection('CommunityPosts').doc(selectedPost.id);
        batch.update(userDoc, { comments: firestore.FieldValue.arrayUnion(newComment) })
        batch.commit()
            .then(() => {
                console.log('Value added to array successfully');
            })
            .catch(error => {
                console.error('Error adding value to array:', error);
            });
        const tempComments = [...selectedPostComments, newComment]
        setSelectedPostComments(tempComments);
        setAllPosts(updatePostById('comments', tempComments))
        setCommentText('');
    };

    const handleReport = () => {
        // Implement report functionality here
        setConfirmModal(true)
        setUploadStatus('report')
    };

    const handleShare = () => {
        setOnlyModal(true)

    };
    const handleExternalShare = async () => {
        try {
            const postId = item.id; // Get the post ID you want to share

            // Deep Link (For users who have the app)
            const appLink = `StudyBuddy://post`;

            // Web Link (For users who don’t have the app)
            const webLink = `https://yourwebsite.com/post/${postId}`;

            // Message to share
            const message = `Check out this post: ${webLink}`;

            // Share the link
            const result = await Share.share({
                title: 'Post share',
                message,
            });

            if (result.action === Share.sharedAction) {
                console.log('Shared successfully');
            } else if (result.action === Share.dismissedAction) {
                console.log('Share cancelled');
            }
        } catch (error) {
            console.error('Error sharing:', error.message);
        }
    }
    const handleFlag = async (id) => {
        try {
            const updatedPosts = allPosts.map((post) =>
                post.id === id ? { ...post, flagged: "pending" } : post
            );

            setAllPosts(updatedPosts); // Update UI state

            const activePostRef = doc(firestore, "CommunityPosts", id);
            const reportDocRef = doc(firestore, "Reports", "postFlags");

            await updateDoc(activePostRef, { flagged: "pending" });
            await setDoc(reportDocRef, { [`pending.${id}`]: allPosts.find((p) => p.id === id)?.img }, { merge: true });

            console.log("Post flagged successfully!");
        } catch (error) {
            console.error("Error flagging post:", error);
        }
    };



    const handleBookmark = async(id) => {
        // Implement bookmark functionality here
        console.log('Bookmark button pressed');
        const batch = writeBatch(firestore);
        const activeDocRef = doc(firestore, "SBusers", activeUser);
        const bookmarkId = id || optionId;
    
        if (!bookmarkId) return;
    
        let updatedBookmarks;
        if (activeUserData.bookMarkedPost?.includes(bookmarkId)) {
            // Remove bookmark
            updatedBookmarks = activeUserData.bookMarkedPost.filter(item => item !== bookmarkId);
            batch.update(activeDocRef, { bookMarkedPost: arrayRemove(bookmarkId) });
        } else {
            // Add bookmark
            updatedBookmarks = [...(activeUserData.bookMarkedPost || []), bookmarkId];
            batch.update(activeDocRef, { bookMarkedPost: arrayUnion(bookmarkId) });
        }
    
        // Update Redux state
        dispatch(updateUserField({ userUid: activeUser, updates: { bookMarkedPost: updatedBookmarks } }));
    
        try {
            await batch.commit();
            console.log("Bookmark updated successfully!");
        } catch (error) {
            console.error("Error updating bookmark:", error);
        }

        // setOptionVisible(false);
    };
    const sortedData = (data, attribute) => {
        return data.sort((a, b) => Number(b[attribute]) - Number(a[attribute]));
    }

    const handleCardSelect = (id) => {
        setSelectedCards(id)
    }
    const handleCardDeselect = () => setSelectedCards(null);

    const handleCopy = () => {
        const value = selectedPostComments.find(item => item.key === selectedCards);
        if (!value) return;

        navigator.clipboard.writeText(value.text)
            .then(() => {
                toast.success("Text Copied!", {
                    position: "top-center",
                    autoClose: 2000, // Auto close after 2s
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            })
            .catch(err => console.error("Failed to copy text: ", err));

        setSelectedCards(null);
    };
    const handleDelete = () => {
        const selectedComment = selectedPostComments.filter(item => item.key == selectedCards)[0]
        const tempComments = selectedPostComments.filter(item => item.key != selectedCards)
        const batch = firestore().batch()
        const postDoc = firestore().collection('CommunityPosts').doc(selectedPost.id);
        batch.update(postDoc, { comments: firestore.FieldValue.arrayRemove(selectedComment) })
        batch.commit()
            .then(() => {
                console.log('Value added to array successfully');
                setSelectedCards(null)
                setAllPosts(deleteComment(selectedPost.id, selectedComment.key))
                setSelectedPostComments(tempComments)
            })
            .catch(error => {
                console.error('Error adding value to array:', error);
            });
    }
    useEffect(() => {
        if (!isModalVisible) {
            setSelectedCards(null)
        }
    }, [isModalVisible])
    const handlePostDelete = (id) => {
        setConfirmModal(true)
        setPostToDelete(id)
    }
    const reportData = [
        { label: 'Spam', value: 'spam' },
        { label: 'Inappropriate Content', value: 'inappropriate_content' },
        { label: 'Harassment or Bullying', value: 'harassment' },
        { label: 'False Information', value: 'false_information' },
        { label: 'Hate Speech', value: 'hate_speech' },
        { label: 'Violence or Harm', value: 'violence' },
        { label: 'Nudity or Sexual Content', value: 'nudity' },
        { label: 'Scam or Fraud', value: 'scam' },
        { label: 'Intellectual Property Violation', value: 'ip_violation' },
        { label: 'Other', value: 'other' }
    ];

    const handleTags = (item) => {
        setTaggedPeople(item.taggedPeople ? item.taggedPeople.length > 0 ? item.taggedPeople : [] : [])
        setSelectedPost(item);
        setModalVisible(true)
    }
    const handleConfirm = () => {
        setUploadStatus('pending')
        const tempArray = allPosts.filter(item => item.id != postToDelete)
        setAllPosts(tempArray)
        const batch = firestore().batch()
        const userdoc = firestore().collection('SBusers').doc(data.id);
        batch.update(userdoc, { posts: firestore.FieldValue.arrayRemove(postToDelete) })
        batch.commit()
            .then(() => {
                console.log('Value added to array successfully');
                setUploadStatus('success');
                if (location.state?.onGoBack) {
                    location.state.onGoBack();  // Calls the function passed from the previous screen  
                    setConfirmModal(false);
                    navigation.goBack();      // Navigates back to the previous screen  
                }
            })
            .catch(error => {
                console.error('Error adding value to array:', error);
            });


    }
    const handleReportConfirm = () => {
        if (!selectedReport) return;
        setUploadStatus('pending')
        const tempArray = allPosts
        const tempFullArray = allPosts
        const reportObject = { reporterId: activeUser, reportType: selectedReport.value, reportText: reportText, reportId: `${activeUser}${tempArray.reports ? tempArray.reports.length + 1 : '1'}`, reportDate: Date.now() }
        tempArray['reports'] = [...(tempArray['reports'] || []), reportObject]
        setAllPosts([...tempArray])
        const batch = firestore().batch()
        const postDoc = firestore().collection('CommunityPosts').doc(optionId);
        const reportDoc = firestore().collection('Reports').doc('postReport')
        batch.update(postDoc, { reports: firestore.FieldValue.arrayUnion(reportObject) })
        batch.set(reportDoc, { [optionId]: firestore.FieldValue.arrayUnion(reportObject) }, { merge: true })
        batch.commit()
            .then(() => {
                console.log('Value added to array successfully');
                setUploadStatus('success');
                setReportText('')
                setConfirmModal(false);
            })
            .catch(error => {
                console.error('Error adding value to array:', error);
            });
    }

    const handleBack = () => {
        setPostToDelete('')
        setUploadStatus('')
        setReportText('')
        setConfirmModal(false);
        setTaggedPeople('')
        setSelectedReport('')
    };
    const getTimeAgo = (timestamp) => {
        const now = Date.now();
        const diffInSeconds = Math.floor((now - timestamp) / 1000);

        if (diffInSeconds < 60) {
            return `${diffInSeconds} seconds ago`;
        }

        const diffInMinutes = Math.floor(diffInSeconds / 60);
        if (diffInMinutes < 60) {
            return `${diffInMinutes} min${diffInMinutes > 1 ? 's' : ''} ago`;
        }

        const diffInHours = Math.floor(diffInMinutes / 60);
        if (diffInHours < 24) {
            return `${diffInHours} hour${diffInHours > 1 ? 's' : ''} ago`;
        }

        // If it's more than 24 hours, return the formatted date
        const date = new Date(timestamp);
        return `${date.getDate()} ${date.toLocaleString('en-US', { month: 'short' })} ${date.getFullYear()}`;
    };

    const sendMessage = () => {
        setSelectedUser('')

    }
    const navigateUser = () => {
        const userData = {
            id: data.objectID
        }
        navigation('/Datascreen', {
            data: userData,
            cardtype: 'SBUser'
        });
    }

  

    return (
        <>
            <div style={styles.item}>
                <div style={styles.fullWidthContainer}>
                    <div style={styles.head}>
                        <div activeOpacity={0.8} 
                        onClick={navigateUser}
                         style={{ flexDirection: 'row', alignContent: 'center', alignItems: 'center' , display:"flex" }}>
                            <img
                                src={data.profilePic ? data.profilePic : require('../../assets/images/profile.webp')}
                                alt="Profile"
                                style={styles.pfp}
                            />
                            <p style={styles.name}>{data.username}</p>
                        </div>
                        <div onClick={() => openOption(item.id)}>
                            <img src={require('../../assets/images/dots.webp')} style={styles.logo} />
                        </div>
                    </div>
                    <div style={styles.imageContainer}>
                        <img
                            src={item.reported ? item.reportImg : item.img || require('../../assets/images/3rd-leader.webp')}
                            alt="Post Image"
                            style={styles.image}
                        />
                        {
                            (item.taggedPeople && item.taggedPeople.length > 0) &&
                            <div onClick={() => handleTags(item)} activeOpacity={0.6} style={{ width: 25, height: 25, backgroundColor: 'rgba(0,0,0,0.8)', opacity: 1, position: 'absolute', borderRadius: 20, bottom: 0, margin: 10, justifyContent: 'center', alignItems: 'center', overflow: "hidden" }}>
                                <img style={{ width: '70%', height: '70%', tintColor: 'white', padding: 3 }} src={require('../../assets/images/tag-user.png')} resizeMode='contain' />
                            </div>
                        }
                    </div>
                    {!item.reported &&
                        <div style={styles.bottom}>
                            <div style={{ flexDirection: 'row', alignItems: 'center', display: "flex" }}>
                                <div onClick={() => toggleLike(item.id)}>
                                    {(likes?.length > 0 && likes?.includes(item.id)) ? (
                                        <img
                                            src={require('../../assets/images/like.webp')}
                                            style={styles.logo}
                                        />
                                    ) : (
                                        <img
                                            src={require('../../assets/images/dislike.webp')}
                                            style={{ ...styles.logo, height: 28, width: 28 }}
                                        />
                                    )}
                                </div>
                                <p style={{ color: 'black', fontFamily: 'Marker Felt', fontSize: 15, marginRight: 3 }}>
                                    {formatNumber(likeCount) || ''}
                                </p>
                                <div onClick={() => openCommentsModal(item.id)}>
                                    <img
                                        src={require('../../assets/images/chat-bubble.png')}
                                        style={styles.logo}
                                    />
                                </div>
                                <p style={{ color: 'black', fontFamily: 'Marker Felt', fontSize: 15, marginRight: 3 }}>
                                    {formatNumber(allPosts.comments.length) || ''}
                                </p>
                                <div onClick={() => handleShare(item.id)}>
                                    <img
                                        src={require('../../assets/images/postShare.png')}
                                        style={{ ...styles.logo, height: 28, width: 28 }}
                                    />
                                </div>
                            </div>
                            <div>
                                <div onClick={() => handleBookmark(item.id)}>
                                    <img
                                        src={(activeUserData?.bookMarkedPost && activeUserData?.bookMarkedPost.includes(item.id)) ? require('../../assets/images/save-fill.png') : require('../../assets/images/save.png')}
                                        style={{ ...styles.logo, width: 28, height: 28 }}
                                    />
                                </div>
                            </div>
                        </div>}
                    {item.caption && !item.reported && (
                        <div style={{ ...styles.caption, columnGap: 5 }}>
                            <p style={styles.lowerUserName}>{data.username}</p>
                            <p numberOfLines={2} style={{ ...styles.text, fontSize: 14 }}>
                                {item.caption}ewfew
                            </p>
                        </div>
                    )}
                    {item.date &&
                        <div style={{ marginHorizontal: 10, marginVertical: 5 }}>
                            <p numberOfLines={2} style={styles.dateTxt}>
                                {getTimeAgo(item.date)}
                            </p>
                        </div>
                    }
                </div>
            </div>

            {/* Comments Modal */}
            <ReusableModal
                visible={isModalVisible}
                onClose={() => (setModalVisible(false), setTaggedPeople(''))}
            >
                {selectedPost && (
                    <div style={{ flex: 1, display: "flex" , flexDirection:"column" ,  }}>
                        <div style={styles.modalHeader}>
                            <div style={{ flexDirection: 'row', alignItems: 'center', flex: 1, display: "flex" }}>
                                <img
                                    src={require('../../assets/images/profile.webp')}
                                    style={styles.modalPfp}
                                />
                                <div style={styles.modalHeaderText}>
                                    <p style={styles.modalUsername}>{data.username}</p>
                                    {selectedPost.caption && <p style={styles.modalCaption}>{selectedPost.caption}</p>}
                                </div>
                            </div>
                            {
                                ((!taggedPeople && taggedPeople.length == 0)) ?
                                    <div style={{ flex: 1, justifyContent: 'flex-end', flexDirection: 'row', padding: 4, columnGap: 5, display: "flex" }}>
                                        <div style={{ justifyContent: 'center', flexDirection: 'row', alignItems: 'center', padding: 4, columnGap: 5 }}>
                                            <p style={{ color: 'black', fontFamily: 'Marker Felt' }}>
                                                {selectedPostComments ? selectedPostComments.length : '0'}
                                            </p>
                                            <img src={require('../../assets/images/chat-bubble.png')} style={{ width: 18, height: 18 }} />
                                        </div>
                                        <div style={{ justifyContent: 'center', flexDirection: 'row', alignItems: 'center', padding: 4, columnGap: 5 }}>
                                            <p style={{ color: 'black', fontFamily: 'Marker Felt' }}>
                                                {selectedPost.taggedPeople ? selectedPost.taggedPeople.length : '0'}
                                            </p>
                                            <img src={require('../../assets/images/tag-user.png')} style={{ width: 18, height: 18 }} />
                                        </div>
                                    </div>
                                    : null
                            }
                        </div>
                        <div style={styles.commentsList} contentContainerStyle={{ flexGrow: 1 }}
                            nestedScrollEnabled={true}
                            keyboardShouldPersistTaps="always"
                            showsVerticalScrollIndicator={false}>
                            {

                                // taggedPeople ? taggedPeople.length > 0 ?
                                //     <UserSearch searchDisabled idsArray={taggedPeople} SBUser navigate />
                                //     : <div style={{ justifyContent: 'center', alignItems: 'center' }}>
                                //         <p style={{ color: 'black' }}>No taggedPeople</p>
                                //     </div>
                                //     : null
                            }
                            {(!taggedPeople && taggedPeople.length == 0) && sortedData(selectedPostComments, 'key').map((comment) => {
                                // console.log('selectedCards: ', selectedCards, comment.key);

                                return (
                                    <motion.div
                                    key={comment.key}
                                    style={styles.commentItem}
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: -20 }}
                                    // transition={{ duration: 0.3 }}
                                >

                                        <CommentSearch commentArray={comment}
                                            onSelect={handleCardSelect}
                                            onDeselect={handleCardDeselect}
                                            isSelected={selectedCards}
                                            disableInteraction={selectedCards && selectedCards !== comment.key}
                                        />

</motion.div>
                                )
                            })}
                        </div>
                        <div style={styles.inputContainer}>
                            {(!taggedPeople && taggedPeople.length == 0) ?
                                (
                                    !selectedCards ?
                                        <>
                                            <ThemeTextInput
                                                placeholderTextColor={'black'}
                                                style={styles.ThemeTextInput}
                                                value={commentText}
                                                onChangeText={setCommentText}
                                                placeholder="Add a comment..."
                                            />
                                            <div
                                                onClick={handleAddComment}
                                                style={styles.sendButton}
                                            >
                                                <p style={styles.sendButtonText}>Send</p>
                                            </div>
                                        </>

                                        :
                                        <div style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', flex: 1, paddingHorizontal: 10, display: "flex" }}>
                                            <div style={{ flexDirection: 'row', columnGap: 10, alignItems: 'center' }}>
                                                <div style={{ height: 40, width: 40 }} onClick={handleCopy} >
                                                    <img style={{ width: '100%', height: '100%' }} src={require('../../assets/images/copy.png')} />
                                                </div>
                                                {
                                                    selectedPostComments.filter(item => item.key == selectedCards)[0].id == activeUser &&
                                                    <div style={{ height: 35, width: 35 }} onClick={handleDelete} >
                                                        <img style={{ width: '100%', height: '100%' }} src={require('../../assets/images/dustbin.png')} />
                                                    </div>
                                                }
                                            </div>
                                            <div style={{ height: 35, width: 35 }} onClick={() => setSelectedCards(null)}>
                                                <img style={{ width: '100%', height: '100%' }} src={require('../../assets/images/close.webp')} />
                                            </div>

                                        </div>
                                )
                                :
                                null
                            }
                        </div>
                    </div>
                )}
            </ReusableModal>

            {/* Options Modal */}
            <ReusableModal
                visible={isOptionVisible}
                onClose={() => setOptionVisible(false)}
                heightPercent='50'
            >
                <div style={styles.optionsContainer}>
                    {optionId && !allPosts.reported &&
                        <>
                            <div onClick={handleReport} style={styles.optionButton}>
                                <p style={styles.optionButtonText}>Report</p>
                            </div>
                            <div onClick={handleShare} style={styles.optionButton}>
                                <p style={styles.optionButtonText}>Share</p>
                            </div>
                            <div onClick={() => handleBookmark(optionId)} style={{ ...styles.optionButton, backgroundColor: activeUserData.bookMarkedPost ? activeUserData.bookMarkedPost.includes(optionId) ? '#007BFF' : '#f2f2f2' : '#f2f2f2' }}>
                                <p style={{ ...styles.optionButtonText, color: activeUserData.bookMarkedPost ? activeUserData.bookMarkedPost.includes(optionId) ? '#f2f2f2' : '#007BFF' : '#007BFF' }}>{activeUserData.bookMarkedPost ? activeUserData.bookMarkedPost.includes(optionId) ? 'Bookmarked' : 'Bookmark' : 'Bookmark'}</p>
                            </div>
                        </>
                    }
                    {optionId && allPosts.reported &&
                        <div style={{ flexDirection: 'column', justifyContent: 'center', marginBottom: 15, display: "flex" }}>
                            <p style={{ ...styles.optionButtonText, color: 'black', textAlign: 'center' }}>This image or caption is inappropriate and may violate community guidelines.</p>
                            {
                                allPosts.flagged &&
                                <p style={{ color: 'black', textAlign: 'center' }}>Status Information: {allPosts.flagged}</p>}
                        </div>
                    }
                    {activeUser == data.id &&
                        <div onClick={() => handlePostDelete(optionId)} style={styles.optionButton}>
                            <p style={{ ...styles.optionButtonText, color: 'red' }}>Delete</p>
                        </div>
                    }
                    {optionId && allPosts.reported && !allPosts.flagged &&
                        <div onClick={() => handleFlag(optionId)} style={styles.optionButton}>
                            <p style={{ ...styles.optionButtonText, color: 'red' }}>Report as False</p>
                        </div>
                    }
                    {optionId && allPosts.flagged && allPosts.reported &&
                        <div style={{ ...styles.optionButton, backgroundColor: '#007BFF' }} disabled>
                            <p style={{ ...styles.optionButtonText, color: 'white' }}>Reported</p>
                        </div>
                    }

                </div>
            </ReusableModal>
            <Modal
                transparent={true}
                visible={confirmModal}
                animationType="slide"
                onRequestClose={() => setConfirmModal(false)}
            >
                <div style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0,0,0,0.5)', display: "flex" }}>
                    {uploadStatus === 'pending' && (
                        <div style={{ padding: 20, backgroundColor: 'white', borderRadius: 10, alignItems: 'center' }}>
                            <ActivityIndicator size="large" color="#0000ff" />
                            <p style={{ marginTop: 10, color: 'black' }}>Uploading...</p>
                        </div>
                    )}

                    {uploadStatus === 'success' && (
                        <div style={{ paddingHorizontal: 40, backgroundColor: 'white', borderRadius: 10, alignItems: 'center', justifyContent: 'space-evenly', height: 200 }}>
                            <img src={require('../../assets/images/check.webp')} style={{ height: 45, width: 45 }} />
                            <p style={{ fontSize: 20, marginBottom: 10, color: 'black' }}>Upload Successful!</p>

                        </div>
                    )}

                    {uploadStatus === 'error' && (
                        <div style={{ paddingHorizontal: 40, backgroundColor: 'white', borderRadius: 10, alignItems: 'center', height: 200, justifyContent: 'space-evenly' }}>
                            <img src={require('../../assets/images/error.webp')} style={{ height: 45, width: 45 }} />
                            <p style={{ fontSize: 20, marginBottom: 10, color: 'black' }}>Upload Failed</p>
                            <div style={{ flexDirection: 'row', justifyContent: 'space-between', width: '80%' }}>
                                <ThemeButton
                                    title="Back"
                                    variant="red"
                                    width="45%"
                                    func={handleBack}
                                />
                            </div>
                        </div>
                    )}
                    {uploadStatus === 'confirm' && (
                        <div style={{ paddingHorizontal: 40, backgroundColor: 'white', borderRadius: 10, alignItems: 'center', height: 200, justifyContent: 'space-evenly' }}>
                            <img src={require('../../assets/images/dustbin.png')} style={{ height: 45, width: 45 }} />
                            <p style={{ fontSize: 20, marginBottom: 10, color: 'black' }}>Delete Post</p>
                            <div style={{ flexDirection: 'row', justifyContent: 'space-between', width: '80%' }}>
                                <ThemeButton
                                    title="Back"
                                    variant="red"
                                    width="45%"
                                    func={handleBack}
                                />
                                <ThemeButton
                                    title="confirm"
                                    variant="green"
                                    width="50%"
                                    func={handleConfirm}
                                />
                            </div>
                        </div>
                    )}
                    {
                        uploadStatus == 'report' &&
                        <div style={{ paddingHorizontal: 40, backgroundColor: 'white', borderRadius: 10, alignItems: 'center', minHeight: 200, justifyContent: 'space-evenly', paddingVertical: 10 }}>
                            <img src={require('../../assets/images/reportPost.png')} style={{ height: 45, width: 45 }} />
                            <p style={{ fontSize: 20, marginBottom: 10, color: 'black' }}>Confirm Report Post</p>
                            <div style={{ maxWidth: '100%', flexDirection: "row" , display:'flex' }}>
                                <ThemeDropdown
                                    title={'Choose Report'}
                                    labelField={'label'}
                                    valueField={'value'}
                                    type={'single'}
                                    data={reportData}
                                    value={selectedReport}
                                    func={setSelectedReport}
                                    placeholder={'Choose Option'}

                                />
                            </div>
                            <div style={{ justifyContent: 'flex-start', minWidth: '90%', marginTop: 5 }}>
                                <p style={{ color: 'black', textAlign: 'left' }}>
                                    Enter Any Comment
                                </p>
                            </div>
                            <ThemeTextInput
                                value={reportText}
                                maxLength={150}
                                placeholder={'Enter Report Reason'}
                                onChangeText={(text) => setReportText(text)}
                                placeholderTextColor={'grey'}
                                multiline={true}
                                style={{ borderColor: 'black', borderWidth: 1, color: 'black', borderRadius: 10, minWidth: '90%', marginVertical: 10 }}

                            />
                            <div style={{ flexDirection: 'row', justifyContent: 'space-between', width: '80%' }}>
                                <ThemeButton
                                    title="Back"
                                    variant="red"
                                    width="45%"
                                    func={handleBack}
                                />
                                <ThemeButton
                                    title="confirm"
                                    variant="green"
                                    width="50%"
                                    func={handleReportConfirm}
                                />
                            </div>
                        </div>
                    }
                </div>
            </Modal>
            {
                onlyModal &&
                <UserCardDropdown share onShare={handleExternalShare} onlyModal={onlyModal} setOnlyModal={setOnlyModal} data={activeUserData.friends} selectedCard={selectedUser} setSelectedCard={setSelectedUser} SBuser outerFunc={sendMessage} />
            }
        </>
    );
};

const styles = {

    item: {
        backgroundColor: 'red',
        margin: 10,
        borderRadius: 10,
        overflow: 'hidden',
        width: '50%',
        flexDirection: "column",
        // height: ITEM_HEIGHT,
        display: "flex"
    },
    fullWidthContainer: {
        flex: 1,
        width: '100%',
        justifyContent: 'space-between',
        flexDirection: 'column',
        height: ITEM_HEIGHT,
        display: "flex"

    },
    imageContainer: {
        // flex: 6,
        height: 500,
        display: "flex",
       
    },
    head: {
        flexDirection: 'row',
        alignItems: 'center',
        padding: 5,
        display: "flex",
        justifyContent: 'space-between'
    },
    name: {
        marginLeft: 5,
        fontWeight: 'bold',
        fontSize: 15,
        color: 'black'
    },
    pfp: {
        width: 40,
        height: 40,
        borderRadius: 20,
    },
    image: {
        width: '100%',
        height: '100%',
        borderRadius: 0,
        
objectFit:"contain"
    },
    bottom: {
        flexDirection: 'row',
        // marginBottom: 5,
        alignItems: 'center',
        paddingVertical: 8,
        display: "flex",

        justifyContent: 'space-between',
        paddingHorizontal: 12,
        // backgroundColor: "green"
    }, logo: {
        width: 30,
        height: 30,
        marginHorizontal: 5,
    },
    caption: {
        paddingHorizontal: 10,
        // paddingBottom: 1,
        flexDirection: 'row',
        display: "flex",

        alignItems: 'baseline',
    },
    text: {
        fontSize: 14,
        color: 'black'
    },
    lowerUserName: {
        fontWeight: 'bold',
        color: 'black',
        fontSize: 19
    },
    modalHeader: {
        flexDirection: 'row',
        alignItems: 'center',
        display: "flex",

        marginBottom: 10,
    },
    modalPfp: {
        width: 30,
        height: 30,
        borderRadius: 15,
        marginRight: 10,
    },
    modalHeaderText: {
        flex: 1,
        display: "flex",

        color: 'black'
    },
    modalUsername: {
        fontSize: 16,
        fontWeight: 'bold',
        color: 'black'
    },
    modalCaption: {
        fontSize: 14,
        color: 'black'
    },
    commentsList: {
        flex: 1,
        display: "flex",
        flexDirection: 'column',    
    },
    dateTxt: { color: 'black', fontFamily: 'Marker Felt', color: 'grey', opacity: 0.8 },
    commentItem: {
        padding: "10px",
        borderRadius: "8px",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        marginBottom: "10px",
    },

    commentText: {
        fontSize: 14,
        color: 'white'
    },
    inputContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 10,
        borderTopWidth: 1,
        display: "flex",

        borderTopColor: '#ddd',
    },
    ThemeTextInput: {
        flex: 1,
        borderRadius: 20,
        borderWidth: 1,
        display: "flex",

        borderColor: '#ddd',
        paddingHorizontal: 15,
        paddingVertical: 10,
        marginRight: 10,
        color: 'black'
    },
    sendButton: {
        backgroundColor: '#007BFF',
        borderRadius: 20,
        paddingHorizontal: 15,
        paddingVertical: 10,
    },
    sendButtonText: {
        color: 'white',
        fontWeight: 'bold',
    },
    optionsContainer: {
        flex: 1,
        marginTop: 20,
        display: "flex",

    },
    optionButton: {
        backgroundColor: '#f2f2f2',
        paddingVertical: 15,
        paddingHorizontal: 20,
        marginVertical: 5,
        borderRadius: 25, // Rounded edges for pill shape
        alignItems: 'center',
    },
    optionButtonText: {
        fontSize: 16,
        color: '#007BFF',
        fontWeight: 'bold',
    },
};

export default PostCard;