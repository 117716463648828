import { useEffect, useState } from 'react';
import { Mail, Lock, Eye, EyeOff, ArrowRight } from 'lucide-react';
import studentImage from '../../assets/images/student.webp';
import { useTranslation } from 'react-i18next';
import Alert from '../../Components/Alert';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  auth,
} from "../../firebase";
import { setSideBar, setUser } from "../../reduxStore/slices/authSlice";
function App() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({ email: '', password: '' });
const [ showError,setShowError]= useState();

const [errorModalActive, setErrorModalActive] = useState(false);
const [errorMessage, setErrorMessage] = useState("");
const [forgotPasswordActive, setForgotPasswordActive] = useState(false);
const [resetEmail, setResetEmail] = useState("");
const dispatch = useDispatch();
const navigate = useNavigate();
const token = useSelector((state) => state.auth.token);
const screenType = useSelector((state) => state.website.screenType);
  const validateForm = () => {
    let isValid = true;
    const newErrors = { email: '', password: '' };

    if (!email) {
      newErrors.email = 'Email is required';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = 'Please enter a valid email';
      isValid = false;
    }

    if (!password) {
      newErrors.password = 'Password is required';
      isValid = false;
    } else if (password.length < 6) {
      newErrors.password = 'Password must be at least 6 characters';
      isValid = false;
    }

    setErrors(newErrors);
   
    return isValid;
  };

  const handleSubmit = async() => {
  validateForm()

  if (!email) {
       setErrorMessage("Please enter your email.");
       setShowError(true)
       setErrorModalActive(true);
       return;
     }
     
     if (!password) {
       setErrorMessage("Please enter your password.");
       setErrorModalActive(true);
       setShowError(true)
       return;
     }
 
     try {
       const userCredential = await signInWithEmailAndPassword(
         auth,
         email,
         password
       );
       
       const user = userCredential.user;
       const token = await user.getIdToken();
       dispatch(setUser({ user: user.toJSON(), token }));
       setEmail("");
       setPassword("");
       navigate("/LMS");
       dispatch(setSideBar(true));
     } catch (error) {
         // Check if the error is related to invalid credentials
    if (error.code === 'auth/wrong-password') {
      setErrorMessage("Incorrect password. Please try again.");
    } else if (error.code === 'auth/user-not-found') {
      setErrorMessage("No user found with this email address.");
    } else {
      setErrorMessage(`Error: ${error.message}`);
    }
    setErrorModalActive(true);
    setShowError(true);
  }
     
  };


  const styles = {
    container: {
      minHeight: '90vh',
      width:"95%",
      margin: screenType==="tab"||screenType==="tablet" ?  "5vw auto": screenType==="phone"||screenType==="smallPhone"? "2vh auto" : "1vw auto" ,
      borderRadius:"20px",
      
      display: 'flex',
      flexDirection: window.innerWidth <= 768 ? 'column' : 'row',
      boxShadow: '0 10px 15px rgba(0, 0, 0, 0.5)',

    },
    leftPanel: {
      flex: '1',
      background: 'linear-gradient(135deg, rgb(32, 32, 32) 0%, rgb(5, 1, 9) 100%)',
      display: 'flex',
      flexDirection: 'column' ,
      justifyContent: 'center',
      overflow:"hidden",
      alignItems: 'center',
      padding: window.innerWidth <= 768 ? '20px' : '40px',
      color: 'white',
      position: 'relative' ,
      borderRadius:"10px 0px 0px 10px",
      minHeight: window.innerWidth <= 768 ? '300px' : 'auto',
    },
    graphicOverlay: {
      position: 'absolute' ,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundImage: `url(${studentImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      opacity: 0.2,
    },
    leftContent: {
      position: 'relative' ,
      zIndex: 1,
      textAlign: 'center' ,
      maxWidth: '400px',
    },
    rightPanel: {
      flex: '1',
      display: 'flex',
      borderRadius:"0px 10px 10px 0px",
      overflow:"hidden",
      justifyContent: 'center',
      alignItems: 'center',
      padding: window.innerWidth <= 768 ? '20px' : '40px',
      background: 'white',
    },
    form: {
      width: '100%',
      maxWidth: '400px',
    },
    title: {
      fontSize: '2.5rem',
      color: 'white',
      marginBottom: '20px',
      fontWeight: '700',
      textShadow: '0 2px 4px rgba(0,0,0,0.1)',
    },
    rightTitle: {
      fontSize: '2rem',
      color: '#1a1a1a',
      marginBottom: '10px',
      fontWeight: '700',
    },
    subtitle: {
      fontSize: '1.1rem',
      color: 'rgba(255,255,255,0.9)',
      lineHeight: '1.6',
    },
    rightSubtitle: {
      color: '#666',
      fontSize: '1rem',
      marginBottom: '30px',
    },
    inputGroup: {
      position: 'relative' ,
      marginBottom: '20px',
    },
    input: {
      width: '80%',
      padding: '14px 40px',
      fontSize: '1rem',
      border: '2px solid #e1e1e1',
      borderRadius: '12px',
      outline: 'none',
      transition: 'all 0.3s ease',
    },
    icon: {
      position: 'absolute' ,
      left: '12px',
      top: '50%',
      transform: 'translateY(-50%)',
      color: '#666',
    },
    togglePassword: {
      position: 'absolute' ,
      right: '12px',
      top: '50%',
      transform: 'translateY(-50%)',
      cursor: 'pointer',
      background: 'none',
      border: 'none',
      padding: '0',
      color: '#666',
    },
    error: {
      color: '#dc2626',
      fontSize: '0.875rem',
      marginTop: '4px',
    },
    button: {
      width: '100%',
      background: 'linear-gradient(135deg, #667eea 0%, #764ba2 100%)',
      color: 'white',
      padding: '14px',
      borderRadius: '12px',
      border: 'none',
      fontSize: '1rem',
      fontWeight: '600',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '8px',
      transition: 'all 0.3s ease',
    },
  };

  // Add window resize listener for responsive design
  useState(() => {
    const handleResize = () => {
     
      setEmail(email);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });
  const {t} = useTranslation()
  return (
    <div style={styles.container}>
      <div style={styles.leftPanel}>
        <div style={styles.graphicOverlay} />
        <div style={styles.leftContent}>
          <h1 style={styles.title}>{t("Welcome to Our Platform")}</h1>
          <p style={styles.subtitle}>
            {t("Join thousands of users who trust our platform for their daily needs.   Experience seamless integration and powerful features.")}
          </p>
        </div>
      </div>

      <div style={styles.rightPanel}>
        <div style={styles.form}>
          <h2 style={styles.rightTitle}>{t("Sign In")}</h2>
          <p style={styles.rightSubtitle}>{t("Please sign in to continue")}</p>

          <div>
            <div style={styles.inputGroup}>
              <Mail size={20} style={styles.icon} />
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{
                  ...styles.input,
                  borderColor: errors.email ? '#dc2626' : '#e1e1e1',
                }}
                onFocus={(e) => e.target.style.borderColor = '#667eea'}
                onBlur={(e) => e.target.style.borderColor = errors.email ? '#dc2626' : '#e1e1e1'}
              />
            </div>
              {errors.email && <p style={styles.error}>{errors.email}</p>}

            <div style={styles.inputGroup}>
              <Lock size={20} style={styles.icon} />
              <input
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={{
                  ...styles.input,
                  borderColor: errors.password ? '#dc2626' : '#e1e1e1',
                }}
                onFocus={(e) => e.target.style.borderColor = '#667eea'}
                onBlur={(e) => e.target.style.borderColor = errors.password ? '#dc2626' : '#e1e1e1'}
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                style={styles.togglePassword}
              >
                {showPassword ?<Eye size={20} /> : <EyeOff size={20} />}
              </button>
            </div>
              {errors.password && <p style={styles.error}>{errors.password}</p>}

            <button
              // type="submit"
              style={styles.button}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = 'translateY(-2px)';
                e.currentTarget.style.boxShadow = '0 4px 12px rgba(0, 0, 0, 0.15)';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = 'none';
                e.currentTarget.style.boxShadow = 'none';
              }}
              onClick={ handleSubmit}
            >
              {t("Sign In")}
              <ArrowRight size={20} />
            </button>
            
          </div>
          
        </div>
      </div>
          {showError && (
          <Alert
            type="error"
            message="Invalid email and password"
            show={showError}
            onClose={() => setShowError(false)}
          />
        )}
    </div>
  );
}

export default App;