import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import useScreenSizes from "../../utils/useScreenSize";
import AnimatedBird from "../studyBuddyWA/AnimationBird";
import { useSelector, useDispatch } from "react-redux";
import { setAnimate, setTreeClick } from "../../reduxStore/slices/WebSiteData";
import SidebarMobile from "./SidebarMobile";
import NotificationChatSidebar from "../test/ChatSideBar";
import { width } from "@mui/system";
import App from "../SidebarNotification/MessageBox";

const TitleBar = () => {
  const screen = useScreenSizes();
  const dispatch = useDispatch();
  const [height, setHeight] = useState("small");
  const [reach, setReach] = useState(true);
  const location = useLocation();
  const screenType = useSelector((state) => state.website.screenType);
  const TitlebarHeight = useSelector((state) => state.website.TitlebarHeight);
  const animate = useSelector((state) => state.website.animate);
  const treeClick = useSelector((state) => state.website.treeClick);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [woodenBoardState, setWoodenBoardState] = useState(null);
  const currentTheme = useSelector((state) => state.website.theme);
  const [expandTitlebar, setExpandTitlebar] = useState(false);
  const [woodenBoard, setWoodenBoard] = useState({
    myHome: { height: "110px", width: "130px" },
    Blogs: { height: "110px", width: "130px" },
    Communities: { height: "110px", width: "130px" },
    LearningArena: { height: "110px", width: "130px" },
  });

  useEffect(() => {
    if (screen === "desktop") {
      setWoodenBoard({
        myHome: { height: "110px", width: "130px" },
        Blogs: { height: "110px", width: "130px" },
        Communities: { height: "110px", width: "130px" },
        LearningArena: { height: "110px", width: "130px" },
      });
    } else if (screenType === "tablet") {
      setWoodenBoard({
        myHome: { height: "110px", width: "130px" },
        Blogs: { height: "110px", width: "130px" },
        Communities: { height: "110px", width: "130px" },
        LearningArena: { height: "110px", width: "130px" },
      });
    } else if (screenType === "tab") {
      setWoodenBoard({
        myHome: { height: "110px", width: "130px" },
        Blogs: { height: "110px", width: "130px" },
        Communities: { height: "110px", width: "130px" },
        LearningArena: { height: "110px", width: "130px" },
      });
    }
  }, [screen, screenType]);

  const getActiveBoard = () => {
    const pathname = location.pathname;
    if (pathname.includes("vision")) return "myHome";
    if (pathname.includes("blogs")) return "Blogs";
    if (pathname.includes("Communities")) return "Communities";
    if (pathname.includes("solution")) return "LearningArena";
    return null;
  };

  useEffect(() => {
    if (animate) {
      setTimeout(() => dispatch(setAnimate(false)), 2000);
    }
  }, [animate]);

  const handleTreeButtonClick = () => {
    dispatch(setTreeClick(!treeClick));
  };

  const activeBoard = getActiveBoard();

  const handleMouseEnter = (board) => {
    setWoodenBoardState(board);

    if (screenType !== "tab") {
      setWoodenBoard((prevSizes) => ({
        ...prevSizes,
        [board]: {
          ...prevSizes[board],
          height: activeBoard === board ? "130px" : "130px",
          width: activeBoard === board ? "150px" : "150px",
          textTop: "55px",
          transition: "0.3s all linear",
        },
      }));
    } else {
      setWoodenBoard((prevSizes) => ({
        ...prevSizes,
        [board]: {
          ...prevSizes[board],
          height: activeBoard === board ? "130px" : "130px",
          width: activeBoard === board ? "150px" : "150px",
          textTop: activeBoard === board ? "57px" : "49px",
          transition: "0.3s all linear", // Add transition for smooth animation
        },
      }));
    }
  };

  const handleNotificationClick = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const handleMouseLeave = (board) => {
    setWoodenBoardState(null);

    if (screenType !== "tab") {
      setWoodenBoard((prevSizes) => ({
        ...prevSizes,
        [board]: {
          ...prevSizes[board],
          height: activeBoard === board ? "130px" : "110px",
          width: activeBoard === board ? "150px" : "130px",
          textTop: activeBoard === board ? "55px" : "49px",
        },
      }));
    } else {
      setWoodenBoard((prevSizes) => ({
        ...prevSizes,
        [board]: {
          ...prevSizes[board],
          height: activeBoard === board ? "130px" : "110px",
          width: activeBoard === board ? "150px" : "130px",
          textTop: activeBoard === board ? "57px" : "49px",
        },
      }));
    }
  };


  useEffect(() => {
    if (TitlebarHeight === "100dvh") {
      setExpandTitlebar(true)
    }
    else {
      setExpandTitlebar(false)
    }
  }, [TitlebarHeight])

  const handleNavigation = (path) => {

  };
  const placeholders = ["Search news", "Search user", "Search course", "Search products"];
  const [currentPlaceholder, setCurrentPlaceholder] = useState(placeholders[0]);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentIndex = placeholders.indexOf(currentPlaceholder);
      const nextIndex = (currentIndex + 1) % placeholders.length;
      setCurrentPlaceholder(placeholders[nextIndex]);
    }, 3000); // Change placeholder every 3 seconds

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [currentPlaceholder]);
  const [search, setSearch] = useState(false)
  const styles = {
    container: {
      margin: 0,
      padding: 0,
      // height: TitlebarHeight,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      position: "fixed",
      zIndex: 100,
    },
    header: {
      backgroundImage: `url(${require("../../assets/images/background.webp")})`,
      display: "flex",
      alignItems: "end",
      width: "100%",
      backgroundPosition: "top",
      height: TitlebarHeight,
      backgroundRepeat: "repeat-x",

      transition: "height 0.5s ease-in-out",
      position: "absolute",
      overflow: "hidden",
    },
    GrassContainer: {
      position: "absolute",
      display: "flex",
      width: "100vw",
      height: screenType === "smallPhone" || screenType === "phone" ? "10px" : "10px",
      flexDirection: "row",
      backgroundImage: `url(${require("../../assets/images/grass.webp")})`,
      backgroundSize: "contain",
      backgroundRepeat: "repeat-x",
      zIndex: 2,
    },
    TreeButton: {
      cursor: "pointer",
      position: "absolute",
      zIndex: 1,
      bottom: "-3px",
      width: screenType === "phone" || screenType === "smallPhone" ? "10vh" : screenType === "tab" || screenType === "tablet" ? "13vh" : "16vh",
    },
    wordmark: {
      position: "absolute",
      zIndex: 0,
      cursor: "pointer",
      left: screenType === "phone" ? "41%" : screenType === "smallPhone" ? "41%" : screenType === "tablet" ? "40%" : screenType === "tab" ? "40%" : "41%",
      top: screen >= 500 && screen <= 650 ? "29%" : screenType === "smallPhone" ? "53%" : screenType === "phone" ? "48%" : screenType === "tab" ? "29%" : screenType === "tablet" ? "30%" : "25%",
      transition: "height 0.5s ease-out, left 0.5s ease-out, top 0.5s ease-out",
      zIndex: 100,
    },
    myHome: {
      position: "absolute",
      left: screenType === "tab" ? "12%" : "12%",
      top: TitlebarHeight === "10vh" || TitlebarHeight === "6vh" || search ? "-40px" : "-25px",
      transition: "height 3s ease-in-out, top 0.5s ease-out",
      opacity: reach ? 1 : 0,
      cursor: "pointer",
      zIndex: 10,
    },
    boardTextVision: {
      fontFamily: "lemonfont",
      position: "absolute",
      left:  "24%",
      fontSize: screenType === "tab" ? "11px" : "small",
      top: woodenBoardState === "myHome" || activeBoard === "myHome" ? screenType === "tab" ? "59px" : "67px" : reach ? screenType === "tab" ? "56px" : "57px" : "-25px",
      transition: "top s ease-in-out",
      opacity: reach ? 1 : 0,
      color: "white",
    },

    Blogs: {
      position: "absolute",
      right: "22%",
      top: TitlebarHeight === "10vh" || TitlebarHeight === "6vh" || search ? "-40px" : "-25px",
      transition: "top 0.5s ease-out",
      opacity: reach ? 1 : 0,
      cursor: "pointer",
      zIndex: 10,
    },
    boardTextBlogs: {
      fontFamily: "lemonfont",
      position: "absolute",
      right:  "32%",
      fontSize: screenType === "tab" ? "small" : "small",
      top: woodenBoardState === "Blogs" || activeBoard === "Blogs" ? screenType === "tab" ? "57px" : "67px" : reach ? screenType === "tab" ? "56px" : "57px" : "-25px",
      transition: "top 0s ease-out",
      opacity: reach ? 1 : 0,
      color: "white",
    },
    Communities: {
      position: "absolute",
      right: screenType === "tab" ? "8%" : screenType === "tablet" ? "8%" : "8%",
      top: TitlebarHeight === "10vh" || search ? "-40px" : "-25px",
      transition: "top 0.5s ease-out",
      opacity: reach ? 1 : 0,
      cursor: "pointer",
      zIndex: 10,
    },
    boardTextFAQ: {
      fontFamily: "lemonfont",
      position: "absolute",
      right:  activeBoard === "LearningArena" ? "29%" : "10.4%",
      fontSize: screenType === "tab" ? "small" : "small",
      top: woodenBoardState === "Communities" || activeBoard === "Communities" ? screenType === "tab" ? "57px" : "67px" : reach ? screenType === "tab" ? "56px" : "57px" : "-25px",
      transition: "top 0s ease-out",
      opacity: reach ? 1 : 0,
      color: "white",
    },
    LearningArena: {
      position: "absolute",
      left: screenType === "tab" || screenType === "tablet" ? "26%" : "26%",
      top: TitlebarHeight === "10vh" || TitlebarHeight === "10vh" || search ? "-40px" : "-25px",
      transition: "top 0.5s ease-out",
      opacity: reach ? 1 : 0,
      cursor: "pointer",
      zIndex: 10,
    },
    boardTextLearningArena: {
      fontFamily: "lemonfont",
      position: "absolute",
      width:"35%",
      fontSize: screenType === "tab" ? "small" : "small",
      left:  "29%",
      top: woodenBoardState === "LearningArena" || activeBoard === "LearningArena" ? screenType === "tab" ? "57px" : "60px" : reach ? screenType === "tab" ? "49px" : "49px" : "-25px",
      transition: "top 0s ease-out",
      opacity: reach ? 1 : 0,
      color: "white",
    },
    rockContainer: {
      backgroundColor: "transparent",
      position: "absolute",
      right: 0,
      bottom: -2,
      opacity: reach ? 1 : 0,
      right: reach ? "10px" : "-200px",
      transition: "right 1s ease-out",
      cursor: "pointer",
      zIndex: 10,
    },
  };
  const hanldleClick = () => {

    setSearch(true);
  }
  const handleBlur = () => {
    setSearch(false); // Set to false when input loses focus
  };

  const handleCloseSidebar = () => {
    // Logic for closing the sidebar
    setIsSidebarOpen(false); // Example: setting the sidebar to closed
  };
  return (
    <div>
      <div style={styles.container}>
        <AnimatedBird />
        <div style={styles.header}>


        
          <div>
            {screenType === "desktop" && TitlebarHeight === "10vh" || (screenType === "tab" || screenType === "tablet") && TitlebarHeight === "10vh" || search || TitlebarHeight === "6vh" ? (
              <div>
                <img
                  src={require("../../assets/images/wordmark.webp")}
                  style={{
                    height: screen >= 500 && screen <= 700 ? "5vw" : screenType === "phone" ? "25px" : screenType === "smallPhone" ? "20px" : screenType === "tab" ? "4vw" : screenType === "tablet" ? "2vw" : "45px",
                  }}
                />
              </div>
            ) : (
              <div style={styles.TreeButton} onClick={handleTreeButtonClick}>
                <img src={require("../../assets/images/logo.webp")} style={{ width: "100%", marginLeft: 10 }} />
              </div>
            )}
          </div>
          <div style={styles.GrassContainer}></div>

          {!expandTitlebar ? (

            <>
  <div
            style={{
              height: "10%",
              display: "flex",
              // flex: 1,
              flexShrink: 4,
              position: "absolute",
              top: screenType === "tab" ? "65%" : "58%",
              right: screenType === "smallPhone" ? "8%" : screenType === "phone" ? "28.1%" : screenType === "tab" ? "35.4%" : screenType === "tablet" ? "36%" : "38%",
              zIndex: 10,
              alignItems: "center", // Align content vertically in the container
            }}
          >
            <img
              src={require("../../assets/images/search.webp")}
              alt="search icon"
              style={{
                position: "absolute",
                // left: "10px", // Position the search icon inside the input
                right: screenType === "smallPhone" ? "17.5%" : screenType === "phone" ? "25%" : screenType === "tab" ? "28.4%" : "15%",
                width: "20px", // Size of the icon
                height: "20px", // Ensure it fits within the input field
              }}
            />
            <input
              placeholder={currentPlaceholder}
              style={{
                width: screenType === "desktop" ? "90%" : screenType === "tab" ? "75%" : "80%", // Adjust width based on screen size
                padding: screenType === "smallPhone" ? "2px" : screenType === "phone" ? "7px" : screenType === "tab" ? "10px" : "8px", // Adjust padding for smaller screens
                fontSize: screenType === "phone" ? "14px" : screenType === "tab" ? "14px" : "18px", // Adjust font size for different screen types
                borderRadius: "5px",
                border: "1px solid #ccc",
                boxSizing: "border-box", // Ensures padding doesn't affect the width calculation
                transition: "all 0.3s ease", // Smooth transition for resizing
                // paddingLeft: "30px", // Create space for the search icon

              }}
              onBlur={handleBlur}
              onClick={hanldleClick}
            />

          </div>
              <div style={styles.rockContainer} onClick={() => handleNotificationClick()}>
                <img src={require("../../assets/images/notification.webp")} style={{ height: TitlebarHeight === "10vh" || TitlebarHeight === "6vh" || search ? "40px" : "70px", width: "auto", transition: "height 0.5s ease-out" }} />
              </div>
              {!(screenType === "smallPhone" || screenType === "phone"||screen<=900&& screen>=650) ? (
                <>
                  <div
                    style={styles.myHome}
                    {...(screenType !== "tab" && {
                      onMouseEnter: () => handleMouseEnter("myHome"),
                      onMouseLeave: () => handleMouseLeave("myHome"),
                    })}
                    onClick={() => handleNavigation("/vision")}
                  >
                    <img
                      src={require("../../assets/images/Wooden-Board.webp")}
                      style={{
                        height: woodenBoard.myHome.height,
                        width: woodenBoard.myHome.width,
                      }}
                      alt="myHome"
                    />
                    <div style={styles.boardTextVision}>{"My Home"}</div>
                  </div>

                  <div
                    style={styles.Blogs}
                    {...(screenType !== "tab" && {
                      onMouseEnter: () => handleMouseEnter("Blogs"),
                      onMouseLeave: () => handleMouseLeave("Blogs"),
                    })}
                    onClick={() => handleNavigation("/blogs")}
                  >
                    <img
                      src={require("../../assets/images/Wooden-Board.webp")}
                      style={{
                        height: woodenBoard.Blogs.height,
                        width: woodenBoard.Blogs.width,
                      }}
                      alt="Blogs"
                    />
                    <div style={styles.boardTextBlogs}>{"Blogs"}</div>
                  </div>
                  <div
                    style={styles.Communities}
                    {...(screenType !== "tab" && {
                      onMouseEnter: () => handleMouseEnter("Communities"),
                      onMouseLeave: () => handleMouseLeave("Communities"),
                    })}
                    onClick={() => handleNavigation("/Communities")}
                  >
                    <img
                      src={require("../../assets/images/Wooden-Board.webp")}
                      style={{
                        height: woodenBoard.Communities.height,
                        width: woodenBoard.Communities.width,
                      }}
                      alt="Blogs"
                    />
                    <div style={styles.boardTextFAQ}>{"Communities"}</div>
                  </div>
                  <div
                    style={styles.LearningArena}
                    {...(screenType !== "tab" && {
                      onMouseEnter: () => handleMouseEnter("LearningArena"),
                      onMouseLeave: () => handleMouseLeave("LearningArena"),
                    })}
                    onClick={() => handleNavigation("/solution")}
                  >
                    <img
                      src={require("../../assets/images/Wooden-Board.webp")}
                      style={{
                        height: woodenBoard.LearningArena.height,
                        width: woodenBoard.LearningArena.width,
                      }}
                      alt="LearningArena"
                    />
                    <div style={styles.boardTextLearningArena}>{"Learning Arena"}</div>
                  </div>
                </>

              ) : (
                <div style={{ zIndex: 100 }}>
                  <SidebarMobile />
                </div>
              )}
              {isSidebarOpen && <App isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen}  onClose={handleCloseSidebar} />}
            </>) : null}
        </div>
      </div>
    </div>
  );
};

export default TitleBar;