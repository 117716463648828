import { useEffect } from 'react';
import { auth, storage, messaging, functions, firestore } from '../firebase';
import { convertToJSON, convertToStringified, storageEvents } from './notificationService';
import  encryptData  from '../utils/encrypt';
import { FCMTOKENKEY } from '../environmentVariables';
import { decryptData } from '../utils/decrypt';

export const backgroundServiceHandler = async () => {
  console.log('Background task is running...');
  let counter = 0; // Initialize the counter

  while (true) { // Infinite loop to simulate background work
    counter++; // Increment the counter
    console.log(`Performing background work... Counter: ${counter}`);

    try {
      // Fetch data from localStorage
      const asyncStorageData = localStorage.getItem('persist:input');
      if (!asyncStorageData) {
        console.error('No data found in localStorage');
        return;
      }

      // Convert stored data to JSON
      const hello = convertToJSON(JSON.parse(asyncStorageData));
      let pendingTaskTracker = hello['pendingTaskTracker'] || {};
      console.log('pendingTaskTracker: ', pendingTaskTracker);

      // Process tasks
      const currentTimestamp = new Date().getTime();
      for (const id in pendingTaskTracker) {
        const task = pendingTaskTracker[id];
        console.log('task: ', task);

        if (!task || !task.timeStampToExecute) {
          console.warn(`Task with ID ${id} is missing required fields.`);
          continue;
        }

        if (task.timeStampToExecute <= currentTimestamp) {
          const result = await executePendingTask(task);
          console.log('result: ', result);

          if (result === 'success') {
            console.log('Task processed successfully:', task.id);
            if (
              task.id !== 'updateUserDataOnFirestore' &&
              task.id !== 'storeDataInStorageBucket'
            ) {
              delete pendingTaskTracker[id]; // Remove the processed task
            } else {
              // Update timestamp for certain tasks
              const prevTimeStamp = task.timeStampToExecute;
              const nextDayTimeStamp = prevTimeStamp + 60 * 1000; // 1 min
              pendingTaskTracker[id] = {
                ...task,
                timeStampToExecute: nextDayTimeStamp,
              };
            }
          } else {
            console.error('Failed to process task:', task);
          }
        } else {
          console.log('Task skipped (timestamp in future):', task);
        }
      }

      // Update localStorage with the updated tracker
      hello['pendingTaskTracker'] = pendingTaskTracker;
      const backgroundServiceData = hello['backgroundServiceRunTime'] || [];
      const newDate = new Date();
      backgroundServiceData.push(newDate.toLocaleString());
      hello['backgroundServiceRunTime'] = backgroundServiceData;

      const parsedData = convertToStringified(hello);
      localStorage.setItem('persist:input', JSON.stringify(parsedData));
      console.log('Tasks updated in localStorage successfully.');

    } catch (error) {
      console.error('Error processing tasks:', error);
      break; // If error occurs, break out of the loop
    }

    // Wait for a period before the next iteration (simulating a background task)
    await new Promise(resolve => setTimeout(resolve, 900000)); // 15 minutes
  }
};

const executePendingTask = async (task) => {
  switch (task.type) {
    case 'notification':
      const response = await handleNotificationType(task.data);
      return response === 'success' ? 'success' : 'failure';
    case 'updateUserDataOnFirestore':
      const response1 = await updateUserDataOnFirestore();
      return response1 === 'success' ? 'success' : 'failure';
    case 'storeDataInStorageBucket':
      const responseData = await storeFileInStorageBucket();
      return responseData === 'success' ? 'success' : 'failure';
    default:
      console.error('Unknown task type:', task.type);
      return 'failure';
  }
};

const handleNotificationType = async (notification) => {
  console.log('notification: ', notification);
  
  // Using browser notifications
  if ('Notification' in window) {
    const notificationData = {
      title: notification.title,
      body: notification.body,
      data: notification.targetScreen ? { targetScreen: notification.targetScreen } : {},
    };

    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      new Notification(notificationData.title, notificationData);
      return 'success';
    } else {
      console.error('Notification permission denied');
      return 'failure';
    }
  }
};

const updateUserDataOnFirestore = async () => {
  console.log('update userdata on firestore called');
  // Update Firebase Firestore as needed
  return 'success';
};

// Function to start background-like service (Web Worker)
export const startBackgroundService = async () => {
  try {
    const worker = new Worker('./backgroundWorker.js'); // Your Web Worker file
    worker.postMessage({ type: 'start' });

    console.log('Background service started');
  } catch (error) {
    console.error('Error starting background service:', error);
  }
};

// Storing data in Firebase Storage (in Web)
 export const storeFileInStorageBucket = async () => {
  const storageBucketTimeStamp = Date.now();
  const fileContent = JSON.stringify({ data: 'example' });
  
  try {
    const encryptedFileContent = await encryptData(fileContent, FCMTOKENKEY);
    
    // Save to Firebase storage (using Firebase Web SDK)
    const storageRef = storage().ref('storedData.txt');
    await storageRef.putString(encryptedFileContent);

    storageEvents.emit('storageBucket', { key: 'user', value: fileContent });
    console.log('File uploaded to Firebase Storage');
    return 'success';
  } catch (error) {
    console.error('Error uploading file:', error);
    return 'failure';
  }
};

// Web Worker for simulating long-running tasks in the background
// backgroundWorker.js (code for web worker)
window.addEventListener('message', (e) => {
  const { type } = e.data;
  if (type === 'start') {
    backgroundServiceHandler();
  }
});

// HTML5 Web Notifications for browser
export const showBrowserNotification = (title, body) => {
  if (Notification.permission === "granted") {
    new Notification(title, {
      body,
      icon: '/path/to/icon.png',
    });
  } else if (Notification.permission !== "denied") {
    Notification.requestPermission().then(permission => {
      if (permission === "granted") {
        new Notification(title, { body, icon: '/path/to/icon.png' });
      }
    });
  }
};

