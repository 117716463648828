import React from 'react';
import { Bell, Settings } from 'lucide-react';

const styles = {
  container: {
    padding: 'var(--spacing-md)'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 'var(--spacing-md)'
  },
  title: {
    fontSize: '1.125rem',
    fontWeight: 600,
    color: 'var(--text-primary)'
  },
  settingsButton: {
    padding: 'var(--spacing-sm)',
    borderRadius: 'var(--radius-md)',
    transition: 'all 0.2s',
          borderColor:"transparent"
  },
  notificationList: {
    display: 'flex',
    flexDirection: 'column',
    gap: 'var(--spacing-md)'
  },
  notificationItem: (read) => ({
    padding: 'var(--spacing-md)',
    borderRadius: 'var(--radius-lg)',
    backgroundColor: read ? 'var(--bg-message-read)' : 'var(--bg-message-unread)'
  }),
  notificationContent: {
    display: 'flex',
    gap: 'var(--spacing-sm)',
    alignItems: 'flex-start'
  },
  iconContainer: (read) => ({
    marginTop: '4px',
    padding: 'var(--spacing-sm)',
    borderRadius: 'var(--radius-full)',
    backgroundColor: read ? 'var(--bg-hover)' : 'var(--primary-light)',
    color: read ? 'var(--text-secondary)' : 'white'
  }),
  notificationTitle: {
    fontWeight: 500,
    color: 'var(--text-primary)'
  },
  notificationMessage: {
    fontSize: '0.875rem',
    color: 'var(--text-secondary)',
    marginTop: '4px'
  },
  notificationTime: {
    fontSize: '0.75rem',
    color: 'var(--text-light)',
    display: 'block',
    marginTop: '8px'
  }
};

const notifications = [
  {
    id: 1,
    title: 'New feature available',
    message: 'Check out our latest update with exciting features!',
    time: '2 hours ago',
    read: false,
  },
  {
    id: 2,
    title: 'System maintenance',
    message: 'Scheduled maintenance will occur tonight at 2 AM',
    time: '5 hours ago',
    read: true,
  },
];

function NotificationPanel() {
  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <h2 style={styles.title}>Notifications</h2>
        <button
          style={styles.settingsButton}
          onMouseEnter={(e) => {
            e.currentTarget.style.backgroundColor = 'var(--bg-hover)';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.backgroundColor = 'transparent';
          }}
        >
          <Settings style={{ width: '20px', height: '20px' }} />
        </button>
      </div>

      <div style={styles.notificationList}>
        {notifications.map((notification) => (
          <div
            key={notification.id}
            style={styles.notificationItem(notification.read)}
          >
            <div style={styles.notificationContent}>
              <div style={styles.iconContainer(notification.read)}>
                <Bell style={{ width: '16px', height: '16px' }} />
              </div>
              <div>
                <h3 style={styles.notificationTitle}>
                  {notification.title}
                </h3>
                <p style={styles.notificationMessage}>
                  {notification.message}
                </p>
                <span style={styles.notificationTime}>
                  {notification.time}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default NotificationPanel;