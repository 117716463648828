import React, { useState, useCallback, useEffect } from 'react';
import {
    SafeAreaView,
    StatusBar,
    StyleSheet
} from 'react-native';
import { algoliasearch } from 'algoliasearch';
import { InstantSearch, Configure, Index } from 'react-instantsearch-core';
import  InfiniteHits from '../../searching/infiniteHits';
import PostCard from '../postCard';
import Thoughts from '../thoughts';
import Skeleton from 'react-loading-skeleton'; // Skeleton package

import { useLocation } from 'react-router-dom';

const searchClient = algoliasearch('7BER79SCQK', '51f6b19d6579616e0bad6f843d293325');

function PostSearch({ idsArray }) {
    useEffect(()=>{
    } , [idsArray])
    const [usersData, setUsersData] = useState({});
    const [fetchedUserIds, setFetchedUserIds] = useState(new Set());
    const [isFetchingUsers, setIsFetchingUsers] = useState(false);
    const [posts, setPosts] = useState([]); // Store posts locally
    const location = useLocation();
    // Fetch user details
    const fetchUsers = async (userIds) => {
        const newUserIds = userIds.filter(id => !fetchedUserIds.has(id));
        if (newUserIds.length === 0) return;

        setIsFetchingUsers(true);

        try {
            const response = await searchClient.getObjects({
                requests: newUserIds.map(id => ({
                    objectID: id,
                    indexName: "SBUser"
                }))
            });

            const users = response.results || [];

            const userMap = {};
            users.forEach(user => {
                if (user && user.objectID) {
                    userMap[user.objectID] = user;
                }
            });

            setUsersData(prev => ({ ...prev, ...userMap }));
            setFetchedUserIds(prev => new Set([...prev, ...newUserIds]));
        } catch (error) {
            console.error('Error fetching user details:', error);
        }

        setIsFetchingUsers(false);
    };

    const fetchPosts = async () => {
        try {
            const { hits } = await searchClient.searchSingleIndex({
                indexName: "CommunityPosts",
                searchParams: {
                    query: "",
                    facetFilters: idsArray?.length > 0 ? [idsArray.map(id => `objectID:${id}`)] : [],
                    hitsPerPage: 100,
                },
            });

            if (JSON.stringify(hits) !== JSON.stringify(posts)) {
                setPosts(hits);
                const userIds = [...new Set(hits.map(hit => hit.postedBy).filter(Boolean))];
                fetchUsers(userIds);
            }
        } catch (error) {
            console.error("Error fetching posts:", error);
        }
    };


    // Poll Algolia every 5 seconds for changes (only updates when needed)
    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         fetchPosts();
    //     }, 5000); // Check every 5 seconds

    //     return () => clearInterval(interval);
    // }, [posts]); // Depend on `posts`, only update if needed

    useEffect(() => {
        console.log('oojoj');
        setUsersData({});
        // searchClient.clearCache();
        setFetchedUserIds(new Set());
        setPosts([]);
        fetchPosts();
    }, [location.pathname]);  // Runs when the route 
    function CommunityPost({ hit }) {
        console.log('hit: ', hit);
        console.log("{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{")
        const [expendEnabled, setExpendEnabled] = useState('');
        const [disabled, setDisabled] = useState(false);
        useEffect(() => {
            setDisabled(true);
            setTimeout(() => {
                setDisabled(false); // Re-enable after 0.8 seconds
            }, 800);
        }, [expendEnabled]);

        const user = usersData[hit.postedBy];
        const post = posts.filter(item => item.objectID == hit.objectID)[0]

        return (user && post) ? (

            post.title === 'Post' ?
                <PostCard item={post} data={user} />
                :
                <Thoughts post={post} data={user} disabled={disabled} expendEnable={expendEnabled} setExpendEnable={setExpendEnabled} communityVersion={true} />
        ) : (
            <Skeleton
                isLoading={true}
                animationType="shiver"
                boneColor="#E1E9EE"
                highlightColor="#F2F8FC"
                animationDirection="horizontalRight"
                layout={[
                    {
                        key: 'someId',
                        width: '100%',
                        height: 180,
                        borderRadius: 12,
                        marginVertical: 5,
                    },
                ]} />
        );
    }

    return (
        <SafeAreaView style={styles.safe}>
            <StatusBar style="light" />
            <InstantSearch searchClient={searchClient}>
                <Index indexName="CommunityPosts">
                    <Configure
                        facetFilters={idsArray?.length > 0 ? [idsArray.map(id => `objectID:${id}`)] : []}
                        hitsPerPage={100}
                    />
                    <InfiniteHits hitComponent={CommunityPost} onHitsUpdate={() => console.log('postSearch.js')} />
                </Index>
            </InstantSearch>
        </SafeAreaView>
    );
}
export default PostSearch;
const styles = {
    safe: { flex: 1, backgroundColor: '#C7985F', display: "flex", flexDirection: "column", },
};