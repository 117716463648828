import React, { useEffect, useState } from "react";
import { User, Mail, Building2, Phone } from "lucide-react";
import { addDoc, collection } from "firebase/firestore";
import { firestore } from "../../firebase";
import { useTranslation } from "react-i18next";
import Alert from "../../Components/Alert";

const styles = {
  container: {
    minHeight: "100vh",
    display: "flex",
    width: "95%",
    borderRadius: "10px",
    margin: "2vw auto",
    backgroundColor: "rgba(239, 239, 242, 0.8)",
    marginBottom:"20px",
    boxShadow: '0 10px 10px rgba(0, 0, 0, 0.5)',
  },
  hero: {
    display: "none",
    flex: "1 1 50%",
    position: "relative",
    overflow:"hidden"
  },
  heroImage: {
    position: "absolute",
    inset: 0,
    width: "100%",
    height: "100%",
    borderRadius: "10px 0px 0px 10px",
    objectFit: "cover",
  },
  overlay: {
    position: "absolute",
    inset: 0,
    borderRadius: "10px",
    backgroundColor: "rgba(61, 61, 69, 0.8)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "3rem",
  },
  heroContent: {
    maxWidth: "28rem",
    textAlign: "center",
  },
  heroTitle: {
    fontSize: "2.25rem",
    fontWeight: "bold",
    color: "white",
    marginBottom: "1.5rem",
  },
  heroDescription: {
    fontSize: "1.125rem",
    color: "rgba(255, 255, 255, 0.9)",
  },
  formSection: {
    width: "100%",
    flex: "1 1 50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "2rem",
  },
  formWrapper: {
    maxWidth: "28rem",
    width: "100%",
  },
  formHeader: {
    textAlign: "center",
    marginBottom: "2.5rem",
  },
  formTitle: {
    fontSize: "1.875rem",
    fontWeight: "bold",
    color: "#1a1a1a",
    marginBottom: "0.5rem",
  },
  subtitle: {
    color: "#666666",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "1.5rem",
  },
  fieldContainer: {
    marginBottom: "1rem",
  },
  label: {
    display: "block",
    fontSize: "0.875rem",
    fontWeight: "500",
    color: "#374151",
    marginBottom: "0.25rem",
  },
  inputWrapper: {
    position: "relative",
  },
  iconWrapper: {
    position: "absolute",
    left: "0.75rem",
    top: "50%",
    transform: "translateY(-50%)",
    display: "flex",
    alignItems: "center",
    pointerEvents: "none",
  },
  icon: {
    width: "1.25rem",
    height: "1.25rem",
    color: "#9CA3AF",
  },
  input: {
    width: "100%",
    padding: "0.5rem 0.75rem 0.5rem 2.5rem",
    borderRadius: "0.5rem",
    border: "1px solid #D1D5DB",
    fontSize: "1rem",
    outline: "none",
    transition: "all 0.2s",
  },
  button: {
    width: "100%",
    padding: "0.75rem 1rem",
    backgroundColor: "#4F46E5",
    color: "white",
    border: "none",
    borderRadius: "0.5rem",
    fontSize: "1rem",
    fontWeight: "500",
    cursor: "pointer",
    transition: "all 0.2s",
  },
  terms: {
    textAlign: "center",
    fontSize: "0.875rem",
    color: "#666666",
    marginTop: "1rem",
  },
  link: {
    color: "#4F46E5",
    textDecoration: "none",
  },
};
const FormField = ({
  name,
  label,
  icon,
  type,
  placeholder,
  value,
  onChange,
}) => (
  <div style={styles.fieldContainer}>
    <label style={styles.label}>{label}</label>
    <div style={styles.inputWrapper}>
      <span style={styles.iconWrapper}>{icon}</span>
      <input
        name={name}
        type={type}
        required
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        style={styles.input}
      />
    </div>
  </div>
);

function App() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    InstituteName: "",
    phone: "",
  });

 

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
const [showSuccess, setShowSucces ]= useState(false)
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(firestore, "getStartedUser"), {
        ...formData,
        timestamp: new Date(),
      });

      setShowSucces(true);
      setFormData({ name: "", email: "", InstituteName: "", phone: "" });
    } catch (error) {
      console.error("Error sending message: ", error);
      alert("An error occurred. Please try again.");
    }
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 1024px)");
    const heroElement = document.querySelector("[data-hero]");

    const handleMediaChange = (e) => {
      if (e.matches) {
        heroElement.style.display = "block";
      } else {
        heroElement.style.display = "none";
      }
    };

    mediaQuery.addListener(handleMediaChange);
    handleMediaChange(mediaQuery);

    return () => mediaQuery.removeListener(handleMediaChange);
  }, []);

const {t} = useTranslation();

  return (
    <div style={styles.container}>
      <div style={styles.hero} data-hero>
        <img
          src="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80"
          alt={t("Workspace with laptop and modern technology")}
          style={styles.heroImage}
          loading="lazy" 
        />
        <div style={styles.overlay}>
          <div style={styles.heroContent}>
            <h2 style={styles.heroTitle}>{t("Start Your Journey")}</h2>
            <p style={styles.heroDescription}>
              {t("Join thousands of users who have already transformed their experience with our platform. Get started today and unlock endless possibilities.")}
            </p>
          </div>
        </div>
      </div>
  
      <div style={styles.formSection}>
        <div style={styles.formWrapper}>
          <div style={styles.formHeader}>
            <h1 style={styles.formTitle}>{t("Get Started")}</h1>
            <p style={styles.subtitle}>{t("Fill in your information to begin")}</p>
          </div>
  
          <form onSubmit={handleSubmit} style={styles.form}>
            <FormField
              name="name"
              label={t("Full Name")}
              icon={<User style={styles.icon} />}
              type="text"
              placeholder={t("John Doe")}
              value={formData.name}
              onChange={handleChange}
            />
            <FormField
              name="email"
              label={t("Email Address")}
              icon={<Mail style={styles.icon} />}
              type="email"
              placeholder={t("abc@gmail.com")}
              value={formData.email}
              onChange={handleChange}
            />
            <FormField
              name="InstituteName"
              label={t("Institute Name")}
              icon={<Building2 style={styles.icon} />}
              type="text"
              placeholder={t("Institute Inc.")}
              value={formData.InstituteName}
              onChange={handleChange}
            />
            <FormField
              name="phone"
              label={t("Phone Number")}
              icon={<Phone style={styles.icon} />}
              type="tel"
              placeholder={t("+91 9384774487")}
              value={formData.phone}
              onChange={handleChange}
            />
  
            <button type="submit" style={styles.button}>
              {t("Get Started Now")}
            </button>
  
           
          </form> 
          {showSuccess && (
          <Alert
          type="success" 
            message="Message sent successfully!"
            show={showSuccess}
            onClose={() => setShowSucces(false)}
          />
        )}
        </div>
      </div>
    </div>
  );
}

export default App;
