import { initializeApp } from "firebase/app";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword , signOut,} from "firebase/auth";
import { getMessaging, getToken,  onMessage ,   } from "firebase/messaging";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore"; // Import Firestore
import { getFunctions, httpsCallable } from "firebase/functions";
import { getStorage , ref, uploadBytes, getDownloadURL } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAhZ8FU8oGwmKgimwoRyKnVWoXFUbdlV38",
  authDomain: "studybuddy-d48bb.firebaseapp.com",
  databaseURL: "https://studybuddy-d48bb-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "studybuddy-d48bb",
  storageBucket: "studybuddy-d48bb.appspot.com",
  messagingSenderId: "229668529371",
  appId: "1:229668529371:web:dadc6ab1e65b84284898fe",
  measurementId: "G-80SNRCN5F0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firestore and export it
const firestore = getFirestore(app);

// Initialize Firebase Authentication and export it
export const db = getFirestore(app)
export const auth = getAuth(app);
export {signOut};

export { createUserWithEmailAndPassword, signInWithEmailAndPassword };
export const functions = getFunctions(app);

const messaging = getMessaging(app);
export { messaging, getToken, onMessage , };
export const storage = getStorage(app);
export { analytics };

export { firestore };
export {  ref, uploadBytes, getDownloadURL };
export default app;


