import { View, p, div, StyleSheet } from 'react-native'
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

const PinMainComponent = ({userUid,purpose}) => {

const navigate=useNavigate()
const {t}=useTranslation()
const styles = {
  
    buttonBlue: {
      backgroundColor: '#007bff',
      padding: 15,
      borderRadius: 10,
      marginBottom: 15,
      cursor:"pointer"
    }
 ,
 buttonText: {
    color: '#fff',
    fontSize: 16,
     cursor:"pointer",
    textAlign: 'center',
  },
 forgotPin: {
    marginTop: 20,
    alignItems: 'center',
     cursor:"pointer"
  },
  forgotPinText: {
    color: '#007bff',
    fontSize:17 ,
     cursor:"pointer"
  },
   
   
  };
   // purpose can have values---> setPin || removePin || forgotPin
  //  numberOfSecurityQuestions are required in the case of setPin
  return (

    <div style={purpose!=='forgotPin'?styles.buttonBlue:styles.forgotPin}            onClick={() => navigate('allPinChanges', { state: { userUid, purpose, numberOfSecurityQuestions: 3 } })}> 

    <p style={purpose!=='forgotPin'?styles.buttonText:styles.forgotPinText}>{t(purpose=='setPin'?'Set Pin':purpose=='removePin'?'Remove Pin':purpose=='forgotPin'?'forgot Pin ?':purpose=='updateSecurityQuestions'?'updateSecurityQuestions':null)}</p>
  </div>
  )
}

export default PinMainComponent