import { display } from '@mui/system';
import React, { useState, useRef, useEffect } from 'react';
import { StyleSheet, Text, Dimensions, Animated, PanResponder } from 'react-native';
import { useLocation } from 'react-router-dom';

const { height: screenHeight, width } = Dimensions.get('window'); // Get screen height

const ImageViewer = ({ route }) => {
    const location = useLocation()
    const [images, setImages] = useState(location?.state?.images || []);

    const [selectedImage, setSelectedImage] = useState('')
    const [value, setValue] = useState(false)
    const [selectedId, setSelectedId] = useState()
    const translateY = useRef(new Animated.Value(0)).current;
    const MAX_TRANSLATE_X = 250;
    const scrollViewRef = useRef(null);
    useEffect(() => {
        if (scrollViewRef.current && selectedId !== undefined) {
            scrollViewRef.current.scrollTo({
                x: selectedId * width, // Calculate the offset based on the index
                animated: true,
            });
        }
    }, [selectedId]);
    const panResponder = useRef(
        PanResponder.create({
            onMoveShouldSetPanResponder: (evt, gestureState) => {
                return Math.abs(gestureState.dy) > 20; // Start pan responder if moved more than 20 horizontally
            },
            onPanResponderMove: (evt, gestureState) => {
                // Clamp the translation value within the defined range
                const clampedTranslateY = Math.max(0, Math.min(gestureState.dy, MAX_TRANSLATE_X));

                // Move the card to the right based on the clamped value
                Animated.timing(translateY, {
                    toValue: clampedTranslateY, // Use the clamped value for translation
                    duration: 0,
                    useNativeDriver: true,
                }).start();
            },
            onPanResponderRelease: (evt, gestureState) => {
                if (gestureState.dy > 230) {
                    goBack()
                }

                // Return the card to its original position
                Animated.spring(translateY, {
                    toValue: 0, // Return to initial position
                    useNativeDriver: true,
                }).start();
            }
        })
    ).current;

    const select = (id) => {
        setSelectedId(id)
        Animated.spring(translateY, {
            toValue: 0, // Return to initial position
            useNativeDriver: true,
        }).start();

        setSelectedImage(id)
        setValue(true)

    }

    const goBack = () => {
        setSelectedImage('')
        setValue(false)
    }
    return (
        <div style={styles.container}>
            {
                value &&
                <>
                    <div style={styles.header}>
                        <div activeOpacity={0.7} onClick={goBack} style={styles.backButton}>
                            <img src={require('../../assets/images/back4.png')} style={styles.backImage} />
                        </div>
                        <div style={styles.forwardButton}>
                            <img src={require('../../assets/images/forward.png')} style={styles.forwardImage} />
                        </div>
                    </div>

                    <div
                        {...panResponder.panHandlers}
                        style={{...styles.animatedView,  transform: { translateY }}}>

                        {/* Horizontal div with all images */}
                        <div
                            ref={scrollViewRef}
                            horizontal={true}
                            pagingEnabled={true} // Enable snapping effect
                            showsHorizontalScrollIndicator={false}
                            contentContainerStyle={styles.horizontalScrollView}
                            style={{ width: width, }}

                        >
                            {images.map((uri, index) => (
                                <div key={index} style={styles.scrollImageContainer}>
                                    <img src={uri} style={styles.scrollImage} />
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            }

            <div contentContainerStyle={styles.scrollContainer} showsVerticalScrollIndicator={false}>
                {images.length > 0 ? (

                    images.map((uri, index) => (

                        <div
                            key={index}
                            style={styles.imageContainer}
                            activeOpacity={0.9}
                            onClick={() => select(index)}
                        >
                            <img src={uri} style={styles.image} />
                        </div>
                    ))
                ) : (
                    <Text>No images to display</Text>
                )}
            </div>
        </div>
    );
};

const styles = {
    container: {
        flex: 1,
        backgroundColor: 'white',
    },
    scrollContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: 20, // Extra padding to prevent cutoff at the bottom
    },
    imageContainer: {
        marginVertical: 10, // Space between images
        height: screenHeight * 0.5, // Set each image container to 50% of the screen height
    },
    image: {
        width: Dimensions.get('window').width * 0.95, // Make the image take 95% of the screen width
        height: '100%', // Fill the image container height
        borderRadius: 10,
        resizeMode: 'contain',
        borderColor: 'black',
        borderWidth: 2,
    },
    header: {
        width: width,
        height: 60,
        position: 'absolute',
        backgroundColor: '#21c063',
        zIndex: 20,
        display:"flex",
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: 20,
    },
    backButton: {
        width: 30,
        height: 30,
        justifyContent: 'center',
        alignItems: 'center',
    },
    backImage: {
        width: '100%',
        height: '100%',
    },
    forwardButton: {
        width: 30,
        height: 30,
        justifyContent: 'center',
        alignItems: 'center',
    },
    forwardImage: {
        width: '100%',
        height: '100%',
    },
    animatedView: {
        flex: 1,
        // position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: 10,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 20,
        backgroundColor: 'white',
        paddingTop: 70,
        display:"flex"
    },
    horizontalScrollView: {
        justifyContent: 'center',
        alignItems: 'center',
display:"flex",
    },
    scrollImageContainer: {
        width: width, // Each image container takes the full screen width
        justifyContent: 'center',
        alignItems: 'center',
        display:"flex"
    },
    scrollImage: {
        width: width * 0.9, // img width is 90% of screen width
        height: '90%', // img height is 90% of the available height
        resizeMode: 'contain',
    },
};

export default ImageViewer;
