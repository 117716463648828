import React, { forwardRef, useEffect, useState } from 'react';
import {algoliasearch} from 'algoliasearch'; // ✅ Correct import
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const searchClient = algoliasearch('7BER79SCQK', '51f6b19d6579616e0bad6f843d293325');

const InfiniteHits = forwardRef(({ hitComponent: Hit, query = '', container, widthRatio, loading, onHitsUpdate, ...props }, ref) => {
    const [hits, setHits] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [isLastPage, setIsLastPage] = useState(false);
    const { innerWidth: width } = window;

    // ✅ Updated Fetch function for v5
    const fetchAlgoliaData = async (newPage = 0, append = false) => {
        setIsLoading(true);
        try {
            const response = await searchClient.searchSingleIndex({
                indexName: 'CommunityPosts',
                searchParams: { query, page: newPage },
            });

            const newHits = response.hits;
            console.log('Fetched hits:', newHits);

            setHits(append ? [...hits, ...newHits] : newHits);
            setIsLastPage(newHits.length === 0);
        } catch (error) {
            console.error('Error fetching data from Algolia:', error);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchAlgoliaData();
    }, [query]);

    const loadMore = () => {
        if (!isLastPage) {
            const nextPage = page + 1;
            setPage(nextPage);
            fetchAlgoliaData(nextPage, true);
        }
    };

    useEffect(() => {
        if (onHitsUpdate) {
            onHitsUpdate(hits);
        }
    }, [hits, onHitsUpdate]);
    console.log('hits: ', hits);

    return (
        <div style={{ flex: 1, ...container }}>
            {hits.length === 0 ? (
                isLoading ? (
                    <div style={{ textAlign: 'center', padding: '20px' }}>
                        <Skeleton width={width / (widthRatio || 1.1)} height={80} />
                    </div>
                ) : (
                    <p style={{ textAlign: 'center', color: 'black' }}>No data</p>
                )
            ) : (
                <div ref={ref} style={{ justifyContent: 'center' }}>
                    {hits.map((item) => (
                        <div key={item.objectID} style={{ padding: 5, marginVertical: 1 }}>
                            <Hit hit={item} />
                        </div>
                    ))}
                    {!isLastPage && (
                        <div style={{ textAlign: 'center', padding: '10px' }}>
                            <button onClick={loadMore} disabled={isLoading}>
                                {isLoading ? 'Loading...' : 'Load More'}
                            </button>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
});

export default InfiniteHits;
