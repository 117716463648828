import React, { useEffect, useState } from 'react';
import ThemeButton from '../ResuableThemeComponent/ThemeButton';
import ThemeDropdown from '../ResuableThemeComponent/ThemeDropdown';
import ThemeTextInput from '../ResuableThemeComponent/ThemeTextInput';
import { Modal } from "react-native";
import UserCardDropdown from './Home/UserDropDown';
import {  toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import ReusableModal from './ReusableModal';
import CommentSearch from '../searching/commentSearch';
import { updateUserField } from '../../reduxStore/slices/UsersSlice';
import { useNavigate } from 'react-router-dom';
import { firestore } from '../../firebase';
import { arrayRemove, arrayUnion, doc, updateDoc, writeBatch } from 'firebase/firestore';
import { display } from '@mui/system';


const Thoughts = ({ post, data, expendEnable, setExpendEnable, disabled, deleteThought, communityVersion }) => {
    const navigation = useNavigate()
    const [expend, setExpend] = useState(false);
    const dispatch = useDispatch()
    const userData = useSelector(state => state.user.users);
    const activeUser = useSelector(state => state.user.activeUser);
    const activeUserData = userData[activeUser]
    const [isModalVisible, setModalVisible] = useState(false);


    const [isOptionVisible, setOptionVisible] = useState(false);
    const [selectedPost, setSelectedPost] = useState(null);
    const [selectedPostComments, setSelectedPostComments] = useState([]);
    const [commentText, setCommentText] = useState('');
    const [likeCount, setLikeCount] = useState('');
    const [optionId, setOptionId] = useState('');
    const [selectedCards, setSelectedCards] = useState('');
    const [confirmModal, setConfirmModal] = useState(false)
    const [uploadStatus, setUploadStatus] = useState('confirm');
    const [allPosts, setAllPosts] = useState(post ? post : [])
    const [postToDelete, setPostToDelete] = useState('');
    const [reportText, setReportText] = useState('');
    const [taggedPeople, setTaggedPeople] = useState('');
    const [selectedReport, setSelectedReport] = useState('');
    const [onlyModal, setOnlyModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState('');
    useEffect(() => {
        setAllPosts(post)
    }, [post])
    const handlePress = (e) => {
        // if (e && e.preventDefault) {
        //     e.preventDefault(); // Prevent page refresh or default behavior
        // }
        // if (disabled) return;

        // setExpend(!expend);

        // if (!setExpendEnable) return;

        // if (expendEnable === post.id) {
        //     setExpendEnable("");
        // } else {
        //     setExpendEnable(post.id);
        // }
    };
    useEffect(() => {
        setSelectedCards('')
    }, [])
    // useEffect(() => {
    //     if (!setExpendEnable) return;
    //     if (expendEnable == post.id) {
    //         return
    //     } else {
    //         // LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    //         setExpend(false)
    //     }
    // }, [expendEnable])

    const openCommentsModal = () => {
        // Fetch the selected post and comments based on post.id
        const selectedPost = allPosts
        setSelectedPost(selectedPost);
        setSelectedPostComments(selectedPost.comments || []);
        setModalVisible(true);
    };
    const handleBack = () => {
        setPostToDelete('')
        setUploadStatus('')
        setReportText('')
        setConfirmModal(false);
        setTaggedPeople('')
        setSelectedReport('')
    };
    function updatePostById(key, value) {
        const tempPost = { ...allPosts }
        tempPost[key] = value
        return tempPost
    }
    const capitalizeAfterDot = (text) => {
        return text
            .toLowerCase()
            .replace(/(^\w|\.\s*\w)/g, (match) => match.toUpperCase());
    };
    const handleAddComment = async () => {
        if (commentText.trim() === "") return;
    
        // Create a new comment object
        const newComment = {
          id: activeUser, // Unique ID for the comment
          key: `${Date.now()}`,
          text: commentText,
        };
    
        try {
          // Reference to the Firestore document
          const postRef = doc(firestore, selectedPost.communityVersion ? "CommunityPosts" : "Thoughts", selectedPost.id);
    
          // Update Firestore document (adds comment to the array)
          await updateDoc(postRef, {
            comments: arrayUnion(newComment),
          });
    
          console.log("Value added to array successfully");
    
          // Update local state
          const tempComments = [...selectedPostComments, newComment];
          setSelectedPostComments(tempComments);
          setAllPosts(updatePostById("comments", tempComments));
    
          // Clear ThemeTextInput field
          setCommentText("");
        } catch (error) {
          console.error("Error adding value to array:", error);
        }
      };
    const toggleLike = () => {
        // setLikes((prevLikes) => ({
        //   ...prevLikes,
        //   [post.id]: !prevLikes[post.id], // Toggle the like status for this specific post
        // }));
        const batch = firestore().batch()
        const activeDoc = firestore().collection('SBusers').doc(activeUser);
        const userDoc = firestore().collection(communityVersion ? 'CommunityPosts' : 'Thoughts').doc(post.id);
        if (activeUserData.likedThoughts?.length > 0 && activeUserData.likedThoughts.includes(post.id)) {
            const tempLike = [...activeUserData.likedThoughts]
            const newArray = tempLike.filter(item => item !== post.id)
            dispatch(updateUserField({ userUid: activeUser, updates: { likedThoughts: newArray } }))
            batch.update(activeDoc, { likedThoughts: newArray })
            batch.update(userDoc, { likes: firestore.FieldValue.arrayRemove(activeUser) })
            setLikeCount((prev) => ({
                ...prev,
                [post.id]: likeCount[post.id] != 0 ? likeCount[post.id] - 1 : 0
            }))
            const tempArray = allPosts.likes.filter(item => item != activeUser)
            setAllPosts(updatePostById('likes', tempArray))

        } else {
            const tempLike = [...(activeUserData.likedThoughts ? activeUserData.likedThoughts : []), post.id]
            dispatch(updateUserField({ userUid: activeUser, updates: { likedThoughts: tempLike } }))
            batch.update(activeDoc, { likedThoughts: tempLike })
            batch.update(userDoc, { likes: firestore.FieldValue.arrayUnion(activeUser) })
            setLikeCount((prev) => ({
                ...prev,
                [post.id]: likeCount[post.id] + 1
            }))
            const tempArray = [...allPosts.likes, activeUser]
            setAllPosts(updatePostById('likes', tempArray))
        }
        batch.commit()
            .then(() => {
                console.log('Value added to array successfully');
            })
            .catch(error => {
                console.error('Error adding value to array:', error);
            });
    };
    const getTimeAgo = (timestamp) => {
        const now = Date.now();
        const diffInSeconds = Math.floor((now - timestamp) / 1000);

        if (diffInSeconds < 60) {
            return `${diffInSeconds} seconds ago`;
        }

        const diffInMinutes = Math.floor(diffInSeconds / 60);
        if (diffInMinutes < 60) {
            return `${diffInMinutes} min${diffInMinutes > 1 ? 's' : ''} ago`;
        }

        const diffInHours = Math.floor(diffInMinutes / 60);
        if (diffInHours < 24) {
            return `${diffInHours} hour${diffInHours > 1 ? 's' : ''} ago`;
        }

        // If it's more than 24 hours, return the formatted date
        const date = new Date(timestamp);
        return `${date.getDate()} ${date.toLocaleString('en-US', { month: 'short' })} ${date.getFullYear()}`;
    };

    const sendMessage = () => {
        setSelectedUser('')

    }
    const handleCardSelect = (id) => {
        setSelectedCards(id)
    }
    const handleCardDeselect = () => setSelectedCards(null);

    const handleCopy = () => {
        const value = selectedPostComments.find(item => item.key === selectedCards);
        if (!value) return;

        navigator.clipboard.writeText(value.text)
            .then(() => {
                toast.success("Text Copied!", {
                    position: "top-center",
                    autoClose: 2000, // Auto close after 2s
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            })
            .catch(err => console.error("Failed to copy text: ", err));

        setSelectedCards(null);
    };

    const handleDelete = () => {
        const selectedComment = selectedPostComments.filter(item => item.key == selectedCards)[0]
        const tempComments = selectedPostComments.filter(item => item.key != selectedCards)
        const tempPost = allPosts
        tempPost['comments'] = tempComments
        const batch = firestore().batch()
        const postDoc = firestore().collection(communityVersion ? 'CommunityPosts' : 'Thoughts').doc(selectedPost.id);
        batch.update(postDoc, { comments: firestore.FieldValue.arrayRemove(selectedComment) })
        batch.commit()
            .then(() => {
                console.log('Value added to array successfully');
                setSelectedCards(null)
                setAllPosts(tempPost)
                setSelectedPostComments(tempComments)
            })
            .catch(error => {
                console.error('Error adding value to array:', error);
            });
    }
    useEffect(() => {
        if (!isModalVisible) {
            setSelectedCards(null)
        }
    }, [isModalVisible])
    const handlePostDelete = () => {
        setConfirmModal(true)
        setPostToDelete(post.id)
    }
    const handleConfirm = () => {
        setUploadStatus('pending')
        setAllPosts(allPosts)
        setOptionVisible(false)
        const batch = firestore().batch()
        const userdoc = firestore().collection('SBusers').doc(data.id);
        batch.update(userdoc, { thougths: firestore.FieldValue.arrayRemove(postToDelete) })
        deleteThought(post.id)
        batch.commit()
            .then(() => {
                console.log('Value added to array successfully');
                setUploadStatus('success');
                setConfirmModal(false);

            })
            .catch(error => {
                console.error('Error adding value to array:', error);
            });


    }
    const reportData = [
        { label: 'Spam', value: 'spam' },
        { label: 'Inappropriate Content', value: 'inappropriate_content' },
        { label: 'Harassment or Bullying', value: 'harassment' },
        { label: 'False Information', value: 'false_information' },
        { label: 'Hate Speech', value: 'hate_speech' },
        { label: 'Violence or Harm', value: 'violence' },
        { label: 'Nudity or Sexual Content', value: 'nudity' },
        { label: 'Scam or Fraud', value: 'scam' },
        { label: 'Intellectual Property Violation', value: 'ip_violation' },
        { label: 'Other', value: 'other' }
    ];
    const openOption = () => {
        if (allPosts.reported && activeUser != data.id) return;
        setOptionId(post.id)
        setOptionVisible(true);
    };
    const handleBookmark = async() => {
        const batch = writeBatch(firestore);

        const activeDoc = doc(firestore, "SBusers", activeUser);
    
        let updatedBookmarks;
        if (activeUserData.bookMarkedThoughts?.includes(post.id)) {
            // Remove bookmark
            updatedBookmarks = activeUserData.bookMarkedThoughts.filter(item => item !== post.id);
            batch.update(activeDoc, { bookMarkedThoughts: arrayRemove(post.id) });
        } else {
            // Add bookmark
            updatedBookmarks = [...(activeUserData.bookMarkedThoughts || []), post.id];
            batch.update(activeDoc, { bookMarkedThoughts: arrayUnion(post.id) });
        }
    
        // Update Redux state
        dispatch(updateUserField({ userUid: activeUser, updates: { bookMarkedThoughts: updatedBookmarks } }));
    
        try {
            await batch.commit();
            console.log("Bookmark updated successfully!");
        } catch (error) {
            console.error("Error updating bookmark:", error);
        }
        // setOptionVisible(false);
    };
    const sortedData = (data, attribute) => {
        return data.sort((a, b) => Number(b[attribute]) - Number(a[attribute]));
    }
    const handleReport = () => {
        // Implement report functionality here
        setConfirmModal(true)
        setUploadStatus('report')
    };

    const handleShare = () => {
        setOnlyModal(true)
    };
    const handleFlag = () => {
        const post = allPosts
        post.flagged = 'pending'
        const batch = firestore().batch()
        const activePost = firestore().collection(communityVersion ? 'CommunityPosts' : 'Thoughts').doc(post.id);
        const reportDoc = firestore().collection('Reports').doc('thoughtFlags')
        batch.update(activePost, { flagged: 'pending' })
        batch.set(reportDoc, { [`pending.${post.id}`]: post.thought }, { merge: true })

        batch.commit()
            .then(() => {
                console.log('Value added to array successfully');
                setAllPosts(post)
            })
            .catch(error => {
                console.error('Error adding value to array:', error);
            });
    }
    const handleTags = () => {
        setTaggedPeople(post.taggedPeople ? post.taggedPeople.length > 0 ? post.taggedPeople : [] : [])
        setSelectedPost(post);
        setModalVisible(true)
    }
    const handleExternalShare = async () => {
        try {
            const postId = post.id; // Get the post ID to share
            const webLink = `https://yourwebsite.com/post/${postId}`;
            const message = `Check out this post: ${webLink}`;

            if (navigator.share) {
                // Native sharing (for Chrome, Safari, Edge, Mobile)
                await navigator.share({
                    title: "Post Share",
                    text: message,
                    url: webLink,
                });
                console.log("Shared successfully");
            } else {
                // Fallback for Firefox: Copy to clipboard and show manual share options
                await navigator.clipboard.writeText(message);
                alert("Link copied! You can paste it anywhere.");

                // Optionally, open social media share links
                const twitterShare = `https://twitter.com/intent/tweet?text=${encodeURIComponent(message)}`;
                const facebookShare = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(webLink)}`;

                const shareOptions = `
                    <div style="position:fixed;top:50%;left:50%;transform:translate(-50%,-50%);background:white;padding:20px;border-radius:8px;box-shadow:0px 4px 6px rgba(0,0,0,0.1);">
                        <p>Share this post on:</p>
                        <a href="${twitterShare}" target="_blank" style="display:block;margin-bottom:10px;">Twitter</a>
                        <a href="${facebookShare}" target="_blank">Facebook</a>
                    </div>
                `;

                const shareDiv = document.createElement("div");
                shareDiv.innerHTML = shareOptions;
                document.body.appendChild(shareDiv);
            }
        } catch (error) {
            console.error("Error sharing:", error.message);
        }
    };

    const handleReportConfirm = async() => {
        if (!selectedReport) return;
        setUploadStatus('pending')
        const tempArray = allPosts
        const tempFullArray = allPosts
        const reportObject = { reporterId: activeUser, reportType: selectedReport.value, reportText: reportText, reportId: `${activeUser}${tempArray.reports ? tempArray.reports.length + 1 : '1'}`, reportDate: Date.now() }
        tempArray['reports'] = [...(tempArray['reports'] || []), reportObject]
        setAllPosts(tempArray)
        const batch = writeBatch(firestore);
        const postDoc = doc(firestore, communityVersion ? "CommunityPosts" : "Thoughts", post.id);
        const reportDoc = doc(firestore, "Reports", "thoughtReport");

        batch.update(postDoc, { reports: arrayUnion(reportObject) });
        batch.set(reportDoc, { [post.id]: arrayUnion(reportObject) }, { merge: true });
    
        try {
            await batch.commit();
            console.log("Value added to array successfully");
            setUploadStatus("success");
            setReportText("");
            setConfirmModal(false);
        } catch (error) {
            console.error("Error adding value to array:", error);
            setUploadStatus("error");
        }
    }


    function formatNumber(num) {
        num = Number(num); // Ensure it's a number
        if (num === 0) {
            return '';
        }
        if (num >= 1000000) {
            return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
        } else if (num >= 1000) {
            return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
        } else {
            return num.toString();
        }
    }
    const navigateUser = () => {
        const userData = {
            id: data.objectID
        }
        navigation('Datascreen', {
            data: userData,
            cardtype: 'SBUser'
        });
    }
    const styles = {
        container: {
            flex: 1,
            alignItems: "center",
            margin: 15,
            marginBottom: 5,
            display: 'flex',
        },
        itemContainer: {
            flexDirection: "column",
            backgroundColor: "#fff",
            borderRadius: 15,
            width: "100%",
            padding: 10,
            justifyContent: "flex-start",
            display: 'flex',
            // alignItems: "center",
        },
        userContainer: {
            alignItems: "center",
            flexDirection: 'row',
            display: 'flex',    
            justifyContent: 'space-between'
        },
        mainContainer: {
            margin: 10
        },
        user: {
            objectFit: "contain",
            width: 30,
            height: 30
        },
        name: {
            marginLeft: 5,
            fontWeight: 'bold',
            fontSize:  18,
            color: 'black'
        },
        pfp: {
            width:  40 ,
            height: 40 ,
            borderRadius: 20,
        },
        asssigneeName: {
            color: "black",
            fontWeight: "bold",
            fontSize: 17
        },
        bottom: {
            flexDirection: 'row',
            // marginBottom: 5,
            alignItems: 'center',
            paddingVertical: 8,
            justifyContent: 'space-between',
            paddingHorizontal: 0,
            borderColor: 'lightgrey',
            borderTopWidth: 1,
            display: 'flex',
        },
        logo: {
            width: 30,
            height: 30,
            marginHorizontal: 5,
        },
        dateTxt: { color: 'black', fontFamily: 'Marker Felt', color: 'grey', opacity: 0.8 },
        text: {
            fontSize: 14,
            color: 'black'
        },
        lowerUserName: {
            fontWeight: 'bold',
            color: 'black',
            fontSize: 19
        },
        modalHeader: {
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: 10,
            display: 'flex',
        },
        modalPfp: {
            width: 30,
            height: 30,
            borderRadius: 15,
            marginRight: 10,
        },
        modalHeaderText: {
            flex: 1,
            color: 'black',
            display: 'flex',    
        },
        modalUsername: {
            fontSize: 16,
            fontWeight: 'bold',
            color: 'black'
        },
        modalCaption: {
            fontSize: 14,
            color: 'black'
        },
        commentsList: {
            flex: 1,
            display: 'flex',    
        },
        commentItem: {
            // padding: 1,
            backgroundColor: 'rgb(12,11,36)',
            borderRadius: 10,
        },
        commentText: {
            fontSize: 14,
            color: 'white'
        },
        inputContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingVertical: 10,
            borderTopWidth: 1,
            borderTopColor: '#ddd',
            display: 'flex',    
        },
        ThemeTextInput: {
            flex: 1,
            borderRadius: 20,
            borderWidth: 1,
            borderColor: '#ddd',
            paddingHorizontal: 15,
            paddingVertical: 10,
            marginRight: 10,
            color: 'black'
        },
        sendButton: {
            backgroundColor: '#007BFF',
            borderRadius: 20,
            paddingHorizontal: 15,
            paddingVertical: 10,
        },
        sendButtonText: {
            color: 'white',
            fontWeight: 'bold',
        },
        optionsContainer: {
            flex: 1,
            marginTop: 20
        },
        optionButton: {
            backgroundColor: '#f2f2f2',
            paddingVertical: 15,
            paddingHorizontal: 20,
            marginVertical: 5,
            borderRadius: 25, // Rounded edges for pill shape
            alignItems: 'center',
            display: 'flex',
        },
        optionButtonText: {
            fontSize: 16,
            color: '#007BFF',
            fontWeight: 'bold',
        },
        loader: {
            width: "50px",
            height: "50px",
            border: "5px solid #f3f3f3",
            borderTop: "5px solid #0000ff", // Same color as React Native example
            borderRadius: "50%",
        },
    }
    return (
        <div style={styles.container}>
            <div style={styles.itemContainer}  onClick={() => handlePress()} disabled={disabled}>
                <div style={styles.userContainer}>
                    <div  onClick={navigateUser}  style={{ flexDirection: 'row', alignItems: 'center', columnGap: 10 , display:"flex"}}>
                        <img src={data.profilePic ? { uri: data.profilePic } : require("../../assets/images/profile1.webp")} style={styles.pfp} />
                        <p style={styles.name}>{data?.username || 'UserName'}</p>
                    </div>
                    <div>
                        {!expend && <p style={styles.dateTxt}>
                            {getTimeAgo(post.date)}
                        </p>}
                        {
                            expend &&
                            <div activeOpacity={0.7} onClick={openOption}>
                                <img src={require('../../assets/images/dots.webp')} style={styles.logo} />
                            </div>
                        }

                    </div>
                </div>
                <div style={styles.mainContainer}>

                    <p
                        animate={{ height: "40px", opacity: 1 }}
                        transition={{ duration: 0.3, ease: "easeInOut" }}
                        style={{ color: "black", fontSize: 25, fontFamily: "Marker Felt", overflow: "hidden" }}
                    >
                        {capitalizeAfterDot(post.thought)}
                    </p>
                </div>
                {!expend && <div style={{ flexDirection: 'row', alignItems: 'center', columnGap: 10 , display:"flex"}}>
                    <div style={{ flexDirection: "row", alignItems: 'center', columnGap: 5, display:"flex" }}>
                        {
                            allPosts.likes.includes(activeUser) ?
                                (
                                    <img
                                        src={require('../../assets/images/like.webp')}
                                        style={{ width: 20, height: 20 }}
                                    />
                                ) : (
                                    <img
                                        src={require('../../assets/images/dislike.webp')}
                                        style={{ width: 20, height: 20 }}
                                    />
                                )}
                        <p style={{ color: 'black', fontFamily: 'Marker Felt', fontSize: 12 }}>
                            {allPosts.likes ? formatNumber(allPosts.likes.length) : '0'}
                        </p>
                    </div>
                    <div style={{ flexDirection: "row", alignItems: 'center', columnGap: 5 , display:"flex" }}>
                        <img style={{ width: 20, height: 20 }} src={require('../../assets/images/chat-bubble.png')} />
                        <p style={{ color: 'black', fontFamily: 'Marker Felt', fontSize: 12 }} >
                            {allPosts.comments ? formatNumber(allPosts.comments.length) : '0'}
                        </p>
                    </div>
                </div>}
                {
                    (expend) &&
                    <div style={styles.bottom}>
                        <div style={{ flexDirection: 'row', alignItems: 'center'  , display:"flex"}}>
                            <div onClick={() => toggleLike()}>
                                {
                                    allPosts.likes.includes(activeUser) ?
                                        (
                                            <img
                                                src={require('../../assets/images/like.webp')}
                                                style={styles.logo}
                                            />
                                        ) : (
                                            <img
                                                src={require('../../assets/images/dislike.webp')}
                                                style={{...styles.logo,height: 28, width: 28 }}
                                            />
                                        )}
                            </div>
                            <p style={{ color: 'black', fontFamily: 'Marker Felt', fontSize: 15, marginRight: 3 }}>
                                {allPosts.likes ? formatNumber(allPosts.likes.length) : '0'}
                            </p>
                            <div onClick={() => openCommentsModal()}>
                                <img
                                    src={require('../../assets/images/chat-bubble.png')}
                                    style={styles.logo}
                                />
                            </div>
                            <p style={{ color: 'black', fontFamily: 'Marker Felt', fontSize: 15, marginRight: 3 }}>
                                {allPosts.comments ? formatNumber(allPosts.comments.length) : '0'}
                            </p>
                            <div onClick={() => handleShare()}>
                                <img
                                    src={require('../../assets/images/postShare.png')}
                                    style={{...styles.logo, height: 28, width: 28 }}
                                />
                            </div>
                        </div>
                        <div>
                            <div onClick={() => handleBookmark()}>
                                <img
                                    src={(activeUserData.bookMarkedThoughts && activeUserData.bookMarkedThoughts.includes(post.id)) ? require('../../assets/images/save-fill.png') : require('../../assets/images/save.png')}
                                    style={{...styles.logo,  width: 28, height: 28 }}
                                />
                            </div>
                        </div>

                    </div>}
                {
                    expend &&
                    <div style={{ margin: 3, paddingHorizontal: 10 }}>
                        <p style={styles.dateTxt}>{getTimeAgo(post.date)}</p>
                    </div>
                }
            </div>
            <Modal
                transparent={true}
                visible={confirmModal}
                animationType="slide"
                onRequestClose={() => setConfirmModal(false)}
            >
                <div style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0,0,0,0.5)' , display:"flex" }}>
                    {uploadStatus === 'pending' && (
                        <div style={{ padding: 20, backgroundColor: 'white', borderRadius: 10, alignItems: 'center' , display:"flex" }}>
                            <motion.div
                                animate={{ rotate: 360 }}
                                transition={{ repeat: Infinity, duration: 1, ease: "linear" }}
                                style={styles.loader}
                            />                            <p style={{ marginTop: 10, color: 'black' }}>Uploading...</p>
                        </div>
                    )}

                    {uploadStatus === 'success' && (
                        <div style={{ paddingHorizontal: 40, backgroundColor: 'white', borderRadius: 10, alignItems: 'center', justifyContent: 'space-evenly', height: 200 , display:"flex" }}>
                            <img src={require('../../assets/images/check.webp')} style={{ height: 45, width: 45 }} />
                            <p style={{ fontSize: 20, marginBottom: 10, color: 'black' }}>Upload Successful!</p>

                        </div>
                    )}

                    {uploadStatus === 'error' && (
                        <div style={{ paddingHorizontal: 40, backgroundColor: 'white', borderRadius: 10, alignItems: 'center', height: 200, justifyContent: 'space-evenly' , display:"flex" }}>
                            <img src={require('../../assets/images/error.webp')} style={{ height: 45, width: 45 }} />
                            <p style={{ fontSize: 20, marginBottom: 10, color: 'black' }}>Upload Failed</p>
                            <div style={{ flexDirection: 'row', justifyContent: 'space-between', width: '80%' }}>
                                <ThemeButton
                                    title="Back"
                                    variant="red"
                                    width="45%"
                                    func={handleBack}
                                />
                            </div>
                        </div>
                    )}
                    {uploadStatus === 'confirm' && (
                        <div style={{ paddingHorizontal: 40, backgroundColor: 'white', borderRadius: 10, alignItems: 'center', height: 200, justifyContent: 'space-evenly' }}>
                            <img src={require('../../assets/images/dustbin.png')} style={{ height: 45, width: 45 }} />
                            <p style={{ fontSize: 20, marginBottom: 10, color: 'black' }}>Delete Post</p>
                            <div style={{ flexDirection: 'row', justifyContent: 'space-between', width: '80%' }}>
                                <ThemeButton
                                    title="Back"
                                    variant="red"
                                    width="45%"
                                    func={handleBack}
                                />
                                <ThemeButton
                                    title="confirm"
                                    variant="green"
                                    width="50%"
                                    func={handleConfirm}
                                />
                            </div>
                        </div>
                    )}
                    {
                        uploadStatus == 'report' &&
                        <div style={{ paddingHorizontal: 40, backgroundColor: 'white', borderRadius: 10, alignItems: 'center', minHeight: 200, justifyContent: 'space-evenly', paddingVertical: 10 , display:"flex" }}>
                            <img src={require('../../assets/images/reportPost.png')} style={{ height: 45, width: 45 }} />
                            <p style={{ fontSize: 20, marginBottom: 10, color: 'black' }}>Confirm Report Post</p>
                            <div style={{ maxWidth: '100%', flexDirection: "row" }}>
                                <ThemeDropdown
                                    title={'Choose Report'}
                                    labelField={'label'}
                                    valueField={'value'}
                                    type={'single'}
                                    data={reportData}
                                    value={selectedReport}
                                    func={setSelectedReport}
                                    placeholder={'Choose Option'}

                                />
                            </div>
                            <div style={{ justifyContent: 'flex-start', minWidth: '90%', marginTop: 5 }}>
                                <p style={{ color: 'black', textAlign: 'left' }}>
                                    Enter Any Comment
                                </p>
                            </div>
                            <ThemeTextInput
                                value={reportText}
                                maxLength={150}
                                placeholder={'Enter Report Reason'}
                                onChangeText={(text) => setReportText(text)}
                                placeholderTextColor={'grey'}
                                multiline={true}
                                style={{ borderColor: 'black', borderWidth: 1, color: 'black', borderRadius: 10, minWidth: '90%', marginVertical: 10 }}

                            />
                            <div style={{ flexDirection: 'row', justifyContent: 'space-between', width: '80%' }}>
                                <ThemeButton
                                    title="Back"
                                    variant="red"
                                    width="45%"
                                    func={handleBack}
                                />
                                <ThemeButton
                                    title="confirm"
                                    variant="green"
                                    width="50%"
                                    func={handleReportConfirm}
                                />
                            </div>
                        </div>
                    }
                </div>
            </Modal>
            {/* Comments Modal */}
            <ReusableModal
                visible={isModalVisible}
                onClose={() => (setModalVisible(false), setTaggedPeople(''))}
            >
                {selectedPost && (
                    <div style={{ flex: 1 }}>
                        <div style={styles.modalHeader}>
                            <div style={{ flexDirection: 'row', alignItems: 'center', flex: 1  , display:"flex"}}>
                                <img
                                    src={require('../../assets/images/profile.webp')}
                                    style={styles.modalPfp}
                                />
                                <div style={styles.modalHeaderText}>
                                    <p style={styles.modalUsername}>{data.username || 'ff'}</p>
                                    {selectedPost.caption && <p style={styles.modalCaption}>{selectedPost.caption}</p>}
                                </div>
                            </div>
                            {
                                ((!taggedPeople && taggedPeople.length == 0)) ?
                                    <div style={{ flex: 1, justifyContent: 'flex-end', flexDirection: 'row', padding: 4, columnGap: 5 }}>
                                        <div style={{ justifyContent: 'center', flexDirection: 'row', alignItems: 'center', padding: 4, columnGap: 5 , display:"flex" }}>
                                            <p style={{ color: 'black', fontFamily: 'Marker Felt' }}>
                                                {selectedPostComments ? selectedPostComments.length : '0'}
                                            </p>
                                            <img src={require('../../assets/images/chat-bubble.png')} style={{ width: 18, height: 18 }} />
                                        </div>
                                        <div style={{ justifyContent: 'center', flexDirection: 'row', alignItems: 'center', padding: 4, columnGap: 5 , display:"flex" }}>
                                            <p style={{ color: 'black', fontFamily: 'Marker Felt' }}>
                                                {selectedPost.taggedPeople ? selectedPost.taggedPeople.length : '0'}
                                            </p>
                                            <img src={require('../../assets/images/tag-user.png')} style={{ width: 18, height: 18 }} />
                                        </div>
                                    </div>
                                    : null
                            }
                        </div>
                        <div style={styles.commentsList} contentContainerStyle={{ flexGrow: 1 }}
                            nestedScrollEnabled={true}
                            keyboardShouldPersistTaps="always"
                            showsVerticalScrollIndicator={false}>
                            {

                                // taggedPeople ? taggedPeople.length > 0 ?
                                //     <UserSearch searchDisabled idsArray={taggedPeople} SBUser navigate />

                                //     : <div style={{ justifyContent: 'center', alignItems: 'center' }}>
                                //         <p style={{ color: 'black' }}>No taggedPeople</p>
                                //     </div>
                                //     : null
                            }
                            {(!taggedPeople && taggedPeople.length == 0) && sortedData(selectedPostComments, 'key').map((comment) => {
                                // console.log('selectedCards: ', selectedCards, comment.key);

                                return (
                                    <motion.div
                                    key={comment.key}
                                    initial={{ opacity: 0, y: 10 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: -10 }}
                                    transition={{ duration: 0.3 }}
                                    style={styles.commentItem}
                                >
                                    <CommentSearch
                                        commentArray={comment}
                                        onSelect={handleCardSelect}
                                        onDeselect={handleCardDeselect}
                                        isSelected={selectedCards}
                                        disableInteraction={selectedCards && selectedCards !== comment.key}
                                    />
                                </motion.div>
                        
                                )
                            })}
                        </div>
                        <div style={styles.inputContainer}>
                            {(!taggedPeople && taggedPeople.length == 0) ?
                                (
                                    !selectedCards ?
                                        <>
                                            <ThemeTextInput
                                                placeholderTextColor={'black'}
                                                style={styles.ThemeTextInput}
                                                value={commentText}
                                                onChangeText={setCommentText}
                                                placeholder="Add a comment..."
                                            />
                                            <div
                                                onClick={handleAddComment}
                                                style={styles.sendButton}
                                            >
                                                <p style={styles.sendButtonText}>Send</p>
                                            </div>
                                        </>

                                        :
                                        <div style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', flex: 1, paddingHorizontal: 10 , display:"flex"}}>
                                            <div style={{ flexDirection: 'row', columnGap: 10, alignItems: 'center' ,   display:"flex" }}>
                                                <div style={{ height: 40, width: 40 }} onClick={handleCopy} >
                                                    <img style={{ width: '100%', height: '100%' }} src={require('../../assets/images/copy.png')} />
                                                </div>
                                                {
                                                    selectedPostComments.filter(item => item.key == selectedCards)[0].id == activeUser &&
                                                    <div style={{ height: 35, width: 35 }} onClick={handleDelete} >
                                                        <img style={{ width: '100%', height: '100%' }} src={require('../../assets/images/dustbin.png')} />
                                                    </div>
                                                }
                                            </div>
                                            <div style={{ height: 35, width: 35 }} onClick={() => setSelectedCards(null)}>
                                                <img style={{ width: '100%', height: '100%' }} src={require('../../assets/images/close.webp')} />
                                            </div>

                                        </div>
                                )
                                :
                                null
                            }
                        </div>
                    </div>
                )}
            </ReusableModal>
            {/* Options Modal */}
            <ReusableModal
                visible={isOptionVisible}
                onClose={() => setOptionVisible(false)}
                heightPercent='50'
            >
                <div style={styles.optionsContainer}>
                    {optionId && !allPosts.reported &&
                        <>
                            <div onClick={handleReport} style={styles.optionButton}>
                                <p style={styles.optionButtonText}>Report</p>
                            </div>
                            <div onClick={handleShare} style={styles.optionButton}>
                                <p style={styles.optionButtonText}>Share</p>
                            </div>
                            <div onClick={() => handleBookmark(optionId)} style={{...styles.optionButton, backgroundColor: (activeUserData?.bookMarkedThoughts && activeUserData?.bookMarkedThoughts.includes(post.id)) ? '#007BFF' : '#f2f2f2' }}>
                            <p style={{
    ...styles.optionButtonText, 
    color: (activeUserData?.bookMarkedThoughts && activeUserData?.bookMarkedThoughts.includes(post.id)) 
        ? '#f2f2f2' 
        : '#007BFF' 
}}>
    {(activeUserData?.bookMarkedThoughts && activeUserData?.bookMarkedThoughts.includes(post.id)) 
        ? 'Bookmarked' 
        : 'Bookmark'}
</p>
                            </div>
                        </>
                    }
                    {optionId && allPosts.reported &&
                        <div style={{ flexDirection: 'column', justifyContent: 'center', marginBottom: 15 , display:"flex"}}>
                            <p style={{...styles.optionButtonText,  color: 'black', textAlign: 'center' }}>This image or caption is inappropriate and may violate community guidelines.</p>
                            {
                                allPosts.flagged &&
                                <p style={{ color: 'black', textAlign: 'center' }}>Status Information: {allPosts.flagged}</p>}
                        </div>
                    }
                    {activeUser == data.id &&
                        <div onClick={() => handlePostDelete()} style={styles.optionButton}>
                            <p style={{...styles.optionButtonText,  color: 'red' }}>Delete</p>
                        </div>
                    }
                    {optionId && allPosts.reported && !allPosts.flagged &&
                        <div onClick={() => handleFlag(optionId)} style={styles.optionButton}>
                            <p style={{...styles.optionButtonText, color: 'red' }}>Report as False</p>
                        </div>
                    }
                    {optionId && allPosts.flagged && allPosts.reported &&
                        <div style={{...styles.optionButton,  backgroundColor: '#007BFF' }} disabled>
                            <p style={{...styles.optionButtonText,  color: 'white' }}>Reported</p>
                        </div>
                    }

                </div>
            </ReusableModal>
            {
                onlyModal && <UserCardDropdown share={communityVersion} onShare={handleExternalShare} onlyModal={onlyModal} setOnlyModal={setOnlyModal} data={activeUserData.friends} selectedCard={selectedUser} setSelectedCard={setSelectedUser} SBuser outerFunc={sendMessage} />
            }
        </div>
    )
}



export default Thoughts;