import React, { useEffect, useRef, useState } from "react";
import useScreenSizes from "../../utils/useScreenSize";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { width } from "@mui/system";

// Custom hook for typing effect
const useTypingEffect = (text, speed = 100, pause = 1000) => {
  const [displayedText, setDisplayedText] = useState("");
  const [index, setIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    let timer;
    if (isDeleting) {
      if (index > 0) {
        timer = setTimeout(() => {
          setDisplayedText((prev) => prev.slice(0, -1));
          setIndex((prev) => prev - 1);
        }, speed);
      }
    } else {
      if (index < text.length) {
        timer = setTimeout(() => {
          setDisplayedText((prev) => prev + text[index]);
          setIndex((prev) => prev + 1);
        }, speed);
      }
    }

    return () => clearTimeout(timer);
  }, [index, isDeleting, text, speed, pause]);

  return displayedText;
};

const StudybuddyGraphics = () => {
  const [isInView, setIsInView] = useState(false);
  const [hasAnimated, setHasAnimated] = useState(false);
  const containerLeftRef = useRef(null);
  const containerRightRef = useRef(null);

  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const texts = ["Designer", "Developer", "Creator", "Thinker"];
  const animatedText = useTypingEffect(texts[currentTextIndex]);

  const screenType = useSelector((state) => state.website.screenType);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !hasAnimated) {
          setIsInView(true);
          setHasAnimated(true);
        }
      });
    });

    const useTypingEffect = (text, speed = 100) => {
      const [displayedText, setDisplayedText] = useState("");
      const [index, setIndex] = useState(0);

      useEffect(() => {
        let timer;
        if (index < text.length) {
          timer = setTimeout(() => {
            setDisplayedText((prev) => prev + text[index]);
            setIndex((prev) => prev + 1);
          }, speed);
        }
        return () => clearTimeout(timer);
      }, [index, text, speed]);

      return displayedText;
    };

    if (containerLeftRef.current) observer.observe(containerLeftRef.current);
    if (containerRightRef.current) observer.observe(containerRightRef.current);

    return () => {
      if (containerLeftRef.current)
        observer.unobserve(containerLeftRef.current);
      if (containerRightRef.current)
        observer.unobserve(containerRightRef.current);
    };
  }, [hasAnimated]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 5000);
    return () => clearTimeout(timer);
  }, [currentTextIndex]);

  const styles = {
    listContainer: {
      display: "flex",

      // alignItems: 'center',
      justifyContent: "center",
      marginTop:
        screenType === "smallPhone" || screenType === "phone" ? "" : "",
      gap: "20px",
          height:"100%",
          width:"100%",
          alignItems:"center",
          overflowWrap:"anywhere"
    },
    fixedText: {
      fontSize:
      screenType === "smallPhone" || screenType === "phone" ? "20px" : "28px",
    fontFamily: "",
    color: "black",
    overflowY: "auto", // Enables vertical scrolling
    overflowX: "hidden", //
    display:"flex",
    alignItems:"center",
    color: 'black',
    },
    animatedText: {
      fontSize: screenType === "smallPhone" ? "20px" : "34px",
      fontFamily: "",
      color: "black",
      marginBottom:"10px",
    },
  };

  const containerStyle = {
    width:
      screenType === "smallPhone" || screenType === "phone" ? "100%" : "60vw",
      height: screenType==="smallPhone"||screenType==="phone" ? '50vh' : screenType==="tab"||screenType==="tablet"?"35vh" : '60vh',

    opacity: isInView ? 1 : 0,
    transition: "transform 0.5s ease-in-out, opacity 0.5s ease-in-out",
    position: "relative",
    borderRadius: "10px",
    margin: "10px",
    display: "flex",
    justifyContent: "center",
    // overflow: "hidden",
  };

  const containerWrapperStyle = {
    display: "flex",
    flexDirection:
      screenType === "smallPhone" || screenType === "phone" ? "column" : "row",
    justifyContent: "space-between",
    // alignItems: "center",
    overflow: "hidden",
  };

  const leftContainerStyle = {
    width:
      screenType === "smallPhone" || screenType === "phone" ? "100%" : "60vw",
    // height: screenType === "smallPhone" || screenType === "phone" ? "" : "35vh",
    opacity: isInView ? 1 : 0,
    transition: "transform 0.1s ease-in-out, opacity 0.1s ease-in-out",
    position: "relative",
    borderRadius: "10px",
    margin: "10px",
    display: "flex",

    overflow: "scroll",
    transform: isInView ? "translateY(0)" : "translateY(100%)",
  };

  const rightContainerStyle = {
    ...containerStyle,
    transform: isInView ? "translateY(0)" : "translateY(100%)",
    display: "flex",
    justifyContent: "center",
    alignItems:screenType==="smallPhone"||screenType==="phone" ?"center" :""
  };
const {t} = useTranslation(); 
  return (
    <div
      style={{
        width: "95%",
        overflow: "hidden",
        backgroundColor: "#ffffff",
        borderRadius: "10px",
        boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.3)",
        height:screenType==="tablet"? "35vh": screenType==="tab" ? "35vh":"" ,
        margin: "5vh auto",
        padding: "15px",
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundImage: `url(${require("../../assets/images/forvision.webp")})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          opacity: 0.7, // Set the opacity of the image
          zIndex: 0, // Ensure it stays behind the content
        }}
      ></div>

      <div style={containerWrapperStyle}>
        <motion.div ref={containerLeftRef} style={leftContainerStyle}>
          <div style={styles.listContainer}>
            <div style={styles.fixedText}>
              <p
                style={{
                  fontSize:
                  screenType === "smallPhone" || screenType === "phone"
                  ? "20px"
                  : screenType==="tab"||screenType==="tablet" ? "25px" : "40px",
                  fontFamily: "'Roboto', sans-serif",
                  fontWeight: "600",
                  lineHeight: "1.5",
                  textAlign: "center",
                  color: "#2E3A59",
                  margin: "20px",
                  textShadow: "1px 1px 2px rgba(0, 0, 0, 0.2)",
                }}
              >
             {t("“Empowering Educators with Streamlined Tools for Effective Teaching”")}
              </p>
            </div>
          </div>
        </motion.div>
        <div ref={containerRightRef} style={rightContainerStyle}>
          <img
            src={require("../../assets/images/forteacher.webp")}
            style={{ height:screenType==="smallPhone"||screenType==="phone" ?"70%" : "100%", objectFit: "contain" }}
            loading="lazy"  />
        </div>
      </div>
    </div>
  );
};

export default StudybuddyGraphics;
