import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  formData: {},
};

const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    storeFormData: (state, action) => {
      state.formData = action.payload;
    },
    deleteFormData: (state) => {
      state.formData = {};
    },
    storeOtp: (state, action) => {
        state.otp = action.payload.otp;
        state.otpTimestamp = action.payload.timestamp;
      },
      clearOtp: (state) => {
        state.otp = null;
        state.otpTimestamp = null;
      },
  },
});

export const { storeFormData, deleteFormData, storeOtp, clearOtp } = formSlice.actions;
export default formSlice.reducer;