import React, { useEffect } from "react";

const DeepLinkRedirect = () => {
    useEffect(() => {
      // Extract postId from the URL path
      const pathSegments = window.location.pathname.split('/');
      const postId = pathSegments[pathSegments.length - 1]; // Get the last segment (post001)
  
      // If no postId is present, do nothing
      if (!postId) return;
  
      // Define deep link for the app
      const appLink = `studybuddy://post/${postId}`;
  
      // Define Play Store & App Store links
      const playStoreLink = "https://play.google.com/store/apps/details?id=com.yourapp";
      const appStoreLink = "https://apps.apple.com/app/yourapp/id123456789";
  
      // Open the app using deep link
      window.location.href = appLink;
  
      // If the app doesn't open, redirect to Play Store/App Store after 2 seconds
      setTimeout(() => {
        if (/android/i.test(navigator.userAgent)) {
          window.location.href = playStoreLink; // Redirect Android users
        } else if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
          window.location.href = appStoreLink; // Redirect iOS users
        }
      }, 2000);
    }, []);
  
    return null; // No UI needed
  };
  
  export default DeepLinkRedirect;
  
  