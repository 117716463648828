// import notifee, { AndroidImportance, AndroidStyle } from '@notifee/react-native';
import {auth, storage , messaging , functions , firestore, getToken} from '../firebase';

import { store } from '../reduxStore/store'; // Import the Redux store

// import { backgroundServiceHandler } from './backgroundService';
// import {
//   setMessageConversationArray,
//   setMessageRefreshTrigger,
//   setMessagesArray,
// } from '../redux/slice/input';
import { EventEmitter } from 'events';

export const storageEvents = new EventEmitter();

export async function requestUserPermission() {
  const authStatus = await messaging().requestPermission();
  const enabled =
    authStatus === messaging.AuthorizationStatus.AUTHORIZED ||
    authStatus === messaging.AuthorizationStatus.PROVISIONAL;

  if (enabled) {
    console.log('Authorization status:', authStatus);
    getFCMTOKEN(); // store token in a variable an store the variable in the firestore user document
  }
}

let messageQueue = [];
let isProcessingMessage = false;

const getUserSliceData = async (sliceName) => {
    // Retrieve item from localStorage
    const jsonString = localStorage.getItem(`persist:${sliceName}`);
  
    // Parse the JSON string to get the entire object
    let parsedData;
    try {
      parsedData = JSON.parse(jsonString);
      return parsedData;
    } catch (error) {
      console.error("Error parsing JSON string:", error);
      return;
    }
  };
  
const processNextMessage = async () => {
  if (!isProcessingMessage && messageQueue.length > 0) {


const userSliceAllUsersParsedData=await getUserSliceData('user')
let allLmsUsersParsedData;
let allInstitutesParsedData;

    isProcessingMessage = true;
    const message = messageQueue.shift();
   const messageData= JSON.parse(message.data.FCMData);
   console.log('messageData: ', messageData);

   if(messageData.sliceName=='institute'){
    allInstitutesParsedData=await getUserSliceData('institute')

   }
   else if(messageData.sliceName=='lmsUser'){
    allLmsUsersParsedData=await getUserSliceData('lmsUser')

   }
    
    const result = await handleFCMMessage(message,userSliceAllUsersParsedData,allLmsUsersParsedData,allInstitutesParsedData);
    console.log('result::::::::::::::::::::::::::::::::::::: ', result);

    if (result === 'success') {
      console.log('result:+++++++++++++++++++++++++++++++ ', result);
      isProcessingMessage = false;
      processNextMessage(); // Process the next message in the queue
    } else {
      console.error('Failed to process message:', message);
      isProcessingMessage = false;
    }
  }
};




export function convertToJSON(parsedData) {
  const convertedData = {};

  // Iterate over the keys in parsedData
  for (const key in parsedData) {
    if (parsedData.hasOwnProperty(key)) {
      try {
        // Check if the value is a string and can be parsed as JSON
        if (typeof parsedData[key] === 'string') {
          const value = parsedData[key].trim();

          // Handle empty arrays, empty objects, and empty strings
          if (value === '[]' || value === '{}' || value === '""') {
            convertedData[key] = JSON.parse(value);
          } else {
            // Try to parse other JSON-like strings
            convertedData[key] = JSON.parse(value);
          }
        } else {
          // If it's not a string, just add it as is
          convertedData[key] = parsedData[key];
        }
      } catch (error) {
        // If parsing fails, retain the original value
        convertedData[key] = parsedData[key];
      }
    }
  }

  return convertedData;
}
 export const  getFCMTOKEN = async () => {
    try {
    
  
      
  
      // Request permission to send notifications
      const permission = await Notification.requestPermission();
  
      if (permission === 'granted') {
        // Get the token after user grants permission
const token = await getToken(messaging, {
          vapidKey: "BOaQ482qDKa3uJWM5SQUVpRAOAh0-MSmmKYUdugqArdyluZe1vzoO8789D1kRFCtXxo8sVm72Weqr9drJpi8EYU"
        });        console.log('FCM Token:', token);
        return token;
      } else {
        console.log('Notification permission denied');
      }
    } catch (error) {
      console.error('Error in getting FCM token:', error);
    }
  };
  
const onDisplayBasicNotification = async (notificationTitle, notificationBody, targetScreen) => {
    console.log("Notification body:", notificationTitle, notificationBody);
  
    // Check if the browser supports notifications
    if (!("Notification" in window)) {
      console.error("This browser does not support desktop notifications.");
      return;
    }
  
    // Request permission from the user
    if (Notification.permission === "default") {
      await Notification.requestPermission();
    }
  
    // If permission is granted, show the notification
    if (Notification.permission === "granted") {
      const notificationOptions = {
        body: notificationBody,
        icon: "/path-to-icon.png", // Optional: add a custom icon
        data: {
          ...(targetScreen ? { targetScreen } : {}), // Adds targetScreen only if it's defined
        },
      };
  
      const notification = new Notification(notificationTitle, notificationOptions);
  
      // Handle click event to navigate to the target screen
      notification.onclick = () => {
        if (targetScreen) {
          window.open(targetScreen, "_blank"); // Opens in a new tab
        }
      };
    } else {
      console.error("Notification permission denied.");
    }
  };
  
  export default onDisplayBasicNotification;
  

const onDisplayBigPictureNotification = async (notificationTitle, notificationBody, targetScreen) => {
    console.log("Notification body:", notificationTitle, notificationBody);
  
    // Check if the browser supports notifications
    if (!("Notification" in window)) {
      console.error("This browser does not support desktop notifications.");
      return;
    }
  
    // Request permission from the user
    if (Notification.permission === "default") {
      await Notification.requestPermission();
    }
  
    // If permission is granted, show the notification
    if (Notification.permission === "granted") {
      const notificationOptions = {
        body: notificationBody,
        icon: "/path-to-big-picture.jpg", // Use an image URL for the "big picture"
        image: "/path-to-big-picture.jpg", // Some browsers support this
        data: {
          ...(targetScreen ? { targetScreen } : {}), // Adds targetScreen only if defined
        },
      };
  
      const notification = new Notification(notificationTitle, notificationOptions);
  
      // Handle click event to navigate to the target screen
      notification.onclick = () => {
        if (targetScreen) {
          window.location.href = targetScreen; // Navigate to the target screen
        }
      };
    } else {
      console.error("Notification permission denied.");
    }
  };
  

export const onMessageReceived = async remoteMessage => {
  console.log(
    '-----------------------------------------------------------------------------------------------------------', remoteMessage, ">>>>>", remoteMessage.data.type
  );
  if (remoteMessage.data.type === 'notification') {
    handleNotification(remoteMessage);
  } else if (remoteMessage.data.type === 'FCMData') {
    messageQueue.push(remoteMessage);
    if (messageQueue.length === 1) {
      processNextMessage();
    }
  } else if (remoteMessage.data.type === 'updateFirestore') {
    console.log('remote message fcm to update user data on firestore recieved');
  } else if (remoteMessage.data.type === 'BOTH') {

    messageQueue.push(remoteMessage);
    if (messageQueue.length === 1) {
      processNextMessage();
    }
  }
};

const handleNotification = remoteMessage => {
  const reduxState = store.getState();
  const userSlice = reduxState.user;
  // console.log('A new FCM Notification arrived:', JSON.stringify(remoteMessage));
  let notificationType = remoteMessage.data.notificationType;
  console.log('userSlice.activeScreen: ', userSlice.activeScreen);
  console.log('notificationType: ', notificationType);
  if (userSlice.activeScreen == 'MessageDemo') {
    notificationType = null;
  }

  // console.log('Notification type:', notificationType);

  switch (notificationType) {
    case 'type1':
      console.log("Sas");
      onDisplayBasicNotification(
        remoteMessage.data.notificationTitle,
        remoteMessage.data.notificationBody,
        remoteMessage.data.targetScreen,
      );
      break;
    case 'type2':
      onDisplayBigPictureNotification(
        remoteMessage.data.notificationTitle,
        remoteMessage.data.notificationBody,
        remoteMessage.data.targetScreen,
      );
      break;
    default:
      break;
  }
};

const handleFCMMessage = async (remoteMessage,userSliceAllUsersParsedData,allLmsUsersParsedData,allInstitutesParsedData) => {
  console.log(
    '09[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[',
  );


 // Step 3: Parse the `users` property from the parsed data
 let parsedData=userSliceAllUsersParsedData
  let userSliceAllUsers;
  try {
    console.log('-================================');
    userSliceAllUsers = await JSON.parse(userSliceAllUsersParsedData.users);

  
  } catch (error) {
    console.log('Error parsing `users` property:', error);
    return;
  }

  


  
  // console.log('A new FCM Data arrived:', JSON.stringify(remoteMessage));
  try {
    //  // Get the current state of messageRefreshTrigger
    //  const messageRefreshTrigger = store.getState().add.messageRefreshTrigger;

    //  // Dispatch from the store directly
    //  const dispatch = store.dispatch;

    if (!!remoteMessage.data.FCMData) {
      const varData = JSON.parse(remoteMessage.data.FCMData); // Parse the stringified object
      // console.log(varData, '-----');

      const sliceName = varData.sliceName;
      const allslices = Object.keys(localStorage).filter(key => key.startsWith("persist:"));
      console.log(
        sliceName,
        '>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>',
        allslices,
      );
      

      // console.log("previous value of the input slice",userSliceAllUsers)

      const userIdsArrayFromFCM = varData.userIds || [];

      const varDataSpecialCase = varData.specialCase;
      console.log('varDataSpecialCase: ', varDataSpecialCase);
      const topicId = varData.topicId;
      console.log('topicId: ', topicId);
      const usersIdArray = Object.keys(userSliceAllUsers);
      console.log('usersIdArray: ', usersIdArray);




      if (sliceName == 'user') {




        for (const userId of usersIdArray) {
          const userData = { ...userSliceAllUsers[userId] };
          console.log('userData: ', userData.messages['bDRcapEk01IZPot9N64y'], userData.messages['bDRcapEk01IZPot9N64y'].length);
          // console.log('userIdsArrayFromFCM: ', userIdsArrayFromFCM, userId);
          let updatedUserData;
          const topicIdsArray = userData.topicIdsArray;
          if (
            (varDataSpecialCase == 'conversationCreation' &&
              userIdsArrayFromFCM.includes(userId)) ||
            (varDataSpecialCase == 'sendRequest' &&
              userIdsArrayFromFCM.includes(userId)) ||
            (varDataSpecialCase == 'cancelRequest' &&
              userIdsArrayFromFCM.includes(userId)) ||
            (varDataSpecialCase == 'sendConversationMessage' &&
              userIdsArrayFromFCM.includes(userId)) ||

            (varDataSpecialCase !== 'sendConversationMessage' && topicIdsArray.includes(topicId)) // Explicitly check for non-conflicting cases
          ) {
            handleNotification(remoteMessage)
            // console.log("???????:::--");
            // console.log("varData.variableNamesArray.length",varData.variableNamesArray.length);
            const length = varData.variableNamesArray.length || 0;
            for (let i = 0; i <= length - 1; i++) {
              // console.log("===============++}}", i);
              const variableName = varData['variableNamesArray'][i]['name'];
              const variableDataTypes =
                varData['variableNamesArray'][i]['type'];
              const variablePath = varData['variableNamesArray'][i]['path'];
              //console.log('variablePath: ', variablePath);
              const operationType =
                varData['operationTypeObject'][variableName];
              const newValue = varData['operationalValuesObject'][variableName];
              console.log("LLLLPPPP");
              updatedUserData = await operateOnData(
                userData,
                variablePath,
                variableDataTypes,
                operationType,
                newValue,
              );
              console.log("MMMM");

              userSliceAllUsers[userId] = updatedUserData;
              console.log('updatedUserData:--= ', updatedUserData.messages['bDRcapEk01IZPot9N64y'], updatedUserData.messages['bDRcapEk01IZPot9N64y'].length);
              // console.log('userSliceAllUsers:++++++++ ', userSliceAllUsers);

              // console.log('Updated actual data:', userSliceAllUsers);

              // Step 4: Update the parsed data and save it back to AsyncStorage
              parsedData.users = JSON.stringify(userSliceAllUsers);
              try {
    localStorage.setItem(`persist:${sliceName}`, JSON.stringify(parsedData));
  } catch (error) {
    console.error("Error saving data to localStorage:", error);
  }
              storageEvents.emit('storageChange', {
                key: variableName,
                value: 'success',
              });
              console.log('Userkk details updated successfully');

            }

          }



        }


      } else if (sliceName == 'lmsUser') {
        for (const userId of usersIdArray) { //to check if the sbuser have the topic for the lmsuser
          const userData = { ...userSliceAllUsers[userId] };
          const topicIdsArray = userData.topicIdsArray;
          if (
            (varDataSpecialCase == 'conversationCreation' &&
              userIdsArrayFromFCM.includes(userId)) ||
            (varDataSpecialCase == 'sendRequest' &&
              userIdsArrayFromFCM.includes(userId)) ||
            (varDataSpecialCase == 'cancelRequest' &&
              userIdsArrayFromFCM.includes(userId)) ||
            (varDataSpecialCase == 'sendConversationMessage' &&
              userIdsArrayFromFCM.includes(userId)) ||

            topicIdsArray.includes(topicId)
          ) {

            handleNotification(remoteMessage)
            const length = varData.variableNamesArray.length || 0;
            const lmsIdsArrayFromFcm = varData['lmsIds'] || [];

       
            // Step 2: Parse the JSON string to get the entire object
            let parsedData=allLmsUsersParsedData
            // Step 3: Parse the `users` property from the parsed data
            let allLmsUsers;
            try {
              //console.log('-================================');
              allLmsUsers = await JSON.parse(parsedData.lmsUsers);
            } catch (error) {
              console.log('Error parsing `users` property:', error);
              return;
            }

            const lmsUserIdsArrayInSlice = Object.keys(allLmsUsers);
            for (const lmsUserId of lmsUserIdsArrayInSlice) {
              let updatedLmsUserData
              if (lmsIdsArrayFromFcm.includes(lmsUserId)) {
                const lmsUserData = { ...allLmsUsers[lmsUserId] }
                for (let i = 0; i <= length - 1; i++) {
                  const variableName = varData['variableNamesArray'][i]['name'];
                  const variableDataTypes =
                    varData['variableNamesArray'][i]['type'];
                  const variablePath = varData['variableNamesArray'][i]['path'];
                  const operationType =
                    varData['operationTypeObject'][variableName];
                  const newValue =
                    varData['operationalValuesObject'][variableName];

                  updatedLmsUserData = await operateOnData(
                    lmsUserData,
                    variablePath,
                    variableDataTypes,
                    operationType,
                    newValue,
                  );

                  allLmsUsers[lmsUserId] = updatedLmsUserData;
                  // console.log('userSliceAllUsers:++++++++ ', userSliceAllUsers);

                  // console.log('Updated actual data:', userSliceAllUsers);

                  // Step 4: Update the parsed data and save it back to AsyncStorage
                  parsedData.lmsUsers = JSON.stringify(allLmsUsers);
                  try {
                    localStorage.setItem(`persist:lmsUser`, JSON.stringify(parsedData));
                  } catch (error) {
                    console.error("Error saving LMS user data to localStorage:", error);
                  }
                  storageEvents.emit('storageChange', {
                    key: variableName,
                    value: 'success',
                  });
                  console.log('LmsUser details updated successfully');

                }
              }

            }
          }

        }


      } else if (sliceName == 'institute') {
        for (const userId of usersIdArray) { //to check if the sbuser have the topic for the institute
          const userData = { ...userSliceAllUsers[userId] };
          const topicIdsArray = userData.topicIdsArray;
          if (
            (varDataSpecialCase == 'conversationCreation' &&
              userIdsArrayFromFCM.includes(userId)) ||
            (varDataSpecialCase == 'sendRequest' &&
              userIdsArrayFromFCM.includes(userId)) ||
            (varDataSpecialCase == 'cancelRequest' &&
              userIdsArrayFromFCM.includes(userId)) ||
            (varDataSpecialCase == 'sendConversationMessage' &&
              userIdsArrayFromFCM.includes(userId)) ||

            topicIdsArray.includes(topicId)
          ) {

            handleNotification(remoteMessage)
            const length = varData.variableNamesArray.length || 0;
            const instituteIdsArrayFromFcm = varData['instituteIds'] || [];

            // Step 2: Parse the JSON string to get the entire object
            let parsedData=allInstitutesParsedData;
          

            // Step 3: Parse the `users` property from the parsed data
            let allInstitutesOfAllUsers;
            let allInstitutesOfParticularUser;
            try {
              console.log('-================================');
              allInstitutesOfAllUsers = await JSON.parse(parsedData.institutes);
              allInstitutesOfParticularUser = allInstitutesOfAllUsers[userId]

            } catch (error) {
              console.log('Error parsing `users` property:', error);
              return;
            }

            const institutesIdsOfParticularUser = Object.keys(allInstitutesOfParticularUser);
            for (const instituteId of institutesIdsOfParticularUser) {
              let updatedInstituteData;
              if (instituteIdsArrayFromFcm.includes(instituteId)) {
                const instituteData = { ...allInstitutesOfParticularUser[instituteId] }
                for (let i = 0; i <= length - 1; i++) {
                  const variableName = varData['variableNamesArray'][i]['name'];
                  const variableDataTypes =
                    varData['variableNamesArray'][i]['type'];
                  const variablePath = varData['variableNamesArray'][i]['path'];
                  const operationType =
                    varData['operationTypeObject'][variableName];
                  const newValue =
                    varData['operationalValuesObject'][variableName];

                  updatedInstituteData = await operateOnData(
                    instituteData,
                    variablePath,
                    variableDataTypes,
                    operationType,
                    newValue,
                  );


                  allInstitutesOfParticularUser[instituteId] = updatedInstituteData;
                  // console.log('userSliceAllUsers:++++++++ ', userSliceAllUsers);

                  // console.log('Updated actual data:', userSliceAllUsers);

                  // Step 4: Update the parsed data and save it back to AsyncStorage
                  allInstitutesOfAllUsers[userId] = allInstitutesOfParticularUser
                  parsedData.institutes = JSON.stringify(allInstitutesOfAllUsers);
                  try {
                    localStorage.setItem(`persist:institute`, JSON.stringify(parsedData));
                  } catch (error) {
                    console.error("Error saving Institute data to localStorage:", error);
                  }
                  storageEvents.emit('storageChange', {
                    key: variableName,
                    value: 'success',
                  });
                  console.log('institute details updated successfully');


                }
              }

            }
          }
        }


      }


      return 'success';

    }
  } catch (error) {
    console.error('Unable to update the data in the async storage', error);
    return 'failure';
  }
};

export function convertToStringified(parsedData) {
  const stringifiedData = {};

  // Iterate over the keys in parsedData
  for (const key in parsedData) {
    if (parsedData.hasOwnProperty(key)) {
      const value = parsedData[key];

      // Check if the value is an object or array
      if (typeof value === 'object' && value !== null) {
        // Stringify arrays or objects (including empty arrays/objects)
        stringifiedData[key] = JSON.stringify(value);
      } else if (typeof value === 'string') {
        // If it's already a string, just keep it as is
        stringifiedData[key] = `"${value}"`;
      } else {
        // For other data types (e.g., number, boolean), convert to a string
        stringifiedData[key] = String(value);
      }
    }
  }

  return stringifiedData;
}

async function operateOnData(data, path, dataType, operation, newValue) {
  console.log('newValue: ', newValue);
  console.log('operation: ', operation);
  console.log('dataType: ', dataType);
  console.log('data: ', data);
  console.log('path==: ', path);
  // Split path and dataType into arrays for traversal
  const pathArray = path.split('_');
  const dataTypeArray = dataType.split('_');

  // Ensure the lengths match
  if (pathArray.length !== dataTypeArray.length) {
    throw new Error('Path and DataType lengths must match.');
  }

  // Traverse the data using the path
  let currentData = data;
  for (let i = 0; i < pathArray.length - 1; i++) {
    const key = pathArray[i];
    const type = dataTypeArray[i];

    if (type === 'object') {
      currentData = currentData[key] || {};
    } else if (type === 'array') {
      const arrayData = currentData[key] || [];

      // Check if the array contains objects
      if (
        Array.isArray(arrayData) &&
        arrayData.length > 0 &&
        typeof arrayData[0] === 'object'
      ) {
        // Pass the object with the key into currentData
        const matchingObject = arrayData.find(item => item[key] !== undefined);
        currentData = matchingObject || {};
      } else {
        // Otherwise, assign as a plain array
        currentData = arrayData;
      }
    } else {
      throw new Error(`Unsupported data type: ${type}`);
    }

    // console.log('currentData: ', key,">><<,,,",currentData);
  }

  // Target key and its type
  const targetKey = pathArray[pathArray.length - 1];
  console.log('targetKey: ', targetKey);
  const targetType = dataTypeArray[dataTypeArray.length - 1];
  console.log('targetType: ', targetType);
  const targetData = currentData[targetKey];
  console.log('targetData: ', targetData);

  // Perform the specified operation
  switch (operation) {
    case 'append':
      currentData[targetKey] = await Append(targetData, newValue, targetType);
      break;
    case 'deleteValue':
      currentData[targetKey] = await deleteValue(
        targetData,
        newValue, //value to delete
        targetType,
      );
      break;

    case 'deleteVariable':
      currentData[targetKey] = await deleteVariable(targetData, targetType);
      break;
    case 'update':
      // console.log('updatedValueObject: ', updatedValueObject);
      currentData[targetKey] = await updateValue(newValue, targetType);
      // console.log('updatedValue: ', updatedValue);
      break;
    case 'updateMessageViewers': // this is used to update the value inside the variable specially created for conversation
      //in this case nested new value contain the viewersDataObject object with key value pair of those message whose viewer has to be changed
      currentData[targetKey] = await updateMessageViewers(
        targetData, //prev conversation id array
        newValue, //newViewersObject
        targetType,
      );
      // console.log('previousValue[nestedKey] after replaceValue: ', previousValue[nestedKey]);
      break;

    default:
      console.error(`Unknown operation type: ${targetType}`);
  }

  return data;
}

// Helper functions
async function Append(originalValue, newValue, type) {
  let updatedValue;

  if (type === 'array') {
    // it will append if it already exist
    // // Ensure originalValue is an array before appending
    // updatedValue = [...originalValue, newValue];
    // return updatedValue;

    // this code will replace the value if it already exist
    // Check if the value already exists in the array
    const valueIndex = originalValue.findIndex(
      item => JSON.stringify(item) === JSON.stringify(newValue),
    );
    if (valueIndex !== -1) {
      // Replace the existing value
      originalValue[valueIndex] = newValue;
      updatedValue = [...originalValue];
    } else {
      // Append the new value
      updatedValue = [...originalValue, newValue];
    }
    return updatedValue;
  } else if (type === 'object') {
    // Ensure originalValue is an object before merging
    updatedValue = { ...originalValue, ...newValue };
    return updatedValue;
  } else if (type === 'string') {
    // Ensure originalValue is a string before concatenating
    updatedValue = originalValue + newValue;
    return updatedValue;
  } else if (type === 'number') {
    // Ensure originalValue is a number before adding
    updatedValue = originalValue + newValue;
    return updatedValue;
  } else if (type === 'boolean') {
    // Handle boolean logic (example: toggling or OR operation)
    updatedValue = originalValue || newValue; // Example logic
    return updatedValue;
  } else {
    // If the type is not recognized, throw an error
    console.error(`Unsupported type: ${type}`);
  }
}

function updateValue(newValue, type) {
  return newValue;
}

async function deleteVariable(value, variableType) {
  if (variableType == 'array') {
    value.length = 0;
  } else if (variableType == 'object' && value !== null) {
    for (let prop in value) {
      if (value.hasOwnProperty(prop)) {
        delete value[prop];
      }
    }
  } else {
    value = null;
  }
  return value;
}

async function deleteValue(previousValue, valueToDelete, prevValueType) {
  if (prevValueType == 'array') {
    // Filter out the valueToDelete from the array
    return previousValue.filter(item => item !== valueToDelete);
  } else if (prevValueType == 'object' && previousValue !== null) {
    // Iterate through the object and delete matching values
    for (let key in previousValue) {
      if (previousValue.hasOwnProperty(key)) {
        if (previousValue[key] === valueToDelete) {
          delete previousValue[key];
        }
      }
    }
    return previousValue;
  } else {
    // For primitive types, return null if they match the valueToDelete
    return previousValue === valueToDelete ? null : previousValue;
  }
}

async function replaceValue(previousValue, newValue, type) {
  const id = newValue['id'];
  console.log(
    'id:+++++++++++++++++++++++++++++++++++++++++++++++++',
    id,
    newValue['text'],
    newValue['viewers'],
  );

  // Loop from the end of the array to the beginning
  for (let i = previousValue.length - 1; i >= 0; i--) {
    // Get the object at the current index
    const obj = previousValue[i];

    // Check if the id matches
    if (obj.id === id) {
      // Replace the object with the new value
      previousValue[i] = newValue;
      // console.log('previousValue[i]: ', previousValue[i]);

      break; // Exit the loop since we've made the replacement
    }
  }

  return previousValue;
}

// this is used to update the nested value specially created for conversation
async function updateMessageViewers(previousValue, newViewersObject, type) {
  // Iterate over the keys of newViewersObject
  for (const key in newViewersObject) {
    if (newViewersObject.hasOwnProperty(key)) {
      // Get the new viewers value for the current key
      const newViewers = newViewersObject[key];

      // Loop through previousValue starting from the end
      for (let i = previousValue.length - 1; i >= 0; i--) {
        const obj = previousValue[i];

        // Check if the id matches the key from newViewersObject
        if (obj.id === key) {
          // Update the viewers for the matching object
          obj.viewers = newViewers;

          // Break inner loop as match is found
          break;
        }
      }
    }
  }

  return previousValue;
}