import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedEvent: null,
  inviteCode: null,
};

const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    setEventDetails: (state, action) => {
      state.selectedEvent = action.payload.selectedEvent;
      state.inviteCode = action.payload.inviteCode;
      state.selectedClass = action.payload.selectedClass;
    },
    clearEventDetails: (state) => {
      state.selectedEvent = null;
      state.inviteCode = null;
    },
  },
});

export const { setEventDetails, clearEventDetails } = eventSlice.actions;
export default eventSlice.reducer;
