import React, { useState, useEffect } from 'react';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CardStack = ({ cardData, uploading, setImageExist }) => {

    const [imageSources, setImageSources] = useState([]);

    useEffect(() => {
        if (!Array.isArray(cardData) || cardData.length === 0) return;

        const sources = cardData.map((item) => {
            if (item instanceof File) {
                return URL.createObjectURL(item); // Convert File object to URL
            } else if (typeof item === 'string' && item.includes("firebasestorage.googleapis.com")) {
                return item; // Firebase Storage URL
            }
            return null;
        }).filter(Boolean);

        setImageSources(sources);
        setImageExist(sources.length > 0);

        // Cleanup object URLs to prevent memory leaks
        return () => sources.forEach((src) => URL.revokeObjectURL(src));
    }, [cardData]);

    return (
        <>
            {uploading && (
                <div style={styles.loadingContainer}>
                    <div>...loading</div>
                </div>
            )}

            {imageSources.map((src, index) => (
                <div
                    key={index}
                    style={{
                        ...styles.card,
                        bottom: index * 8,
                        left: index * 5,
                        zIndex: imageSources.length - index,
                        opacity: index === 0 ? 1 : Math.max(0.2, 1 - index * 0.2),
                    }}
                >
                    <img
                        src={src}
                        alt="Uploaded"
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                </div>
            ))}
        </>
    );
};

const styles = {
    card: {
        width: '100%',
        height: '97%',
        position: 'absolute',
        borderRadius: '10px',
        overflow: 'hidden',
        border: '2px solid black',
        boxShadow: '0px 2px 3.84px rgba(0, 0, 0, 0.25)',
    },
    loadingContainer: {
        position: 'absolute',
        width: 58,
        height: 58,
        justifyContent: 'center',
        backgroundColor: '#021',
        zIndex: 10,
        left: '40%',
        right: '50%',
        top: '45%',
        bottom: '60%',
        borderRadius: 60,
        display: 'flex',
        alignItems: 'center',
    },
};

export default CardStack;
