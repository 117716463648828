import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import  {useTranslation} from "react-i18next" 

function Banner() {
  const [hovered, setHovered] = useState(false); // Track hover state
  const {t} = useTranslation()
  const screenType = useSelector((state) => state.website.screenType);
  const navigate = useNavigate()
 const handleButtonClick = ()=>{
    navigate("/getStarted")
 }
  const buttonContainerStyles = {
    position: "absolute",
    top: "67%",
    left: "50%",
    transform: "translate(-50%, -50%)", // Center the button
    zIndex: 2,
  };

  const buttonStyles = {
    background: "#162d49",
    border: "none",
    color: "#da8d06",
    padding:
      screenType === "phone" || screenType === "smallPhone"
        ? "5px 10px"
        : "10px 20px",
    fontSize:
      screenType === "phone" || screenType === "smallPhone"
        ? "0.8rem"
        : "1.2rem",
    fontWeight: "bold",
    borderRadius: "5px",
    cursor: "pointer",
    transform: hovered ? "scale(1.1)" : "scale(1)", // Scale up on hover
    transition: "transform 0.3s ease-in-out", // Smooth animation
    animation: "pulse 1.5s infinite", // Optional pulse effect
    borderRadius:"10px"
  };

  return (
    <div
      style={{
        height: "35vw",
        // width: "96vw",
        margin: "2vw auto",
        position: "relative",
        borderRadius: "10px",
      }}
    >
      <div
        style={buttonContainerStyles}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={handleButtonClick}
      >
        <button style={buttonStyles}> {t ("Get Started")}</button>
      </div>
      <img
        src={require("../../assets/images/WSBanner_batcheditor_fotor.webp")}
        alt="Banner"
        style={{ height: "100%", width: "100%", objectFit: "contain",        borderRadius: "10px",
          
        }}
        loading="lazy" 
      />
    </div>
  );
}

export default Banner;
