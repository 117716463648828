import { display } from "@mui/system";
import React, { useState, useEffect } from "react";
import Skeleton from 'react-loading-skeleton'; // Skeleton package

import { useNavigate } from "react-router-dom";

/*
props(
name,
time,
recentText,
unreadmessage
profileLogo
)
 */

const ChatCard = (props) => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    setInterval(() => { // loading intervel
        setLoading(false)
    }, 4000)

    const profileLogo = require('../../assets/images/profile.webp')
    const { width, height } = window.innerWidth

    const styles = {
        container: {
            backgroundColor: '#FFF',
            borderRadius: 12,
            width: '100%',
            paddingVertical: 10,
            paddingHorizontal: 10,
            elevation: 5,
            marginVertical: 10,
            flex: 1,
            display: "flex",
            rowGap: 10,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",

        },
        innerContainer: {
            flexDirection: "row",
            alignItems: "center",
            columnGap: 10 ,
            display:"flex"
        },
        profileLogo: {
            width: 50, height: 50
        },
        textcantainer: {
            rowGap: 5

        },
        text: {
            color: "#3A4870",
            width: width * 0.5,
            fontFamily: "MarkerFelt",
            fontSize: 20
        },
        subtext: {
            color: "grey",
            width: width * 0.5,

        },
        statusContainer: {
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '80%',
            display:"flex"
        },
        unreadCountContainer: {
            borderRadius: 50,
            alignSelf: "flex-end",
            backgroundColor: "#EA4D3D",
            justifyContent: "center",
            alignItems: "center",
            width: 15,
            height: 15,
        },
        unreadCountText: {
            color: "white",
            fontSize: 10,
        },
        time: {
            color: "#3A4870",

        }

    }


    return (
        loading ? (
            <Skeleton
                style={{ flex: 1, width: '100%', height: 70, margin: '10px 0', borderRadius: 12 }}
                baseColor="#E1E9EE"
                highlightColor="#F2F8FC"
            />
        ) :
       

            <div style={styles.container} activeOpacity={0.8} onClick={() =>navigate('/MessageDemo',{ state: {  id: props.id } })}>

                <div style={styles.innerContainer}>

                    <img src={profileLogo} style={styles.profileLogo} />

                    <div style={styles.textcantainer}>
                        <p numberOfLines={1} style={styles.text}>{props.name}</p>
                        <p numberOfLines={1} style={styles.subtext}>{props.recentText}</p>
                    </div>

                </div>
                <div style={styles.statusContainer}>

                    <div style={styles.unreadCountContainer}>
                        <p style={styles.unreadCountText}>{props.unreadmessage}</p>
                    </div>

                    <p style={styles.time}>{props.time}</p>
                </div>
            </div>
       )
         
    

}



export default ChatCard;