import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    institute: {}, // Nested structure for institute data
};

const Slice = createSlice({
    name: 'institute',
    initialState,
    reducers: {
        resetState: (state) => {
            // Resetting the state to initial state
            return initialState;
        },
        setInstitute: (state, action) => {
            const { id, ActiveUser, data, filter } = action.payload;

            // Ensure nested objects exist before setting values
            if (!state.institute[ActiveUser]) {
                state.institute[ActiveUser] = {};
            }
            if (!state.institute[ActiveUser][id]) {
                state.institute[ActiveUser][id] = {};
            }

            // Handle `filter` logic
            if (filter) {
                state.institute[ActiveUser][id][filter] = data;
            } else {
                state.institute[ActiveUser][id] = data;
            }
        },
    },
});

export const { resetState, setInstitute } = Slice.actions;

export default Slice.reducer;