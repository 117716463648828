import { createSlice } from '@reduxjs/toolkit';

const initialData={
  announcements:['ABCD']
}
const initialState = {
  activeScreen: '',
  users: {},
  pendingMessagesQueue:[],
  activeUser: null,
  isPinEnteredSuccessfully:false,
  isInternetConnected:false,
  appState:null, //to check whether the app is in active or not
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      const { userUid, userName, userBranch } = action.payload;
      // console.log('action.payload:==09- ', action.payload);
      state.users[userUid] = { ...action.payload };
    },
    setActiveSBUser: (state, action) => {
      state.activeUser = action.payload;
    },
    clearUserData: (state, action) => {
      const userUid = action.payload;



      // const userdata=  state.users
      // console.log(state.users, "<><><><><><><><><><><><><>")
      delete state.users[userUid];

      // console.log(state.users, "{}{}{}{}{}{}{}{}{}{}{}{}")
      //  state.users=userdata;




      if (state.activeUser === userUid) {
        state.activeUser = null;
      }
    },
    updateUserField: (state, action) => {
      const { userUid, updates } = action.payload;
      if (state.users[userUid]) {
        // Spread the updates into the user's current data
      //   console.log( ...state.users[userUid], ...updates)
        state.users[userUid] = { ...state.users[userUid], ...updates };
      }

    },
    setActiveScreen:(state,action) =>{
      state.activeScreen=action.payload;
    },
    setIsPinEnteredSuccessfully:(state,action)=>{
      state.isPinEnteredSuccessfully=action.payload
    },

    setConversationDataFromStorageBucket:(state,action)=>{
      const {uid,data}=action.payload
state.users[uid]['conversationData']=data
    },
    setIsInternetConnected:(state,action)=>{
      state.isInternetConnected=action.payload
    },
    appendPendingMessageQueue: (state, action) => {
      // Ensure pendingMessagesQueue is always an array before updating
      if (!Array.isArray(state.pendingMessagesQueue)) {
          state.pendingMessagesQueue = [];
      }
      state.pendingMessagesQueue = [...state.pendingMessagesQueue, action.payload];
  },
  
    removeMessageFromQueue: (state, action) => {
      // console.log('action:-=-== ', action.payload.id);
      state.pendingMessagesQueue = state.pendingMessagesQueue.filter(
        message => message.id !== action.payload.id
      );
      // console.log(state.pendingMessagesQueue,">LOOOOPPPPP");
    },
    updateUserField: (state, action) => {
      const { userUid, updates } = action.payload;
      console.log(userUid);

      
      // console.log('userUid: ', userUid);

      if (state.users[userUid]) {
        // Spread the updates into the user's current data
        //   console.log( ...state.users[userUid], ...updates)
        // dispatch(
        //   updateUserField({ userUid: activeUser, updates: { userBranch: 'MBA' } })
        // );
        state.users[userUid] = { ...state.users[userUid], ...updates };


      }

    },

    updateUserMessages: (state, action) => {
      // console.log("L<lsocp");
      const { userUid, updates, conversationId,append } = action.payload;
      if (state.users[userUid]) {
        // Spread the updates into the user's current data
        //   console.log( ...state.users[userUid], ...updates)
        // dispatch(
        //   updateUserField({ userUid: activeUser, updates: { userBranch: 'MBA' } })
        // );
        const convo = { ...state.users[userUid].messages }
        if(append){
          console.log('append');
          convo[conversationId] = [...convo[conversationId],updates]
        }else{
          // console.log(">MAAAAAAAA");
          convo[conversationId] = updates
        }
        // console.log("><MNNXK");
        state.users[userUid] = { ...state.users[userUid], messages: convo };
      }

    },
    updateConversationBg: (state, action) => {
      const {userUid, id, background_image } = action.payload;
      const conversation = state.users[userUid].conversations[id];
      if (conversation) {
          conversation.background_image = background_image;
      }
  },
    updateUserMessagesRef: (state, action) => {
      const { userUid, updates, conversationId,append } = action.payload;
      
      if (state.users[userUid]) {
        // Spread the updates into the user's current data
        //   console.log( ...state.users[userUid], ...updates)
        // dispatch(
        //   updateUserField({ userUid: activeUser, updates: { userBranch: 'MBA' } })
        // );
        const convo = { ...state.users[userUid].messageRefs }
        if(append){
          console.log('append');
          convo[conversationId] = [...convo[conversationId],updates]
        }else{
          convo[conversationId] = updates
        }
        state.users[userUid] = { ...state.users[userUid], messageRefs: convo };
      }

    },
    deleteUserMessages: (state, action) => {

      const { userUid,conversationId, ids } = action.payload;

      // Check if the conversation exists in the messagesArray
      if (state.users[userUid].messages[conversationId]) {

          // Filter out messages whose id is in the ids array
          const filteredMessages = state.users[userUid].messages[conversationId].filter(
              message => !ids.includes(message.id)
          );

          // Update the state with the filtered messages
          state.users[userUid].messages[conversationId] = filteredMessages;
      }
  },
    setActiveScreen: (state, action) => {
      state.activeScreen = action.payload;
    },

    setAppState:(state,action)=>{
      // console.log(action.payload,">>>>>>>>>>>>>>>>>>>>>>>>>>");
      state.appState=action.payload
    },

    setAllUsers:(state,action)=>{
      
      state.users= action.payload 
    }



  },
});

export const { setUserData,setAllUsers, updateConversationBg,  setAppState,updateUserMessages,updateUserMessagesRef,deleteUserMessages,appendPendingMessageQueue ,removeMessageFromQueue , setActiveSBUser, clearUserData ,updateUserField,setActiveScreen,setIsPinEnteredSuccessfully,setConversationDataFromStorageBucket,setIsInternetConnected} = userSlice.actions;
export default userSlice.reducer;


let updateQueue = [];
let isProcessing = false;

const processQueue = async (dispatch, getState) => {
  // console.log('processQueue:_+_+_ ');
  // console.log('isProcessing: ', isProcessing);

  // Avoid re-entry
  if (isProcessing) return;
  isProcessing = true; // Set processing flag
  
  try {
    while (updateQueue.length > 0) {
      // console.log(">?<?<./,ks");

      // Dequeue the first item
      const {payload, resolve} = updateQueue.shift();
      const {type, userUid, updates, conversationId, append} = payload;
      console.log('updates: ===================', updates);

      if (type === 'userMessage') {
        const state = getState(); // Use the latest state
        // console.log('state:---------------------========== ', state);
        // console.log('updateQueue: ', updateQueue);

        const existingMessages =
          state.user.users[userUid]?.messages?.[conversationId] || [];
        // console.log('existingMessages: ', existingMessages);

        // console.log('append:=-=', append,"><><",updates,"<MAMS",append);

        // Merge messages
        const mergedMessages = append
          ? [...existingMessages, ...updates]
          : updates;
        // console.log('mergedMessages: ', mergedMessages);

        // console.log({
        //   userUid,
        //   updates: mergedMessages,
        //   conversationId,
        //   append: false, // Already merged, no need to append again
        // });

        // Dispatch and wait for completion
        await dispatch(
          updateUserMessages({
            userUid,
            updates: mergedMessages,
            conversationId,
            append: false,
          })
        );
      } else if (type === 'userData') {
        // console.log(userUid,">?<>updates",updates['conversations']['nUCGyuYggoKXnS3VSGvY']);

        // Update user data
        await dispatch(setUserData(updates));
      }
      else if(type==='userMessageRefs'){
        const state = getState(); // Use the latest state
        // console.log('state:---------------------========== ', state);
        // console.log('updateQueue: ', updateQueue);

        const existingMessageRefs =
          state.user.users[userUid]?.messageRefs?.[conversationId] || [];
        // console.log('existingMessages: ', existingMessages);

        // console.log('append:=-=', append,"><><",updates,"<MAMS",append);

        // Merge messages
        const mergedMessages = append
          ? [...existingMessageRefs, ...updates]
          : updates;
        // console.log('mergedMessages: ', mergedMessages);

        // console.log({
        //   userUid,
        //   updates: mergedMessages,
        //   conversationId,
        //   append: false, // Already merged, no need to append again
        // });

        // Dispatch and wait for completion
        await dispatch(
          updateUserMessagesRef({
            userUid,
            updates: mergedMessages,
            conversationId,
            append: false,
          })
        );
      }
      else if(type==='updateUserField'){
        console.log("98080---");
        await dispatch(updateUserField({userUid:userUid, updates:updates}))
      }else {
        console.error('Unknown payload type:', type);
      }

      // Resolve the promise for this update
      resolve();
    }
  } catch (error) {
    console.error('Error in processQueue:', error); // Log the error for debugging
  } finally {
    // Always reset processing flag, even if an error occurs
    isProcessing = false;
    // console.log("cmowirtuwo",isProcessing);
  }
};


export const queueUpdateData = payload => (dispatch, getState) => {
  
 
  return new Promise(resolve => {
    updateQueue.push({payload, resolve});
    processQueue(dispatch, getState);
  });
};