/**
 * useAudioRecorder Hook
 *
 * This is a custom hook for handling audio recording and playback in a React Native application.
 * It encapsulates the logic for requesting audio recording permissions, starting and stopping
 * recordings, and playing back the recorded audio. This hook is designed to be reusable, providing
 * the core functionality without any UI. Developers can easily integrate this hook into their
 * components for recording and playing audio in their app.
 * 
 * Features:
 * - Request permissions to record audio on Android devices.
 * - Start and stop audio recording.
 * - Save and track the recorded audio file path.
 * - Start and stop playback of the recorded audio.
 * - Manage playback and recording state (whether recording/playing is in progress).
 * 
 * Example Usage:
 * 
 * import useAudioRecorder from './useAudioRecorder';
 * 
 * const YourComponent = () => {
 *   const {
 *     recording,
 *     playing,
 *     recordedFilePath,
 *     startRecording,
 *     stopRecording,
 *     startPlaying,
 *     stopPlaying,
 *   } = useAudioRecorder();
 * 
 *   return (
 *     <View>
 *       <Button title={recording ? "Stop Recording" : "Start Recording"} onPress={recording ? stopRecording : startRecording} />
 *       <Button title={playing ? "Stop Playing" : "Play Recording"} onPress={playing ? stopPlaying : startPlaying} disabled={!recordedFilePath} />
 *     </View>
 *   );
 * };
 * 
 * @returns {object} - An object containing:
 *   - `recording` (boolean): Indicates if recording is in progress.
 *   - `playing` (boolean): Indicates if playback is in progress.
 *   - `recordedFilePath` (string): Path of the recorded audio file.
 *   - `startRecording` (function): Function to start recording.
 *   - `stopRecording` (function): Function to stop recording.
 *   - `startPlaying` (function): Function to start playing the recorded audio.
 *   - `stopPlaying` (function): Function to stop playing the recorded audio.
 */

import { useState, useRef } from 'react';
import useSound from '../../marks/sound';

const useAudioRecorder = () => {
    const [recording, setRecording] = useState(false);
    const [playing, setPlaying] = useState(false);
    const [recordedFilePath, setRecordedFilePath] = useState('');
    const [recordingTime, setRecordingTime] = useState('00:00');
    const [recordingDuration, setRecordingDuration] = useState(0);
    const [hasPermission, setHasPermission] = useState(false);
    const [isStarting, setIsStarting] = useState(false); // Track if startRecording is in progress
    const [isStopping, setIsStopping] = useState(false); // Track if stopRecording is in progress
    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);
  
    const startTimeRef = useRef(null);
    const intervalRef = useRef(null);
    const audioRef = useRef(new Audio());
    const play = useSound('recordingstart.mp3');
    const stop = useSound('recordingstop.mp3');

    const requestPermissions = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            console.log("Microphone permission granted");
            return stream; // ✅ Return the actual MediaStream
        } catch (error) {
            console.error("Microphone permission denied:", error);
            return null; // ✅ Return null on error
        }
    };
    
    const formatTime = (milliseconds) => {
        const minutes = Math.floor(milliseconds / 60000);
        const seconds = ((milliseconds % 60000) / 1000).toFixed(0);
        return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };
    const startRecording = async () => {
        if (recording) return;

        const stream = await requestPermissions();
        if (!stream) return;

        mediaRecorderRef.current = new MediaRecorder(stream);
        audioChunksRef.current = [];
        startTimeRef.current = Date.now();

        mediaRecorderRef.current.ondataavailable = (event) => {
            if (event.data.size > 0) {
                audioChunksRef.current.push(event.data);
            }
        };

        mediaRecorderRef.current.onstop = () => {
            const audioBlob = new Blob(audioChunksRef.current, { type: "audio/webm" });
            const audioUrl = audioBlob;
            setRecordedFilePath(audioUrl);
        };

        mediaRecorderRef.current.start();
        setRecording(true);
        setIsStarting(false);
        // Track recording time
        intervalRef.current = setInterval(() => {
            const elapsedTime = Date.now() - startTimeRef.current;
            setRecordingTime(formatTime(elapsedTime));
            setRecordingDuration(elapsedTime);

        }, 1000);
    };

    const stopRecording = () => {
        if (!recording || !mediaRecorderRef.current) return;
    
        mediaRecorderRef.current.stop();
        
        // Stop the media stream tracks (important to release the microphone)
        const tracks = mediaRecorderRef.current.stream.getTracks();
        tracks.forEach(track => track.stop()); // Stop each track to release the microphone
        
        clearInterval(intervalRef.current);
        setRecording(false);
        setRecordingTime("00:00");
    };
    
    const startPlaying = () => {
        if (!recordedFilePath) return;

        audioRef.current.src = recordedFilePath;
        audioRef.current.play();
        setPlaying(true);

        audioRef.current.onended = () => {
            setPlaying(false);
        };
    };


    const stopPlaying = () => {
        if (playing) {
            audioRef.current.pause();
            setPlaying(false);
        }
    };


    return {
        recording,
        playing,
        recordedFilePath,
        startRecording,
        recordingTime,
        stopRecording,
        startPlaying,
        stopPlaying,
    };
};

export default useAudioRecorder;