import React, { useState } from "react";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

/*
props:
  - title
  - type: 'single' / 'multi'
  - data
  - value
  - func
  - isSearchEnable
  - placeholder
  - labelField
  - valueField
*/

const App = (props) => {
    console.log('props: ', props);
    const [isActive, setIsActive] = useState(false);

    const customStyles = {
        control: (base) => ({
            ...base,
            backgroundColor: "white",
            borderRadius: "10px",
            padding: "5px",
            fontSize: "14px",
            fontFamily: "MarkerFelt",
            color: "black",
        }),
        menu: (base) => ({
            ...base,
            backgroundColor: "#eef",
            borderRadius: "10px",
        }),
        option: (base, state) => ({
            ...base,
            backgroundColor: state.isSelected ? "#bcdea1" : "#eef",
            color: state.isSelected ? "white" : "black",
            fontFamily: "MarkerFelt",
        }),
        placeholder: (base) => ({
            ...base,
            fontSize: "16px",
            color: "#bfbfbf",
        }),
        singleValue: (base) => ({
            ...base,
            color: "#3B486F",
        }),
    };

    const renderItem = (item) => (
        <div style={styles.itemContainer}>
            {item.icon && <img src={item.icon} alt="icon" style={styles.iconStyle} />}
            <span style={styles.itemLabel}>{item[props.labelField]}</span>
        </div>
    );
console.log(props.type)
    return (
        <div style={styles.container}>
            <div style={styles.header}>
                <span style={styles.title}>{props.title}</span>
                {props.type === 'single' ? (
                    <Select
                        styles={customStyles}
                        options={props.data.map(item => ({
                            label: renderItem(item),
                            value: item[props.valueField],
                        }))}
                        placeholder={props.placeholder}
                        isSearchable={props.isSearchEnable}
                        value={props.value}
                        onChange={(item) => props.func(item)}
                    />
                ) : props.type === 'multi' ? (
                    <CreatableSelect
                        styles={customStyles}
                        isMulti
                        options={props.data.map(item => ({
                            label: renderItem(item),
                            value: item[props.valueField],
                        }))}
                        placeholder={props.placeholder}
                        isSearchable={props.isSearchEnable}
                        value={props.value}
                        onChange={(items) => props.func(items)}
                        onFocus={() => setIsActive(true)}
                        onBlur={() => setIsActive(false)}
                    />
                ) : null}
            </div>
            {props.instructions && (
                <span style={styles.instructions}>* {props.instructions}</span>

            )}
        </div>
    );
};

export default App;

const styles = {
    container: {
        backgroundColor: "#E9F2FF",
        borderRadius: "10px",
        margin: "5px",
        padding: "10px",
        width: "100%",
    },
    header: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: "10px 30px",
        justifyContent: "space-between",
    },
    title: {
        fontFamily: "MarkerFelt",
        fontSize: "20px",
        color: "#3B486F",
        alignSelf: "center",
    },
    itemContainer: {
        display: "flex",
        alignItems: "center",
        gap: "10px",
    },
    iconStyle: {
        width: "25px",
        height: "25px",
        marginRight: "5px",
    },
    itemLabel: {
        color: "black",
        fontFamily: "MarkerFelt",
    },
    instructions: {
        color: "black",
        alignSelf: "flex-start",
        marginTop: "5px",
    },
};
