import React, { useState } from 'react';
import { p, div, ActivityIndicator, Modal, img } from 'react-native'
import CustomConfirmationModal from './CustomConfirmationModal';
import ThemeButton from '../ResuableThemeComponent/ThemeButton';
import ThemeParaInput from '../ResuableThemeComponent/ThemeParaInput';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';

const RemoveUser = ({ isVisible, setIsVisible, id, enrollmentIds, instituteId }) => {

    const [visible, setVisible] = useState(isVisible)
    const [description, setDescription] = useState('')
    const [error, setError] = useState(false)
    const [action, setAction] = useState('')
    const [uploadStatus, setUploadStatus] = useState('')
    const [isModalVisible, setModalVisible] = useState(false)
    const removeUser = (actions) => {
        setAction(actions)
    }
    
    const closeFunc = () => {
        if (action) {
            setAction('')
        } else {
            setVisible(false)
            setIsVisible(false)
        }
    }
    const confirmation = () => { return (description ? true : false) }
    const archiveEnrollments = async (enrollmentIds, instituteId,) => {
        if (!Array.isArray(enrollmentIds) || enrollmentIds.length === 0) {
            console.error("Enrollment IDs must be a non-empty array.");
            return;
        }
    
        if (!instituteId) {
            setUploadStatus("error");
            setModalVisible(true);
            return;
        }
    
        try {
            setUploadStatus("pending");
            setModalVisible(true);
    
            for (const enrollmentId of enrollmentIds) {
                const enrollmentDocRef = doc(firestore, "enrollment", enrollmentId);
                const enrollmentDocSnap = await getDoc(enrollmentDocRef);
    
                if (!enrollmentDocSnap.exists()) {
                    console.warn(`Enrollment ID ${enrollmentId} does not exist.`);
                    continue;
                }
    
                const enrollmentData = enrollmentDocSnap.data();
                const { type } = enrollmentData;
    
                if (!type) {
                    console.warn(`Enrollment ID ${enrollmentId} does not have a 'type' field.`);
                    continue;
                }
    
                // Reference to the archivedLMSusers subcollection
                const archivedDocRef = doc(firestore, "Institutes", instituteId, "archivedLMSusers", enrollmentId);
    
                await updateDoc(archivedDocRef, {
                    [type]: {
                        [enrollmentId]: enrollmentData, // Store type as a field inside the document
                    },
                });
    
                console.log(`Enrollment ${enrollmentId} archived successfully.`);
            }
    
            setUploadStatus("success");
            console.log("All enrollments have been archived successfully.");
        } catch (error) {
            setUploadStatus("error");
            setModalVisible(true);
            console.error("Error processing enrollments:", error);
        }
    };
    const handleConfirm = () => {
        if (!confirmation()) {
            setError(true)
            return;
        }
        archiveEnrollments(enrollmentIds, instituteId)

    }
    const handleBack = () => {
        setIsVisible(false)
        setModalVisible(false)
    }
    const handleRetry = () => {
        setModalVisible(false)
    }
    const handleDone = () => {
        setIsVisible(false)
        setModalVisible(false)
    }
        const capitalizeFirstLetter = (str) => {
        if (!str) return ''; // Handle empty or undefined strings
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    return (
        <>
            <CustomConfirmationModal visible={visible} onCancel={closeFunc} onConfirm={action ? handleConfirm : null}  >
                <p style={{ color: 'black', fontSize: 22, textAlign: 'center' }}>
                    {!action ? "Choose any Action" : capitalizeFirstLetter(action)}
                </p>
                {!action &&
                    <div style={{ width: '100%', alignItems: 'center', rowGap: 10 }}>
                        <ThemeButton
                            title={'Remove User'}
                            width={'80%'}
                            func={() => removeUser('remove')}
                        />
                        <ThemeButton
                            title={'Suspend User'}
                            width={'80%'}
                            variant={'red'}
                            func={() => removeUser('suspend')}
                        />
                        <ThemeButton
                            title={'Rusticate User'}
                            width={'80%'}
                            variant={'red'}
                            func={() => removeUser('rusticate')}
                        />
                    </div>}
                {action && <div style={{ width: '100%' }}>
                    <ThemeParaInput
                        title={'Description'}
                        placeholder={`Enter Decription of this ${action}`}
                        value={description}
                        func={setDescription}
                        maxLength={500}
                        
                    />
                    {
                        error && !description && <p style={{ color: 'red' }}>Enter Description</p>
                    }
                </div>}
                <Modal
                    transparent={true}
                    visible={isModalVisible}
                    animationType="slide"
                    onRequestClose={() => setModalVisible(false)}
                >
                    <div style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0,0,0,0.5)' }}>
                        {uploadStatus === 'pending' && (
                            <div style={{ padding: 20, backgroundColor: 'white', borderRadius: 10, alignItems: 'center' }}>
                                <ActivityIndicator size="large" color="#0000ff" />
                                <p style={{ marginTop: 10, color: 'black' }}>Uploading...</p>
                            </div>
                        )}

                        {uploadStatus === 'success' && (
                            <div style={{ paddingHorizontal: 40, backgroundColor: 'white', borderRadius: 10, alignItems: 'center', justifyContent: 'space-evenly', height: 200 }}>
                                <img src={require('../../assets/images/check.webp')} style={{ height: 45, width: 45 }} />
                                <p style={{ fontSize: 20, marginBottom: 10, color: 'black' }}>Upload Successful!</p>
                                <ThemeButton
                                    title="Done"
                                    variant="green"
                                    width="50%"
                                    func={handleDone}
                                />
                            </div>
                        )}

                        {uploadStatus === 'error' && (
                            <div style={{ paddingHorizontal: 40, backgroundColor: 'white', borderRadius: 10, alignItems: 'center', height: 200, justifyContent: 'space-evenly' }}>
                                <img src={require('../../assets/images/error1.gif')} style={{ height: 45, width: 45 }} />
                                <p style={{ fontSize: 20, marginBottom: 10, color: 'black' }}>Upload Failed</p>
                                <div style={{ flexDirection: 'row', justifyContent: 'space-between', width: '80%' }}>
                                    <ThemeButton
                                        title="Retry"
                                        variant="green"
                                        width="45%"
                                        func={handleRetry}
                                    />
                                    <ThemeButton
                                        title="Back"
                                        variant="red"
                                        width="45%"
                                        func={handleBack}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </Modal>
            </CustomConfirmationModal>
        </>
    )
}

export default RemoveUser;