import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentUser: null,
  activeUser: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    addSBUser: (state, action) => {
      state.currentUser = {
        uid: action.payload.uid,
        userData: action.payload.userData,
      };
    },
    setActiveSBUser: (state, action) => {
      state.activeUser = action.payload;
    },
    logoutUser: (state) => {
      state.currentUser = null;
      state.activeUser = null;
    },
  },
});

export const { addSBUser, setActiveSBUser, logoutUser } = authSlice.actions;
export default authSlice.reducer;
