import React, { useEffect, useState } from 'react';
import {auth, storage , messaging , functions , firestore, signInWithEmailAndPassword, getToken, signOut,} from '../../firebase';
import { motion } from "framer-motion";
import { collection , query, where, getDocs} from "firebase/firestore";
import { processInput } from '../../utils/processInput';
import { validateInput } from '../../utils/isValidInput';
import { useNavigate , useLocation } from 'react-router-dom';
 function OTPScreen1({ route }) {
  const location= useLocation()
  const  selectedOption  = location.state?.selectedOption;
  const otpMetaData=location.state?.otpMetaData
  const fcmToken=location.state?.fcmToken
  const [inputValue, setInputValue] = useState('');
  const [users,setusers]=useState([])
  console.log('users: ', users);
  const [userFound,setuserFound]=useState(false)
const [isloading,setisloading]=useState(false)
const [isInputValid,setisInputValid]=useState(true);
 const navigation=useNavigate();

  // Function to search in Firestore
  const searchInFirestore = async ( processedText) => {
    setisloading(true);
    const trimmedText = processedText.trim();
    try {
      const usersCollection = collection(firestore, "LMSusers"); // Reference to Firestore collection
      const q = query(usersCollection, where(selectedOption.toLowerCase(), "==", trimmedText)); // Query Firestore
      console.log('q: ', q);
      
      const querySnapshot = await getDocs(q);
      console.log('querySnapshot: ', querySnapshot);

      let userDataArray = [];

      if (!querySnapshot.empty) {
        
        querySnapshot.forEach((documentSnapshot) => {
          userDataArray.push({ documentId: documentSnapshot.id, data: documentSnapshot.data() });
        });
      } else {
        console.log("No Match", "No matching user found.");
      }

      // setUserFound(userDataArray.length > 0);
      setisloading(false);
      return userDataArray;
    } catch (error) {
        setuserFound(true)
        setisloading(false)
      console.error('Error searching Firestore: ', error);
    alert('Error', 'An error occurred while searching.');
      return [];
    }
  };

  // Function to handle the button press
  const handleSearch = async () => {
    const processedText = processInput(inputValue)
    console.log('processedText: ', processedText);
    const selectedOptionLowerCaseValue = (selectedOption || "").toLowerCase();
    console.log('selectedOption: ', selectedOption);
    const isTextInputValid = validateInput(selectedOptionLowerCaseValue, processedText);
let resultArray;
if(isTextInputValid){
    resultArray = await searchInFirestore(processedText);
    console.log('processedText:',processedText);
    console.log('resultArray: ', resultArray);
    setusers(resultArray);
}
else{
    setisInputValid(false)
}

    // Do something with the resultArray if needed
  };


  const handleUserProfilePress=(data)=>{
console.log(data.documentId,">>>")
navigation('/UserVerificationScreen',{state:{otpMetaData:otpMetaData,fcmToken:fcmToken,selectedUserData:data.data,documentId:data.documentId}})
  }


//   useEffect(()=>{
//     console.log(users)
//   },[users])

const Spinner = () => {
  return (
    <motion.div
      animate={{ rotate: 360 }}
      transition={{ repeat: Infinity, duration: 1, ease: "linear" }}
      style={{
        width: 40,
        height: 40,
        borderRadius: "50%",
        border: "4px solid rgba(0, 0, 255, 0.3)",
        borderTop: "4px solid blue",
      }}
    />
  );
};
  return (

    <div style={styles.mainContainer}>


{users.length==0?
    <div style={styles.inputContainer}>
      <p  style={styles.text}>You selected: {selectedOption} </p >
      <input
inputMode={(selectedOption && typeof selectedOption === "string") 
  ? (selectedOption.toLowerCase() === "username" 
    ? "text" 
    : selectedOption.toLowerCase() === "email" 
    ? "email" 
    : "numeric")
  : "text"}        style={styles.input}
        placeholder={`Enter your ${selectedOption}`}
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value);
          setisInputValid(true);
          setuserFound(false);
        }}      />

      {isInputValid?null:<div><p  style={styles.errorText}>please enter a valid input</p ></div>}
      {userFound?<div><p  style={styles.errorText}>User not found</p ></div>:null}
      {isloading ? (
        <Spinner />
      ) : (
        <button
          onClick={handleSearch}
          style={{
            padding: "10px 20px",
            background: "blue",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Find Account
        </button>
      )}    </div>
    :

    <div>
        {users.length>0?users.map((item,index)=>(
            <div key={index} style={styles.button} onClick={()=>{
                handleUserProfilePress(item)
            }}>
                <p  style={styles.buttonText}>{item.data.firstName}</p >
                </div>
        )):null}
    </div>

}




    </div>
  );
}
export default OTPScreen1;
const styles ={
    mainContainer:{
        flex:1
    },
  inputContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  text: {
    fontSize: 20,
    marginBottom: 15,
  },
  errorText:{
    fontSize: 15,
    marginBottom: 10,
    color:'red'
  },
  input: {
    width: '100%',
    padding: 10,
    borderColor: '#ccc',
    borderWidth: 1,
    borderRadius: 5,
    marginBottom: 15,
  },
  button:{
    backgroundColor:"orange",
    paddingHorizontal:20,
    paddingVertical:10,
    marginVertical:10,
    borderRadius:20
  },
  buttonText:{
    color:'black',
    fontSize:14
  }
};