import React from 'react'
import { useRef } from 'react';
const Attachments = ({ forGallery, forDocument }) => {
    const fileInputRef = useRef(null);
    return (
        <div style={{ width: '100%', backgroundColor: 'white', flexDirection: 'row', justifyContent: '', flexWrap: 'wrap', alignItems: 'center', borderRadius: 30, paddingHorizontal: 30, elevation: 2, display:"flex" }}>

            <input
                type="file"
                multiple
                onChange={forDocument}
                style={{ display: "none" }}
                ref={fileInputRef}
            />
            <div style={styles.documentCard} onClick=  {() => fileInputRef.current.click()}  >
                <div style={styles.innerDoc}>
                    <img src={require('../../assets/images/galleryImg.png')} style={styles.docImage} />
                </div>
                <div style={{}}><p style={styles.text}>Document</p></div>
            </div>
            <div style={styles.documentCard} onClick={forGallery} >
                <div style={styles.innerDoc}>
                    <img src={require('../../assets/images/gallery.webp')} style={styles.docImage} />
                </div>
                <div style={{}}><p style={styles.text}>Gallery</p></div>
            </div>
            <div style={styles.documentCard}  >
                <div style={styles.innerDoc}>
                    <img src={require('../../assets/images/galleryImg.png')} style={styles.docImage} />
                </div>
                <div style={{}}><p style={styles.text}>Other</p></div>
            </div>
            <div style={styles.documentCard}  >
                <div style={styles.innerDoc}>
                    <img src={require('../../assets/images/galleryImg.png')} style={styles.docImage} />
                </div>
                <div style={{}}><p style={styles.text}>Other</p></div>
            </div>

        </div>
    )
}
const styles = {
    documentCard: {
        padding: 15,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        rowGap: 5,
        margin: 5,
        display:"flex",
    },
    docImage: {
        height: 30,
        width: 30,
    },
    innerDoc: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: 'white',
        elevation: 5,
        padding: 10,
        display:"flex",

        borderRadius: 10
    },
    text: {
        color: 'black',
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        WebkitLineClamp: 2, // This mimics numberOfLines={2}
        textOverflow: "ellipsis",
        maxWidth: "300px", // Optional: Prevents text from stretching

    }
}

export default Attachments;