import {
  
    Modal,
    StyleSheet,
    Animated,
    Vibration,
    Button,
    BackHandler
  } from 'react-native';
  import React, {useState, useRef, useEffect} from 'react';
  import {useDispatch, useSelector} from 'react-redux';
  import { useLocation } from 'react-router-dom';

  import {updateUserField} from '../../reduxStore/slices/UsersSlice';
  import {auth, storage , messaging , functions , firestore} from '../../firebase';
  import { getFunctions, httpsCallable } from "firebase/functions";

  import { setTwoFactorAuthenticationData } from '../../reduxStore/slices/TwoFactorAuthenticationVerification';
   function OTPCreatorAndValidator({route, navigation}) {
    const userData = useSelector(state => state.user.users);
    const location = useLocation()
    const activeUser = useSelector(state => state.user.activeUser);
    const activeUserData = userData[activeUser];
    const sendOTPToUser = httpsCallable('sendOTPToUser');
    const [OtpSendDateAndTime,setOtpSendDateAndTime] = useState(location.state?.data.OtpSendDateAndTime);
    const otpMetaData = location.state?.data.otpMetaData;
    const otpLength = otpMetaData?.otpLength;
    const otpIsFor = otpMetaData?.otpIsFor;
    const otpType = otpMetaData?.otpType;
    const otpValidationTime = otpMetaData?.otpValidationTime;
    const initialScreen=otpMetaData?.initialScreen
    const fcmToken = location.state?.data.fcmToken;
  const [isResendButtonDisabled,setisResendButtonDisabled]=useState(false)
    const OTPSendCountObject=location.state?.data.OTPSendCountObjectData
    const falseAttemptObject=location.state?.data.falseAttemptObjectData
    const [tempOTP,setTempOTP]=useState('')
    const documentId = location.state?.data.documentId;
    const [actualOTP, setactualOTP] = useState('');
    const [showRemainingAttemptContainer, setShowRemainingAttemptContainer] =
      useState(false);
    const dispatch = useDispatch();
    const [isCurrentOTPValid, setisCurrentOTPValid] = useState(true);
    const [otp, setOtp] = useState(['', '', '', '']);
  const selectedOTPMethod=location.state?.data.selectedOTPMethod
    const [isMismatched, setIsMismatched] = useState(false);
    const [isdisabled, setisdisabled] = useState(true);
    const otpInputs = [];
    const shakeAnimation = useRef(new Animated.Value(0)).current;
    const [countWrongOtp, setcountWrongOtp] = useState(0);
    const [isAccessEnable, setisAccessEnable] = useState(true);
    const [timer, setTimer] = useState(1 * 60);
    const [intervalId, setIntervalId] = useState(null);
    const [showInvalidOTPContainer,setShowInvalidOTPContainer]=useState(false)
    const [forgetPassAttempt,setforgetPassAttempt]=useState('')
  const selectedUserData=location.state?.data.selectedUserData;
  
    const handleOtpChange = (text, index) => {
      console.log(otpLength,"hubi")
      setShowRemainingAttemptContainer(false);
      setIsMismatched(false);
      if (/^[0-9]$/.test(text)) {
        const newOtp = [...otp];
        newOtp[index] = text;
        setOtp(newOtp);
  
        if (text && index < (otp.length - 1)) {
          otpInputs[index + 1].focus();
        }
      }
    };
  
    const handleCharacterOtpChange = text => {
      setShowRemainingAttemptContainer(false);
      setIsMismatched(false);
      console.log(`--${text}--`);
      setOtp(text);
    };
  
    useEffect(() => {
  
   
      const handleBackButton = () => {
        // Handle the back button press here
        // For example, you can navigate to a different screen, show an alert, etc.
        console.log("backButton pressed")
  
  
          dispatch(setTwoFactorAuthenticationData({verificationIsFor:initialScreen,status:'failure'}))
          navigation.navigate(`${initialScreen}`)
        
        // Return true to prevent default behavior (closing the app)
        // Return false to allow default behavior (going back in navigation stack)
        return true;
      };
    
      BackHandler.addEventListener('hardwareBackPress', handleBackButton);
    
      return () => {
        BackHandler.removeEventListener('hardwareBackPress', handleBackButton)
    
      }
    }, [])
    //   creating initial otp array to render text inputs
    useEffect(() => {
      const temporaryotp = [];
      for (let i = 0; i < otpLength; i++) {
        temporaryotp.push('');
      }
      setTempOTP(temporaryotp)
      setOtp(temporaryotp);
    }, []);
  
    useEffect(() => {
      console.log('afewfgergeg', countWrongOtp);
      if (countWrongOtp >= 10) {
        //increase false attempt of the user in the firestore data
        setisAccessEnable(false);
        updateActiveDeviceFalseAttempt();
      }
      if (countWrongOtp > 0) {
        setShowRemainingAttemptContainer(true);
      }
    }, [countWrongOtp]);
  
    const handleKeyPress = (key, index) => {
      console.log(key, index, '>>>>>>>>>>>');
      if (key === 'Backspace') {
        if (index > 0) {
          otpInputs[index - 1].focus();
          const newOtp = [...otp];
          newOtp[index] = ''; // Clear the current box
          setOtp(newOtp);
        } else {
          console.log('.........................................');
          const newOtp = [...otp];
          newOtp[index] = ''; // Clear the current box
          setOtp(newOtp);
        }
      }
      if (otp[(otpLength-1)] == '') {
        //last index of the otp array
        if (otp[index] !== '') {
          if (/^[0-9]$/.test(key)) {
            console.log(index, '>>');
            const newOtp = [...otp];
            newOtp[index + 1] = key;
            setOtp(newOtp);
  
            // Move focus to the next input box
            if (key && index < otp.length - 1) {
              otpInputs[index + 1].focus();
            }
          }
        }
      }
    };
    const formatTime = seconds => {
      const minutes = Math.floor(seconds / 60);
      const secs = seconds % 60;
      return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    };
  
    useEffect(()=>{
      if(forgetPassAttempt.length>10){
        setisResendButtonDisabled(true)
      }
    },[forgetPassAttempt])
  
  
    useEffect(() => {
      console.log(activeUser);
      if(otpType=='numerical'){
        if (otp.join('').length == otpLength) {
          setisdisabled(false);
        } else {
          setisdisabled(true);
        }
      }
      else{
        setisdisabled(false)
      }
     
    }, [otp]);
  
    // const createFormattedDate=()=>{
    //   const date = new Date();
    //   let formattedDate = '';
    //   formattedDate = formattedDate + date.getDate();
    //   const month = (date.getMonth() + 1).toString(); //adding 1 because it return the index of month and index start from 0
    //   const formattedMonth = month.length == 1 ? `0${month}` : month;
    //   formattedDate = formattedDate + formattedMonth;
    //   formattedDate = formattedDate + date.getFullYear();
  
    //   return formattedDate;
    // }
  
    async function generateOTP() {
      // Create a string of digits (0-9)
      const digits =
        otpType == 'numerical'
          ? '0123456789'
          : 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ123456789';
  
      // Generate a random string of length 6
      let otp = '';
      for (let i = 0; i < otpLength; i++) {
        otp += digits[Math.floor(Math.random() * digits.length)].toString();
      }
      sendOTP(otp)
      
      // // Return the generated OTP as a number
      return otp;
    }
    useEffect(() => {
      if (timer <= 0) {
  setisCurrentOTPValid(false)
        clearInterval(intervalId);
      }
    }, [timer]);
  
    useEffect(() => {
      if (intervalId) {
        clearInterval(intervalId);
      }
  
      if (isCurrentOTPValid) {
        const id = setInterval(() => {
          setTimer(prevTimer => prevTimer - 1);
        }, 1000);
        setIntervalId(id);
  
        return () => clearInterval(id);
      }
    }, [isCurrentOTPValid]);
  
    const sendOTP=(generatedOTP)=>{
      if(selectedOTPMethod=='email'){
  //send otp through email
  updateOTPSendCount()
  console.log("send otp through email is done")
      }
      else{
        updateOTPSendCount() 
  const phone=selectedUserData.verifiedEntities.mobile;
  const name=selectedUserData.firstName
  console.log(phone,name,"}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}")
        sendOTPToUser({phone:phone,name:name,otp:generatedOTP,otpValidationTime:otpValidationTime})
      }
    }
  
    const updateOTPSendCount=async ()=>{
      
      if (otpIsFor == 'forgotPassword') {
        const date = new Date();
      const dateToStore = date.toString();
      const activeDeviceOTPSendCount = OTPSendCountObject[fcmToken]?OTPSendCountObject[fcmToken]:[];
      console.log(activeDeviceOTPSendCount, 'h');
      const testArray = forgetPassAttempt.length==0?[...activeDeviceOTPSendCount, dateToStore]:[...forgetPassAttempt, dateToStore];
      setforgetPassAttempt(testArray)
      console.log(testArray, '+++++++++++++++++++++++');
      const field = `OTPSendCountObject.${fcmToken}`;
        console.log('forgot pass update condition has run', otpIsFor);
        //  update on firestore
        try {
          await firestore()
            .collection('LMSusers')
            .doc(documentId)
            .update({
              OTPSendCountObject: {
                [fcmToken]: testArray,
              },
  
            }).then(()=>{
              console.log("ftytttttttt")
            })
          console.log('User otp send count updated! only on Firestore');
        } catch (error) {
          console.error('Error updating user: ', error);
  
        }
    }
  }
  
    const resendButtonPress = async () => {
      const generatedOTP = await generateOTP();
      if(otpType=='numerical'){
        otpInputs[0].focus();
      }
      setOtp(tempOTP);
      setactualOTP(generatedOTP);
      setShowInvalidOTPContainer(false)
      setTimer(1 * 60);
      setisCurrentOTPValid(true);
      const date = new Date();
      const newOtpSendDateAndTime = {date: date.getDate(), time: date.getTime()};
      setOtpSendDateAndTime(newOtpSendDateAndTime)
    };
    useEffect(() => {
      const oneTimeOtpCreation = async () => {
        const generatedOTP = await generateOTP();
        console.log(generatedOTP,"-=-=");
        setactualOTP(generatedOTP);
      };
      oneTimeOtpCreation();
    }, []);
  
    useEffect(()=>{
      console.log('actual otp',actualOTP)
    },[actualOTP])
  
    const updateActiveDeviceFalseAttempt = async () => {
      const date = new Date();
      const dateToStore = date.toString();
      const activeDeviceFalseAttempts = falseAttemptObject[fcmToken]?falseAttemptObject[fcmToken]:[];
      console.log(activeDeviceFalseAttempts, 'h');
      const testArray = [...activeDeviceFalseAttempts, dateToStore];
      console.log(testArray, '-------------===+++++++++++++++++++++++');
      const field = `falseAttemptObject.${fcmToken}`;
      if (otpIsFor == 'forgotPassword') {
        console.log('forgot pass condition has run', otpIsFor);
        //  update on firestore
        try {
          await firestore()
            .collection('LMSusers')
            .doc(documentId)
            .update({
              falseAttemptObject: {
                [fcmToken]: testArray,
              },
  
            });
          console.log('User updated! only on Firestore');
        } catch (error) {
          console.error('Error updating user: ', error);
  
        }
      } else if (otpIsFor == 'forgotPin') {
        console.log('forgot pin condition has run', otpIsFor);
        // updating in async storage
        let newFalseAttemptObject = {...falseAttemptObject};
        newFalseAttemptObject[fcmToken] = testArray;
  
        console.log('updating the user');
        
        dispatch(
          updateUserField({
            userUid: activeUser,
            updates: {falseAttemptObject: newFalseAttemptObject},
          }),
        );
        //also update on firestore
        try {
          await firestore()
            .collection('LMSusers')
            .doc(documentId)
            .update({
              falseAttemptObject: {
                [fcmToken]: testArray,
              },
            });
          console.log('User updated! on both userslice and Firestore');
        } catch (error) {
          console.error('Error updating user: ', error);
          // You can also display an alert or perform additional error handling here
        }
      }
    };
  
    const handleOTPSubmit = () => {
      const date = new Date();
      const currentDate = date.getDate();
      const currentTime = date.getTime();
  
  console.log(OtpSendDateAndTime,"{}{}{}{}",currentTime,currentDate)
      const timeDifference = Math.abs(currentTime - OtpSendDateAndTime.time);
      console.log(timeDifference, 'timedifference',otpValidationTime*60*1000);
  
  
  
      if (
        OtpSendDateAndTime.date == currentDate &&
        timeDifference < otpValidationTime*60*1000
      ) {
        ///match otp validation time
        console.log(OtpSendDateAndTime, 'time');
        const enteredOTP = otpType == 'numerical' ? otp.join('') : otp;
        console.log(enteredOTP, '>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
        if (enteredOTP === actualOTP) {
          console.log('otp matched');
          dispatch(setTwoFactorAuthenticationData({verificationIsFor:initialScreen,status:'success'}))
          navigation.navigate(`${initialScreen}`,{status:'success'})
        } else {
          setcountWrongOtp(prev => {
            return prev + 1;
          });
          setIsMismatched(true);
          shakeAnimationEffect();
          Vibration.vibrate(100);
        }
      } else {
        console.log('this otp is not valid now');
        setShowInvalidOTPContainer(true)
        setisCurrentOTPValid(false);
        setIsMismatched(true);
        shakeAnimationEffect();
        Vibration.vibrate(100);
      }
    };
  
    const shakeAnimationEffect = () => {
      Animated.sequence([
        Animated.timing(shakeAnimation, {
          toValue: 10, // Move to the right
          duration: 50,
          useNativeDriver: true,
        }),
        Animated.timing(shakeAnimation, {
          toValue: -10, // Move to the left
          duration: 50,
          useNativeDriver: true,
        }),
        Animated.timing(shakeAnimation, {
          toValue: 10, // Move to the right
          duration: 50,
          useNativeDriver: true,
        }),
        Animated.timing(shakeAnimation, {
          toValue: -10, // Move to the left
          duration: 50,
          useNativeDriver: true,
        }),
        Animated.timing(shakeAnimation, {
          toValue: 0, // Reset to the original position
          duration: 50,
          useNativeDriver: true,
        }),
      ]).start();
    };
  
    const styles = StyleSheet.create({
      mainContainer: {
        flex: 1,
      },
      modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        display:"flex",
      },
      modalContent: {
        width: '80%',
        padding: 20,
        backgroundColor: '#fff',
        borderRadius: 10,
      },
      otpTitle: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 15,
        textAlign: 'center',
      },
      otpContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        display:"flex",
        marginBottom: 20,
      },
      otpInput: {
        color:'black',
        width: 50,
        height: 50,
        borderWidth: 1,
        borderColor: '#007bff',
        borderRadius: 10,
        textAlign: 'center',
        fontSize: 18,
        marginHorizontal: 5,
      },
      otpModalContentError: {
        borderWidth: 1,
        borderColor: 'red',
        width: '80%',
        padding: 20,
        backgroundColor: '#fff',
        borderRadius: 10,
      },
      buttonBlue: {
        backgroundColor: isdisabled ? 'grey' : '#007bff',
        padding: 15,
        borderRadius: 10,
        marginBottom: 15,
      },
      buttonText: {
        color: '#fff',
        fontSize: 16,
        textAlign: 'center',
      },
    });
  
    return (
      <div style={styles.mainContainer}>
        {isAccessEnable ? (
          <div style={styles.modalContainer}>
            <Animated.div
              style={[
                isMismatched ? styles.otpModalContentError : styles.modalContent,
                {transform: [{translateX: shakeAnimation}]},
              ]}>
              <p style={styles.otpTitle}>Enter OTP</p>
              {otpType == 'numerical' ? (
                <div style={styles.otpContainer}>
                  {otp.map((value, index) => (
                    <input
                      key={index}
                      ref={ref => (otpInputs[index] = ref)}
                      style={[styles.otpInput]}
                      keyboardType="numeric"
                      maxLength={1}
                      value={otp[index]}
                      onChangeText={text => handleOtpChange(text, index)}
                      onKeyPress={({nativeEvent}) =>
                        handleKeyPress(nativeEvent.key, index)
                      }
                    />
                  ))}
                </div>
              ) : (
                <div style={styles.otpContainer}>
                  <input
                    placeholder="enter code"
                    value={otp}
                    onChangeText={text => handleCharacterOtpChange(text)}
                  />
                </div>
              )}
              {showRemainingAttemptContainer ? (
                <div style={{marginBottom: 10}}>
                  <p style={{color: 'red', textAlign: 'center'}}>
                    {10 - countWrongOtp} attempts left
                  </p>
                </div>
              ) : null}
              <div style={{alignItems:'flex-end',marginVertical:5}}>
              <div
              disabled={isCurrentOTPValid && isResendButtonDisabled}
              onClick={()=>{resendButtonPress()}}
                style={{marginHorizontal: 20,flexDirection:'row',justifyContent:'center',alignItems:'center',marginBottom:10,  display:"flex", display:"flex",}}>
                <p style={{...styles.buttonText,color:isCurrentOTPValid?'grey':'blue'}}>Resend OTP</p>
              {timer!==0 && !isResendButtonDisabled?<p style={{...styles.buttonText,color:isCurrentOTPValid?'grey':'blue',marginLeft:5}}>{formatTime(timer)}</p>:null}
              </div>
  
              </div>
              <div
                disabled={isdisabled}
                style={[styles.buttonBlue, {marginHorizontal: 20}]}
                onClick={handleOTPSubmit}>
                <p style={styles.buttonText}>Submit</p>
              </div>
  
              {showInvalidOTPContainer?<div><p style={{fontSize:15,color:'red',textAlign:'right'}}>The current otp is not valid now please resend OTP</p></div>:null}
              
            </Animated.div>
          </div>
        ) : (
          <div>
            <p>You have reached the limit of wrong attempt</p>
            <Button
              title="ok"
              onClick={() => {
                dispatch(setTwoFactorAuthenticationData({verificationIsFor:initialScreen,status:'failure'}))
                navigation.navigate(`${initialScreen}`,{status:'failure'});
              }}
            />
          </div>
        )}
      </div>
    );
  }
  export default OTPCreatorAndValidator;