import {  doc, getDoc } from "firebase/firestore";
import { firestore } from "../firebase";

 // Initialize Firestore instance

export async function generateRandomDocumentID() {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const length = 20;
    let code = "";
    let maxAttempts = 1000;
    let attempts = 0;
    let inviteCodeDoc = null;

    do {
        if (attempts > maxAttempts) return { status: "failure" };
        code = "";
        for (let i = 0; i < length; i++) {
            code += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        attempts++;

        // Query Firestore to check if the code exists
        const docRef = doc(firestore, "messages", code);
        const docSnap = await getDoc(docRef);

        inviteCodeDoc = docSnap.exists(); // Firestore check

    } while (inviteCodeDoc);

    return { code: code, status: "success" };
}
