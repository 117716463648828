import React, { useState, useEffect, useRef } from "react";
import FancyCarousel from "react-fancy-circular-carousel";
import "react-fancy-circular-carousel/FancyCarousel.css";
import { motion } from "framer-motion";
import image1 from "../../assets/images/digital backbone.webp";
import image2 from "../../assets/images/equalAccessToEducation.webp";
import image3 from "../../assets/images/holisticDevelopment 1.webp";
import image4 from "../../assets/images/skillBasedLearning 1.webp";
import image5 from "../../assets/images/aComprehensiveCurriculum.webp";
import image6 from "../../assets/images/ConnectedEducationEcosystem.webp";
import image7 from "../../assets/images/nationalPride.webp";
import useScreenSizes from "../../utils/useScreenSize";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// VisionContainer component definition
const VisionContainer = () => {
  const [focusElement, setFocusElement] = useState(0);
  const [textScale, setTextScale] = useState(1); // State to control text scale
  const [isInView, setIsInView] = useState(false);
  const [hasAnimated, setHasAnimated] = useState(false); // Track if animation has run
  const containerLeftRef = useRef(null);
  const containerRightRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !hasAnimated) {
          // Check if not animated yet
          setIsInView(true);
          setHasAnimated(true); // Set animated to true after first animation
        }
      });
    });

    if (containerLeftRef.current) observer.observe(containerLeftRef.current);
    if (containerRightRef.current) observer.observe(containerRightRef.current);

    return () => {
      if (containerLeftRef.current)
        observer.unobserve(containerLeftRef.current);
      if (containerRightRef.current)
        observer.unobserve(containerRightRef.current);
    };
  }, [hasAnimated]); // Add hasAnimated as a dependency

  const screenType = useSelector((state) => state.website.screenType);
  const screen = useScreenSizes();

  const images = [image1, image2, image3, image4, image5, image6, image7];
  const [autoRotate, setAutoRotate] = useState(true); // State to control auto-rotation

  const handleMouseEnter = () => {
    setAutoRotate(false); // Stop auto-rotation on hover
  };

  const handleMouseLeave = () => {
    setAutoRotate(true); // Resume auto-rotation when hover ends
  };

  const increaseTextScale = () => {
    setTextScale((prevScale) => prevScale + 0.1);
  };

  const decreaseTextScale = () => {
    setTextScale((prevScale) => (prevScale > 1 ? prevScale - 0.1 : 1));
  };

  // Content for each focus element
  const info = [
    {
      title: "A Digital Backbone for Education",
      content:
        "We aim to modernize India's education infrastructure, providing a seamless and efficient digital platform for tracking, managing, and enhancing educational outcomes.",
    },
    {
      title: "Equal Access to Education",
      content:
        "No student should be left behind. StudyBuddy is committed to ensuring that every individual, regardless of their background, has access to quality education and the resources they need to succeed.",
    },
    {
      title: "Holistic Development",
      content:
        "We believe that education is more than just academics. StudyBuddy fosters character development, critical thinking, and social responsibility to create well-rounded individuals who can contribute positively to society.",
    },
    {
      title: "Skill-Based Learning",
      content:
        "In today's competitive world, skills are as important as degrees. StudyBuddy equips students with the practical skills and knowledge they need to thrive in the workplace and contribute to India's economic growth.",
    },
    {
      title: "A Comprehensive Curriculum",
      content:
        "Beyond traditional subjects, StudyBuddy offers essential life skills like financial literacy, time management, and stress management to prepare students for the challenges of the real world.",
    },
    {
      title: "A Connected Education Ecosystem",
      content:
        "We envision a more interconnected educational landscape where students, teachers, and institutions can collaborate, share resources, and learn from each other.",
    },
    {
      title: "Pride in India",
      content:
        "StudyBuddy is dedicated to promoting Indian culture, history, and values. We believe that by fostering a sense of national pride, we can inspire students to contribute to India's growth and development.",
    },
    {
      title: "Student Empowerment",
      content:
        "We empower students to be active participants in their education. StudyBuddy provides a platform for students to connect, collaborate, and share their ideas and experiences.",
    },
    {
      title: "Healthy Competition",
      content:
        "We believe that healthy competition can drive excellence. StudyBuddy creates a platform for students, teachers, and institutions to compete and showcase their achievements.",
    },
  ];

  // Inline styles
  const styles = {
    carousel: {
      position: "relative",
      width: "100%",
      height:
        screenType === "phone"
          ? "110vw"
          : screenType === "smallPhone"
          ? autoRotate
            ? "60vh"
            : "70vh"
          : screenType === "tablet"
          ? "60vw"
          : screenType === "tab"
          ? "82vw"
          : "100vh", // Adjust height for phone
      display: "flex",
      flexDirection:
        screenType === "smallPhone" || screenType === "phone" ? "column" : "",
      justifyContent: "center",
      alignItems: "center",
      marginBottom:
        screenType === "smallPhone" || screenType === "phone" ? "5vh" : "",
      marginLeft:
        screenType === "smallPhone" || screenType === "phone"
          ? autoRotate
            ? ""
            : "20px"
          : "",
    },
    main: {
      position: "absolute",
      left:
        screenType === "phone"
          ? ""
          : screenType === "smallPhone"
          ? ""
          : screenType === "tab" || screenType === "tablet"
          ? ""
          : "7vw",
    },
    infoBoxWrapper: {
      position: "relative",
      top:
        screenType === "smallPhone" || screenType === "phone"
          ? "0px"
          : screenType === "tab" || screenType === "tablet"
          ? ""
          : -475,
      left:
        screenType === "tab" || screenType === "tablet"
          ? "10px"
          : screenType === "smallPhone" || screenType === "phone"
          ? "10px"
          : "45vw",
      backgroundColor: "#fff",
      padding: "12px",
      width:
        screenType === "smallPhone" ||
        screenType === "phone" ||
        screenType === "tab" ||
        screenType === "tablet"
          ? "95%"
          : "50%",
      borderRadius: "10px",
      boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.3)",
      zIndex: 1,
    },
    title: {
      fontSize: `${textScale * (screenType === "smallPhone" ? 20 : 34)}px`,
      gap: "20px",
      zIndex: 100,
    },
    intro: {
      display: "flex",
      fontFamily: "raleway",
      marginLeft: "10px",
      borderRadius: "20px",
      fontSize: `${textScale * (screenType === "smallPhone" ? 14 : 16)}px`, // Adjust font size for intro text
      lineHeight: `${textScale * (screenType === "smallPhone" ? 1.2 : 1.5)}`, // Adjust line height for readability
    },
  };

  // Navigation button styling
  const navigationButtonStyling = {
    borderRadius: "50%",
    backgroundColor: "#ffffff",
    color: "#1c364f",
    width: "40px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "2rem",
    transition: "background-color 0.3s ease",
  };

  const handleImageClick = (index) => {
    setFocusElement(index); // Update state when an image is clicked
  };

  const { t } = useTranslation();
   const scrollRef = useRef(null);
   
    useEffect(() => {
      const scrollContainer = scrollRef.current;
      const speed = 1.5; // Adjust scroll speed
  
      const scrollText = () => {
        if (scrollContainer) {
          // Scroll the content smoothly
          scrollContainer.scrollTop += speed;
  
          // When we reach near the end, reset the scroll to top
          if (scrollContainer.scrollTop >= scrollContainer.scrollHeight - scrollContainer.clientHeight) {
            // Reset the scroll position without causing a jump
            scrollContainer.scrollTop = 0;
          }
        }
      };
  
      // Start the interval for the infinite scroll
      const interval = setInterval(scrollText, 50); // Adjust speed
  
      // Cleanup the interval on component unmount
      return () => clearInterval(interval);
    }, []);
    const combinedRef = (node) => {
      if (node) {
        scrollRef.current = node;
        containerLeftRef.current = node;
      }
    };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        width: "100vw",
        overflow: "hidden",
        marginTop : "25px"
      }}
    >
      <div
        style={{
          height:
            screenType === "tab" || screenType === "tablet"
              ? "35vw"
              : screenType === "smallPhone" || screenType === "phone"
              ? ""
              : "60vh",
          width: "95vw",
          backgroundColor: "white",
          // marginTop: "40px",
          position: "relative",
          display: "flex",
          flexDirection:
            screenType === "smallPhone" || screenType === "phone"
              ? "column"
              : "row",
          boxShadow: "10px 10px 15px rgba(0, 0, 0, 0.3)",
          borderRadius: "20px",
          margin: screenType==="phone"||screenType==="smallPhone" ? "10vw auto": "2vw auto",
          padding: "15px",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundImage: `url(${require("../../assets/images/forvision.webp")})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            opacity: 0.4, // Set the opacity of the image
            zIndex: 0, // Ensure it stays behind the content
          }}
        ></div>
        <div
          style={{
            // height: "100%",
            flexShrink: 1,
          maxWidth : screenType==="phone"||screenType==="smallPhone" ? "100%" : "50%",
          overflowX:"hidden",
          overflowY:"scroll",
            transform: isInView
              ? screenType === "phone"
                ? " translateY(0%)"
                : screenType === "tab"
                ? "translateY(0%)"
                : screenType === "tablet"
                ? "translateY(0%)"
                : "translateY(0%)"
              : "translateY(-100%)",
            opacity: isInView ? 1 : 0,
            padding: "15px",
            transition: "transform 1s ease-in-out, opacity 1s ease-in-out",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          ref={combinedRef}
        >
          <p
            style={{
              flexShrink: 1,
              fontSize: screenType === "smallPhone" || screenType === "phone" ? 20 : screenType === "tab" ? 25 : screenType === "tablet" ? 25 : 40,
              fontFamily: "'Roboto', sans-serif",
              fontWeight: "600",
              lineHeight: "1.5",
              textAlign: "center",
              color: "#2E3A59",
             

              margin: "20px",
              textShadow: "1px 1px 2px rgba(0, 0, 0, 0.2)",
            }}
          >
            {t(`"Shaping the Future Together – Inspiring Progress Through Innovation and Excellence."`)} 
          </p>
        </div>
        <div
          style={{
            height: "100%",
            width:
              screenType === "smallPhone" || screenType === "phone" ? "" : "",
            transform: isInView ? "translateX(0)" : "translateX(100%)",
            opacity: isInView ? 1 : 0,
            transition: "transform 1s ease-in-out, opacity 1s ease-in-out",
          }}
          ref={containerRightRef}
        >
          <img
            src={require("../../assets/images/for vision.webp")}
            style={{
              height: "100%",
              width: "100%",
              objectFit: "contain",
            }}
            loading="lazy"  />
        </div>
      </div>
      <div
        style={{
          transition: "opacity 0.6s ease-in-out",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          borderRadius: "12px",
          padding: "15px",
          width: "95vw",
          overflowWrap:"anywhere",
          flexDirection: screenType === "smallPhone" ? "column" : "column",
          justifyContent: "space-between",
          backgroundColor: "white",
          margin: "2vw auto",
          
          boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.3)",
        }}
      >

        <motion.p style={{ ...styles.title, alignSelf: "center" }}>
          {t(`"Unlock Your Potential with Study Buddy—Your Ultimate Learning Companion"`)}
        </motion.p>
        <div>
          <p
            style={{
              fontSize: `${textScale * 20}px`, // Adjusted for readability
              lineHeight: `${textScale * 1.8}`, // Better line spacing for readability
              fontFamily: "'Raleway', sans-serif", // Modern and professional font
              fontWeight: "400", // Light to keep it readable
              textAlign: "justify", // Ensures alignment for a clean look
              color: "#2E3A59", // Soft, dark blue for better contrast
              margin: "20px 10px", // Adds balanced spacing around the text
              padding: "10px 15px", // Ensures content doesn't touch the edges
              backgroundColor: "#F8F9FA", // Light grey for subtle emphasis
              borderRadius: "8px", // Smooth corners for a modern touch
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
              textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)", // Light text shadow for clarity
            }}
          >
            {t("studybuddy vision")}
          </p>
        </div>
        <p style={styles.intro}>
          {t(` "Imagine a world where every student, regardless of their
          circumstances, has the power to unlock their full potential. A world
          where classrooms are not just places of learning, but incubators of
          innovation and social responsibility. A world where India's youth are
          not just consumers of knowledge, but active contributors to the
          nation's progress. This is the vision that drives StudyBuddy. We
          believe that education is the cornerstone of a thriving nation, and
          that every student deserves the opportunity to reach their highest
          aspirations. Join us as we embark on a journey to transform India's
          education landscape and create a brighter future for generations to
          come."`)}
        </p>
      </div>
      <div style={styles.carousel}>
        <div style={styles.main}>
          <div
            style={{
              height: screenType === "tab" ? "" : "40%",
              // width: screenType==="smallPhone" || screenType==="tab" || screenType==="tablet" ? "96%" : "96%",
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <FancyCarousel
              images={images}
              setFocusElement={handleImageClick} // Pass function for image click
              carouselRadius={
                screen < 400
                  ? 120
                  : screenType === "smallPhone" || screenType === "phone"
                  ? 150
                  : screenType === "tab"
                  ? 200
                  : screenType === "tablet"
                  ? 250
                  : 200
              } // Adjust radius for phone
              peripheralImageRadius={
                screenType === "smallPhone" || screenType === "phone"
                  ? 30
                  : screenType === "tab"
                  ? 50
                  : screenType === "tablet"
                  ? 65
                  : 50
              } // Adjust radius for phone
              centralImageRadius={
                screen < 400
                  ? 60
                  : screenType === "smallPhone" || screenType === "phone"
                  ? 80
                  : screenType === "tab"
                  ? 80
                  : 120
              } // Adjust radius for phone
              focusElementStyling={{ border: "2px solid #ba4949" }}
              autoRotateTime={autoRotate ? 2 : 0}
              borderWidth={4}
              borderHexColor={"#1c364f"} // Corrected hex format
              navigationTextSize={2}
              navigationButtonRadius={20}
              navigationButtonColor={"#1c364f"} // Use '#' for hex
              navigationButtonBgColor={"#ffffff"} // Use '#' for hex
              navigationButtonStyling={navigationButtonStyling}
            />
          </div>
        </div>

        {screenType === "desktop" ? (
       <div style={ { position: "absolute",  backgroundColor: "#fff",
        right:"32px",
        padding: "12px",
        width:
          screenType === "smallPhone" ||
          screenType === "phone" ||
          screenType === "tab" ||
          screenType === "tablet"
            ? "95%"
            : "50%",
        borderRadius: "10px",
        boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.3)",
        zIndex: 1, } }>
            {info.map((item, index) => {
              if (index === focusElement) {
                // Only show the focused element
                return (
                  <div
                    key={index}
                    style={
                      {
                        // height:"70vh"
                      }
                    }
                  >
                    <h3 style={{ fontFamily: "raleway", fontSize: "30px" }}>
                      {item.title}
                    </h3>
                    <p
                      style={{
                        fontSize: "18px", // A comfortable reading size
                        lineHeight: "1.8", // Enhanced line spacing for better readability
                        fontFamily: "'Raleway', sans-serif", // Elegant and modern font
                        fontWeight: "400", // Regular weight for easy reading
                        textAlign: "justify", // Neat alignment for clean presentation
                        color: "#2E3A59", // Subtle dark blue for better contrast
                        margin: "10px 0", // Balanced spacing around the text
                        padding: "10px", // Adds spacing within the text block
                        backgroundColor: "#F4F6F8", // Light grey background for subtle emphasis
                        borderRadius: "8px", // Rounded corners for a modern look
                        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)", // Soft shadow for depth
                        textShadow: "0px 1px 2px rgba(0, 0, 0, 0.1)", // Slight text shadow for clarity
                      }}
                    >
                      {t(item.content)}
                    </p>
                  </div>
                );
              }
              return null; // Avoid rendering other elements
            })}
          </div>
        ) : null}
      </div>{" "}
      {screenType === "desktop" ? null : (
        <div style={styles.infoBoxWrapper}>
          {info.map((item, index) => {
            if (index === focusElement) {
              // Only show the focused element
              return (
                <div
                  key={index}
                  style={
                    {
                      // height:"70vh"
                    }
                  }
                >
                  <h3 style={{ fontFamily: "raleway", fontSize: "30px" }}>
                    {item.title}
                  </h3>
                  <p
                    style={{
                      fontSize: "18px", // A comfortable reading size
                      lineHeight: "1.8", // Enhanced line spacing for better readability
                      fontFamily: "'Raleway', sans-serif", // Elegant and modern font
                      fontWeight: "400", // Regular weight for easy reading
                      textAlign: "justify", // Neat alignment for clean presentation
                      color: "#2E3A59", // Subtle dark blue for better contrast
                      margin: "10px 0", // Balanced spacing around the text
                      padding: "10px", // Adds spacing within the text block
                      backgroundColor: "#F4F6F8", // Light grey background for subtle emphasis
                      borderRadius: "8px", // Rounded corners for a modern look
                      boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)", // Soft shadow for depth
                      textShadow: "0px 1px 2px rgba(0, 0, 0, 0.1)", // Slight text shadow for clarity
                    }}
                  >
                    {t(item.content)}
                  </p>
                </div>
              );
            }
            return null; // Avoid rendering other elements
          })}
        </div>
      )}
      {/* <div >
        <p onClick={increaseTextScale}>  increase text scale </p>
        <p onClick={decreaseTextScale}>  decrease text scale </p>
      </div> */}
    </div>
  );
};

export default VisionContainer;
