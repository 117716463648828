import React, { useEffect, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';


const ThemeDashboardCard = (props) => {

    const [filled,setFilled]=useState()

    const getDashboardColor = () => {
      
      if(props.color){
          switch(props.color){
              case 'green':
                  return "#1CAC1F"
              case 'orange':
                  return '#EB9431'
              case 'grey':
                  return '#BFBFBF'
              case 'red':
                  return '#E00202'
              case 'yellow':
                  return '#E8C000'
              default:
                return props.color
                  
          }
        }else{
          return 'black'
        }
      
  }

    const styles ={
      card: {
        borderRadius: 15,
        backgroundColor: 'aliceblue',
        padding: 15,
        paddingRight:0,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 10 },
        shadowOpacity: 0.1,
        shadowRadius: 20,

        display:"flex",
        elevation: 5,
        flexDirection: 'row',
        alignItems: 'center',
        position: 'relative',
        width:'300px',
        marginVertical:5,
        boxShadow:' 0px 10px 20px rgba(0 , 0 , 0 , 0.5)',
       
       
      },
      cardContent: {
        flex: 1,
        display:"flex",
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
      icon: {
        width: 30,
        height: 30,
        marginBottom: 10,
      },
      pWrapper: {
        flex: 1,
        flexDirection:"column",
        marginBottom: 5,
        display:"flex",
      },
      title: {
        fontSize: 16,
        fontWeight: 'bold',
        width:"max-content",
        color: '#2B2E4A',
      },
      value: {
        fontSize: 24,
        fontWeight: 'bold',
        color: '#2B2E4A',
      },
      subValue: {
        fontSize: 16,
        fontWeight: 'normal',
        color: '#7D7F85',
      },
      caption: {
        fontSize: 12,
        color: '#7D7F85',
        marginTop: 5,
      },
      progressWrapper: {
        marginRight: 10,
        marginTop: 0,
        width:"50px",
        
        marginRight:"30px",
      },
      progress: {
        borderWidth: 1,
        borderRadius: 100,
        display:"flex",
        alignItems: 'center',
        justifyContent: 'center',
        borderColor: 'white',
      },
      sidePanelContainer: {
        position: 'absolute',
        right: 0,
        top: 0,
        bottom: 0,
        width: 15,
        justifyContent: 'center',
        alignItems: 'center',
      },
      sidePanel: {
        width: 15,
        height: '50%',
        borderTopLeftRadius: 15,
        borderBottomLeftRadius: 15,
      },
      trapezoid: {
        width: 0,
        height: 130,
        borderRightWidth: 15,
        borderRightColor: getDashboardColor(),
        borderBottomWidth: 15,
        borderBottomColor: "transparent",
        borderTopWidth: 15,
        borderLeft:"0px",
        borderTopColor: "transparent",
        borderStyle: 'solid',
      },
    };

    useEffect(()=>{
        setFilled(props.fill)
    })
  return (
    <div style={styles.card}>
 
      <div style={styles.cardContent}>
        <div style={styles.pWrapper}>
          <img 
            src={props.image ? props.image : require('../../assets/images/staff.webp')}
            style={styles.icon}
          />
          <text style={styles.title}>{props.title || 'Ready to usage'}</text>
          <div style={{flexDirection:"row" , alignItems:"center", display:"flex"}}>
            <p style={styles.value}>
              {props.fill ? props.fill : 80} 
            </p>
            <img src ={props.trend == 'up' ? require('../../assets/images/transactionUpArrow.webp') : 
                    require('../../assets/images/transactionDownArrow.webp')} style={{width:15, height:15, paddingHorizontal:10, objectFit:"contain"}} />
            <p style={styles.subValue}>{props.subValue ? props.subValue : -42}</p>
          </div>
          <p style={styles.caption}>{props.caption ||'Some caption here'}</p>

        </div>
        <div style={styles.progressWrapper}>
        <CircularProgressbar
          value={props.fill || 80}
          text={`${props.fill || 80}%`}
          styles={buildStyles({
            textSize: '30px',
            pathColor: getDashboardColor(),  // Set dynamic color
            textColor: getDashboardColor(),  // Set text color to match the dynamic color
            trailColor: '#e3e5e8',  // Light background color
            strokeWidth: 5,  // Set the width of the progress bar
            
          })}
        />
        </div>
      </div>

      
        <div style={styles.trapezoid} />
      
    </div>
  );
};



  

export default ThemeDashboardCard;
