import { ScrollView, StyleSheet, div, img, p } from 'react-native';
import React, { useEffect, useState } from 'react';
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ErrorCard from './errorcard';
import Profilelogo from '../ReusableCards/Profilelogo';
import { Highlight } from '../searching/highlight';
import ReusableModal from '../PostsComponent/ReusableModal';
import ThemeButton from '../ResuableThemeComponent/ThemeButton';
import RemoveUser from './removeUser';
import { useNavigate } from 'react-router-dom';
import { firestore } from '../../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { display, width } from '@mui/system';

/**
 * UserCard Component
 * 
 * This component displays user information in a card format with different styles based on the user's role.
 * It supports the following props:
 * 
 * Props:
 * - image: URL of the user's profile picture
 * - name: Name of the user
 * - rollno: Roll number (for students)
 * - routeno: Route number (for drivers)
 * - Role: User's role (e.g., Student, Teacher, Driver, Accountant, Head)
 * - designation: User's designation (for staff, accountants, etc.)
 * - action: to enable action button
 * 
 * Features:
 * - Fetches user data from Firestore if not already provided via props.
 * - Displays a skeleton loader while fetching data.
 * - Provides a modal with actions like "div Profile," "Message," and "Take Action."
 * - Uses Firebase Firestore for user data retrieval.
 * - Highlights search results when used with search functionality.
 * - Supports navigation to the user's details screen on card press.
 * 
 */
const UserCard = (props) => {
    console.log('props: ', props);


    const [loading, setLoading] = useState(true);
    const navigation = useNavigate();
    const [data, setdata] = useState(null);
    const [modal, SetModal] = useState(false)
    const [isVisible, setIsVisible] = useState(false)
    const [id, setId] = useState('');
    useEffect(() => {
      if (!props.hit) {
          userFetch();
      } else {
          setId(Highlight({ hit: props.hit, document: true }))
      }
  }, []);


//   const userFetch = async () => {

//     const document = props.data.LMSid ? props.data.LMSid : props.data
//     console.log('document: ', document);
//     try {
//         const userdoc = await firestore()
//             .collection('LMSusers')
//             .doc(document)
//             .get();
//         const userData = userdoc.data();
//         console.log('userData: ', userData);
//         setdata(userData);
//         setLoading(false);
//     } catch (e) {
//         console.log(e);
//     }
// };

      const userFetch = async () => {
          if (!data) return;
          const documentId = data.LMSid ? data.LMSid : data;

          try {
              console.log("Fetching document:", documentId);
              const userDocRef = doc(firestore, "LMSusers", documentId);
              const userDocSnap = await getDoc(userDocRef);

              if (userDocSnap.exists()) {
                  const userData = userDocSnap.data();
                  console.log("User Data:", userData);
                  setdata(userData);
              } else {
                  console.warn("No user found for ID:", documentId);
              }
          } catch (error) {
              console.error("Error fetching user:", error);
          } finally {
              setLoading(false);
          }
      };

  



    try {
        const initialCover = true;
        const endCover = false;
        const coverFlow = 'vertically';
     

        setInterval(() => {
            // loading intervel
            if (props.hit) {
                setLoading(false);
            }
            // userFetch()r
        }, 1000);
     
        // console.log('document: ', document);
      
        const styles = {
            mainContainer: {
                flexDirection: coverFlow === 'horizontal' ? 'row' : 'column',
                borderRadius: 10,
                // marginHorizontal: 10,
                marginVertical: 10,
                display:"flex",
             
                 
            },
            contentContainer: {
                backgroundColor: '#fff',
                flex: 1,
                borderRadius: 10,
                display:"flex" , 
               
            },
            innerContainer: {
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                marginHorizontal: 10,
                display:"flex"
            },
            imageContainer: {
                borderRadius: 35,
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden',
                width: 60,
                height: 60,
            },
            instituteImage: {
                width: 60,
                height: 60,
            },
            infoContainer: {
                flex: 1,
                marginTop: 10,
            },
            name: {
                fontSize: 18,
                fontWeight: 'bold',
                color: '#333',
            },
            username: {
                fontSize: 16,
                color: 'grey',
            },
            role: {
                fontSize: 15,
                color: 'black',
                textAlign: 'center',
            },
            additionalInfo: {
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: 5,
                display:"flex"
            },
            label: {
                fontSize: 14,
                color: '#555',
            },
        };

        const openModal = () => {
            SetModal(true)
        }
        const handleCardPress = data => {
            if (!props.readOnly) {
                navigation.navigate('Datascreen', {
                    data: id ? id : props.data.LMSid ? props.data.LMSid : props.data,
                    cardtype: props.cardtype,
                });
            }
        };

        return (
            props.hit ? <div activeOpacity={1} style={{ flexDirection: 'row', backgroundColor: 'white', padding: 10, borderRadius: 10, columnGap: 10, flexWrap: 'wrap' , display:"flex" , width:"300px" }} onClick={() => (console.log('clicked'), props.handlePress ? props.handlePress() : console.log('jjj'))}>
                <div style={{ position: 'absolute', right: 5, top: 5, flexDirection: 'row' }}>
                    {props.hit.primaryRole === 'student' ? (
                        <img
                            src={require('../../assets/images/student.png')}
                            style={{ width: 20, height: 20 }}
                        />
                    ) : props.hit.primaryRole === 'teacher' ? (
                        <img
                            src={require('../assets/images/staff.png')}
                            style={{ width: 25, height: 25 }}
                        />
                    ) : props.hit.primaryRole === 'accountant' ? (
                        <img
                            src={require('../../assets/images/accountant.png')}
                            style={{ width: 25, height: 25 }}
                        />
                    ) : props.hit.primaryRole === 'driver' ? (
                        <img
                            src={require('../../assets/images/driver.png')}
                            style={{ width: 25, height: 25 }}
                        />
                    ) : props.hit.primaryRole === 'admin' ? (
                        <img
                            src={require('../../assets/images/principal.png')}
                            style={{ width: 25, height: 25 }}
                        />
                    ) : null}


                </div>
                <div>
                    <Profilelogo width={50} />
                </div>
                <div style={{ marginTop: 10, }}>
                    <p numberOfLines={1} style={{ fontSize: 20, color: "white", }}><Highlight textColor={'black'} hit={props.hit} attribute="firstName" /> <Highlight textColor={'black'} hit={props.hit} attribute="lastName" /></p>
                </div>
            </div> :
                <div onClick={handleCardPress}>
                    <div
                        style={[
                            styles.mainContainer,
                            {
                                backgroundColor:
                                    data ?
                                        Object.keys(data.primaryRole) == 'student'
                                            ? '#D4D0FF'
                                            : Object.keys(data.primaryRole) == 'staff'
                                                ? 'green'
                                                : Object.keys(data.primaryRole) == 'head'
                                                    ? '#33e8b2'
                                                    : Object.keys(data.primaryRole) == 'driver'
                                                        ? '#d97f3b'
                                                        : '#e838b9'
                                        : '#e838b9',
                            },
                        ]}>
                        <Skeleton // Should add as replacement of div
                            containerStyle={{ width: '100%', height: 'auto' }}
                            isLoading={false}
                            animationType="shiver"
                            boneColor="#E1E9EE"
                            highlightColor="#F2F8FC"
                            animationDirection="horizontalRight"
                            layout={[
                                // same style as your component to replace the loading
                                {
                                    key: 'someId',
                                    width: '100%',
                                    height: 80,
                                    borderRadius: 10,
                                },
                            ]}>
                            {initialCover && (
                                <div
                                    style={coverFlow === 'horizontal' ? { width: 7 } : { height: 7 }}
                                />
                            )}

                            {(
                                <div style={styles.contentContainer}>
                                    <div style={styles.innerContainer}>
                                        <div style={{ padding: 2, marginRight: '2%', rowGap: 5 }}>
                                            <div style={styles.imageContainer}>
                                                <Profilelogo
                                                    width={55}
                                                    profileimage={
                                                        (data?.profilePic && { uri: data.profilePic }) ||
                                                        (props?.item?.profilePic && { uri: props.item.profilePic }) ||
                                                        require('../../../assets/images/profile.png')
                                                    }
                                                />


                                            </div>
                                        </div>

                                        <div style={styles.infoContainer}>
                                            {/* <div style={{ position: 'absolute', right: 0, flexDirection: 'row' }}>
                                                {Object.keys(data.primaryRole) == 'student' ? (
                                                    <img
                                                        src={require('../../../assets/images/student.png')}
                                                        style={{ width: 25, height: 25 }}
                                                    />
                                                ) : Object.keys(data.primaryRole) == 'teacher' ? (
                                                    <img
                                                        src={require('../../../assets/images/staff.png')}
                                                        style={{ width: 25, height: 25 }}
                                                    />
                                                ) : Object.keys(data.primaryRole) == 'accountant' ? (
                                                    <img
                                                        src={require('../../../assets/images/accountant.png')}
                                                        style={{ width: 25, height: 25 }}
                                                    />
                                                ) : Object.keys(data.primaryRole) == 'driver' ? (
                                                    <img
                                                        src={require('../../../assets/images/driver.png')}
                                                        style={{ width: 25, height: 25 }}
                                                    />
                                                ) : Object.keys(data.primaryRole) == 'admin' ? (
                                                    <img
                                                        src={require('../../../assets/images/principal.png')}
                                                        style={{ width: 25, height: 25 }}
                                                    />
                                                ) : null}
                                                {
                                                    props.action &&
                                                    <div style={{ justifyContent: 'center', alignItems: 'center', width: 20, height: 30, marginLeft: 10 }} onClick={openModal}>
                                                        <img style={{ height: '100%', width: '100%' }} src={require('../../../assets/images/dots.png')} />
                                                    </div>
                                                }
                                            </div> */}

                                            <div style={{ marginRight: '20%' }}>
                                                <p numberOfLines={1} style={styles.name}>
                                                {`${props.item.firstName} ${props.item.lastName}` || 'Amrinder'}

                                                </p>
                                            </div>

                                            {/* {Object.keys(data.primaryRole||props.item.primaryRole) == 'student' ? (
                                                <p numberOfLines={1} style={styles.username}>
                                                    {data.Rollno || '21'}
                                                </p>
                                            ) : Object.keys(data.primaryRole||props.item.primaryRole) == 'staff' ? (
                                                <p style={{ color: 'gray' }}>
                                                    {data.designation || 'Teacher'}
                                                </p>
                                            ) : Object.keys(data.primaryRole||props.item.primaryRole) == 'head' ? (
                                                <p style={{ color: 'gray' }}>
                                                    {data.designation || 'Principal'}
                                                </p>
                                            ) : Object.keys(data.primaryRole||props.item.primaryRole) == 'driver' ? (
                                                <div>
                                                    <p style={{ color: 'gray' }}>Driver</p>
                                                    <p style={{ color: 'gray' }}>
                                                        Route: {data.routeno || 'N/A'}
                                                    </p>
                                                </div>
                                            ) : Object.keys(data.primaryRole||props.item.primaryRole) == 'accountant' ? (
                                                <p style={{ color: 'gray' }}>
                                                    {data.designation || 'Accountant'}
                                                </p>
                                            ) : (
                                                <p style={{ color: 'black' }}>{Object.values(data.primaryRole||props.item.primaryRole)}</p>
                                            )} */}
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: 5 }} />
                                </div>
                            )}

                            {endCover && (
                                <div
                                    style={coverFlow === 'horizontal' ? { width: 7 } : { height: 7 }}
                                />
                            )}
                        </Skeleton>
                        {
                            modal &&
                            <ReusableModal onClose={SetModal} visible={modal} heightPercent={40} >
                                <div style={{ flex: 1, alignItems: 'center', margin: 10, padding: 10, justifyContent: 'space-evenly', marginTop: 0, paddingTop: 0 , display:"flex" }}>
                                    <p style={{ color: 'black', fontSize: 30 }}>{data.name || 'lllll'}</p>
                                    <ThemeButton
                                        title={'div Profile'}
                                        width={'80%'}
                                    />
                                    <ThemeButton
                                        title={'Message'}
                                        width={'80%'}
                                    />
                                    <ThemeButton
                                        title={'Take Action'}
                                        width={'80%'}
                                        variant={'red'}
                                        func={() => setIsVisible(!isVisible)}
                                    />
                                </div>
                            </ReusableModal>}
                        {
                            isVisible &&
                            <RemoveUser isVisible={isVisible} setIsVisible={setIsVisible} id={id ? id : props.data.LMSid ? props.data.LMSid : props.data}
                                instituteId={data.instituteId} enrollmentIds={data.enrollments} />
                        }
                    </div>
                </div>
            // <p>{props.item.firstName}</p>
        );
    } catch (error) {
        console.log('User card Error', error);
        return (<ErrorCard />)

    }
};

export default UserCard;