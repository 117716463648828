import { Modal,Activity} from 'react-native'
import React,{useState} from 'react'
import  encryptData  from '../../utils/encrypt';
import { FCMTOKENKEY } from '../../environmentVariables';
import {auth, storage , messaging , functions , firestore, signInWithEmailAndPassword, getToken, signOut,} from '../../firebase';

import { useNavigate } from 'react-router-dom';
export default function UserVerificatonFlow(props) {
    // props
    // otpMetaData:{
    // otpLength: 4,
    //  otpType: 'numerical',
    //   otpValidationTime: 5
    // }   //otpType can be numerical or alphabetical -- otpLength can be from 4 to 6 -- otpValidationTime is in minutes
    // initialScreen props is compulsory to pass where this component is rendered
    const otpMetaData = props.otpMetaData

    ? props.otpMetaData
    : {otpLength: 4, otpType: 'numerical', otpValidationTime: 5,otpIsFor:'forgotPassword'}; //if routes donot exist then default values will be passed
    const buttonText=props.buttonText?props.buttonText:'forgot Password'
    const navigate=useNavigate();
const [FCMToken,setFCMToken]=useState(null)

    const [modalVisible, setModalVisible] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');

    const handleOptionSelect = (option) => {
      setSelectedOption(option);
      setModalVisible(false) // Close the modal after selection
    navigate('/OTPScreen1', {   state:{ selectedOption: option, otpMetaData:otpMetaData,fcmToken:FCMToken}});
    };
    const handleForgotButtonPress=async()=>{


        const getFCMTOKEN = async () => {
          try {
            // await messaging().registerDeviceForRemoteMessages();
            // const tokendata = await messaging().getToken();
            const tokendata = await getToken(messaging, {
                vapidKey: "BOaQ482qDKa3uJWM5SQUVpRAOAh0-MSmmKYUdugqArdyluZe1vzoO8789D1kRFCtXxo8sVm72Weqr9drJpi8EYU"
            });
            console.log('fcm token--', tokendata);
            return tokendata;
          } catch (error) {
            console.log('error in creating token');
            return null;
          }
        };
        
        const fcmToken=await getFCMTOKEN();
        try {
          
          const encryptFcmToken= encryptData(fcmToken,FCMTOKENKEY)
          setFCMToken(encryptFcmToken);
          setModalVisible(true);
        } catch (error) {
          console.log("erro while encrypting token");
          return;
        }
        
        // navigation.navigate('UserVerificationScreen',{otpMetaData:otpMetaData,fcmToken:fcmToken}) 
            
          }
  return (
    <div style={styles.mainContainer}>
       <Modal onRequestClose={()=>{setModalVisible(false)}} visible={modalVisible} transparent={true} animationType="slide">
      <div style={styles.modalContainer}>
        <div style={styles.modalContent}>
          <p style={styles.modalTitle}>Selected an option to find account</p>
          <div
            style={styles.optionButton}
            onClick={() => handleOptionSelect('Username')}
          >
            <p style={styles.optionText}>Username</p>
          </div>
          <div
            style={styles.optionButton}
            onClick={() => handleOptionSelect('Email')}
          >
            <p style={styles.optionText}>Email</p>
          </div>
          <div
            style={styles.optionButton}
            onClick={() => handleOptionSelect('Mobile')}
          >
            <p style={styles.optionText}>Mobile</p>
          </div>
          <div style={styles.closeButton} onClick={()=>{setModalVisible(false)}}>
            <p style={styles.closeButtonText}>Close</p>
          </div>
        </div>
      </div>
    </Modal>
   <div
        onClick={() => {handleForgotButtonPress()}}
        style={styles.forgotPass}>
        <p style={styles.forgotPassText}>{buttonText} ?</p>
      </div>
    </div>
    
  )
}

const styles={

    forgotPass: {
        marginTop: 20,
        alignItems: 'center',
        cursor:"pointer"
      },
      forgotPassText: {
        color: '#007bff',
        fontSize: 16,
      },
      modalContainer: {
        flex: 1,
        justifyContent: 'flex-end',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
      },
      modalContent: {
        width: '100%',
        backgroundColor: 'white',
        borderRadius: 8,
        padding: 20,
        alignItems: 'center',
      },
      modalTitle: {
        fontSize: 18,
        marginBottom: 20,
      },
      optionButton: {
        width: '100%',
        padding: 10,
        backgroundColor: '#007BFF',
        borderRadius: 5,
        marginBottom: 10,
        alignItems: 'center',
      },
      optionText: {
        color: 'white',
        fontSize: 16,
      },
      closeButton: {
        marginTop: 10,
      },
      closeButtonText: {
        color: '#007BFF',
        fontSize: 16,
      },
}