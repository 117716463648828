import React, { useRef, useState } from 'react';
import { Configure, InstantSearch } from 'react-instantsearch-core';
import {algoliasearch} from 'algoliasearch';
import { SearchBox } from './searchBox2';
import  InfiniteHits  from './infiniteHits';
import UserCard from '../ReusableCards/userCard';
import { useNavigate } from 'react-router-dom';

const searchClient = algoliasearch('7BER79SCQK', '51f6b19d6579616e0bad6f843d293325');

export default function UserSearch({ userObject, setUserObject, idsArray, singleUser, navigate, SBUser, searchDisabled, setFullScreen }) {
    const [localQuery, setLocalQuery] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const listRef = useRef(null);
    const navigation = useNavigate();

    function scrollToTop() {
        if (listRef.current) {
            listRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    }

    function Hit({ hit }) {
        const handlePress = () => {
            if (navigate) {
                const data = {
                    firstName: hit.firstName,
                    lastName: hit.lastName,
                    id: hit.objectID,
                    ...(SBUser ? { userRole: hit.userRole } : { gender: hit.gender }),
                };
                navigation('/Datascreen', { state: { data, cardtype: SBUser ? 'SBUser' : 'LMSuser' } });
                return;
            }

            if (singleUser) {
                if (userObject && Array.isArray(userObject)) {
                    if (userObject.some(user => user.objectID === hit.objectID)) {
                        setUserObject({});
                        return;
                    }
                }
                setUserObject([hit]);
            } else {
                setUserObject(prev => {
                    const isPresent = prev[hit.objectID];
                    if (isPresent) {
                        const updatedObject = { ...prev };
                        delete updatedObject[hit.objectID];
                        return updatedObject;
                    } else {
                        return { ...prev, [hit.objectID]: hit };
                    }
                });
            }
        };

        return (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '5px',
                    backgroundColor: singleUser && userObject.some(user => user.objectID === hit.objectID) ? '#50C878' : '#50C878',
                    borderRadius: '10px',
                    cursor: 'pointer',
                    backgroundColor:"yellow",
                    justifyContent:"space-between"
                }}
                onClick={handlePress}
            >
                <UserCard hit={hit} readOnly handlePress={handlePress} />
                {!singleUser && !navigate && (
                    <img
                        src={
                            userObject?.[hit.objectID]
                                ? require('../../assets/images/black-check-box-with-white-check.png')
                                : require('../../assets/images/checkBox.png')
                        }
                        alt="Checkbox"
                        style={{ width: '30px', height: '30px', cursor: 'pointer' }}
                    />
                )}
            </div>
        );
    }

    if (idsArray.length === 0) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <h2 style={{ color: 'black', fontFamily: 'Marker Felt' }}>No user exists</h2>
            </div>
        );
    }

    return (
        <div style={{ backgroundColor: '#fff', minHeight: '100vh' }}>
            <InstantSearch searchClient={searchClient} indexName={SBUser ? 'SBUser' : "LMSuser"}>
                <Configure
                    // hitsPerPage={1000}  
                    facetFilters={[idsArray.map((id) => `objectID:${id}`)]}
                    highlightPreTag="<mark>"
                    highlightPostTag="</mark>"
                />
                {
                    !searchDisabled &&
                    <SearchBox onChange={(query) => {
                        
                        scrollToTop();
                        idsArray.length < 1000 && setLocalQuery(query);
                    }}
                    setIsloading={setIsLoading}
                    setFullScreen={setFullScreen}
                        idsLength={idsArray.length}
                        localQuery={localQuery}
                        backStyle={{ backgroundColor: '#fff', padding: 5,marginBottom:10 }} 
                        boxStyle={{ backgroundColor: '#252b33', borderColor: 'black', color: 'white' }} 
                        />
                }
               {isLoading? <div>Loading...</div> : <InfiniteHits ref={listRef} setFullScreen={setFullScreen} idsLength={idsArray.length} hitComponent={Hit} query={localQuery} widthRatio = {!navigate && 1.6 } />}
            </InstantSearch>
        </div>
    );
}

const styles = {
    safe: {
        flex: 1,
        backgroundColor: '#fff',
    },

};