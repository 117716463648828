import CryptoJS from 'crypto-js';
import { IV } from "../environmentVariables";

const encryptData = (plaintext, keyValue) => {
  try {
    // Ensure key is 32 bytes (AES-256 requires a 256-bit key)
    let key = CryptoJS.enc.Utf8.parse(keyValue.padEnd(32, ' '));
    
    // Define a fixed IV (must be 16 bytes for AES-256-CBC)
    let iv = CryptoJS.enc.Utf8.parse(IV);

    // Convert plaintext to Hex
    let hexString = CryptoJS.enc.Utf8.parse(plaintext).toString(CryptoJS.enc.Hex);

    // Encrypt the data
    let encrypted = CryptoJS.AES.encrypt(hexString, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }).toString();

    // Return IV + encrypted text
    return iv.toString(CryptoJS.enc.Hex) + encrypted;
  } catch (error) {
    console.error('Encryption Error:', error.message);
    return null;
  }
};

export default encryptData
