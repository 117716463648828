import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  TwoFactorAuthenticationData: { status: null, verificationIsFor: null },
};

const TwoFactorAuthenticationSlice = createSlice({
  name: "TwoFactorAuthenticationSlice",
  initialState,
  reducers: {
    setTwoFactorAuthenticationData: (state, action) => {
      state.TwoFactorAuthenticationData.verificationIsFor = action.payload.verificationIsFor;
      state.TwoFactorAuthenticationData.status = action.payload.status;
    },
    clearTwoFactorAuthenticationData: (state) => {
      state.TwoFactorAuthenticationData.verificationIsFor = null;
      state.TwoFactorAuthenticationData.status = null;
    },
  },
});

export const {
  setTwoFactorAuthenticationData,
  clearTwoFactorAuthenticationData,
} = TwoFactorAuthenticationSlice.actions;
export default TwoFactorAuthenticationSlice.reducer;
