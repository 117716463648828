import  React from "react" 
import { useState } from "react";
import { httpsCallable } from "firebase/functions";
import { signInWithCustomToken } from "firebase/auth";
import {
    setUserData,
    setActiveSBUser,
    updateUserField,
    clearUserData,
    setConversationDataFromStorageBucket,
    addSBUser
  } from '../../reduxStore/slices/UsersSlice';
import { auth, storage, messaging, functions, firestore, signInWithEmailAndPassword, getToken, signOut, } from '../../firebase';
import { useDispatch, useSelector } from 'react-redux';

import { doc, getDoc, writeBatch, setDoc, updateDoc, where, query , limit , collection , getDocs } from "firebase/firestore";
const SignUp =()=>{
    const [formData, setFormData] = useState({
      name: "",
      username: "",
      password: "",
      email: "",
      phone: "",
      dob: "",
      gender: "",
      role: "",
      verificationMethod: "",
      });
      const dispatch = useDispatch()
      const signupUser = async () => {
        const createUser = httpsCallable(functions, "createUser");

        try {
          const fcmToken = await getToken(messaging, {
            vapidKey: "BOaQ482qDKa3uJWM5SQUVpRAOAh0-MSmmKYUdugqArdyluZe1vzoO8789D1kRFCtXxo8sVm72Weqr9drJpi8EYU"
          });
          let tokens = { [fcmToken]: { deviceID: "", lastUpdatedTimestamp: new Date().getTime() } };
          const userData = {
            // name : formData.name,
            // firstName: formData.name ? formData.name.split(" ")[0]?.toLowerCase().trim() : "",
            // lastName: formData.name && formData.name.includes(" ") ? formData.name.split(" ")[1]?.toLowerCase().trim() : "",
            // dob: formData.dob.toLocaleString(),
            // gender:formData.gender,
            // role:formData.role,
            // verificationMethod: formData.verificationMethod,
            // verifiedEntity:
            // formData.verificationMethod === "email"
            // ? formData.email.trim()
            // : formData.verificationMethod === "phone"
            // ? formData.phone.trim()
            // : { email: formData.email.trim(), phone: formData.phone.trim() },
            //               joinedOn: new Date().getTime(),
            // karmaPoints: 0,
            // institutes: [],
            // fcmTokenObject: {},
            // LMSIDs: {},
            // topicIDs: [],
            // profilePic: "",
            // announcements: [],
            // conversationsRef: [],
            // schedule: {},
            // topicIdsArray: [],
            // CompletedActivities: [],
            // friends: {},
            // pendingRequests: {},
            // OTPSendCountObject: {},
            // goals: {},
            // expiredActivities: [],
            // receipts: [],
            // sentRequests: {},
            // LMSIDs: [],
            // OTPSendCountObject: {},
            // profilePinCode: "",
            // falseAttemptObject: {},
            // rewardcollected: [],
            // userBranch: '',
          };
          console.log("++++++++++++++",{
            
            username: formData.username.toLowerCase().trim(),
            password: formData.password,
            fcmTokens: tokens,
            userData,
            
          });
          const signUpResponse = await createUser({
            
            username: formData.username.toLowerCase().trim(),
            fcmTokens: tokens,
            userData:{nvgh:'jyfhjhv'},
            password:'1231313434234'
            
          });
          
          console.log(formData.username.toLowerCase().trim() ,formData.password , fcmToken , userData)
          console.log('signUpResponse: ', signUpResponse);
          console.log('signUpResponse.data.status: ', signUpResponse.data);
    
    
          if (signUpResponse.data.status === "success") {
            const response = await signInWithCustomToken(auth, signUpResponse.data.token);
    
            if (response.user) {
              const lUserData = { ...userData, fcmTokens: tokens, username: formData.username.toLowerCase().trim(), userUID: response.user.uid };
              
              console.log("???????????????????????????????????????????????????????????")
              // Save user to Firestore
              await setDoc(doc(firestore, "users", response.user.uid), lUserData);
    
              // Update Redux
            //   dispatch(addSBUser({ uid: response.user.uid, userData: lUserData }));
              // dispatch(setActiveSBUser(response.user.uid));
            }
          } else {
            console.error("Signup failed:", signUpResponse.data.message);
          }
        } catch (error) {
          console.error("Error signing up:", error);
        }
      };
    
    
     
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
     signupUser();
        };
        console.log(formData);
     
   
    const styles = {
      container: {
        maxWidth: "400px",
        margin: "auto",
        padding: "24px",
        backgroundColor: "white",
        borderRadius: "12px",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)"
      },
      buttonHover: {
        backgroundColor: "#2563eb",
      },
      title: {
        textAlign: "center"
      },
      input: {
        width: "100%",
        padding: "12px",
        marginBottom: "12px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        outline: "none"
      },
      select: {
        width: "100%",
        padding: "12px",
        marginBottom: "12px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        outline: "none"
      },
      button: {
        width: "100%",
        padding: "12px",
        backgroundColor: "#3b82f6",
        color: "white",
        borderRadius: "8px",
        border: "none",
        cursor: "pointer"
      }
    };
      return(
        <>
          <div style={styles.container}>
      <h2 style={styles.title}>User Sign Up</h2>
      <form onSubmit={handleSubmit}>
        <input type="text" name="name" placeholder="Full Name" value={formData.name} onChange={handleChange} style={styles.input} required />
        <input type="text" name="username" placeholder="Username" value={formData.username} onChange={handleChange} style={styles.input} required />
        <input type="password" name="password" placeholder="Password" value={formData.password} onChange={handleChange} style={styles.input} required />
        <input type="date" name="dob" value={formData.dob} onChange={handleChange} style={styles.input} required />
        <select name="gender" value={formData.gender} onChange={handleChange} style={styles.select} required>
          <option value="">Select Gender</option>
          <option value="male">Male</option>
          <option value="female">Female</option>
          <option value="other">Other</option>
        </select>
        <select name="role" value={formData.role} onChange={handleChange} style={styles.select} required>
          <option value="">Select Role</option>
          <option value="admin">Admin</option>
          <option value="staff">Staff</option>
          <option value="student">Student</option>
          <option value="parent">Parent</option>
        </select>
                <select name="verificationMethod" value={formData.verificationMethod} onChange={handleChange} style={styles.select} required>
          <option value="">Select Verification Method</option>
          <option value="email">Email</option>
          <option value="phone">Phone</option>
          <option value="both Email & Phone">Email & Phone</option>  {/* Updated label */}
          </select>
        {formData.verificationMethod === "email" || formData.verificationMethod === "both Email & Phone" ? (
          <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} style={styles.input} required />
        ) : null}
        {formData.verificationMethod === "phone" || formData.verificationMethod === "both Email & Phone" ? (
          <input type="tel" name="phone" placeholder="Phone" value={formData.phone} onChange={handleChange} style={styles.input} required />
        ) : null}
        <button type="submit" style={styles.button}>Submit</button>
      </form>
    </div>
        </>
      )
} ;
export default SignUp;