/**
 * CustomConfirmationModal Component
 * 
 * This component renders a confirmation modal that displays either a message 
 * (provided via the `message` prop) or custom content (provided via the `children` prop),
 * along with two buttons: "Cancel" and "Sure." The modal appears when the `visible` 
 * prop is set to true and can be dismissed with the "Cancel" button or confirmed with 
 * the "Sure" button.
 * 
 * Props:
 * - visible (boolean): Controls the visibility of the modal.
 * - onConfirm (function): Callback function when the user confirms the action by pressing the "Sure" button.
 * - onCancel (function): Callback function when the user cancels the action by pressing the "Cancel" button or outside the modal.
 * - message (string): Optional message to display in the modal. If not provided, custom content from the `children` prop is rendered.
 * - children (ReactNode): Optional custom content to display in place of the message.
 * - width(number): default is 300
 * Example Usage:
 * 
 * <CustomConfirmationModal
 *   visible={modalVisible}
 *   onConfirm={handleConfirm}
 *   onCancel={handleCancel}
 *   width=300
 *   message="Are you sure you want to delete this item?"
 * />
 * 
 * OR
 * 
 * <CustomConfirmationModal
 *   visible={modalVisible}
 *   onConfirm={handleConfirm}
 *   onCancel={handleCancel}
 *   width=300
 * >
 *   <p>Custom message or content</p>
 * </CustomConfirmationModal>
 */

import React from 'react';
import { Modal } from 'react-native';

const CustomConfirmationModal = ({ visible, onConfirm, onCancel, message, children, width }) => {
  const styles = {
    // Main container to center the modal and apply background color
    container: {
      flex: 1, // Take up the full screen
      justifyContent: 'center', // Center vertically
      alignItems: 'center', // Center horizontally
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
    },

    // Modal styling (popup box)
    modal: {
      width: width ? width : 300, // Fixed width
      padding: 20, // Padding around content
      backgroundColor: 'white', // White background for modal
      borderRadius: 10, // Rounded corners
      alignItems: 'center', // Center content horizontally
      elevation: 5, // Elevation for shadow (Android)
      shadowColor: '#000', // Shadow color (iOS)
      shadowOffset: { width: 0, height: 2 }, // Shadow offset (iOS)
      shadowOpacity: 0.3, // Shadow opacity (iOS)
      shadowRadius: 4, // Shadow blur radius (iOS),
      justifyContent: 'space-between',
      rowGap: 10
    },

    // Message styling inside the modal
    message: {
      fontSize: 18, // Font size for the message text
      marginBottom: 20, // Space below the message
      color: 'black', // p color
      fontFamily: 'Lora-SemiBold', // Custom font family for the message text
      textAlign: 'center', // Center text alignment
    },

    // Button container to arrange buttons horizontally
    buttonContainer: {
      flexDirection: 'row', // Arrange buttons in a row
      justifyContent: 'space-between', // Space between buttons
      width: '100%', // Full width of modal
    },

    // Base button styling
    button: {
      padding: 10, // Padding inside the button
      borderRadius: 5, // Rounded corners
      backgroundColor: '#ccc', // Default button background color
      flex: 1, // Take up equal space for both buttons
      margin: 5, // Small margin between buttons
    },

    // Confirm button styling (Overrides base button style)
    confirmButton: {
      backgroundColor: '#007bff', // Blue background for the confirm button
    },

    // Button text styling
    buttonText: {
      color: 'white', // White text color
      fontSize: 16, // Font size for button text
      textAlign: 'center', // Center text horizontally
    },
  };

  return (
    <Modal
      transparent={true}  // Make the modal's background semi-transparent
      visible={visible}    // Show/hide modal based on the 'visible' prop
      animationType="fade" // Use fade effect for the modal animation
      onRequestClose={onCancel}  // Call onCancel when the user requests to close the modal (Android back button)
    >
      <div style={styles.container}>
        <div style={styles.modal}>
          {/* Render the message if provided, otherwise render children */}
          {message ? (
            <p style={styles.message}>{message}</p>
          ) : (
            children
          )}

          {/* Button container with "Cancel" and "Sure" buttons */}
          <div style={styles.buttonContainer}>
            {/* Cancel Button */}
            <div style={styles.button} onClick={onCancel}>
              <p style={styles.buttonText}>Cancel</p>
            </div>

            {/* Confirm Button */}
            {
              onConfirm && <div style={{...styles.button, ...styles.confirmButton}} onClick={onConfirm}>
              <p style={styles.buttonText}>Sure</p>
            </div>
            }
          </div>
        </div>
      </div>
    </Modal>
  );
};


export default CustomConfirmationModal;