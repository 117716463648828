import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import useScreenSizes from '../../utils/useScreenSize';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Typing animation function
const useTypingEffect = (text, speed = 50, pause = 500) => {
  const [displayedText, setDisplayedText] = useState('');
  const [index, setIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    let timer;
    if (isDeleting) {
      if (index > 0) {
        timer = setTimeout(() => {
          setDisplayedText((prev) => prev.slice(0, -1));
          setIndex((prev) => prev - 1);
        }, speed);
      } else {
        setIsDeleting(false);
        timer = setTimeout(() => {
          setIndex(0);
        }, pause);
      }
    } else {
      if (index < text.length) {
        timer = setTimeout(() => {
          setDisplayedText((prev) => prev + text[index]);
          setIndex((prev) => prev + 1);
        }, speed);
      } else {
        timer = setTimeout(() => {
          setIsDeleting(true);
        }, pause);
      }
    }

    return () => clearTimeout(timer);
  }, [index, isDeleting, text, speed]);

  return displayedText;
};

const AnimatedList = ({ items }) => {
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const text = useTypingEffect(items[currentItemIndex]);
  const screenType = useSelector((state) => state.website.screenType);
  
const styles = {
  listContainer: {
    display: 'flex',
    alignItems: 'center', // Align items vertically in the center
    gap: '20px', // Space between fixed text and animated items
  },
  fixedText: {
    fontSize: screenType==="tab" ? '20px' : screenType==="phone" ? '18px' :screenType==="smallPhone"? "18px": '34px',
    fontFamily: 'raleway', // Font for fixed text
    color: 'black', // Fixed text color
  },
  animatedTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
  },
  listItem: {
    fontSize: screenType==="tab" ? '15px' : screenType==="phone" ? '12px' :screenType==="smallPhone"? "12px" : '34px',
    fontFamily: 'raleway', // Font for animated items
    color: 'red', // Animated text color
  },
};
  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentItemIndex((prev) => (prev + 1) % items.length); // Cycle through items
    }, 500); // Delay between items
    return () => clearTimeout(timer);
  }, [text, items.length]);
const { t } = useTranslation();
  return (
    <div style={styles.listContainer}>
      <div style={styles.animatedTextContainer}>
      
        <motion.div
          style={styles.listItem}
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.5 }}
        >
          {text}
        </motion.div>
      </div>
      <div style={styles.fixedText}>{t("Welcome to Study Buddy")}</div>
    </div>
  );
};

export default AnimatedList;
