import React, { useState } from "react";
import { motion } from "framer-motion";
import zIndex from "@mui/material/styles/zIndex";

const ItemContainer = ({ data, itemsPerRow, func, title, subTitle, defaultCount }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleViewAll = () => {
    setIsExpanded(!isExpanded);
    setCurrentIndex(0); // Reset to first slide when toggling
  };

  const totalSlides = Math.ceil(data.length / defaultCount);

  const goToSlide = (index) => {
    if (index >= 0 && index < totalSlides) {
      setCurrentIndex(index);
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.content}>
        {title && (
          <div style={styles.header}>
            <div>
              <h2 style={styles.title}>{title}</h2>
              {subTitle && <p style={styles.subtitle}>{subTitle}</p>}
            </div>
            {data.length > defaultCount && (
              <motion.button
                style={styles.viewAllButton}
                onClick={handleViewAll}
                whileTap={{ scale: 0.95 }}
              >
                {isExpanded ? "View Less" : "View All"}
              </motion.button>
            )}
          </div>
        )}

        {/* Carousel Section */}
        <div style={styles.carouselWrapper}>
          {/* Previous Button */}
          {isExpanded && currentIndex > 0 && (
            <button style={styles.prevButton} onClick={() => goToSlide(currentIndex - 1)}>
              ❮
            </button>
          )}

          <motion.div
            style={{ ...styles.carousel, transform: `translateX(-${currentIndex * 100}%)` }}
            transition={{ type: "spring", stiffness: 200 }}
          >
            {Array.from({ length: totalSlides }, (_, i) => (
              <div key={i} style={styles.slide}>
                {data.slice(i * defaultCount, (i + 1) * defaultCount).map((item, index) => (
                  <div
                    key={index}
                    style={{ ...styles.item, width: `${100 / itemsPerRow}%` }}
                    onClick={() => func(item)}
                  >
                    <img src={item.path} alt={item.name} style={styles.itemImage} />
                    <p style={styles.itemText}>{item.name}</p>
                  </div>
                ))}
              </div>
            ))}
          </motion.div>

          {/* Next Button */}
          {isExpanded && currentIndex < totalSlides - 1 && (
            <button style={styles.nextButton} onClick={() => goToSlide(currentIndex + 1)}>
              ❯
            </button>
          )}
        </div>

        {/* Navigation Controls */}
        {isExpanded && (
          <div style={styles.controls}>
            <div style={styles.dots}>
              {Array.from({ length: totalSlides }, (_, i) => (
                <span
                  key={i}
                  onClick={() => goToSlide(i)}
                  style={{
                    ...styles.dot,
                    backgroundColor: currentIndex === i ? "#4763bf" : "#ccc",
                  }}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  container: {
    width: "400px",
    backgroundColor: "#fff",
    borderRadius: 10,
    padding: 20,
    overflow: "hidden",
    position: "relative",
  },
  content: {
    margin: "10px 0",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontSize: 20,
    color: "#000",
    margin: 0,
  },
  subtitle: {
    fontSize: 14,
    color: "#666",
  },
  viewAllButton: {
    backgroundColor: "#4763bf",
    color: "#fff",
    border: "none",
    borderRadius: 5,
    padding: "5px 10px",
    cursor: "pointer",
    fontSize: 16,
  },
  carouselWrapper: {
    overflow: "hidden",
    width: "100%",
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  carousel: {
    display: "flex",
    transition: "transform 0.5s ease-in-out",
    width: "100%",
  },
  slide: {
    minWidth: "100%",
    display: "flex",
    flexWrap: "wrap",
    gap: "10px",
    justifyContent: "center",
  },
  item: {
    textAlign: "center",
    padding: 10,
    position: "relative",
  },
  itemImage: {
    maxWidth: "80%",
    maxHeight: 50,
    objectFit: "contain",
    margin: "0 auto",
    cursor: "pointer",
  },
  itemText: {
    fontSize: 10,
    color: "black",
    margin: "5px 0",
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 10,
  },
  dots: {
    display: "flex",
    gap: "5px",
  },
  dot: {
    width: 10,
    height: 10,
    borderRadius: "50%",
    backgroundColor: "#ccc",
    cursor: "pointer",
  },
  prevButton: {
    position: "absolute",
    left: 0,
    top: "50%",
    transform: "translateY(-50%)",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    color: "#fff",
    zIndex:100,
    border: "none",
    borderRadius: "50%",
    // padding: "10px",
    fontSize: "20px",
    cursor: "pointer",
  },
  nextButton: {
    position: "absolute",
    right: 0,
    top: "50%",
    transform: "translateY(-50%)",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    color: "#fff",
    border: "none",
    borderRadius: "50%",
    // padding: "10px",
    fontSize: "20px",
    cursor: "pointer",
  },
};

export default ItemContainer;
