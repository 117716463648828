import React, { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../../firebase";
import { algoliasearch } from "algoliasearch";
import PostCard from "./PostCard";

import RouterError from "./Error";
import FeaturedBlog from "./FeaturedBlogs";

// Initialize Algolia search client
const searchClient = algoliasearch('6J12A4VQ29', 'c8eaef42a89fb332edfa889426c45176');
const BlogCategoryList = () => {
    const [categories, setCategories] = useState([]);
    const [blogsData, setBlogsData] = useState({});
    const[data, setData]= useState()
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [filter, setFilter] = useState()
      const [textScale, setTextScale] = useState(1); // State for text scale
      const [isLoading, setIsLoading] = useState(true);
      const [hasError, setHasError] = useState(false);
    const [ dataExist,setDataExist] = useState()
    // Fetch categories from Firestore
    const fetchCategories = async () => {
        try {
            const querySnapshot = await getDocs(collection(firestore, "blogsCategory"));
            const categoryList = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setCategories(categoryList);
            return categoryList;
        } catch (err) {
            setError(true);
            return [];
        }
    };

    // Fetch blogs from Algolia based on object IDs
    const fetchBlogsFromAlgolia = async (objectIDArray) => {
      if (!objectIDArray || objectIDArray.length === 0) {
          return [];
      }
  
      const objectIDFilter = objectIDArray[0]; // Assuming you're working with the first filter
      delete objectIDFilter['id'];
  
      const categories = Object.keys(objectIDFilter);  // Extract category names from objectIDFilter
  
      let allResults = {};  // To store all results from different searches
  
      // Function to fetch data using getObjects method
      const fetchAlgoliaData = async (idsArray, indexName) => {
          // const index = searchClient.initIndex(indexName);
          const chunkSize = 1000; // Maximum allowed by Algolia for getObjects
          const chunks = [];
  
          // Break idsArray into chunks of size chunkSize
          for (let i = 0; i < idsArray.length; i += chunkSize) {
              chunks.push(idsArray.slice(i, i + chunkSize));
          }
  
          const results = await Promise.all(
            chunks.map(async (chunk) => {
                return searchClient.getObjects({
                    requests: chunk.map(id => ({
                        objectID: id,
                        indexName: "Blogs"
                    }))
                });
            })
        );

        const hits = results.flatMap(res => res.results.filter(Boolean));
        return hits;
      };
  
      // Loop through categories and fetch data for each category
      for (const category of categories) {
          const ids = objectIDFilter[category];
          
          try {
              // Fetch data for the current category using getObjects
              const hits = await fetchAlgoliaData(ids, 'Blogs'); // Assuming "Blogs" is the index name
              allResults[category] = hits;
          } catch (err) {
              console.error(`Error fetching data for category: ${category}`, err);
              setError(true);
          }
      }
  
      setData(allResults);
  };
  

    const styles =(textScale, screenType) =>( {
        container: {
          width: "100%",
          display: "flex",
         //  flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
          margin: "2vw auto",
        },
        container2 :{
            width: "100%",
            display: "flex",
           //  flexWrap: "wrap",
            justifyContent: "center",
            flexDirection:"column",
            gap: "20px",
            margin: "2vw auto",
        } ,
        blogsHeader: {
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "20vh",
        },
        blogsText: {
          fontSize: "2rem",
          fontWeight: "bold",
          color: "#333",
          textAlign: "center",
          letterSpacing: "2px",
        },
        post: {
          width:  "400px",
          height: "600px",
          display: "flex",
          borderRadius: "10px",
          overflow: "hidden",
          flexDirection: "column",
          backgroundColor: "white",
          position: "relative",
          transition: "transform 0.2s",
        },
        headerPost: {
          width: "100%",
          background: "#ddd",
          position: "absolute",
          top: 0,
          transition: "margin-top 0.2s",
        },
        headerPostImage: {
          width: "100%",
          height: "100%",
          transition: "transform 0.6s",
        },
        bodyPost: {
          width: "100%",
          height: `${textScale * 400}px`,
          background: "#fff",
          position: "absolute",
          bottom: 0,
          display: "flex",
          justifyContent: "center",
          // alignItems: "center",
          transition: "height 0.2s",
          cursor: "pointer",
        },
        postContent: {
          width: "80%",
          height: "80%",
          background: "#fff",
          position: "relative",
        },
        title: {
          fontSize: "15px",
          fontWeight: "bold",
          margin:0
        },
        paragraph: {
          fontSize: "14px",
          fontWeight: "normal",
        },
        containerInfos: {
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          position: "absolute",
          borderTop: "1px solid rgba(0, 0, 0, 0.2)",
          paddingTop: "25px",
        },
        postedBy: {
          display: "flex",
          flexDirection: "column",
          textTransform: "uppercase",
          letterSpacing: "1px",
          fontSize: "12px",
        },
        containerTags: {
          display: "flex",
          flexDirection: "column",
        },
        span: {
          fontSize: "12px",
          textTransform: "uppercase",
          opacity: 0.5,
          letterSpacing: "1px",
          fontWeight: "bold",
        },
        tagsList: {
          display: "flex",
        },
        tagItem: {
          fontSize: "12px",
          letterSpacing: "2px",
          listStyle: "none",
          marginLeft: "8px",
          textTransform: "uppercase",
          position: "relative",
          zIndex: 1,
          display: "flex",
          justifyContent: "center",
          cursor: "pointer",
        },
        detailContainer: {
          maxWidth: "800px",
          padding: "20px",
          backgroundColor: "#fff",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
          fontFamily: "Arial, sans-serif",
        },
        backButton: {
          fontSize: "16px",
          color: "#007BFF",
          border: "none",
          backgroundColor: "transparent",
          cursor: "pointer",
          marginBottom: "20px",
          display: "inline-flex",
          alignItems: "center",
        },
        detailImage: {
          width: "100%",
          height: "auto",
          borderRadius: "8px",
          marginBottom: "20px",
        },
        detailTitle: {
          fontSize: "2rem",
          fontWeight: "bold",
          color: "#333",
          marginBottom: "20px",
       
        },
        detailContent: {
          fontSize: "1rem",
          lineHeight: "1.6",
          color: "#555",
        },
        categoryContainer: {
          marginTop: "20px",
          fontSize: "1.1rem",
        },
        categoryLabel: {
          fontWeight: "bold",
          color: "#333",
        },
        categoryText: {
          fontStyle: "italic",
          color: "#007BFF",
        },
        buttonContainer: {
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '20px',
        },
        button: {
          padding: '10px 20px',
          margin: '0 10px',
          borderRadius: '5px',
          border: 'none',
          backgroundColor: '#007bff',
          color: '#fff',
          cursor: 'pointer',
        },
        errorCard: {
         width: "40%",

         height: "300px",
         display: "flex",
         justifyContent: "center",
         alignItems: "center",
         backgroundColor: "white",
         borderRadius: "10px",
         color: "#d32f2f",
         fontWeight: "bold",
         fontSize: "1.5rem",
       },
       skeletonLoader: {
        flex:"0 0 auto",
         width: "300px",
         height: "600px",
         display: "flex",
        //  flex: screenType==="phone"||screenType==="smallPhone"? "0 0 75%" :  "0 0 35%",
         flexDirection: "column",
         backgroundColor: "#f0f0f0",
         borderRadius: "10px",
         position: "relative",
         overflow: "hidden",
       },
       skeletonImage: {
         width: "100%",
         height: "300px",
         backgroundColor: "#e0e0e0",
       },
       skeletonText: {
         width: "80%",
         height: "20px",
         backgroundColor: "#e0e0e0",
         marginBottom: "10px",
       },
       button: {
         padding: '10px 20px',
         margin: '0 10px',
         borderRadius: '5px',
         border: 'none',
         backgroundColor: '#007bff',
         color: '#fff',
         cursor: 'pointer',
       },
       });
    const SkeletonLoader = () => {
        return (
          <div style={styles().skeletonLoader}>
            <div style={styles().skeletonImage}></div>
            <div style={styles().skeletonText}></div>
            <div style={styles().skeletonText}></div>
            <div style={styles().skeletonText}></div>
          </div>
        );
      };
      
      const ErrorCard = ({ reloadPage }) => {
        return (
          <div >
      
          <div style={styles().errorCard}>
            Please Try Again! 
            <button onClick={reloadPage} style={styles().button}>
              Reload
            </button>
          </div>
          </div>
        );
      };


    const fetchData = async () => {
        setLoading(true);
        try {
            const categoriesData = await fetchCategories();

            // Logging categories data for debugging
            const blogsPromises = categoriesData.map(async (category) => {

                const objectIDArrays = categoriesData
                // const objectIDArrays = Object.values(category); // Dynamically get values from the category object


                if (objectIDArrays && objectIDArrays.length > 0) {
                    const blogs = await fetchBlogsFromAlgolia(objectIDArrays);
                    return { [category.id]: blogs };
                }

                return { [category.id]: [] };
            });

            const blogsResults = await Promise.all(blogsPromises);

            const blogsData = blogsResults.reduce((acc, curr) => ({ ...acc, ...curr }), {});
            setBlogsData(blogsData);

        } catch (err) {
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    if (loading) { return(
        <div style={styles(textScale).container}>
        {Array(5).fill(null).map((_, index) => (
          <SkeletonLoader key={index} />
        ))} 

      </div>
    )
    
    }

    if (error) {
        return(
            <div style={{height:"100%", display:"flex", width:"100%", justifyContent:"center", alignItems:"center", boxShadow:  "0 4px 8px rgba(0, 0, 0, 0.1)",}}>
    
              <RouterError/>
            </div>
        );
    }
    
    const { featuredBlogs, ...remainingData } = data;

    

    return (
        <>
{ data ?  (
    <>
    <div style={{display:"flex", flexDirection:"column"}}>

    <FeaturedBlog  posts={featuredBlogs} />
    <div style={styles().container2}>
                {Object.entries(remainingData).map(([category, blogs]) => (
                 
                    <div key={category} style={{ marginBottom: '40px' , textTransform:"capitalize", padding:"0px 10px"  }}>
                         <h2 style={{ fontSize: '24px', fontWeight: 'bold', color: '#333', lineHeight: '1.5', textAlign: 'left' , }}>
    {category}
  </h2>
                        <div style={{ display: 'flex' , overflowX:"scroll", overflowY:"hidden",  }}>
                            {blogs?.map((blog) => (
                                <PostCard key={blog.objectID} post={blog} textScale={textScale} />
                            ))}
                        </div>
                    </div>
                ))}
            </div>
    </div>
</>
) : 

<RouterError/>
     
}
      
          </>
    );
};

export default BlogCategoryList;
