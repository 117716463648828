import React, { useState, useEffect, useRef } from "react";
import { Box, Typography } from "@mui/material";
import ThemeDashboardCard from "../../Components/test/ThemeDashboard";
import { useSelector } from "react-redux";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../../firebase";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import useScreenSizes from "../../utils/useScreenSize";
import ItemContainer from "../../Components/test/itemContainer";
import { borderRadius } from "@mui/system";

function LMS({source}) {
  const screen = useScreenSizes();
  const screenType = useSelector((state) => state.website.screenType);
  const styles = {
    container: {
      display: "flex",
      flexDirection: (screenType === "smallPhone" || screenType === "phone" || (screen >= 450 && screen <= 890))
        ? "column"
        : "row",
      height: "100%",
      paddingTop: "20px",
      gap: screenType === "smallPhone" || screenType === "phone" ? "" : "20px",
      overflowY: "scroll",
      width: "95%",
    },
    section: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      gap: "20px",
      borderRadius: "10px",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      backgroundColor: "#f9f9f9",
    },
    academicSection: {
      flexDirection: "column",
      display: "flex",
      flex: 2,
      flexShrink: 2,
      padding: "10px 10px",
    },
    financeSection: {
      flexDirection: "column",
      display: "flex",
      flex: 1,
      flexShrink: 1,
      padding: "10px 20px",
      marginLeft: "20px"
    },
    cardWrapper: {
      // flexBasis: "calc(50% - 20px)", // Ensures two cards per row
    },
    noticeSection: {
      width: "95%",
      padding: "20px",
      backgroundColor: "#ffecb3",
      borderRadius: "10px",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    },
    notificationBox: {
      width: "95%",
      margin: "2vw auto",
      padding: "20px",
      display: "flex",
      backgroundColor: "#ffecb3",
      borderRadius: "10px",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    },
    notificationWrapper: {
      position: "relative",
      height: "140px",
      overflow: "hidden",
    },
    notificationSlider: {
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      width: "95%",
      animation: `scrollup 15s linear infinite`, // Infinite scrolling animation
    },
    notificationItem: {
      padding: "12px",
      marginBottom: "10px",
      backgroundColor: "#e9f5ff", // Light blue background for the notification items
      borderRadius: "8px",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      fontSize: "1rem",
      color: "#333",
      transition: "background-color 0.3s ease, transform 0.3s ease",
    },
    notificationItemHover: {
      backgroundColor: "#d4e8ff", // Slightly darker blue on hover
      transform: "translateY(-5px)", // Lift effect on hover
    },
    title: {
      fontSize: "1.25rem",
      fontWeight: "bold",
      marginBottom: "1rem",
      color: "#333",
      margin: "5px"
    },
    container: {
      position: "relative",
      width: "100%",
      overflow: "hidden",
      justifyContent: "space-evenly",
      display: "flex",
      flexWrap: "wrap",
      margin: "2vw auto"
    },
    
    tickerContainer: {
      position: "relative",
      flex:1,
  display:"flex",
  flexDirection:"column",
      overflow: "hidden",
      borderRadius:"10px 0px 0px 10px",
      width:"100%",
      whiteSpace: "nowrap",
    
    },
    scrollingWrapper: {
      display: "flex",
      width: "100%"
    },
    newsItem: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      minWidth: "300px",
      padding: "10px",
      color: "white"
    },
    image: {
      width: "100px",
      height: "100px",
      objectFit: "cover"
    },
    headline: {
      fontSize: "16px",
      textAlign: "center"
    },
    carousel: {
      display: "flex",
      gap: "16px",
      width: "100%",
    },
    newsItem: {
      width: screenType==="phone"||screenType==="smallPhone" ? "100%" : "40%",
      display: "flex",
      flexDirection: "column",
      backgroundColor:"white",
      borderRadius:"10px",
      marginLeft:"5px",
      padding:"5px 20px",
      flexShrink: 0,
    },
    image: {
      width: "100%",
      height: "256px",
      objectFit: "cover",
      borderRadius: "8px",
    },
    headline: {
      textAlign: "center",
      fontSize: "1.125rem",
      fontWeight: "bold",
      marginTop: "8px",
    },
  };

  const data = [
    {
      path: require("../../assets/images/admin.webp"),  // Image URL
      name: "Item 1",  // Item name
      tag: true,  // Optional: "Coming Soon" tag
    },
    {
      path: require("../../assets/images/2nd-leader.webp"),
      name: "Item 2",
    },
    {
      path: "https://via.placeholder.com/150",
      name: "Item 3",
    },
    {
      path: "https://via.placeholder.com/150",
      name: "Item 4",
      tag: true,  // Optional: "Coming Soon" tag
    },
    {
      path: "https://via.placeholder.com/150",
      name: "Item 5",
    },
    {
      path: "https://via.placeholder.com/150",
      name: "Item 6",
    },
    {
      path: "https://via.placeholder.com/150",
      name: "Item 7",
    },
    {
      path: "https://via.placeholder.com/150",
      name: "Item 8",
    },
    {
      path: "https://via.placeholder.com/150",
      name: "Item 8",
    },
    {
      path: "https://via.placeholder.com/150",
      name: "Item 8",
    },
    {
      path: "https://via.placeholder.com/150",
      name: "Item 8",
    },
    {
      path: "https://via.placeholder.com/150",
      name: "Item 8",
    },
    // Add more items as needed
  ];

  const newsData = [
    {
      id: 1,
      image: require("../../assets/images/Accurate Expense Tracking.webp"),
      headline: "Breaking News: Market Hits Record High",
    },
    {
      id: 2,
      image:require("../../assets/images/criticalthinking.webp"),
      headline: "Sports Update: Local Team Wins Championship",
    },
    {
      id: 3,
      image: "https://source.unsplash.com/random/300x200?technology",
      headline: "Technology: New Smartphone Released",
    },
    {
      id: 4,
      image: "https://source.unsplash.com/random/300x200?weather",
      headline: "Weather Alert: Storm Approaching Fast",
    },
  ];

  // Function to handle item click
  const handleItemClick = (item) => {
    console.log("Item clicked:", item);
  };

  // Sample notification array
  const [notifications, setNotifications] = useState([
    "Mid-term exams will start from March 10, 2024.",
    "Project submission deadline extended to March 20, 2024.",
    "Join the webinar on 'AI in Education' on March 15, 2024.",
    "New course on 'Data Science' is now available.",
    "Upcoming holiday: Spring Break on March 25, 2024.",
    "Submit feedback for the recent workshop by March 5, 2024.",
  ]);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [documentData, setDocumentData] = useState();
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % notifications.length);
    }, 2000);

    return () => clearInterval(interval);
  }, [notifications.length]);

  useEffect(() => {
    const fetchBatches = async () => {
      try {
        const batchCollection = collection(firestore, "batch");
        const batchSnapshot = await getDocs(batchCollection);
        const batchList = batchSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setDocumentData(batchList); // Save the documents in state
      } catch (error) {
      }
    };

    fetchBatches();
  }, []);
  const [presentPercentage, setPresentPercentage] = useState(0);

  useEffect(() => {
    if (documentData && Array.isArray(documentData) && documentData.length > 0) {
      // Initialize counters for aggregation
      let totalAbsent = 0;
      let totalStudents = 0;

      // Loop through all document data and aggregate values
      documentData.forEach((doc) => {
        if (doc.aggregation && doc.aggregation.discipline) {
          totalAbsent += doc.aggregation.discipline.absent || 0;  // Safely add absent students
          totalStudents += doc.aggregation.noOfStudents || 0;     // Safely add total students
        }
      });

      const totalPresent = totalStudents - totalAbsent;

      const presentPercentage = (totalPresent / totalStudents) * 100;
      setPresentPercentage(presentPercentage);

      // Log the aggregated results to the console
    } else {
    }
  }, [documentData]); // Dependency on documentData to trigger when it changes
  const { t } = useTranslation();

  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % newsData.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);
  const notification = [
    { task: "Complete project report", notes: "Submit by Friday" },
    { task: "Review code changes", notes: "Check pull request #42" }
  ];

  const scrollRef = useRef(null);
  const [scrollWidth, setScrollWidth] = useState(0);

  useEffect(() => {
    if (scrollRef.current) {
      const containerWidth = scrollRef.current.scrollWidth / 2; // Since we duplicate the data
      setScrollWidth(containerWidth);
    }
  }, [newsData]);
  const quotes = [
    "🌟 The future belongs to those who believe in the beauty of their dreams. — Eleanor Roosevelt",
    "📖 Education is the passport to the future, for tomorrow belongs to those who prepare for it today. — Malcolm X",
    "🚀 Success is not final, failure is not fatal: It is the courage to continue that counts. — Winston Churchill",
    "📚 The beautiful thing about learning is that no one can take it away from you. — B.B. King",
    "🔥 Believe you can and you're halfway there. — Theodore Roosevelt"
  ];

  const [quoteIndex, setQuoteIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setQuoteIndex((prevIndex) => (prevIndex + 1) % quotes.length);
    }, 5000); // Change quote every 5 seconds

    return () => clearInterval(interval);
  }, []);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);
  return (
    <div style={{ width: "95%", margin: "2vw auto" }}>

      <style>
        {`
          @keyframes scrollup {
            0% { transform: translateY(0); }
            100% { transform: translateY(-${notifications.length * 49.5}px); }
          }
        `}
      
      </style>

      <div>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", flexDirection: "column", padding: "20px", borderRadius: "10px", boxShadow: "20px 20px 25px rgba(0, 0, 0, 0.3)", backgroundColor: "white" }}>

            <Box sx={{ textAlign: "center", marginBottom: 4 }}>
              <Typography
                variant="h2"
                component="h1"
                sx={{ fontWeight: "bold", color: "#3b3d4b" }}
              >
                Welcome to StudyBuddy
              </Typography>
              <Typography variant="h6" sx={{ color: "#37474f" }}>
                Your ultimate learning companion
              </Typography>
            </Box>
          </div>

          <div style={styles.notificationBox}>
            <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>

              <h5 style={styles.title}>📢 {t("Important Notices")}</h5>
              <div style={styles.notificationWrapper}>
                <div style={{
                  ...styles.notificationSlider,
                }}>
                  {notifications.concat(notifications).map((notification, index) => (
                    <div key={index}   style={{ ...styles.notificationItem, ...(isHovered ? styles.notificationItemHover : {}) }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}>
                      {t(notification)}
                    </div>
                  ))}
                </div>

              </div>


            </div>
            <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
              <h5 style={styles.title}>📢 {t("Upcoming Notices")}</h5>
              <div style={styles.notificationWrapper}>
                <div style={styles.notificationSlider}>
                  {notification.concat(notification).map((notification, index) => (
                    <div key={index} style={styles.notificationItem}>
                      {/* <strong>{t("Pending Task")}: </strong> {t(notification.task)} <br /> */}
                      <strong>{t("Pending Notes")}: </strong> {t(notification.notes)}
                    </div>
                  ))}
                </div>

              </div>
            </div>
          </div>
          <div style={styles.container}>
            <div style={{ ...styles.section, ...styles.academicSection }}>
              <div>

                <h2 style={{ ...styles.heading, }}>{t("Academic Session")}</h2>
              </div>
              <div style={{ flexWrap: "wrap", gap: "20px", display: "flex", justifyContent: "space-evenly", }}>
                <div style={styles.cardWrapper}>
                  <ThemeDashboardCard fill={presentPercentage} />
                </div>
                <div style={styles.cardWrapper}>
                  <ThemeDashboardCard />
                </div>
                <div style={styles.cardWrapper}>
                  <ThemeDashboardCard />
                </div>
                <div style={styles.cardWrapper}>
                  <ThemeDashboardCard />
                </div>
              </div>
            </div>

            {/* Finance Details Section */}
            <div style={{ ...styles.section, ...styles.financeSection }}>
              <div>

                <h2 style={styles.heading}>{t("Finance Session")}</h2>
              </div>
              <div style={{ gap: "20px", justifyContent: "center", display: "flex", flexWrap: "wrap" }}>

                <div style={styles.cardWrapper}>
                  <ThemeDashboardCard />
                </div>
                <div style={styles.cardWrapper}>
                  <ThemeDashboardCard />
                </div>
              </div>
            </div>
          </div>

          <div style={{ display: "flex", width: "100%", flex: 1, flexWrap: "wrap" , justifyContent:"space-evenly", margin:"2vw auto", flexDirection: screenType==="phone"||screenType==="smallPhone" ? "column"  :"row"}}>
            <div style={styles.tickerContainer}>
              <div
                ref={scrollRef}
                style={{
                  ...styles.scrollingWrapper,
                  animation: `scroll ${newsData.length * 3}s linear infinite`
                }}
              >
                {[...newsData, ...newsData].map((news, index) => (
                  <div key={index} style={styles.newsItem}>
                    <h2 style={styles.headline}>{news.headline}</h2>
                    <img src={news.image} alt={news.headline} style={styles.image} />
                    <div style={{ display:"flex", alignItems:"center" , width:"40%"}} >
                      <img src={require("../../assets/images/link.webp")} style={{ height:"20px", width:"auto"}} />
                    <p style={ { margin:"10px 10px"}}>
                      source: {source||"DD Tv"}
                    </p>
                    </div>
                  </div>
                ))}
              </div>

              <style>
                {`
          @keyframes scroll {
            0% { transform: translateX(0); }
            100% { transform: translateX(-${scrollWidth}px); }
          }
        `}
              </style>
            </div>


          </div>
            <div style={{
  // width: "50%",
  // flex:1,
  height:screenType==="desktop"||screenType==="tablet" ?  "40vh":"",
  display:"flex",
  
  flexDirection: screenType==="phone"||screenType==="smallPhone" ?    "column":"row",
  background: "linear-gradient(135deg, #f3f4f6, #e0e7ff)",
  padding: "20px",
  borderRadius: "12px",
  textAlign: "center",
  fontSize: "16px",
  // height:"30px",
  color: "#333",
  fontWeight: "bold",
  boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",
  marginLeft: screenType==="phone"||screenType==="smallPhone" ? "0px": "10px",
  transition: "transform 0.3s, box-shadow 0.3s",
}}

>
  <div style={{display:"flex", justifyContent:"center", alignItems: screenType==="phone"||screenType==="smallPhone" ? "" : "center", flexDirection:"column" , flex:1 }}>
<img src= {require("../../assets/images/Achievement.png")} style={{ height:"50%", width:"auto"}} />
  <h2 style={{
    marginBottom: "20px",
    color: "#4B6DFF",
    fontSize: "22px",
    fontFamily: "Arial, sans-serif",
    textTransform: "uppercase"
  }}>
    Achievements
  </h2>
  </div>

  <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" , width:"100%",  margin:"2vw auto", flex:1 , overflowX:"scroll" , columnGap:20 }}>
    <div style={{
      textAlign: "center",
      background: "#ffffff",
      borderRadius: "8px",
      padding: "15px",
      boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
      width: "25%",
      transition: "transform 0.3s, box-shadow 0.3s"
    }}
      onMouseEnter={(e) => e.currentTarget.style.transform = "scale(1.05)"}
      onMouseLeave={(e) => e.currentTarget.style.transform = "scale(1)"}
    >
      <span style={{ fontSize: "40px", color: "#FFD700" }}>🏆</span>
      <p style={{ marginTop: "10px", fontSize: "14px", color: "#555" }}>Completed 10 Challenges</p>
    </div>

    <div style={{
      textAlign: "center",
      background: "#ffffff",
      borderRadius: "8px",
      padding: "15px",
      boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
      width: "25%",
      transition: "transform 0.3s, box-shadow 0.3s"
    }}
      onMouseEnter={(e) => e.currentTarget.style.transform = "scale(1.05)"}
      onMouseLeave={(e) => e.currentTarget.style.transform = "scale(1)"}
    >
      <span style={{ fontSize: "40px", color: "#FFD700" }}>🥇</span>
      <p style={{ marginTop: "10px", fontSize: "14px", color: "#555" }}>Achieved First Place</p>
    </div>
    <div style={{
      textAlign: "center",
      background: "#ffffff",
      borderRadius: "8px",
      padding: "15px",
      boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
      width: "25%",
      transition: "transform 0.3s, box-shadow 0.3s"
    }}
      onMouseEnter={(e) => e.currentTarget.style.transform = "scale(1.05)"}
      onMouseLeave={(e) => e.currentTarget.style.transform = "scale(1)"}
    >
      <span style={{ fontSize: "40px", color: "#FFD700" }}>🥇</span>
      <p style={{ marginTop: "10px", fontSize: "14px", color: "#555" }}>Achieved First Place</p>
    </div>
    <div style={{
      textAlign: "center",
      background: "#ffffff",
      borderRadius: "8px",
      padding: "15px",
      boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
      width: "25%",
      transition: "transform 0.3s, box-shadow 0.3s"
    }}
      onMouseEnter={(e) => e.currentTarget.style.transform = "scale(1.05)"}
      onMouseLeave={(e) => e.currentTarget.style.transform = "scale(1)"}
    >
      <span style={{ fontSize: "40px", color: "#FFD700" }}>🥇</span>
      <p style={{ marginTop: "10px", fontSize: "14px", color: "#555" }}>Achieved First Place</p>
    </div>
    <div style={{
      textAlign: "center",
      background: "#ffffff",
      borderRadius: "8px",
      padding: "15px",
      boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
      width: "25%",
      transition: "transform 0.3s, box-shadow 0.3s"
    }}
      onMouseEnter={(e) => e.currentTarget.style.transform = "scale(1.05)"}
      onMouseLeave={(e) => e.currentTarget.style.transform = "scale(1)"}
    >
      <span style={{ fontSize: "40px", color: "#FFD700" }}>🥇</span>
      <p style={{ marginTop: "10px", fontSize: "14px", color: "#555" }}>Achieved First Place</p>
    </div>
    <div style={{
      textAlign: "center",
      background: "#ffffff",
      borderRadius: "8px",
      padding: "15px",
      boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
      width: "25%",
      transition: "transform 0.3s, box-shadow 0.3s"
    }}
      onMouseEnter={(e) => e.currentTarget.style.transform = "scale(1.05)"}
      onMouseLeave={(e) => e.currentTarget.style.transform = "scale(1)"}
    >
      <span style={{ fontSize: "40px", color: "#FFD700" }}>🥇</span>
      <p style={{ marginTop: "10px", fontSize: "14px", color: "#555" }}>Achieved First Place</p>
    </div>
    <div style={{
      textAlign: "center",
      background: "#ffffff",
      borderRadius: "8px",
      padding: "15px",
      boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
      width: "25%",
      transition: "transform 0.3s, box-shadow 0.3s"
    }}
      onMouseEnter={(e) => e.currentTarget.style.transform = "scale(1.05)"}
      onMouseLeave={(e) => e.currentTarget.style.transform = "scale(1)"}
    >
      <span style={{ fontSize: "40px", color: "#FFD700" }}>🥇</span>
      <p style={{ marginTop: "10px", fontSize: "14px", color: "#555" }}>Achieved First Place</p>
    </div>

    <div style={{
      textAlign: "center",
      background: "#ffffff",
      borderRadius: "8px",
      padding: "15px",
      boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
      width: "25%",
      transition: "transform 0.3s, box-shadow 0.3s"
    }}
      onMouseEnter={(e) => e.currentTarget.style.transform = "scale(1.05)"}
      onMouseLeave={(e) => e.currentTarget.style.transform = "scale(1)"}
    >
      <span style={{ fontSize: "40px", color: "#FFD700" }}>🥳</span>
      <p style={{ marginTop: "10px", fontSize: "14px", color: "#555" }}>winner </p>
    </div>
  </div>
</div>
          <div style={{
            width: "100%",
            flex: 1,
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap:screenType==="phone"||screenType==="smallPhone"? "wrap":"nowrap",
            overflowX:"scroll",
                marginTop:"20px",
            alignItems: "flex-start",
            columnGap:20
          }}>
            <div style={{display:"flex" }}>
        
            <ItemContainer
              data={data}
              itemsPerRow={4}
              func={handleItemClick}
              title="Item Gallery"
              subTitle="Explore the latest items"
              defaultCount={8}
            />
                  
                  </div>
                  <div style={{display:"flex" , }}>

                
            <ItemContainer
              data={data}
              itemsPerRow={4}
              func={handleItemClick}
              title="Item Gallery"
              subTitle="Explore the latest items"
              defaultCount={8}
            />
                
              </div>
              <div style={{display:"flex"}}>

            <ItemContainer
              data={data}
              itemsPerRow={4}
              func={handleItemClick}
              title="Item Gallery"
              subTitle="Explore the latest items"
              defaultCount={8}
            />
                
              </div>
              <div style={{display:"flex"}}>
            <ItemContainer
              data={data}
              itemsPerRow={4}
              func={handleItemClick}
              title="Item Gallery"
              subTitle="Explore the latest items"
              defaultCount={8}
            />
            </div>
            
          </div>
        </Box>
      </div>
    </div>
  );
}

export default LMS;