import React from 'react';

const styles = {
  card: {
    backgroundColor: 'white',

    borderRadius: '24px',
    padding: '2rem',
    width: '300px',
    display: 'flex',
    // backgroundImage: `url(${require("../../assets/images/ERORR.webp")})`,
    flexDirection: 'column' ,
    alignItems: 'center',
    textAlign: 'center' ,
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.05)',
    backgroundImage: `url(${require("../../assets/images/ERORR.webp")})`,
    backgroundSize: 'cover',  // Ensures the image covers the entire container
    backgroundPosition: 'top', // Aligns the image to the top of the container
    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
    height: '480px', // Set a specific height for the card
  },
  imageContainer: {
    width: '240px',
    height: '240px',
    marginBottom: '1.5rem',
    position: 'relative' ,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: '#1F2937',
    marginBottom: '0.5rem',
  },
  description: {
    fontSize: '1rem',
    color: '#9CA3AF',
    marginBottom: '1.5rem',
    lineHeight: '1.5',
  },
  button: {
    backgroundColor: '#6366F1',
    color: 'white',
    border: 'none',
    padding: '0.75rem 2.5rem',
    borderRadius: '9999px',
    fontSize: '0.875rem',
    fontWeight: '600',
    cursor: 'pointer',
    transition: 'all 0.2s ease',
    textTransform: 'uppercase' ,
  },
};
const handleLoad = ()=>{
    window.location.reload();
}
const RouterError = () => {
  return (
    <div style={{height:"100vh", width:"100vw", display:"flex", justifyContent:"center", alignContent:"center"}}>

    
    <div style={styles.card}>
      <div style={styles.imageContainer}>
        {/* <img 
        src={require("../../assets/images/ERORR.webp")}
         /> */}
      </div>
      <h2 style={styles.title}>Ohh...SH*T</h2>
      <p style={styles.description}>Looks like someone has stolen your router</p>
      <button
        style={styles.button}
        onMouseEnter={(e) => {
          e.currentTarget.style.backgroundColor = '#4F46E5';
          e.currentTarget.style.transform = 'translateY(-2px)';
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.backgroundColor = '#6366F1';
          e.currentTarget.style.transform = 'translateY(0)';
        }}
        onClick={handleLoad}
      >
        Retry
      </button>
    </div>
    </div>
  );
};

export default RouterError;