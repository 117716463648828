import React, { useState, useRef } from 'react';
import {
  User,
  Send,
  Image,
  Paperclip,
  Mic,
  Video,
  Smile,
  Plus,
  StopCircle,
  Circle,
  Timer
} from 'lucide-react';
import { borderColor } from '@mui/system';

const messages = [
  {
    id: 1,
    sender: 'John Doe',
    message: 'Hey, how are you?',
    time: '10:30 AM',
    unread: true,
  },
  {
    id: 2,
    sender: 'Jane Smith',
    message: 'Can we schedule a meeting for tomorrow?',
    time: 'Yesterday',
    unread: false,
  },
];

function MessagePanel() {
  const [selectedChat, setSelectedChat] = useState(null);
  const [messageText, setMessageText] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [isVideoRecording, setIsVideoRecording] = useState(false);
  const fileInputRef = useRef(null);
  const videoInputRef = useRef(null);

  const [attachments, setAttachments] = useState([]);
  const imageInputRef = useRef(null);

  const [recordingTime, setRecordingTime] = useState(0);
  const timerRef = useRef(null);
  const users = [
    { id: 1, name: 'Alice', avatar: require("../../../assets/images/330909630_3c91c898-559e-449d-b6c6-c75491511a74.webp") , online: true },
    { id: 2, name: 'Bob', avatar:  require("../../../assets/images/3rd-leader.webp"), online: false },
    { id: 3, name: 'Charlie', avatar:  require("../../../assets/images/330909630_3c91c898-559e-449d-b6c6-c75491511a74.webp"),online: true },
    { id: 4, name: 'David', avatar:  require("../../../assets/images/aComprehensiveCurriculum 1.webp") , online: false },
    { id: 5, name: 'Emma', avatar:  require("../../../assets/images/accountant.webp"), online: true },
  ];
  const handleSendMessage = () => {
    if (messageText.trim()) {
      setMessageText('');
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files?.[0];
    if (file) {
      // Here you would typically handle the file upload
      console.log('File selected:', file.name);
      // Reset the input
      event.target.value = '';
    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.type.startsWith('image/')) {
        // Here you would typically handle the image upload
        console.log('Image selected:', file.name);
      } else {
        alert('Please select an image file');
      }
      // Reset the input
      event.target.value = '';
    }
  };

  const startAudioRecording = () => {
    if (!isRecording) {
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then(stream => {
          setIsRecording(true);
          // Here you would typically start recording
          console.log('Started audio recording');
        })
        .catch(err => {
          console.error('Error accessing microphone:', err);
          alert('Could not access microphone');
        });
    } else {
      setIsRecording(false);
      // Here you would typically stop recording and handle the audio file
      console.log('Stopped audio recording');
    }
  };

  const startVideoRecording = () => {
    if (!isVideoRecording) {
      navigator.mediaDevices.getUserMedia({ video: true, audio: true })
        .then(stream => {
          setIsVideoRecording(true);
          // Here you would typically start recording
          console.log('Started video recording');
        })
        .catch(err => {
          console.error('Error accessing camera:', err);
          alert('Could not access camera');
        });
    } else {
      setIsVideoRecording(false);
      // Here you would typically stop recording and handle the video file
      console.log('Stopped video recording');
    }
  };
  const startRecording = (type) => {
    setRecordingTime(0);
    timerRef.current = setInterval(() => setRecordingTime(prev => prev + 1), 1000);

    if (type === 'audio') {
      setIsRecording(true);
    } else {
      setIsVideoRecording(true);
    }
  };

  const stopRecording = () => {
    clearInterval(timerRef.current);
    setIsRecording(false);
    setIsVideoRecording(false);
  };
  const buttonStyle = {
    padding: 'var(--spacing-sm)',
    borderRadius: 'var(--radius-full)',
    backgroundColor: 'var(--bg-hover)',
    color: 'var(--text-secondary)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 0.2s',
    borderColor:"transparent",
    
  };


  function RecordingUI({ type, recordingTime, onStop }) {
    return (
      <div style={{
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        background: '#1a1a1a', 
        color: 'white',
        padding: '15px', 
        borderRadius: '10px',
        position: 'absolute',
        bottom: '80px',
        width: '250px',
        textAlign: 'center',
        boxShadow: '0px 4px 10px rgba(0,0,0,0.3)'
      }}>
        <div style={{ fontSize: '14px', marginBottom: '5px' }}>
          {type === 'audio' ? 'Recording Audio...' : 'Recording Video...'}
        </div>
  
        <div style={{
          display: 'flex', 
          alignItems: 'center', 
          gap: '10px'
        }}>
          {type === 'audio' ? (
            <Mic color="red" size={32} />
          ) : (
            <Video color="red" size={32} />
          )}
          
          <div style={{
            fontSize: '18px', 
            fontWeight: 'bold', 
            background: '#333', 
            padding: '5px 15px', 
            borderRadius: '5px'
          }}>
            <Timer size={16} style={{ marginRight: '5px' }} />
            {Math.floor(recordingTime / 60)}:{String(recordingTime % 60).padStart(2, '0')}
          </div>
  
          <Circle size={12} color="red" className="blink-animation" />
        </div>
  
        <button 
          onClick={onStop}
          style={{
            marginTop: '10px',
            background: 'red',
            color: 'white',
            border: 'none',
            padding: '8px 12px',
            borderRadius: '5px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            gap: '5px'
          }}>
          <StopCircle size={18} /> Stop Recording
        </button>
  
        <style>
          {`
            @keyframes blink {
              0% { opacity: 1; }
              50% { opacity: 0; }
              100% { opacity: 1; }
            }
            .blink-animation {
              animation: blink 1s infinite;
            }
          `}
        </style>
      </div>
    );
  }
  if (selectedChat !== null) {
    return (
      <div style={{ 
        height: '100%', 
        display: 'flex', 
        flexDirection: 'column',
        // backgroundColor: 'var(--bg-main)',
      }}>
        
        {/* Hidden file inputs */}
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileUpload}
          style={{ display: 'none' }}
        />
        <input
          type="file"
          ref={imageInputRef}
          accept="image/*"
          onChange={handleImageUpload}
          style={{ display: 'none' }}
        />
        <input
          type="file"
          ref={videoInputRef}
          accept="video/*"
          onChange={handleFileUpload}
          style={{ display: 'none' }}
        />

    

      <div style={{ display: 'flex', gap: '10px', margin: '10px 0' }}>
        {attachments.map((att, index) => (
            <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            {att.type === 'image' && <Image size={16} />}
            {att.type === 'file' && <Paperclip size={16} />}
            <span>{att.name}</span>
          </div>
        ))}
        </div>
        <div style={{ 
            padding: 'var(--spacing-md)',
            borderBottom: '1px solid var(--border-color)',
        }}>
          <button
            onClick={() => setSelectedChat(null)}
            style={{
              padding: 'var(--spacing-sm) var(--spacing-md)',
              borderRadius: 'var(--radius-md)',
              backgroundColor: 'var(--bg-hover)',
              color: 'var(--text-primary)',
              marginBottom: 'var(--spacing-md)',
              fontWeight: 500,
              transition: 'all 0.2s',
              border:"transparent",
              
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = 'var(--border-color)';
            }}
            onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = 'var(--bg-hover)';
            }}
          >
            Back to Messages
          </button>
          <div style={{ display: 'flex', alignItems: 'center', gap: 'var(--spacing-sm)' }}>
            <div style={{ 
                padding: 'var(--spacing-sm)',
                borderRadius: 'var(--radius-full)',
                backgroundColor: 'var(--bg-hover)'
            }}>

              <User style={{ width: '24px', height: '24px' }} />
            </div>
            <div>
              <h3 style={{ 
                fontWeight: 500,
                color: 'var(--text-primary)'
              }}>
                {messages[selectedChat].sender}
              </h3>
              <span style={{ 
                  fontSize: '0.875rem',
                  color: 'var(--text-secondary)'
              }}>
                Active now
              </span>
            </div>
          </div>
        </div>

        {/* Chat Messages */}
        <div style={{ 
          flex: 1,
          overflowY: 'auto',
          padding: 'var(--spacing-md)'
        }}>
          {/* Messages will be rendered here */}
        </div>
        {isRecording && (
        <RecordingUI type="audio" recordingTime={recordingTime} onStop={stopRecording} />
      )}

      {isVideoRecording && (
        <RecordingUI type="video" recordingTime={recordingTime} onStop={stopRecording} />
      )}

      {/* <div style={{ display: 'flex', gap: '10px', marginTop: '20px' }}>
        <button onClick={() => startRecording('audio')} style={{ background: 'gray', color: 'white', padding: '10px', borderRadius: '5px' }}>
          <Mic /> Record Audio
        </button>

        <button onClick={() => startRecording('video')} style={{ background: 'gray', color: 'white', padding: '10px', borderRadius: '5px' }}>
          <Video /> Record Video
        </button>
      </div> */}
        {/* Message Input */}
        <div style={{ 
            borderTop: '1px solid var(--border-color)',
            padding: 'var(--spacing-md)'
        }}>
          <div style={{ 
            display: 'flex',
            alignItems: 'center',
            gap: 'var(--spacing-sm)',
            marginBottom: 'var(--spacing-sm)'
          }}>
            <button 
              style={buttonStyle}
              onClick={() => {
                // Handle additional actions menu
                console.log('Open actions menu');
              }}
            >
              <Plus style={{ width: '20px', height: '20px' }} />
            </button>
            <button 
              style={buttonStyle}
              onClick={() => imageInputRef.current?.click()}
            >
              <Image style={{ width: '20px', height: '20px' }} />
            </button>
            <button 
              style={buttonStyle}
              onClick={() => fileInputRef.current?.click()}
            >
              <Paperclip style={{ width: '20px', height: '20px' }} />
            </button>
            <button
              style={{
                ...buttonStyle,
                backgroundColor: isRecording ? 'var(--primary-light)' : 'var(--bg-hover)',
                color: isRecording ? 'white' : 'var(--text-secondary)',
              }}
              onClick={() => startRecording('audio')}
            >
              <Mic style={{ width: '20px', height: '20px' }} />
            </button>
            <button
              style={{
                ...buttonStyle,
                backgroundColor: isVideoRecording ? 'var(--primary-light)' : 'var(--bg-hover)',
                color: isVideoRecording ? 'white' : 'var(--text-secondary)',
              }}onClick={() => startRecording('video')} 
            >
              <Video style={{ width: '20px', height: '20px' }} />
            </button>
          </div>
          
          <div style={{ display: 'flex', gap: 'var(--spacing-sm)' }}>
            <div style={{ flex: 1, position: 'relative' }}>
              <textarea
                placeholder="Type a message..."
                value={messageText}
                onChange={(e) => setMessageText(e.target.value)}
                onClick={handleKeyPress}
                style={{
                //   width: '500%',
                flex:1,
                  padding: 'var(--spacing-md)',
                  paddingRight: 'calc(var(--spacing-md) + 40px)',
                  border: '1px solid var(--border-color)',
                  borderRadius: 'var(--radius-md)',
                  backgroundColor: 'var(--bg-main)',
                  color: 'var(--text-primary)',
                  resize: 'none',
                  outline: 'none',
                }}
                onFocus={(e) => {
                  e.target.style.borderColor = 'var(--border-active)';
                  e.target.style.boxShadow = 'var(--shadow-sm)';
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = 'var(--border-color)';
                  e.target.style.boxShadow = 'none';
                }}
                rows={1}
              />
              <button
                style={{
                  position: 'absolute',
                  right: '12px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  ...buttonStyle,
                }}
              >
                <Smile style={{ width: '20px', height: '20px' }} />
              </button>
            </div>
            <button
              onClick={handleSendMessage}
              disabled={!messageText.trim()}
              style={{
                padding: 'var(--spacing-sm) var(--spacing-md)',
                borderRadius: 'var(--radius-md)',
                backgroundColor: messageText.trim() ? 'var(--primary-color)' : 'var(--bg-hover)',
                color: messageText.trim() ? 'white' : 'var(--text-secondary)',
                transition: 'all 0.2s',
                opacity: messageText.trim() ? 1 : 0.7,
              }}
              onMouseEnter={(e) => {
                if (messageText.trim()) {
                  e.currentTarget.style.backgroundColor = 'var(--primary-dark)';
                }
              }}
              onMouseLeave={(e) => {
                if (messageText.trim()) {
                  e.currentTarget.style.backgroundColor = 'var(--primary-color)';
                }
              }}
            >
              <Send style={{ width: '20px', height: '20px' }} />
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div style={{ padding: 'var(--spacing-md)' }}>
      <h2 style={{ 
        fontSize: '1.125rem',
        fontWeight: 600,
        marginBottom: 'var(--spacing-md)',
        color: 'var(--text-primary)', 
        // backgroundColor:"red"
      }}>
        Messages
      </h2>
      <div style={{ display: 'flex', overflowX: 'auto', gap: '10px', padding: '10px 0' }}>
                      {users.map((user) => (
                        <div key={user.id} style={{   textAlign: 'center', position: 'relative' }}>
                          <img
                            src={user.avatar}
                            alt={user.name}
                            style={{ width: '50px', height: '50px', borderRadius: '50%', cursor: 'pointer',                 border: "2px solid var(--border-color)",
                            }}
                          />
                            {user.online && (
              <div style={{
                position: 'absolute',
                top: '5px',
                right: '5px',
                width: '10px',
                height: '10px',
                borderRadius: '50%',
                backgroundColor: 'green',
                border: '2px solid white'
              }}></div>
            )}
                          <p style={{ fontSize: '12px', marginTop: '5px' }}>{user.name}</p>
                        </div>
                      ))}
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        {messages.map((message, index) => {
          const sender = users.find((user) => user.name === message.sender);

          return (
            <div
              key={message.id}
              onClick={() => setSelectedChat(index)}
              style={{
                display: "flex",
                alignItems: "center",
                gap: "12px",
                padding: "var(--spacing-md)",
                borderRadius: "var(--radius-lg)",
                backgroundColor: message.unread
                  ? "var(--bg-message-unread)"
                  : "var(--bg-message-read)",
                cursor: "pointer",
                transition: "all 0.2s",
                boxShadow: "0px 2px 5px rgba(0,0,0,0.1)",
                border: "1px solid var(--border-color)",
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.backgroundColor = message.unread
                  ? "var(--bg-message-unread)"
                  : "var(--bg-hover)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.backgroundColor = message.unread
                  ? "var(--bg-message-unread)"
                  : "var(--bg-message-read)")
              }
            >
              {/* Sender Avatar */}
              <div style={{ position: "relative" }}>
                <img
                  src={sender?.avatar ||require( "../../../assets/images/activityUpdates.webp")}
                  alt={message.sender}
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    border: "2px solid var(--border-color)",
                  }}
                />
              </div>

              {/* Message Content */}
              <div style={{ flex: 1 }}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <h3 style={{ fontWeight: 500, color: "var(--text-primary)" }}>{message.sender}</h3>
                  <span style={{ fontSize: "0.75rem", color: "var(--text-secondary)" }}>
                    {message.time}
                  </span>
                </div>
                <p
                  style={{
                    fontSize: "0.875rem",
                    marginTop: "4px",
                    color: "var(--text-secondary)",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    maxWidth: "200px",
                  }}
                >
                  {message.message}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MessagePanel;
