
import React, { useState, useRef, useEffect } from 'react';
import { doc, updateDoc, getDoc, deleteField } from "firebase/firestore";
import encryptData from '../../utils/encrypt';
import { auth, storage, messaging, functions, firestore, signInWithEmailAndPassword, getToken, signOut, } from '../../firebase';
import { FCMTOKENKEY } from '../../environmentVariables';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserField } from '../../reduxStore/slices/UsersSlice';
import AlertModal from '../AlertModal/alertModal';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion } from "framer-motion";
import { useAnimationControls } from "framer-motion";

import ErrorCard from './error';
export default function AllPinChanges({ route, navigation }) {
  const location = useLocation();
  const navigate = useNavigate()
  const userUid = location.state?.userUid;
  const purpose = location.state?.purpose; // setPin || removePin || forgotPin
  const numberOfSecurityQuestions = location.state?.numberOfSecurityQuestions || 2;
  const [pinValue, setPinValue] = useState('');
  const [confirmPinValue, setConfirmPinValue] = useState('');
  const [confirmPinError, setConfirmPinError] = useState(false);
  const [password, setPassword] = useState('');
  const [currentPin, setCurrentPin] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [removePinError, setRemovePinError] = useState({
    password: false,
    pin: false,
  });
  const [isSetPinVisible, setIsSetPinVisible] = useState(false);
  const [answerError, setAnswerError] = useState(false);
  const [securityQuestions, setSecurityQuestions] = useState([]);
  const [securityAnswers, setSecurityAnswers] = useState({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answer, setAnswer] = useState('');
  const userData = useSelector(state => state.user.users);
  const activeUser = useSelector(state => state.user.activeUser);
  const activeUserData = userData[activeUser]
  const dispatch = useDispatch();
  const [alertModalType, setAlertModalType] = useState(null);
  const [alertModalMessage, setAlertModalMessage] = useState(null);
  const [alertModalHeader, setAlertModalHeader] = useState(null);
  const [alertModalButtons, setAlertModalButtons] = useState(null);
  const [isAlertModalVisisble, setIsAlertModalVisisble] = useState(false);
  console.log('isAlertModalVisisble: ', isAlertModalVisisble);
  const toggleAlertModal = () => {
    setIsAlertModalVisisble(!isAlertModalVisisble);
  };
  const { t } = useTranslation();
  const shakePasswordAnimation = useAnimationControls();
  const shakePinAnimation = useAnimationControls();

  const triggerShake = (animationControl) => {
    animationControl.start({
      x: [0, -10, 10, -10, 10, 0], // Moves left and right
      transition: { duration: 0.5 }, // Smooth shake effect
    });
  };

  // ******      related to security  question when asked in set pin case  ************
  const [availableQuestions, setAvailableQuestions] = useState({
    "What is your mother's maiden name?": '',
    'What is the name of your first pet?': '',
    'What is your favorite book?': '',
    'What city were you born in?': '',
    'What is your favorite movie?': '',
  });
  const [showSecurityQuestions, setShowSecurityQuestions] = useState(false);
  const [selectedSecurityQuestions, setSelectedSecurityQuestions] = useState(
    [],
  );
  const [securityQuestionsAnswers, setSecurityQuestionsAnswers] = useState({});
  const [securityQuestionAnswerError, setSecurityQuestionAnswerError] =
    useState([]);
  const shakeAnswerAnimation = useAnimationControls();
  const handleQuestionSelect = (question) => {
    console.log('question: ', question);
    if (selectedSecurityQuestions.includes(question)) {
      setSecurityQuestionAnswerError(prev => {
        return prev.filter(item => item !== question);
      });
      setSelectedSecurityQuestions(
        selectedSecurityQuestions.filter(q => q !== question),
      );
      setAvailableQuestions((prevQuestions) => {
        const updatedQuestions = { ...prevQuestions }; // Create a copy
        updatedQuestions[question] = ''; // Update the specific value
        return updatedQuestions; // Return the updated array
      });
    } else if (selectedSecurityQuestions.length < numberOfSecurityQuestions) {
      console.log('kjjkjkjkknk');
      setSelectedSecurityQuestions([...selectedSecurityQuestions, question]);
    }
  };

  const handleAnswerChange = (question, value) => {
    console.log('handleAnswerChange: ', question);
    const valueWithoutSpaces = value.replace(/\s+/g, ''); // Remove all spaces
    setAvailableQuestions((prevQuestions) => {
      const updatedQuestions = { ...prevQuestions }; // Create a copy
      updatedQuestions[question] = valueWithoutSpaces; // Update the specific value
      return updatedQuestions; // Return the updated array
    });

  };

  // *************************     ************************************

  try {
    const handleNext = async () => {
      if (!pinValue || pinValue.length !== 4) {
        setAlertModalMessage('Enter a valid 4-digit pin value');
        setAlertModalButtons([{ name: 'ok', onClick: 'toggle' }]);
        setAlertModalHeader('Error');
        setAlertModalType('Error');
        setIsAlertModalVisisble(true);
        return;
      }

      if (pinValue !== confirmPinValue) {
        setConfirmPinError(true);
        triggerShake(shakePinAnimation);
        return;
      }

      setShowSecurityQuestions(true);
    };

    const handleSecurityQuestionsSubmit = () => {
      let index = 0;
      let error = false;
      console.log("?????????????????????????????????????????????????????????")
      // when no answer is filled
      for (const key of selectedSecurityQuestions) {
        console.log('selectedSecurityQuestions: ', selectedSecurityQuestions);
        
        if (!availableQuestions[key] || availableQuestions[key] == '') {
          if (!securityQuestionAnswerError.includes(key)) {
            setSecurityQuestionAnswerError(prev => [...prev, key]);
            triggerShake(shakeAnswerAnimation);
            error = true;
          }
          error = true
          triggerShake(shakeAnswerAnimation);
        } else {
          index++;
        }
      }

      if (error) {
        return;
      } else {
        console.log("all ok", pinValue);
        setIsLoading(true)
        const securityQuestionsData = {}
        for (const key of selectedSecurityQuestions) {
          securityQuestionsData[key] = availableQuestions[key]
        }
        console.log('securityQuestionsData: ', securityQuestionsData);

        handleSetPin(securityQuestionsData)
      }
    };



    const handleSetPin = async (securityquestion = null) => {
      console.log('security: ', securityquestion);
      try {

        const userDocRef = doc(firestore, "users", userUid);
        console.log('userUid: ', userUid);
        if (securityquestion) {
          await updateDoc(userDocRef, {
            profilePinCode: pinValue,
            securityQuestions: securityquestion
          });
        } else {
          await updateDoc(userDocRef, {
            profilePinCode: pinValue,
          });
        }

        dispatch(
          updateUserField({ userUid, updates: { profilePinCode: pinValue } }),
        );

        setAlertModalMessage('Pin set successfully');
        setAlertModalButtons([{ name: 'ok', onClick: () => { navigate('/Login') } }]);
        setAlertModalHeader('Success');
        setAlertModalType('Informative');
        setIsAlertModalVisisble(true);

        setPinValue('');
        setConfirmPinValue('');
        setConfirmPinError(false);
      } catch (error) {
        setAlertModalMessage(
          'There was a problem setting the pin. Please try again later.',
        );
        setAlertModalButtons([{ name: 'ok', onClick: 'toggle' }]);
        setAlertModalHeader('Error');
        setAlertModalType('Error');
        setIsAlertModalVisisble(true);
      }
    };

    const handleRemovePin = async () => {
      console.log('!password || !currentPin: ', !password || !currentPin);
      if (!password || !currentPin) {
        setAlertModalMessage('Please enter both your password and current PIN');
        setAlertModalButtons([{ name: 'ok', onClick: 'toggle' }]);
        setAlertModalHeader('Error');
        setAlertModalType('Error');
        setIsAlertModalVisisble(true);
        return;
      }

      try {
        // const userDocRef = firestore().collection('users').doc(userUid);
        // const userDoc = await userDocRef.get();

        // if (!userDoc.exists) {
        //   setAlertModalMessage('User not found');
        //   setAlertModalButtons([{name: 'ok', onClick: 'toggle'}]);
        //   setAlertModalHeader('Error');
        //   setAlertModalType('Error');
        //   setIsAlertModalVisisble(true);
        //   return;
        // }



        const userData = activeUserData;
        const encryptedPassword = await encryptData(password, FCMTOKENKEY);

        const isPasswordCorrect = userData.password === encryptedPassword;
        console.log('encryptedPassword: ', encryptedPassword);
        console.log('userData.password: ', userData.password);
        const isPinCorrect = userData.profilePinCode === currentPin;
        console.log('userData.profilePinCode: ', userData.profilePinCode);
        console.log('userData: ', userData);
        const userDocRef = doc(firestore, "users", userUid);

        console.log('isPasswordCorrect && isPinCorrec: ', isPasswordCorrect);
        if (isPasswordCorrect && isPinCorrect) {


          if (purpose == 'removePin') {


            await updateDoc(userDocRef, {
              profilePinCode: deleteField(),
            });

            setAlertModalMessage('PIN removed successfully');
            setAlertModalButtons([{ name: 'ok', onClick: 'toggle' }]);
            setAlertModalHeader('Success');
            setAlertModalType('Informative');
            setIsAlertModalVisisble(true);
            dispatch(updateUserField({ userUid, updates: { profilePinCode: null } }));
            setPassword('');
            setCurrentPin('');
            setRemovePinError({ password: false, pin: false });
          } else if (purpose == 'updateSecurityQuestions') {
            setShowSecurityQuestions(true)
          }

        } else {
          setRemovePinError({
            password: !isPasswordCorrect,
            pin: !isPinCorrect,
          });

          if (!isPasswordCorrect) triggerShake(shakePasswordAnimation);
          if (!isPinCorrect) triggerShake(shakePinAnimation);
        }
      } catch (error) {
        console.log('error: ', error);
        setAlertModalMessage(
          'There was a problem removing the pin. Please try again later.',
        );
        setAlertModalButtons([{ name: 'ok', onClick: 'toggle' }]);
        setAlertModalHeader('Error');
        setAlertModalType('Error');
        setIsAlertModalVisisble(true);
      }
    };

    const handleForgotPin = async () => {
      if (!password) {
        setAlertModalMessage('Please enter your password');
        setAlertModalButtons([{ name: 'ok', onClick: 'toggle' }]);
        setAlertModalHeader('Warning');
        setAlertModalType('Warning');
        setIsAlertModalVisisble(true);
        return;
      }

      try {
        setIsLoading(true);
        const userDocRef = doc(firestore, "users", userUid);
        console.log('userDocRef: ', userDocRef);
        const userDoc = await getDoc(userDocRef);
        console.log('userDoc: ', userDoc);
        if (!userDoc.exists) {
          setAlertModalMessage('User not found');
          setAlertModalButtons([{ name: 'ok', onClick: 'toggle' }]);
          setAlertModalHeader('Error');
          setAlertModalType('Error');
          setIsAlertModalVisisble(true);
          return;
        }

        const userData = userDoc.data();
        const encryptedPassword = await encryptData(password, FCMTOKENKEY);
        console.log('password: ', password);
        console.log('FCMTOKENKEY: ', FCMTOKENKEY);

        console.log('encryptedPassword: ', encryptedPassword);
        console.log('userData.password: ', userData.password);
        if (userData.password === encryptedPassword) {
          // Load security questions if password is correct
          const questions =
            userData.securityQuestions ||
            {
              // "What is your mother's maiden name?": 'Smith',
              // 'What is the name of your first pet?': 'Fluffy',
            };
          setSecurityQuestions(Object.keys(questions));
          setSecurityAnswers(questions);
          setCurrentQuestionIndex(0); // Start from the first question
          setAnswer('');
        } else {
          setRemovePinError({ password: true });
          triggerShake(shakePasswordAnimation);
          // Alert.alert('Incorrect password');
        }
      } catch (error) {
        setAlertModalMessage('Error fetching user data');
        setAlertModalButtons([{ name: 'ok', onClick: 'toggle' }]);
        setAlertModalHeader('Error');
        setAlertModalType('Error');
        setIsAlertModalVisisble(true);
      } finally {
        setIsLoading(false);
      }
    };

    const handleAnswerSecurityQuestion = () => {

      const currentQuestion = securityQuestions[currentQuestionIndex];
      const correctAnswer = securityAnswers[currentQuestion];

      if (answer === correctAnswer) {
        if (currentQuestionIndex + 1 < securityQuestions.length) {
          setCurrentQuestionIndex(currentQuestionIndex + 1);
          setAnswer('');
        } else {
          // All questions answered correctly, allow user to set PIN
          setIsSetPinVisible(true);
          setPinValue('');
          setConfirmPinValue('');
          setConfirmPinError(false);
        }
      } else {
        //   Alert.alert('Incorrect answer. Please try again.');
        setAnswer('');
        setAnswerError(true);
        triggerShake(shakeAnswerAnimation);
      }
    };


    const styles = {
      container: {
        flex: 1,
        padding: 20,
        justifyContent: 'center',
        backgroundColor: '#f5f5f5',
        display: "flex",
        flexDirection: "column",
        height: "500px"
      },
      instructions: {
        fontSize: 18,
        color: '#333',
        marginBottom: 15,
        textAlign: 'center',
      },
      input: {
        height: 50,
        borderColor: '#ddd',
        borderWidth: 1,
        borderRadius: 10,
        marginBottom: 5,
        marginTop: 15,
        paddingHorizontal: 15,
        backgroundColor: '#fff',
        fontSize: 16,
      },
      errorBorder: {
        borderColor: 'red',
      },
      errorText: {
        color: 'red',
        fontSize: 14,
        marginBottom: 5,
        marginLeft: 5,
      },
      button: {
        backgroundColor: '#007AFF',
        paddingVertical: 15,
        borderRadius: 10,
        alignItems: 'center',
        cursor: "pointer",
        marginTop: 20,
        display: "flex",
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.3,
        shadowRadius: 5,
        elevation: 5,
      },
      removeButton: {
        backgroundColor: '#007AFF',
        paddingVertical: 15,
        borderRadius: 10,
        alignItems: 'center',
        cursor: "pointer",

        display: "flex",
        marginTop: 20,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.3,
        shadowRadius: 5,
        elevation: 5,
      },
      buttonText: {
        color: '#fff',
        fontSize: 18,
        fontWeight: '600',
        cursor: "pointer"
      },
      button: {
        backgroundColor: "#007bff",
        color: "white",
        padding: "10px 20px",
        border: "none",
        borderRadius: "5px",
        fontSize: "16px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "120px",
        height: "45px",
        outline: "none",
        cursor: "pointer"
      },
      loader: {
        border: "3px solid white",
        borderTop: "3px solid transparent",
        borderRadius: "50%",
        width: "18px",
        height: "18px",
      },

      selectedQuestion: {
        backgroundColor: '#e6f7ff',
        padding: 10,
        borderRadius: 10,
        marginVertical: 5,
        borderWidth: 1,
        borderColor: '#007AFF',
      },
      question: {
        backgroundColor: '#fff',
        padding: 10,
        borderRadius: 10,
        marginVertical: 5,
        borderWidth: 1,
        borderColor: '#ddd',
      },
      instructions: {
        fontSize: 18,
        color: '#333',
        marginBottom: 15,
        textAlign: 'center',
        cursor: "pointer"
      },
    };
    const [pinValue, setPinValue] = useState("");

    const handleChange = (e) => {
      const text = e.target.value;
      setPinValue(text.replace(/[^0-9]/g, "").slice(0, 4)); // Only numbers, max 4 digits
    };
console.log('hjkhjhjhjhjh',selectedSecurityQuestions);
    return (
      <div style={styles.container}>
        {showSecurityQuestions &&
          availableQuestions &&
          Object.keys(availableQuestions).length ? (
          <>
            <p style={styles.instructions}>
              {t(`Select ${numberOfSecurityQuestions} Security Questions:`)}{' '}
              {selectedSecurityQuestions.length} out of{' '}
              {numberOfSecurityQuestions}
            </p>
            {Object.keys(availableQuestions).map((question, index) => (
              <motion.div
                key={question}
                animate={{ x: securityQuestionAnswerError.includes(question) ? [0, -10, 10, -10, 10, 0] : 0 }}
                transition={{ duration: 0.5 }}
              >
                <div
                  style={selectedSecurityQuestions.includes(question)
                    ? { border: '2px solid', borderColor: securityQuestionAnswerError.includes(question) ? 'red' : '#e6f7ff', padding: '10px', marginBottom: '10px', cursor: 'pointer' }
                    : { padding: '10px', marginBottom: '10px', cursor: 'pointer' }}
                  onClick={() => handleQuestionSelect(question)}
                >
                  <p>{question}</p>
                </div>
                  {selectedSecurityQuestions.includes(question) &&
                  
                      <input
                        placeholder="Answer"
                        value={availableQuestions[question] || ''}
                        onChange={(e) => {
                          handleAnswerChange(question, e.target.value);
                          setSecurityQuestionAnswerError(prev => prev.filter(item => item !== question));
                        }}
                      />
                  }
              </motion.div>
            ))}

            <div
              style={{
                ...styles.button,
                backgroundColor:
                  selectedSecurityQuestions.length !== numberOfSecurityQuestions
                    ? 'lightgrey'
                    : '#007AFF',
              }}
              onClick={handleSecurityQuestionsSubmit}
              disabled={
                selectedSecurityQuestions.length !== numberOfSecurityQuestions
              }>
              <p style={styles.buttonText}>{t('Submit')}</p>
            </div>
          </>
        ) : purpose === 'setPin' ? (
          <>
            <p style={styles.instructions}>{t('Enter a 4-digit PIN:')}</p>
            <input
              style={styles.input}
              placeholder={t(isSetPinVisible ? 'Enter New Pin' : 'Enter PIN')}
              value={pinValue}
              onChange={handleChange
              }
              keyboardType="numeric"
              maxLength={4}
              secureTextEntry
            />
            <motion.div
              animate={{ x: confirmPinError ? [0, -10, 10, -10, 10, 0] : 0 }}
              transition={{ duration: 0.5 }}
            >
              <input
                style={{ border: confirmPinError ? '2px solid red' : '1px solid black', padding: '10px', width: '100%' }}
                placeholder={'Confirm PIN'}
                value={confirmPinValue}
                onChange={(e) => {
                  setConfirmPinValue(e.target.value.replace(/[^0-9]/g, '').slice(0, 4));
                  setConfirmPinError(false);
                }}
                type="password"
                maxLength={4}
              />
              {confirmPinError && (
                <p style={{ color: 'red', fontSize: '12px' }}>
                  {'PINs do not match. Please try again.'}
                </p>
              )}
            </motion.div>
            <div style={styles.button} onClick={handleNext}>
              <p style={styles.buttonText}>{t('Next')}</p>
            </div>
          </>
        ) : purpose === 'removePin' ? (
          <>
            <p style={styles.instructions}>{t('Remove PIN:')}</p>
            <motion.div animate={{ x: removePinError.password ? [0, -10, 10, -10, 10, 0] : 0 }} transition={{ duration: 0.5 }}>
              <input
                style={{ border: removePinError.password ? '2px solid red' : '1px solid black', padding: '10px', width: '100%' }}
                placeholder={'Enter Password'}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value.replace(/\s+$/, ''));
                  setRemovePinError({ password: false, pin: removePinError.pin });
                }}
                type="password"
              />
              {removePinError.password && (
                <p style={{ color: 'red', fontSize: '12px' }}>
                  {'Incorrect password. Please try again.'}
                </p>
              )}
            </motion.div>
            <motion.div animate={{ x: removePinError.pin ? [0, -10, 10, -10, 10, 0] : 0 }} transition={{ duration: 0.5 }}>
              <input
                style={{ border: removePinError.pin ? '2px solid red' : '1px solid black', padding: '10px', width: '100%' }}
                placeholder={'Enter Current PIN'}
                value={currentPin}
                onChange={(e) => {
                  setCurrentPin(e.target.value.replace(/[^0-9]/g, '').slice(0, 4));
                  setRemovePinError({ pin: false, password: removePinError.pin });
                }}
                type="password"
                maxLength={4}
              />
              {removePinError.pin && (
                <p style={{ color: 'red', fontSize: '12px' }}>
                  {'Incorrect PIN. Please try again.'}
                </p>
              )}
            </motion.div>
            <div
              style={styles.removeButton}
              onClick={handleRemovePin}>
              <p style={styles.buttonText}>{t('Remove PIN')}</p>
            </div>
          </>
        ) : purpose === 'forgotPin' ? (
          isSetPinVisible ? (
            <>
              <p style={styles.instructions}>Enter a 4-digit PIN:</p>
              <input
                style={styles.input}
                placeholder={t(isSetPinVisible ? 'Enter New Pin' : 'Enter PIN')}
                value={pinValue}
                onChange={(e) =>
                  setPinValue(e.target.value.replace(/[^0-9]/g, "").slice(0, 4))
                }
                keyboardType="numeric"
                maxLength={4}
                secureTextEntry
              />
              <motion.div animate={{ x: confirmPinError ? [0, -10, 10, -10, 10, 0] : 0 }} transition={{ duration: 0.5 }}>
                <input
                  style={{ border: confirmPinError ? '2px solid red' : '1px solid black', padding: '10px', width: '100%' }}
                  placeholder={'Confirm PIN'}
                  value={confirmPinValue}
                  onChange={(e) => {
                    setConfirmPinValue(e.target.value.replace(/[^0-9]/g, '').slice(0, 4));
                    setConfirmPinError(false);
                  }}
                  type="password"
                  maxLength={4}
                />
                {confirmPinError && (
                  <p style={{ color: 'red', fontSize: '12px' }}>
                    {'PINs do not match. Please try again.'}
                  </p>
                )}
              </motion.div>
              <div style={styles.button} onClick={() => handleSetPin()}>
                <p style={styles.buttonText}>{t('Set PIN')}</p>
              </div>
            </>
          ) : (
            <>
              {!securityQuestions.length ? (
                <>
                  <p style={styles.instructions}>
                    {t('Enter Password to Recover PIN:')}
                  </p>
                  <motion.div
                    animate={{ x: removePinError.password ? [0, -10, 10, -10, 10, 0] : 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    <input
                      style={{
                        border: removePinError.password ? '2px solid red' : '1px solid black',
                        padding: '10px',
                        width: '100%',
                      }}
                      placeholder="Enter Password"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value.replace(/\s+$/, ''));
                        setRemovePinError({ password: false });
                      }}
                      type="password"
                    />
                    {removePinError.password && (
                      <p style={{ color: 'red', fontSize: '12px' }}>
                        Incorrect password. Please try again.
                      </p>
                    )}
                  </motion.div>

                  <div
                    disabled={isLoading}
                    style={styles.button}
                    onClick={handleForgotPin}>
                    <p style={styles.buttonText}>
                      <motion.button
                        style={styles.button}
                        disabled={isLoading}
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                      >
                        {isLoading ? <motion.div style={styles.loader} animate={{ rotate: 360 }} transition={{ repeat: Infinity, duration: 0.5, ease: "linear" }} /> : t("Submit")}
                      </motion.button>
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <p style={styles.instructions}>
                    {t('Question')} {currentQuestionIndex + 1}:{' '}
                    {securityQuestions[currentQuestionIndex]}
                  </p>
                  <motion.div
                    animate={{ x: answerError ? [0, -10, 10, -10, 10, 0] : 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    <input
                      style={{
                        border: answerError ? '2px solid red' : '1px solid black',
                        padding: '10px',
                        width: '100%',
                      }}
                      placeholder="Answer"
                      value={answer}
                      onChange={(e) => {
                        setAnswer(e.target.value.replace(/\s+$/, ''));
                        setAnswerError(false);
                      }}
                    />
                    {answerError && (
                      <p style={{ color: 'red', fontSize: '12px' }}>
                        Incorrect answer. Please try again.
                      </p>
                    )}
                  </motion.div>
                  <div
                    style={styles.button}
                    onClick={handleAnswerSecurityQuestion}>
                    <p style={styles.buttonText}>{t('Submit Answer')}</p>
                  </div>
                </>
              )}
            </>
          )
        ) : purpose === 'updateSecurityQuestions' ?
          <>
            <div>
              <>
                <p style={styles.instructions}>{t('updateSecurityQuestions')}</p>
                <motion.div
                  animate={{ x: removePinError.password ? [0, -10, 10, -10, 10, 0] : 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <input
                    style={{
                      border: removePinError.password ? '2px solid red' : '1px solid black',
                      padding: '10px',
                      width: '100%',
                    }}
                    placeholder="Enter Password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value.replace(/\s+$/, ''));
                      setRemovePinError({ password: false, pin: removePinError.pin });
                    }}
                    type="password"
                  />
                  {removePinError.password && (
                    <p style={{ color: 'red', fontSize: '12px' }}>
                      Incorrect password. Please try again.
                    </p>
                  )}
                </motion.div>

                {/* PIN Input */}
                <motion.div
                  animate={{ x: removePinError.pin ? [0, -10, 10, -10, 10, 0] : 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <input
                    style={{
                      border: removePinError.pin ? '2px solid red' : '1px solid black',
                      padding: '10px',
                      width: '100%',
                    }}
                    placeholder="Enter Current PIN"
                    value={currentPin}
                    onChange={(e) => {
                      setCurrentPin(e.target.value.replace(/[^0-9]/g, '').slice(0, 4));
                      setRemovePinError({ pin: false, password: removePinError.password });
                    }}
                    type="password"
                    maxLength={4}
                  />
                  {removePinError.pin && (
                    <p style={{ color: 'red', fontSize: '12px' }}>
                      Incorrect PIN. Please try again.
                    </p>
                  )}
                </motion.div>

                <div
                  style={styles.removeButton}
                  onClick={handleRemovePin}>
                  <p style={styles.buttonText}>{t('Next')}</p>
                </div>
              </>
            </div>
          </>
          : null}

        {isAlertModalVisisble && <AlertModal
          type={alertModalType} // 'Error' | 'Informative'|'Success'|'Warning' (type of Error)
          message={alertModalMessage} // string (message to display)
          headerText={alertModalHeader} // string (text for the header of the Error)
          buttons={alertModalButtons} // Array of objects: { name: string, onClick: function | 'toggle' } (buttons array)
          isVisible={isAlertModalVisisble} // boolean (whether the modal is visible)
          toggleAlertModal={toggleAlertModal} // function to toggle modal visibility
          varient={'middle'} //middle
        />}
      </div>
    );
  } catch (error) {
    return <ErrorCard />;
  }
}