import React, { useEffect, useState, useCallback } from "react";
import Cropper from "react-easy-crop";
import heic2any from "heic2any";

const ImagePreview = React.memo(({ selectedImages, setShowPreview, onDone }) => {

    const [images, setImages] = useState([]); // Store original/cropped File objects
    const [imagePreviews, setImagePreviews] = useState([]); // Store base64 previews
    const [cropping, setCropping] = useState(false);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

    useEffect(() => {
        processImages(selectedImages);
    }, [selectedImages]);

    // Convert and store images
    const processImages = async (files) => {
        const validImages = [];
        const previews = [];

        for (let file of files) {
            if (file instanceof File) {
                const fileSizeInMB = file.size / (1024 * 1024);
                if (fileSizeInMB > 20) {
                    alert(`Image ${file.name} is larger than 20MB and will be removed.`);
                    continue;
                }

                const newFile = await convertToJPEG(file);
                if (newFile) {
                    validImages.push(newFile);
                    const preview = await getBase64(newFile);
                    previews.push(preview);
                }
            }
        }

        setImages(validImages);
        setImagePreviews(previews);
    };

    // Convert HEIC to JPEG and return a File object
    const convertToJPEG = async (file) => {
        if (file.type === "image/heic" || file.name.endsWith(".HEIC")) {
            try {
                const convertedBlob = await heic2any({ blob: file, toType: "image/jpeg" });
                return new File([convertedBlob], file.name.replace(/\.\w+$/, ".jpg"), { type: "image/jpeg" });
            } catch (error) {
                console.error("HEIC conversion error:", error);
                return null;
            }
        }
        return file; // Return the original file if not HEIC
    };

    // Convert file to base64 for previewing
    const getBase64 = (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
        });
    };

    const handleEdit = (index) => {
        setSelectedImageIndex(index);
        setCropping(true);
    };

    const onCropComplete = useCallback((_, croppedPixels) => {
        setCroppedAreaPixels(croppedPixels);
    }, []);

    // Apply cropping
    const applyCrop = async () => {
        if (selectedImageIndex === null || !croppedAreaPixels) return;

        const croppedFile = await cropImage(images[selectedImageIndex], croppedAreaPixels);
        if (croppedFile) {
            const updatedImages = [...images];
            const updatedPreviews = [...imagePreviews];

            updatedImages[selectedImageIndex] = croppedFile;
            updatedPreviews[selectedImageIndex] = await getBase64(croppedFile); // Update preview with cropped version

            setImages(updatedImages);
            setImagePreviews(updatedPreviews);
        }
        setCropping(false);
    };

    // Crop image function
    const cropImage = (file, cropArea) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (event) => {
                const img = new Image();
                img.src = event.target.result;
                img.onload = () => {
                    const canvas = document.createElement("canvas");
                    const ctx = canvas.getContext("2d");

                    canvas.width = cropArea.width;
                    canvas.height = cropArea.height;

                    ctx.drawImage(
                        img,
                        cropArea.x,
                        cropArea.y,
                        cropArea.width,
                        cropArea.height,
                        0,
                        0,
                        cropArea.width,
                        cropArea.height
                    );

                    canvas.toBlob((blob) => {
                        resolve(new File([blob], file.name, { type: "image/jpeg" }));
                    }, "image/jpeg");
                };
            };
        });
    };

    const handleDelete = (index) => {
        const newArray = images.filter((_, i) => i !== index);
        const newPreviews = imagePreviews.filter((_, i) => i !== index);
        if (newArray.length === 0) setShowPreview(false);
        setImages(newArray);
        setImagePreviews(newPreviews);
    };

    return (
        <div style={styles.overlay}>
            <div style={styles.container}>
                {cropping ? (
                    <>
                        <div style={{ position: "relative", width: "100%", height: "300px" }}>
                            <Cropper
                                image={imagePreviews[selectedImageIndex]} // Use base64 preview
                                crop={crop}
                                zoom={zoom}
                                aspect={4 / 3}
                                onCropChange={setCrop}
                                onZoomChange={setZoom}
                                onCropComplete={onCropComplete}
                            />
                        </div>
                        <button onClick={applyCrop} style={styles.doneButton}>
                            Apply Crop
                        </button>
                    </>
                ) : (
                    <div style={styles.scrollable}>
                        {imagePreviews.map((uri, index) => (
                            <div key={index} style={styles.imageContainer}>
                                <img src={uri} alt={`preview-${index}`} style={styles.image} />
                                <button style={styles.editButton} onClick={() => handleEdit(index)}>
                                    Edit
                                </button>
                                <button style={styles.removeButton} onClick={() => handleDelete(index)}>
                                    ❌
                                </button>
                            </div>
                        ))}
                    </div>
                )}
                {!cropping && <button style={styles.doneButton} onClick={() => onDone(images)}>Done</button>}
            </div>
        </div>
    );
});

export default ImagePreview;
const styles = {
    overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 10
    },
    container: {
        backgroundColor: "#fff",
        padding: "20px",
        borderRadius: "10px",
        textAlign: "center",
        width: "90%",
        maxWidth: "600px"
    },
    scrollable: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        gap: "10px",
        marginBottom: "20px"
    },
    imageContainer: {
        position: "relative",
        display: "inline-block",
        width: "150px",
        height: "150px",
        borderRadius: "10px",
        overflow: "hidden",
        border: "2px solid #000"
    },
    image: {
        width: "100%",
        height: "100%",
        objectFit: "cover"
    },
    editButton: {
        position: "absolute",
        bottom: "5px",
        left: "10px",
        backgroundColor: "#000",
        color: "#fff",
        padding: "5px 10px",
        borderRadius: "5px",
        cursor: "pointer",
        fontSize: "12px"
    },
    removeButton: {
        position: "absolute",
        top: "5px",
        right: "5px",
        backgroundColor: "red",
        color: "white",
        border: "none",
        borderRadius: "50%",
        cursor: "pointer",
        fontSize: "16px",
        width: "25px",
        height: "25px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    doneButton: {
        backgroundColor: "#28a745",
        color: "#fff",
        padding: "10px 20px",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
        fontSize: "16px"
    }
};
