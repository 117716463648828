import React, { useState, useEffect, useRef, useCallback } from 'react';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAudioRecorder from '../audio'; // Import your audio recorder hook
import AudioPlayer from '../player';
import Attachments from '../Attachments';
import DocViewer from '../DocViewer';
import Webcam from 'react-webcam';
import ImagePreview from '../ImagePreview';
import CardStack from '../stack';
import Cropper from 'cropperjs';
const SenderInput = ({ onSend, message, setMessage, onPressTextInput, setAudioPath, setImagePath, selectedFiles, setSelectedFiles, reply, setReply }) => {
    const [isLandscape, setIsLandscape] = useState(window.innerWidth > window.innerHeight);
    const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
    const [send, setSend] = useState(false);
    const [audioRecord, setAudioRecord] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [recordingIn, setRecordingIn] = useState(false);
    const [onHold, setOnHold] = useState(false);
    const [imageUri, setImageUri] = useState([]);
    const [openAttachments, setOpenAttachments] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [showCancelRecording, setShowCancelRecording] = useState(false);
    const [selectedImages, setSelectedImages] = useState([]);
    const [imageExist, setImageExist] = useState(true);
    const [widthOnLandscape, setWidthOnLandscape] = useState('94%');
    const [widthNotLandscape, setWidthNotLandscape] = useState('92%');
    const inputRef = useRef(null);
    const webcamRef = useRef(null);

    const {
        recording,
        playing,
        recordedFilePath,
        recordingTime,
        startRecording,
        stopRecording,
        startPlaying,
        stopPlaying,
        audioRef
    } = useAudioRecorder(); // Destructure necessary methods from the hook

    useEffect(() => {
        const updateOrientation = () => {
            setIsLandscape(window.innerWidth > window.innerHeight);
        };

        window.addEventListener('resize', updateOrientation);

        return () => {
            window.removeEventListener('resize', updateOrientation);
        };
    }, []);

    useEffect(() => {
        setDeviceWidth(window.innerWidth);
        if (isLandscape) {
            setWidthNotLandscape('90%');
            setWidthOnLandscape('94%');
        } else {
            setWidthOnLandscape('92%');
            setWidthNotLandscape('92%');
        }
    }, [isLandscape]);

    useEffect(() => {
        if (audioRecord && recordedFilePath === '') {
            setShowCancelRecording(true);
            setTimeout(() => {
                setShowCancelRecording(false);
            }, 4000);
            setAudioRecord(false);
            setIsRecording(false);
            setOnHold(false);
            setAudioPath('');
        }
    }, [audioRecord, recordedFilePath]);

    useEffect(() => {
        if (reply.replyText) {
            setOpenAttachments(false);
        }
    }, [reply]);
    const handleCrop = (imageSrc) => {
        // Convert base64 image to Blob
        const byteString = atob(imageSrc.split(',')[1]);
        const mimeString = imageSrc.split(',')[0].split(':')[1].split(';')[0];
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const uintArray = new Uint8Array(arrayBuffer);
    
        for (let i = 0; i < byteString.length; i++) {
            uintArray[i] = byteString.charCodeAt(i);
        }
    
        const imageBlob = new Blob([uintArray], { type: mimeString });
    
        // Create object URL from Blob
        const imageURL = imageBlob;
    
        // Create an image element for cropping
        const image = document.createElement("img");
        image.src = imageURL;
        document.body.appendChild(image); // Temporarily add to DOM for cropping
    
       const cropper = new Cropper(image, {
    aspectRatio: 1,
    viewMode: 1,

    crop(event) {
      
    },

    ready() {
       

        // Ensure the cropper instance is properly initialized
        if (!cropper) {
            console.error("❌ Cropper instance is undefined!");
            return;
        }

        // Delay to ensure cropper has rendered the canvas
        setTimeout(() => {
            console.log("🔍 Fetching Cropped Canvas...");
            const canvas = cropper.getCropperCanvas();

            if (!canvas) {
                console.error("❌ Cropped Canvas is not available.");
                return;
            }

            console.log("✅ Cropped Canvas Obtained:", canvas);

            // Convert canvas to Blob and get image URL
            canvas.toBlob((blob) => {
                if (!blob) {
                    console.error("❌ Blob generation failed!");
                    return;
                }


                const croppedImageURL = blob;

                setImageUri([croppedImageURL]);
                setImagePath([croppedImageURL]);
                setSend(true);
            }, "image/png");
        }, 500); // Small delay to ensure cropper is ready
    },
});

    };
    
    const checkCameraPermission = async () => {
        try {
            const permissionStatus = await navigator.permissions.query({ name: "camera" });
    
            if (permissionStatus.state === "granted") {
                // Permission already granted, open the camera
                openCamera();
            } else if (permissionStatus.state === "prompt") {
                // Permission not yet granted, request access
                requestCameraAccess();
            } else {
                // Permission denied permanently
                toast.error("Camera access is blocked. Please enable it in browser settings.");
            }
        } catch (error) {
            console.error("Permission Check Error:", error);
            toast.error("Failed to check camera permissions.");
        }
    };
    
    const requestCameraAccess = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
    
            if (stream) {
                // User granted access, open the camera
                openCamera();
            }
        } catch (error) {
            console.error("Camera Access Error:", error);
            toast.error("Camera access denied. Please allow access.");
        }
    };
    
    const openCamera = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            const video = document.createElement("video");
            video.srcObject = stream;
            video.autoplay = true;
            document.body.appendChild(video);
    
            setTimeout(() => {
                const canvas = document.createElement("canvas");
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                const context = canvas.getContext("2d");
    
                context.drawImage(video, 0, 0, canvas.width, canvas.height);
                const uri = canvas.toDataURL("image/png");
    
                handleCrop(uri);
    
                // Stop the camera stream
                stream.getTracks().forEach((track) => track.stop());
                document.body.removeChild(video);
            }, 3000);
        } catch (error) {
            console.error("Camera Error:", error);
            toast.error("Camera access denied or not supported on this device.");
        }
    };
    
    const handleDocumentSelection = async (event) => {
        try {
            // Ensure files exist before processing
            if (!event.target.files || event.target.files.length === 0) {
                console.log("No files were selected.");
                // return; // ✅ Exit early without throwing an error
            }
    
            const files = Array.from(event.target.files); // Convert FileList to array
    
            const newFiles = files.map((file) => ({
                name: file.name,
                uri: file, // Create URL for preview
                type: file.type,
                size: file.size,
            }));
    
    
            // Check if selectedFiles exists before accessing its length
            if ((selectedFiles?.length || 0) + newFiles.length > 10) {
                toast.error("Can't select more than 10 files");
                return;
            }
    
            setOpenAttachments(false);
            setSelectedFiles((prevFiles) => [...(prevFiles || []), ...newFiles]); // Ensure prevFiles exists
            setSend(true);
            toast.success("Files selected successfully!");
        } catch (err) {
            console.error("Document Picker Error:", err);
            toast.error("Error selecting files. Please try again.");
        }
    };
    const openGallery = () => {
        const input = document.createElement("input");
        input.type = "file";
        input.accept = "image/*";
        input.multiple = false; // Ensure only one image is selected
        input.style.display = "none";
    
        input.onchange = (event) => {
            if (!event.target.files || event.target.files.length === 0) {
                console.log("User cancelled image picker");
                return;
            }
    
            const newImage = event.target.files[0];
    
            // Prevent duplicate state updates
            if (selectedImages.length > 0 && selectedImages[0].name === newImage.name) {
                console.log("Same image selected, skipping state update.");
                return;
            }
            // Update state with the newly selected image
            setSelectedImages([newImage]);
            setShowPreview(true);
        };
    
        document.body.appendChild(input);
        input.click();
        document.body.removeChild(input);
    };
    

    const onCancel = (id) => {
        const newArray = selectedFiles.filter((_, index) => index !== id);
        setSelectedFiles(newArray);
    };

    const handleImagesUpdate =  useCallback((updatedImages) => {
        setSelectedImages(updatedImages);
        setImageUri(updatedImages);
        setImagePath(updatedImages);
        setShowPreview(false);
        setSend(true);
    });

    const handleMouseDown = () => {
        // Wait 2 seconds before starting the recording
        
            startRecording();  // Your function to start recording
            setIsRecording(true);
            setOnHold(true);
            setOpenAttachments(false);
     
    };
    
    const handleMouseUp = () => {
        setOnHold(false);
            stopRecording();
          
            setAudioRecord(false);
            setAudioPath(recordedFilePath);
            setIsRecording(false);
    };

    const handleClick = () => {
        onSend();
        setAudioRecord(false);
        setIsRecording(false);
        setOnHold(false);
        setImageUri([]);
        setSelectedImages([]); // Clear the state after sending
   
        setOpenAttachments(false);
    };

    const handleKeyPress = (e) => {
        if (e.key === "Backspace" && message === "") {
            setSend(false);
        } else {
            setSend(true);
        }
    };

    const maxLength =
        imageUri.length > 0
            ? 1800 - imageUri.length * 172
            : selectedFiles.length > 0
                ? 1800 - selectedFiles.length * 167
                : 1800;
            
    return (
        <>
            <div style={{ position: 'absolute', bottom: openAttachments ? 60 : -900, justifyContent: 'center', alignItems: 'center', width: '85%', marginHorizontal: 5, display: "flex" }}>
                <Attachments forGallery={openGallery} forDocument={handleDocumentSelection} />
            </div>
            <div style={{ position: 'absolute', bottom: showCancelRecording ? '250%' : -300, justifyContent: 'center', alignItems: 'center', width: '95%', marginHorizontal: 10, backgroundColor: 'rgba(28, 39, 45, 0.9)', borderRadius: 10, paddingVertical: 10, display: "flex" }}>
                <p style={{ color: 'white', fontSize: 16 }}>Hold more then 2 seconds to record audio</p>
            </div>
            <div style={{ flex: 1, position: 'absolute', bottom: 0, width: deviceWidth, flexDirection: 'row', alignItems: 'center', margin: 5, columnGap: 3, marginRight: 30, display: "flex" }}>
                {
                    imageUri.length !== 0 &&
                    <div style={{
                        position: 'absolute', bottom: "88%", height: 400, width: isLandscape ? widthOnLandscape : widthNotLandscape, backgroundColor: 'white', borderRadius: 20, padding: 8, borderWidth: 1,
                        borderColor: '#ccc', borderBottomRightRadius: 0, borderBottomLeftRadius: 0
                    }}>
                        {
                            imageUri.length > 1 &&
                            <div style={{ position: 'absolute', padding: 6, backgroundColor: 'white', zIndex: 10, top: 0, right: 0, margin: 20, borderRadius: 10, elevation: 5 }}>
                                <p style={{ color: 'black' }}>+{imageUri.length - 1}</p>
                            </div>}
                        {
                            <div onClick={() => setImageUri([])} style={{ position: 'absolute', padding: 6, backgroundColor: 'white', zIndex: 50, top: 0, left: 0, margin: 10, borderRadius: 10, elevation: 5  , cursor:"pointer"}}>
                                <img src={require('../../../assets/images/cross.png')} style={{ width: 20, height: 20 }} />
                            </div>
                        }
                        <div style={{ minWidth: '100%', height: 380, paddingVertical: 8, paddingHorizontal: 10, justifyContent: 'center', alignItems: 'center', overflow: 'hidden', borderRadius: 12, display: "flex" }}>
                            <CardStack cardData={imageUri} setImageExist={setImageExist} />
                        </div>
                    </div>
                }
                {
                    selectedFiles.length !== 0 &&
                    <div style={{
                        position: 'absolute', bottom: "108%", width: selectedFiles.length == 2 ? '88%' : selectedFiles.length == 1 ? '46%' : '100%', backgroundColor: 'white', borderRadius: 20, padding: 5, borderWidth: 1,
                        borderColor: '#ccc',
                    }}>
                        {
                            <div onClick={() => setSelectedFiles([])} style={{ position: 'absolute', backgroundColor: 'white', zIndex: 10, top: 10, right: 10, margin: 0, borderRadius: 10, elevation: 5 , cursor:"pointer"}}>
                                <img src={require('../../../assets/images/cross.png')} style={{ width: 20, height: 20 }} />
                            </div>
                        }
                        <div style={{ flexDirection: 'row', display: "flex" }} horizontal showsHorizontalScrollIndicator indicatorStyle='black'>
                            {
                                selectedFiles.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <DocViewer item={item} id={index} onCancel={onCancel} />
                                        </div>
                                    )
                                })
                            }
                        </div>

                    </div>
                }
                {
                    reply.replyText &&
                    <div style={{
                        position: 'absolute', bottom: "88%", width: isLandscape ? widthOnLandscape : widthNotLandscape, backgroundColor: 'white', borderRadius: 20, padding: 18, borderWidth: 1,
                        borderColor: '#ccc', borderBottomRightRadius: 0, borderBottomLeftRadius: 0
                    }}>
                        <div style={{ width: '100%', height: 50, backgroundColor: 'lightgrey', borderRadius: 20, justifyContent: 'center', paddingHorizontal: 25, display: "flex" }}>
                            <p numberOfLines={1} style={{ color: 'black' }}>{reply.replyText}</p>
                        </div>
                        <div style={{ width: 18, height: 18, backgroundColor: 'white', position: 'absolute', right: 0, borderRadius: 10, margin: 6, overflow: 'hidden', justifyContent: 'center', alignItems: 'center', display: "flex" }} onClick={() => setReply({ replyText: '', replyId: null })} >
                            <img src={require('../../../assets/images/cross.png')} style={{ width: '80%', height: '80%', objectFit: 'cover' }} />
                        </div>
                    </div>
                }
                <div style={{
                    borderWidth: 1,
                    borderColor: '#ccc',
                    borderTopRightRadius: imageUri.length !== 0 || reply.replyText ? 0 : 40,
                    borderTopLeftRadius: imageUri.length !== 0 || reply.replyText ? 0 : 40,
                    borderBottomLeftRadius: 40,
                    borderBottomRightRadius: 40,
                    borderTopWidth: imageUri.length !== 0 || reply.replyText ? 0 : 1,
                    backgroundColor: onHold ? 'lightgrey' : 'white',
                    width: isLandscape ? deviceWidth / 1.06 : deviceWidth / 1.08,
                    paddingHorizontal: 10,
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingRight: 18,
                    paddingVertical: 10,
                    maxHeight: '100%',
                    padding: 5,
                    overflow: 'scroll',
                    display: "flex"
                }}>
                    {
                  
                        onHold ? <div style={{ width: '70%', paddingVertical: 5 }}>
                            {
                                audioRecord ? <AudioPlayer ref={audioRef} audioFilePath={recordedFilePath} startTime={recordingTime} />
                                    :
                                    <div style={{ width: 50, height: 40, alignItems: 'center', flexDirection: 'row', marginLeft: 10, columnGap: 10, display: "flex" }}>
                                        <img src={require('../../../assets/Animations/rec2.gif')} style={{ width: '100%', height: '100%' }} />
                                        <p style={{}}>{recordingTime}</p>
                                    </div>
                            }
                        </div>
                            :
                            <input
                                value={message}
                                onChange={(e) => (setMessage(e.target.value), setSend(e.target.value !== ''))}
                                placeholder="Write a message..."
                                style={styles.messageInput}
                                multiline='true'
                                ref={inputRef}
                                onFocus={() => {
                                    onPressTextInput();
                                    setOpenAttachments(false);
                                }}
                                onKeyDown={handleKeyPress}
                                maxLength={maxLength}
                                autoCapitalize='sentences'
                            />
                    }
                    {
                        onHold ?

                            <div style={{ width: 30, height: 30, justifyContent: 'center', alignItems: 'center', display: "flex" }} onClick={() => (setAudioRecord(false), setIsRecording(false), setOnHold(false), setAudioPath(''))}>
                                <img src={require('../../../assets/images/dustbin.png')} style={{ width: '100%', height: '100%' }} />
                            </div>

                            :
                            imageUri.length !== 0 ? null :
                                selectedFiles.length !== 0 ? null
                                    :
                                    reply.replyText ? null
                                        :
                                        <div style={{ flexDirection: 'row', justifyContent: 'space-between', columnGap: 18, display: "flex" }}>
                                            <div style={{ width: 30, height: 30, justifyContent: 'center', alignItems: 'center', display: "flex" }} onClick={() => {
                                                setOpenAttachments(!openAttachments);
                                                document.activeElement.blur(); ;
                                            }} >
                                                <img src={require('../../../assets/images/attachment.webp')} style={{ width: '100%', height: '100%' }} />
                                            </div>
                                            <div style={{ width: 30, height: 30, justifyContent: 'center', alignItems: 'center', display: "flex" }} onClick={() => (checkCameraPermission(), setOpenAttachments(false))}>
                                                  <img src={require('../../../assets/images/camera.webp')} style={{ width: '100%', height: '100%' }} />
                                            </div>
                                            <Webcam 
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={{ facingMode: 'environment' }} // 'user' for front camera
                style={{position: 'absolute', bottom: -300, right: 0, left: 0, margin: 'auto', width: '500px', height: '300px'}}
            />

                                        </div>
                    }
                </div>
                <div
                    style={{ backgroundColor: '#21c063', width: 50, height: 50, justifyContent: 'center', alignItems: 'center', borderRadius: 25, display: "flex" }}
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    onTouchStart={handleMouseDown}
                    onTouchEnd={handleMouseUp}
                    onClick={handleClick}
                >
                    {audioRecord || send ? (
                        <img src={require('../../../assets/images/send.webp')} style={{
                            width: '50%', height: '50%',
                        }} />
                    ) : (
                        <img src={require('../../../assets/images/mic.png')} style={{ width: '50%', height: '50%' }} />
                    )}
                </div>
            </div>
          
           
            {
                showPreview &&
                <ImagePreview selectedImages={selectedImages} onDone={handleImagesUpdate} setShowPreview={setShowPreview} />
            }
            <ToastContainer position="top-right" autoClose={3000} />

        </>
    );

}



const styles = {
    messageInput: {
        color: 'black',
        backgroundColor: 'transparent',
        width: '70%',
        marginTop: 2,
        marginLeft: 0,
        borderRadius: 20,


    },
};

export default SenderInput;