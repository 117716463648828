import React, { useEffect, useState } from 'react';

import { functions, firestore, } from '../../firebase';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { httpsCallable } from "firebase/functions";

export default function UserVerificationScreen({ route }) {
  // console.log(route.params, '<__________');
  const navigation = useNavigate();
  const location = useLocation();
  console.log('location: ', location.state);
  const getDataForTwoFactorAuthentication = httpsCallable(functions, 'getDataForTwoFactorAuthentication')
  const userData = useSelector(state => state.user.users);
  console.log('userData: ', userData);
  const activeUser = useSelector(state => state.user.activeUser);
  console.log('activeUser: ', activeUser);
  const activeUserData = userData[activeUser];
  const otpMetaData = location.state?.otpMetaData;
  const selectedUserData = location.state?.selectedUserData;
  const documentIdData = location.state?.documentIdData ? location.state?.documentIdData : null
  const otpMethod = location.state?.otpMethod ? location.state?.otpMethod : null
  const fcmToken = location?.state
    ? location.state?.fcmToken
      ? location.state?.fcmToken
      : null
    : null;
  console.log('fcmToken: ', fcmToken);
  const documentId = location.state?.documentId
  const otpLength = otpMetaData?.otpLength;
  const otpType = otpMetaData?.otpType;
  const otpValidationTime = otpMetaData?.otpValidationTime;
  const otpIsFor = location.state.otpMetaData?.otpIsFor
  console.log('otpIsFor: ', otpIsFor);
  const [OTPSendCountObjectData, setOTPSendCountObjectData] = useState({})
  const [falseAttemptObjectData, setfalseAttemptObjectData] = useState({})
  const [isdisabled, setisdisabled] = useState(false);
  console.log('isdisabled: ', isdisabled);
  const [selectedOTPMethod, setselectedOTPMethod] = useState(null);
  const [OTPOptions, setOTPOptions] = useState([]);


  // const createFormattedDate=()=>{
  //   const date = new Date();

  //   let formattedDate = '';
  //   formattedDate = formattedDate + date.getDate();
  //   const month = (date.getMonth() + 1).toString(); //adding 1 because it return the index of month and index start from 0
  //   const formattedMonth = month.length == 1 ? `0${month}` : month;
  //   formattedDate = formattedDate + formattedMonth;
  //   formattedDate = formattedDate + date.getFullYear();

  //   return formattedDate;
  // }

  useEffect(() => {
    if (!otpMethod && !documentIdData) {

      const verifiedEntities = selectedUserData?.verifiedEntities || {};
      console.log(verifiedEntities)
      const keys = Object.keys(verifiedEntities)
      setOTPOptions(keys)
    }

    console.log(selectedUserData, "..")
  }, [])

  const checkFalseAttempt = async () => {
    try {


      // Fetch data from cloud function or use cached data
      const dataOfUser = documentIdData || await getDataForTwoFactorAuthentication({ documentId });
      console.log('dataOfUser: ', dataOfUser);

      if (dataOfUser.status && dataOfUser.status === 'failure') {
        //failed to get data from the cloud function
        setisdisabled(true)
        console.log('failed to get data from the cloud function')
        return;
      }

      const falseAttemptObject =
        otpIsFor === 'forgotPin'
          ? activeUserData.falseAttemptObject || {}
          : dataOfUser.falseAttemptObject || {};

      const OTPSendCountObject = dataOfUser.OTPSendCountObject || {};

      const activeDeviceFalseAttempts = falseAttemptObject[fcmToken] || [];
      const activeDeviceOTPSendCount = OTPSendCountObject[fcmToken] || [];

      // Store data for further use
      setOTPSendCountObjectData(OTPSendCountObject);
      setfalseAttemptObjectData(falseAttemptObject);

      const maxFalseAttempts = 5;
      const maxOTPSendCount = 10;

      if (activeDeviceFalseAttempts.length <= maxFalseAttempts && activeDeviceOTPSendCount.length <= maxOTPSendCount) {
        console.log('User has access');
      } else {
        // Check if recent attempts are within the last 24 hours
        const currentDateTime = Date.now(); // Get current time in milliseconds
        const twentyFourHoursInMillis = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

        const attemptsMoreThanLimitWithin24Hours = await checkAttemptsWithinLast24Hours(activeDeviceFalseAttempts, activeDeviceOTPSendCount, currentDateTime, twentyFourHoursInMillis);
        console.log('attemptsMoreThanLimitWithin24Hours: ', attemptsMoreThanLimitWithin24Hours);

        if (attemptsMoreThanLimitWithin24Hours) {
          console.log("Access denied: too many attempts within the last 24 hours.");
          setisdisabled(true);
        } else {

          console.log("User has access");
        }
      }
    } catch (error) {
      console.error("Error checking attempts:", error);
      return;
    }
  };

  const checkAttemptsWithinLast24Hours = (falseAttempts, otpSendAttempts, currentTime, thresholdMillis) => {
    const isRecent = (attempts, maxAttempts) => {
      return attempts
        .slice(-maxAttempts)
        .every(attempt => {
          const attemptDate = new Date(attempt); // Full Date object
          const timeDifference = currentTime - attemptDate; // Milliseconds difference
          return timeDifference <= thresholdMillis; // Check if within threshold (24 hours)
        });
    };

    const areFalseAttemptsRecent = isRecent(falseAttempts, 5);
    const areOTPSendAttemptsRecent = isRecent(otpSendAttempts, 10);

    return areFalseAttemptsRecent && areOTPSendAttemptsRecent;
  };



  useEffect(() => {
    checkFalseAttempt();
  }, []);


  useEffect(() => {
    if (otpMethod !== null) {
      console.log("otp method present")
      const item = otpMethod
      handleSelectOTPMethod(item)
    }
  }, [otpMethod])

  const handleSelectOTPMethod = (item) => {
    setselectedOTPMethod(item)
  }



  async function handleSendOtpPress() {

    const date = new Date();
    const OtpSendDateAndTime = { date: date.getDate(), time: date.getTime() };
    console.log(OtpSendDateAndTime, '<>>><><><><><><><><><>');
    navigation("/OTPCreatorAndValidator", {
      state: {
        data: {
          OtpSendDateAndTime,
          otpMetaData,
          fcmToken,
          selectedOTPMethod,
          documentId,
          selectedUserData,
          OTPSendCountObjectData,
          falseAttemptObjectData,
        }
      }
    });
  }
  return (

    <div style={styles.maincontainer}>
      {isdisabled ? <div>
        <p style={{ fontSize: 20, color: 'black' }}>Access denied: too many attempts within the last 24 hours.</p>
      </div> :
        fcmToken ?

          selectedOTPMethod ?
            <div>
              <p style={{ color: 'black', fontSize: 20 }}>ForgotPassScreen</p>
              <div
                disabled={isdisabled}
                onClick={() => handleSendOtpPress()}
                style={{
                  backgroundColor: 'blue',
                  paddingHorizontal: 30,
                  paddingVertical: 10,
                  borderRadius: 20,
                }}>
                <p style={{ color: 'white', textAlign: 'center' }}>Send OTP</p>
              </div>
            </div> :
            (
              OTPOptions.length ? OTPOptions.map((item, index) => (
                console.log('OTPOptions: ', OTPOptions),
                <div key={index} onClick={() => { handleSelectOTPMethod(item) }}><p style={{ fontSize: 15 }}>{item}</p></div>
              ))
                : <div><p>Something went wrong please try again later</p></div>
            )
          :
          (
            <div>
              <p style={{ fontSize: 20, color: 'black' }}>
                Something went wrong please try again later
              </p>
            </div>
          )}
    </div>
  );
}

const styles = {
  maincontainer: {
    flex: 1,
    backgroundColor: 'lightblue',
  },
};