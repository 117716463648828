import React from "react";

/*
props:
  - title 
  - maxLength
  - value
  - func (onChange handler)
  - placeholder
*/

const App = (props) => {
  return (
    <div style={styles.container}>
      <span style={styles.title}>{props.title}</span>
      <textarea
        value={props.value}
        maxLength={props.maxLength}
        style={styles.textarea}
        placeholder={props.placeholder}
        onChange={(e) => props.func(e.target.value)}
      />
      <span style={styles.counter}>
        {props.value.length}/{props.maxLength}
      </span>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: "#e9f2ff",
    padding: "10px",
    borderRadius: "10px",
    width: "100%",
    margin: "5px 0",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  title: {
    fontFamily: "MarkerFelt, sans-serif",
    color: "#3B486F",
    fontSize: "18px",
  },
  textarea: {
    width: "100%",
    borderRadius: "10px",
    color: "black",
    fontFamily: "MarkerFelt, sans-serif",
    fontSize: "16px",
    backgroundColor: "white",
    padding: "10px",
    resize: "none",
    boxSizing: "border-box",
  },
  counter: {
    fontFamily: "MarkerFelt, sans-serif",
    alignSelf: "flex-end",
    color: "black",
    marginRight: "10px",
  },
};

export default App;
