import React, { useState, useRef, useEffect } from 'react';

const AudioPlayer = ({ audioFilePath, startTime }) => {
    const audioRef = useRef(new Audio(audioFilePath));
    const [playing, setPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(startTime || 0);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (audioFilePath) {
            audioRef.current = new Audio(audioFilePath);
            audioRef.current.addEventListener('error', () => {
                setError('Failed to load audio file. Please check the URL and format.');
            });
        }
    }, [audioFilePath]);

    useEffect(() => {
        const audio = audioRef.current;

        const updateTime = () => {
            setCurrentTime(audio.currentTime);
        };

        const updateDuration = () => {
            setDuration(audio.duration);
        };

        audio.addEventListener('timeupdate', updateTime);
        audio.addEventListener('loadedmetadata', updateDuration);
        audio.addEventListener('ended', () => setPlaying(false));

        return () => {
            audio.removeEventListener('timeupdate', updateTime);
            audio.removeEventListener('loadedmetadata', updateDuration);
            audio.removeEventListener('error', () => setError(null));
        };
    }, []);

    const togglePlayPause = () => {
        const audio = audioRef.current;
        if (playing) {
            audio.pause();
        } else {
            audio.play().catch(err => {
                setError('Error playing audio: ' + err.message);
            });
        }
        setPlaying(!playing);
    };

    const handleSeek = (event) => {
        const newTime = event.target.value;
        audioRef.current.currentTime = newTime;
        setCurrentTime(newTime);
    };

    const formatTime = (seconds) => {
        const mins = Math.floor(seconds / 60);
        const secs = Math.floor(seconds % 60);
        return `${mins}:${secs < 10 ? '0' : ''}${secs}`;
    };

    return (
        <div style={styles.container}>
            {error && <div style={styles.errorText}>{error}</div>}
            <button onClick={togglePlayPause} style={styles.playPauseButton}>
                {playing ? '⏸️' : '▶️'}
            </button>
            <input
                type="range"
                min="0"
                max={duration || 100}
                value={currentTime}
                onChange={handleSeek}
                style={styles.progressSlider}
            />
            <span style={styles.timerText}>{formatTime(currentTime)} / {formatTime(duration)}</span>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        padding: '10px',
        backgroundColor: 'transparent',
        borderRadius: '10px',
    },
    playPauseButton: {
        fontSize: '20px',
        cursor: 'pointer',
        border: 'none',
        background: 'none',
    },
    progressSlider: {
        flex: 1,
    },
    timerText: {
        fontSize: '12px',
        color: '#333',
    },
    errorText: {
        color: 'red',
        fontSize: '12px',
    }
};

export default AudioPlayer;