import React, { Fragment } from 'react';
import {
    getHighlightedParts,
    getPropertyByPath,
} from 'instantsearch.js/es/lib/utils';

function HighlightPart({ children, isHighlighted }) {
    return (
        <span style={isHighlighted ? styles.highlighted : styles.nonHighlighted}>
            {children}
        </span>
    );
}

export function Highlight({ hit, attribute, separator = ', ', document }) {
    // Check if the document prop is passed
    if (document) {
        return hit.objectID;
    }

    // Extract and process highlighted parts
    const { value: attributeValue = '' } =
        getPropertyByPath(hit._highlightResult, attribute) || {};
    const parts = getHighlightedParts(attributeValue);

    // Render highlighted parts
    return (
        <>
            {parts.map((part, partIndex) => {
                if (Array.isArray(part)) {
                    const isLastPart = partIndex === parts.length - 1;

                    return (
                        <Fragment key={partIndex}>
                            {part.map((subPart, subPartIndex) => (
                                <HighlightPart
                                    key={subPartIndex}
                                    isHighlighted={subPart.isHighlighted}
                                >
                                    {subPart.value}
                                </HighlightPart>
                            ))}

                            {!isLastPart && separator}
                        </Fragment>
                    );
                }

                return (
                    <HighlightPart key={partIndex} isHighlighted={part.isHighlighted}>
                        {part.value}
                    </HighlightPart>
                );
            })}
        </>
    );
}

const styles = {
    highlighted: {
        fontWeight: 'bold',
        backgroundColor: '#f5df4d',
        color: '#6f6106',
    },
    nonHighlighted: {
        backgroundColor: 'transparent',
        color: 'black',
    },
};