import React, { useEffect, useRef, useState } from 'react';

import {algoliasearch} from 'algoliasearch';
import { Configure, InstantSearch } from 'react-instantsearch-core';

import  InfiniteHits  from './infiniteHits';
import { useNavigate } from 'react-router-dom';

const searchClient = algoliasearch('7BER79SCQK', '51f6b19d6579616e0bad6f843d293325');

export default function CommentSearch({ commentArray, disableInteraction, isSelected, onSelect, onDeselect }) {
    const [localQuery, setLocalQuery] = useState('');
    const listRef = useRef(null);
    const [pressTimer, setPressTimer] = useState(null);

    function scrollToTop() {
        listRef.current?.scrollToOffset({ animated: true, offset: 0 });
    }
    const navigation = useNavigate()
    const [idsArray, setIdsArray] = useState('');
    const [timeAgo, setTimeAgo] = useState('');
    
    
    useEffect(() => {
        if (!commentArray) return
        const idArray = [commentArray.id]
        setIdsArray(idArray)
    }, [commentArray])
    function Hit({ hit }) {
        useEffect(() => {
            if (!commentArray?.key) return;
    
            const updateAgo = () => {
                setTimeAgo(getTimeAgo(commentArray.key));
            };
    
            updateAgo(); // Initial update
            const interval = setInterval(updateAgo, 60000); // Update every 60 sec
    
            return () => clearInterval(interval); // Cleanup on unmount
        }, [commentArray]);
        const handlePress = () => {
            onDeselect();
        };
        const handleRightClick = (event) => {
            event.preventDefault(); // Prevents the default browser context menu
            console.log("Right-click detected!");
            const timer = setTimeout(() => {
                if (navigator.vibrate) navigator.vibrate(50); // Vibrate on long press
                onSelect(commentArray.key);            }, 500); // 500ms for long press
            setPressTimer(timer);        };

      
        const getTimeAgo = (timestamp) => {
            const now = Date.now(); // Current time in milliseconds
            const diffInSeconds = Math.floor((now - timestamp) / 1000); // Convert ms to seconds

            if (diffInSeconds < 60) {
                return `${diffInSeconds} seconds ago`;
            }

            const diffInMinutes = Math.floor(diffInSeconds / 60);
            if (diffInMinutes < 60) {
                return `${diffInMinutes} min${diffInMinutes > 1 ? 's' : ''}`;
            }

            const diffInHours = Math.floor(diffInMinutes / 60);
            if (diffInHours < 24) {
                return `${diffInHours} hour${diffInHours > 1 ? 's' : ''}`;
            }

            const diffInDays = Math.floor(diffInHours / 24);
            if (diffInDays < 7) {
                return `${diffInDays} day${diffInDays > 1 ? 's' : ''}`;
            }

            const diffInWeeks = Math.floor(diffInDays / 7);
            if (diffInWeeks < 52) {
                return `${diffInWeeks} week${diffInWeeks > 1 ? 's' : ''}`;
            }

            const diffInYears = Math.floor(diffInWeeks / 52);
            return `${diffInYears} year${diffInYears > 1 ? 's' : ''}`;
        };

        return (
            <div activeOpacity={0.7}
                style={styles.commentItem}    onContextMenu={handleRightClick}
               onClick={handlePress} >
                <div style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between',}}>
                    <div style={{ flexDirection: 'row', marginBottom: 2, alignItems: 'center' }}>
                        <img src={require('../../assets/images/profile(1).webp')} style={styles.modalPfp} />
                        <p style={{...styles.modalUsername,  color: 'white' }}>{hit.username || hit.firstName}</p>
                    </div>
                    <p style={{ color: 'white', fontSize: 10 }}>
                    {timeAgo}
                    </p>
                </div>
                <p style={styles.commentText}>{commentArray.text || ''}</p>
            </div>
        );
    }
    const styles = {
        safe: {
            flex: 1,
        },
        commentsList: {
            flex: 1,
        },
        commentItem: {
            padding: 10,
            backgroundColor: 'rgb(12,11,36)',
            borderRadius: 10,
            rowGap: 10,
            opacity: disableInteraction ? 0.4 : 1
        },
        commentText: {
            fontSize: 14,
            color: 'white'
        },
        modalUsername: {
            fontSize: 16,
            fontWeight: 'bold',
            color: 'black'
        },
        modalPfp: {
            width: 30,
            height: 30,
            borderRadius: 15,
            marginRight: 10,
        },

    };

    return (
        <div style={{ padding: "20px", backgroundColor: "#f8f8f8" }}>
            <div style={{ height: "20px", backgroundColor: "#333" }}></div>
            <InstantSearch searchClient={searchClient} indexName={'SBUser'}>
                {idsArray &&
                    <>
                        <Configure
                            // hitsPerPage={1000}  
                            facetFilters={[idsArray.map((id) => `objectID:${id}`)]}
                            highlightPreTag="<mark>"
                            highlightPostTag="</mark>"
                        />
                        {/* <SearchBox onChange={(query) => {
                    scrollToTop();
                    idsArray.length < 1000 && setLocalQuery(query);
                }}
                    idsLength={idsArray.length}
                    localQuery={localQuery}
                    backStyle={{ backgroundColor: 'white', padding: 5, marginBottom: 10 }}
                    boxStyle={{ backgroundColor: '#252b33', borderColor: 'black', color: 'white' }}
                /> */}
                        <InfiniteHits ref={listRef} idsLength={idsArray.length} hitComponent={Hit} container={{ backgroundColor: 'white' }} query={localQuery} />
                    </>
                }
            </InstantSearch>
        </div>
    );
}