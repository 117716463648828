import React, { useState } from 'react';
import { Bell, MessageSquare, X } from 'lucide-react';
import NotificationPanel from './notifcation/Notifcation';
import MessagePanel from './messageContainer/messagepannel';
import "./Apps.css"
import { useSelector } from 'react-redux';
const  App =({setIsSidebarOpen , isSidebarOpen,   onClose })=> {
//   const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('notifications');
  const TitlebarHeight = useSelector((state) => state.website.TitlebarHeight);
console.log(TitlebarHeight)
const hanleCloseSidebar =()=>{
    setIsSidebarOpen(false);
    if (onClose) onClose();
}
const users = [
    { id: 1, name: 'Alice', avatar: require("../../assets/images/3rd-leader.webp") , online: true },
    { id: 2, name: 'Bob', avatar:  require("../../assets/images/3rd-leader.webp"), online: false },
    { id: 3, name: 'Charlie', avatar:  require("../../assets/images/330909630_3c91c898-559e-449d-b6c6-c75491511a74.webp"),online: true },
    { id: 4, name: 'David', avatar:  require("../../assets/images/aComprehensiveCurriculum 1.webp") , },
    { id: 5, name: 'Emma', avatar:  require("../../assets/images/accountant.webp") },
  ];
  return (
    <div style={{ minHeight: '100vh', backgroundColor: 'var(--bg-main)' }}>
      {/* Main content */}
 

      <div
        style={{
          position: 'fixed',
          top: TitlebarHeight,
          bottom: 0,
          right: 0,
          width: '320px',
          backgroundColor: 'var(--bg-sidebar)',
          boxShadow: 'var(--shadow-lg)',
          transform: isSidebarOpen ? 'translateX(0)' : 'translateX(-100%)',
          transition: 'transform 300ms ease-in-out , top 0.5s ease-in-out',
        }}
      >
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          {/* Header */}
          <div style={{
            padding: 'var(--spacing-md)',
            borderBottom: '1px solid var(--border-color)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
                 borderColor:"transparent"
          }}>
            <div style={{ display: 'flex', gap: 'var(--spacing-md)' }}>
              <button
                onClick={() => setActiveTab('notifications')}
                style={{
                  padding: 'var(--spacing-sm)',
                  borderRadius: 'var(--radius-md)',
                  backgroundColor: activeTab === 'notifications' ? 'var(--bg-message-unread)' : 'transparent',
                  color: activeTab === 'notifications' ? 'var(--primary-color)' : 'var(--text-secondary)',
                  transition: 'all 0.2s',
                        borderColor:"transparent"
                }}
                onMouseEnter={(e) => {
                  if (activeTab !== 'notifications') {
                    e.currentTarget.style.backgroundColor = 'var(--bg-hover)';
                  }
                }}
                onMouseLeave={(e) => {
                  if (activeTab !== 'notifications') {
                    e.currentTarget.style.backgroundColor = 'transparent';
                  }
                }}
              >
                <Bell style={{ width: '20px', height: '20px' }} />
              </button>
              <button
                onClick={() => setActiveTab('messages')}
                style={{
                  padding: 'var(--spacing-sm)',
                  borderRadius: 'var(--radius-md)',
                  backgroundColor: activeTab === 'messages' ? 'var(--bg-message-unread)' : 'transparent',
                  color: activeTab === 'messages' ? 'var(--primary-color)' : 'var(--text-secondary)',
                  transition: 'all 0.2s',
                        borderColor:"transparent"
                }}
                onMouseEnter={(e) => {
                  if (activeTab !== 'messages') {
                    e.currentTarget.style.backgroundColor = 'var(--bg-hover)';
                  }
                }}
                onMouseLeave={(e) => {
                  if (activeTab !== 'messages') {
                    e.currentTarget.style.backgroundColor = 'transparent';
                  }
                }}
              >
                <MessageSquare style={{ width: '20px', height: '20px' }} />
              </button>
            </div>
            <button
              onClick={hanleCloseSidebar}
              style={{
                padding: 'var(--spacing-sm)',
                borderRadius: 'var(--radius-md)',
                transition: 'all 0.2s',
                      borderColor:"transparent"
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = 'var(--bg-hover)';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = 'transparent';
              }}
            >
              <X style={{ width: '20px', height: '20px' }} />
            </button>
          </div>

          {/* Content */}
          <div style={{ flex: 1, overflowY: 'auto' }}>
            {activeTab === 'notifications' ? (
              <NotificationPanel/>
            ) : (<>
              <MessagePanel />
              </>
            )}
         
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;