import React, { useEffect, useRef } from 'react';
import { Routes, Route, useLocation, Navigate, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import About from '../About/About';
import HomePage from '../Home/HomePage';
import Logout from '../loginPage/logout';
import Solutios from '../Solution/Solutions';
import FAQQuestions from '../FAQ/FAQ';
import VisionContainer from '../Vision/Vision';
import SignUp from "../loginPage/signup";
import ContactUs from '../../Screens/Info/contact';
import Info from '../Info/info';
import NotFound from '../../Components/test/Error';
import LMS from '../LMS/LMS';
import PrivacyPolicy from '../Policies/PrivacyPolicy';
import RefundPolicy from '../Policies/RefundPolicy';
import TermsOfUse from '../Policies/TermOfUse';

import JobRequirementForm from '../Info/JobRequirementForm';
import App from '../Info/GetStarted';
import Apps from "../../Components/Conversation/index"
import themes from '../../config/theme';

import BlogDetail from '../Blog/blogDetailsScreen';
import FestInputForm from '../../Components/quizModule.js/FestForm';
import QuizData from '../../Components/quizModule.js/data/quizData';
import BlogCategoryList from '../Blog/FetechBlogData';
import { use } from 'react';

import { display, height } from '@mui/system';
import AllPinChanges from '../forgetPinFlow/allPillChanges';
import OTPCreatorAndValidator from '../forgetPassFlow/OtpCreatorAndValidator';
import OTPScreen1 from '../forgetPassFlow/OTpscreen';
import UserVerificatonFlow from '../forgetPassFlow';
import AuthenticationTesting from '../loginPage/login';
import UserVerificationScreen from '../forgetPassFlow/userVerificationScreen';
import DeepLinkRedirect from '../../Components/test/test';
import PostSearch from '../../Components/PostsComponent/Home/postSearch';
import MessageDemo from '../../Components/Conversation/MessageDemo/MessageDemo';
import ImageViewer from '../../Components/Conversation/ImageViewer';
import FriendRequest from '../../Components/FreindRequest/index';
import LoginPage from '../loginPage/LoginPageB';

const MainContentArea = () => {
  const location = useLocation();
  const token = useSelector((state) => state.auth.token);
 const user = useSelector((state)=>state.event.inviteCode)
const currentTheme = useSelector((state) => state.website.theme);
const selectedTheme = themes[currentTheme] || themes.default; // Use selected theme
  const containersRef = useRef(null);
  const styles = {
    MainContentArea: {
      // minHeight: "100vh",
      backgroundColor: selectedTheme.soilColor,
      width: "100vw",
// display:"flex",  
height:"fit-content",
         overflow:location.pathname==="/blogs" ? "hidden":  location.pathname==="/LMS" ? "hidden" :""
     
    
    },
  };

  useEffect(() => {
    if (containersRef.current) {
      containersRef.current.scrollTo(0, 0);
    }
  }, [location.pathname]); 
  useEffect(() => {
    if (containersRef.current) {
      containersRef.current.scrollTo(0, 0);
    }
  }, [location.state]); 
  const navigate = useNavigate(); // Initialize the navigate function
  return (
    <div  ref={containersRef} style={styles.MainContentArea}>
   
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/logout' element={<Logout />} />
        <Route path="/about" element={<About />} />
        <Route path="/solution" element={<Solutios/>} />
        <Route path="/blogs" element={<BlogCategoryList/>} />
        <Route path="/blogs/BlogDetailsScreen/:blogId" element={<BlogDetail/>} />
        <Route path="/FAQ" element={<FAQQuestions/>} />
        <Route path="/vision" element={<VisionContainer/>} />
        <Route path='/forStudent' element={<HomePage/>} />
        <Route path='/forParent' element={<HomePage/>} />
        <Route path='/forTeacher' element={<HomePage/>} />
        <Route path='/forInstitute' element={<HomePage/>} />
        <Route path='/info' element={<Info/>} />
        <Route path='/jobApplication' element={<JobRequirementForm/>} /> 
        <Route path='/privacyPolicy' element={<PrivacyPolicy/>} />
        <Route path="/LMS" element={token ? <LMS /> : <Navigate to="/Login" />} />
          <Route path="/OTPCreatorAndValidator" element={<OTPCreatorAndValidator/>}  />
          <Route path="/signUp" element={<SignUp/>}  />
          <Route path="/OTPScreen1" element={<OTPScreen1/>}  />
          <Route path="/UserVerificationScreen" element={<UserVerificationScreen/>}  />
          <Route path="/post" element={<PostSearch/>} />
          <Route path="/comPost" element={<DeepLinkRedirect/>} />
          <Route path="/conversation" element={<Apps/>} />
          <Route path="/MessageDemo" element={<MessageDemo/>} />
          <Route path="/MessageDemo/ImageViewer" element={<ImageViewer/>} />
          <Route path="/FreindRequest" element={<Apps/>} />
          
          
          

        <Route path='/refundPolicy' element={<RefundPolicy/>} />
        <Route path='/termofuse' element={<TermsOfUse/>} />
        <Route path='/contactus' element={<ContactUs/>} />
        <Route path="*" element={<NotFound/>} />
        <Route path='/Login' element={<LoginPage/>} />
        <Route path='/Login/allPinChanges' element={<AllPinChanges/>} />
        <Route path="/getStarted" element={<App/>} />
      </Routes>
    </div>
  );
};

export default MainContentArea;
