import React, { useState } from 'react';
import { peopleimage } from '../../config/config';

// Function to get styles based on props using switch-case
const getAlignmentStyles = (layoutType) => {
  switch (layoutType) {
    case 'centerRight':
      return {
        justifyContent: 'center',
        alignItems: 'flex-end',
      };
    case 'centerLeft':
      return {
        justifyContent: 'center',
        alignItems: 'flex-start',
      };
    case 'topCenter':
      return {
        justifyContent: 'flex-start',
        alignItems: 'center',
      };
    case 'bottomCenter':
      return {
        justifyContent: 'flex-end',
        alignItems: 'center',
      };
    case 'bottomRight':
      return {
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
      };
    case 'topLeft':
      return {
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
      };
    case 'topRight':
      return {
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
      };
    case 'bottomLeft':
      return {
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
      };
    default:
      return null;
  }
};

const Profilelogo = ({ layoutType, width, profileimage, sideimage }) => {
  const [imageError, setImageError] = useState(false);
  const [sideImageError, setSideImageError] = useState(false);

  const styles = {
    maincontainer: {
      aspectRatio: '1 / 1',
      width: width,
      height: width,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    profileContainer: {
      width: '100%',
      height: '100%',
      position: 'relative',
    },
    imageStyle: {
      width: layoutType === 'topCenter' ? '65%' : '95%',
      height: layoutType === 'topCenter' ? '65%' : '95%',
      borderRadius: '50%',
      marginTop: layoutType === 'topCenter' ? '16%' : '2%',
      marginLeft: layoutType === 'topCenter' ? '16%' : '2%',
    },
    sidecontainer: {
      height: '30%',
      width: '30%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: layoutType === 'topCenter' ? '-5%' : null,
      position: 'absolute',
      top: layoutType === 'topCenter' ? '0' : 'auto',
      left: layoutType === 'topCenter' ? '0' : 'auto',
    },
  };

  const getSideContainerStyle = (layoutType) => {
    if (!layoutType) {
      return { display: 'none' };
    }
  };

  return (
    <div style={styles.maincontainer}>
      <div
        style={{
          ...styles.profileContainer,
          ...getAlignmentStyles(layoutType),
          backgroundImage: `url(${
            imageError
              ? peopleimage['people']
              : profileimage
              ? typeof profileimage !== 'number'
                ? profileimage
                : require('../../assets/images/profileuser.webp')
              : require('../../assets/images/profileuser.webp')
          })`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
        onError={() => setImageError(true)} // Set image error to show fallback
      >
        <div style={{ ...styles.sidecontainer, ...getSideContainerStyle(layoutType) }}>
          <img
            src={
              sideImageError
                ? require('../../assets/images/crown.webp') // Fallback side image if loading fails
                : sideimage || require('../../assets/images/crown.webp') // Default or provided side image
            }
            style={{ height: '100%', width: '100%' }}
            onError={() => setSideImageError(true)} // Set side image error to show fallback
            alt="side"
          />
        </div>
      </div>
    </div>
  );
};

export default Profilelogo;