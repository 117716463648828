import React from 'react';
import useScreenSizes from '../../utils/useScreenSize';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';


const ERPContainer = () => {
  // Data array for all management systems
  const Data = [
    {
      title: 'Exam Scheduler',
      explanation:
        'With Study Buddy’s smart Exam Scheduler, students and teachers can plan exams effortlessly. The app automatically sets exam dates, sends reminders, and keeps everyone organised.',
      features: [
        {
          title: 'Automated Exam Reminders',
          content: 'The app sends notifications well in advance of exams, ensuring students and teachers never miss important dates.',
          image: require("../../assets/images/automatedExamReminders.webp"),   
             },
        {
          title: 'Customizable Exam Timetables',
          content: 'Users can easily adjust the exam schedule based on personal preferences or institutional requirements, allowing flexibility.',
          image: require("../../assets/images/customizableExamTimetable.webp"),   
        },
        {
          title: 'Sync with Calendars',
          content: 'The scheduler integrates with personal or institutional calendars, making it easier to keep track of exam dates alongside other events.',
          image: require("../../assets/images/syncWithCalenders.webp"),   
        },
      ],
    },
    {
      title: 'Curriculum Generator',
      explanation:
        'Teachers can use the powerful Curriculum Generator to create, edit, and customise lesson plans tailored to students\' needs. It streamlines the process, saving time and ensuring that no topic is left behind.',
      features: [
        {
          title: 'Pre-built Templates for Curriculum Design',
          content: 'Teachers can start with pre-designed templates that follow standard curriculums, helping them set up lesson plans quickly.',
          image: require("../../assets/images/preBuiltTempletesForCurriculum.webp"),   
        },
        {
          title: 'Easy Editing and Sharing',
          content: 'Teachers can modify lesson plans, update topics, and instantly share them with students or colleagues, making collaboration smoother.',
          image: require("../../assets/images/easyEditingAndSharing.webp"),   
        },
        {
          title: 'Aligns with Educational Standards',
          content: 'The generator ensures that curriculums meet specific educational guidelines, ensuring students cover all necessary subjects.',
          image: require("../../assets/images/alignWithEducationalStandard.webp"),   
        },
      ],
    },
    {
      title: 'Academic Calendar',
      explanation:
        'Never worry about missing important academic dates. The Academic Calendar in Study Buddy provides a full-year view of key events like exams, holidays, and assignments.',
      features: [
        {
          title: 'Syncs with Personal Calendars',
          content: 'The academic calendar can integrate with personal digital calendars (like Google or Apple Calendar), so students and teachers can see academic events alongside personal commitments.',
          image: require("../../assets/images/syncWithPersonalCalender.webp"),   
        },
        {
          title: 'Set Reminders for Important Events',
          content: 'Users can set custom reminders for key academic events, such as exam dates, project deadlines, or holidays, ensuring they stay on track.',
          image: require("../../assets/images/setReminderForImportantEvent.webp"),   
        },
        {
          title: 'Customizable for Schools and Institutions',
          content: 'Institutions can personalize the calendar to reflect their specific schedules, including holidays, exam periods, and academic events.',
          image: require("../../assets/images/customizableForSchoolAndInstitute.webp"),   
        },
      ],
    },
    {
      title: 'Report Card Generator',
      explanation:
        'Say goodbye to paper report cards. With Study Buddy’s Report Card Generator, teachers can quickly generate detailed reports on student performance, instantly shareable with parents and students.',
      features: [
        {
          title: 'Customizable Grading Templates',
          content: 'Teachers can create grading systems that fit their specific subjects or institutional guidelines, allowing for flexibility in report creation.',
          image: require("../../assets/images/customizableGradingtempletes.webp"),   
        },
        {
          title: 'Instant Sharing via App or Email',
          content: 'Reports can be shared with parents and students instantly through the app or email, eliminating delays and the need for physical copies.',
          image: require("../../assets/images/instantSharingViaAppOrEmail.webp"),   
        },
        {
          title: 'Performance Analytics for Each Student',
          content: 'The generator provides detailed insights into student performance, helping teachers identify areas where students excel or need improvement.',
          image: require("../../assets/images/PerformanceAnalyticsForEachStudent.webp"),   
        },
      ],
    },
    {
      title: 'Hostel Management',
      explanation:
        'Institutions offering hostel facilities can benefit from Study Buddy’s Hostel Management feature, which helps administrators manage student accommodations, room assignments, and daily logistics with ease.',
      features: [
        {
          title: 'Room and Bed Allocation System',
          content: 'Administrators can easily manage room assignments and track available beds, ensuring efficient use of hostel space.',
          image: require("../../assets/images/roomAndBedAllocationSystem.webp"),   
        },
        {
          title: 'Meal Plan Tracking',
          content: 'The app helps administrators manage meal plans for hostel residents, including dietary preferences and schedules.',
          image: require("../../assets/images/mealPlanTracking.webp"),   
        },
        {
          title: 'Attendance Management for Residents',
          content: 'The system tracks hostel residents\' attendance, making it easier to monitor who is on-site and when, improving security and accountability.',
          image: require("../../assets/images/attendanceManagementForResidents.webp"),   
        },
      ],
    },
    // Add more features here following the same structure...
  ];
  const screen = useScreenSizes();

    const [ hoveredCardIndex, setHoveredCardIndex]= useState()
    const screenType = useSelector((state) => state.website.screenType);
    const styles = {
      mainContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingtop:  screenType==="smallPhone"||screenType==="phone"?"7vh" : '10vh',
          },
      cardContainer: {
        display: 'flex',
        justifyContent: screenType==="smallPhone" || screenType==="phone" ? "center" : 'space-between',
        width: screenType==="smallPhone" || screenType==="phone" ? "100vw" : '95vw',
        flexDirection: screenType==="smallPhone" || screenType==="phone" ? "column" : "",
        height: screenType==="smallPhone" || screenType==="phone" ? "100vh" : '50vh',
        marginBottom: "25px"
      },
      card: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: screenType==="smallPhone" || screenType==="phone" ? "90vw" : '30%',
        borderRadius: '10px',
        overflowY: 'scroll',
        boxShadow: "10px 10px 15px rgba(0, 0, 0, 0.3)",
        marginBottom: "25px",
        transition: 'transform 0.3s ease', // Smooth transition for scaling
      },
      cardHover: {
        transform: 'scale(1.05)', // Scale up the card on hover
      },
      cardImage: {
        height: '70%',
        overflow: 'hidden',
        backgroundColor: '#f0f0f0',
  
      },
      cardContent: {
        height: '30%',
        padding: '10px',
        backgroundColor: '#fff',
        textAlign: 'center',
      },
      image: {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
      },
      dataContainer: {
        marginTop: screenType==="smallPhone" ? "25vw":screenType==="phone"?"25vw" : screenType==="tab"? "15vw":screenType==="tablet"?"12vw": '2vw',  
        width: screenType==="smallPhone" || screenType==="phone" ? "98vw" : '95vw',
        padding: '15px',
        backgroundColor: '#fff',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
      },
    };
  const {t} = useTranslation();
 const [textScale, setTextScale] = useState(1); // State for text scale
 const increaseTextScale = () => {
   setTextScale((prevScale) => prevScale + 0.1);
 };
 
 const decreaseTextScale = () => {
   setTextScale((prevScale) => (prevScale > 1 ? prevScale - 0.1 : 1));
 };
 return (
   <div style={styles.mainContainer}>
    
     {Data.map((system, systemIndex) => (
       <div key={systemIndex} style={styles.dataContainer}>
         <p style={{ fontSize: `${25 * textScale}px`, fontWeight: "bold" }}>{t(system.title)}</p>
         <div style={styles.cardContainer}>
           {system.features.map((feature, index) => (
             <div
               key={index}
               style={{
                 ...styles.card,
                 ...(hoveredCardIndex === index ? styles.cardHover : {}),
               }}
               onMouseEnter={() => setHoveredCardIndex(index)}
               onMouseLeave={() => setHoveredCardIndex(null)}
             >
               <div style={styles.cardImage}>
                 <img
                   src={feature.image} // Dynamic image loading
                   alt={feature.title}
                   style={styles.image}
                   loading="lazy"  />
               </div>
               <div style={styles.cardContent}>
                 <p style={{ fontSize: `${20 * textScale}px`, margin: "0px" }}>{t(feature.title)}</p>
                 <p style={{ fontSize: `${16 * textScale}px` }}>{t(feature.content)}</p>
               </div>
             </div>
           ))}
         </div>
         <p style={{ fontSize: `${16 * textScale}px` }}>{t(system.explanation)}</p>
       </div>
     ))}
   </div>
 );
 };

export default ERPContainer ;
// Styling object
