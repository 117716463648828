import React, { useEffect, useRef, useState } from "react";
import useScreenSizes from "../../utils/useScreenSize";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const cardData = [
  {
    title: "All-in-One Learning Solution",
    smallCards: [
      {
        src: require("../../assets/images/Integrated Learning Tools.webp"),

        title: "Integrated Learning Tools",
        content:
          "Enjoy a suite of features, including flashcards, note-taking, and interactive quizzes, all designed to enhance your study experience. These tools provide a comprehensive approach to learning, making it easier to grasp complex concepts and retain information. By integrating various methods, you can tailor your study sessions to suit your preferences and maximize your understanding.",
      },
      {
        src: require("../../assets/images/Progress Tracking Dashboard.webp"),

        title: "Progress Tracking Dashboard",
        content:
          "Monitor your learning journey with real-time insights into your performance, helping you identify strengths and areas for improvement. This dashboard provides a clear overview of your progress, allowing you to celebrate achievements and address challenges effectively. By visualizing your learning trajectory, you can set achievable goals and stay motivated throughout your studies.",
      },
      {
        src: require("../../assets/images/Flexible Study Plans.webp"),

        title: "Flexible Study Plans",
        content:
          "Create customized study schedules that fit your lifestyle, ensuring you can balance learning with other commitments. This flexibility allows you to design a study routine that aligns with your personal and professional responsibilities. By having control over your study time, you can optimize your learning experience without feeling overwhelmed.",
      },
      {
        src: require("../../assets/images/Community Support.webp"),

        title: "Community Support",
        content:
          "Connect with peers for group studies, discussions, and sharing resources, making learning collaborative and engaging. This supportive community fosters a sense of belonging and encourages knowledge sharing among students. By collaborating with others, you can deepen your understanding of subjects, gain different perspectives, and enhance your overall learning experience.",
      },
     
 ],

 },
 {
  title: "Personalised Learning Experience",
  smallCards:[
    {
      src: require("../../assets/images/Adaptive Learning Paths.webp"),

      title: "Adaptive Learning Paths",
      content:
        "Benefit from customized lesson plans that adjust to your unique strengths and weaknesses, ensuring effective learning. This personalized approach tailors the curriculum to your individual needs, helping you focus on areas that require more attention while reinforcing your existing knowledge. By adapting to your learning style, it maximizes your potential for success.",
    },
    {
      src: require("../../assets/images/Pace Your Progress.webp"),

      title: "Pace Your Progress",
      content:
        "Take control of your study schedule, allowing you to spend more time on challenging topics and breeze through areas of confidence. This flexibility empowers you to learn at your own pace, ensuring that you fully understand difficult concepts before moving on. By managing your time effectively, you can enhance retention and mastery of the material.",
    },

    {
      src: require("../../assets/images/Dynamic Quiz Formats.webp"),

      title: "Dynamic Quiz Formats",
      content:
        "Engage with quizzes that adapt in difficulty based on your performance, keeping you challenged and motivated. This interactive feature ensures that you are consistently tested at the right level, promoting growth without overwhelming you. By providing immediate feedback and adjusting to your skill level, these quizzes enhance your learning experience and keep you engaged.",
    },
    {
      src: require("../../assets/images/goal setting and tracking.webp"),

      title: "Goal Setting and Tracking",
      content:
        "Set personal learning goals and receive feedback on your progress, empowering you to stay focused and achieve success. By defining clear objectives, you can measure your progress and adjust your study strategies as needed. Regular feedback helps you identify areas of strength and opportunities for improvement, keeping you motivated and accountable throughout your learning journey.",
    },
  ]
 },
 {
  title: "Interactive Learning",
  smallCards:[
    {
      src: require("../../assets/images/Gamified Learning Experiences.webp"),

      title: "Gamified Learning Experiences",
      content:
        "Transform studying into an exciting adventure with game-like challenges and rewards that keep you motivated. By incorporating elements of gaming, such as points, levels, and badges, learning becomes more engaging and enjoyable. This approach encourages you to actively participate in your education, making even the most challenging topics feel like a fun challenge to conquer",
    },
    {
      src: require("../../assets/images/Real-Time Feedback.webp"),

      title: "Real-Time Feedback",
      content:
        "Receive instant feedback on quizzes and activities, allowing you to learn from mistakes and reinforce understanding on the spot. This immediate response helps identify areas for improvement right away, enabling you to make necessary adjustments in your learning approach. Real-time feedback not only enhances comprehension but also boosts confidence as you see your progress",
    },
    {
      src: require("../../assets/images/Collaborative Activities.webp"),

      title: "Collaborative Activities",
      content:
        "Participate in group challenges and interactive discussions, fostering teamwork and making learning a social experience. Collaborative activities encourage communication and cooperation among peers, enriching the learning process. By working together, you can share diverse perspectives and solutions, making the learning experience more comprehensive and enjoyable.",
    },
    {
      src: require("../../assets/images/Multimedia Engagement.webp"),

      title: "Multimedia Engagement",
      content:
        "Enjoy a variety of learning formats, including animated videos and interactive simulations, to cater to different preferences and enhance retention. This multimedia approach addresses various learning styles, ensuring that content is accessible and engaging for everyone. By using diverse formats, you can better understand complex concepts and retain information more effectively.",
    }
  ]
 },
 {
  title: "Connect with Teachers and Peers",
  smallCards:[
    {
      src: require("../../assets/images/Instant Messaging Features.webp"),

      title: "Instant Messaging Features",
      content:
        "Easily reach out to teachers for quick clarifications or guidance, ensuring you never feel stuck on a problem. This immediate access allows you to get timely support on challenging topics, helping you overcome obstacles without delay. By eliminating communication barriers, instant messaging fosters a supportive learning environment where questions can be addressed promptly.",
    },
    {
      src: require("../../assets/images/Peer Colaboration.webp"),

      title: "Peer Collaboration",
      content:
"Form study groups with classmates to share insights, resources, and tackle challenging subjects together. Collaborative learning encourages teamwork and helps you gain different perspectives on complex topics. By working with peers, you can deepen your understanding, enhance retention, and make studying a more enjoyable experience."
   },
    {
      src: require("../../assets/images/Discussion Fourms.webp"),

      title: "Discussion Forums",
      content:
"Engage in topic-based discussions with peers and educators, fostering a collaborative learning environment where ideas thrive. These forums provide a space for exchanging thoughts, asking questions, and sharing resources. By participating in discussions, you can explore diverse viewpoints and clarify concepts, enriching your learning experience."

   },
    {
      src: require("../../assets/images/Regular Q&A Sessions.webp"),

      title: "Regular Q&A Sessions",
      content:
"Participate in scheduled Q&A sessions with teachers, allowing for in-depth exploration of topics and personalized support. These dedicated sessions offer an opportunity to dive deeper into subjects, clarify doubts, and engage in meaningful conversations with educators. Regular interaction helps reinforce learning and builds a strong connection between students and teachers."

   },
  ]
 },

 {
  title: "Learn Anytime, Anywhere",
  smallCards:[
    {
      src: require("../../assets/images/Offline Mode.webp"),
  
      title: "Offline Mode",
      content:
  "Download lessons and resources to study without an internet connection, making it easy to learn during commutes or in remote areas. This feature is perfect for busy lifestyles, allowing you to take your studies anywhere. Whether you’re on a train, waiting in line, or in a place with limited connectivity, you can continue your education seamlessly without interruption"
  
   },
   {
    src: require("../../assets/images/Mobile Accessibility.webp"),

    title: "Mobile Accessibility",
    content:
"Access all your study materials and lessons from any device, ensuring you can learn on your phone, tablet, or laptop—whenever you want. This flexibility allows you to study at your convenience, whether you’re at home, in class, or on the go. With everything available at your fingertips, you can maximize your learning opportunities and never miss a chance to reinforce your knowledge."

 },
 {
   src: require("../../assets/images/Flexible Scheduling.webp"),
 
   title: "Flexible Scheduling",
   content:
 "Fit learning into your busy lifestyle by choosing when and where you study, whether it’s during lunch breaks, while traveling, or at home. This adaptability empowers you to create a study routine that suits your personal schedule. By integrating learning into your daily life, you can make the most of every moment and enhance your productivity without feeling overwhelmed"
 
 },
 
 
  {
    src: require("../../assets/images/Instant Resouce Availability.webp"),

    title: "Instant Resource Availability",
    content:
"Find and access resources quickly without the need for physical textbooks, allowing for spontaneous study sessions whenever inspiration strikes. This instant availability eliminates the hassle of searching for materials, enabling you to dive into learning whenever the opportunity arises. With just a few taps, you can engage with a wealth of information and resources, making study time efficient and effectively"

 },
  ]
 },
//  {
//     title:"Boost Your Confidence",
//     smallCards:[
//       {
//         src: require("../../assets/images/real time occupancy monitoring.webp"),

//         title: "Regular Assessments",
//         content:
// "Take frequent quizzes and tests to monitor your understanding, ensuring you stay aware of your academic progress and knowledge gaps. Regular assessments help reinforce what you’ve learned and provide insights into how well you grasp the material. By keeping tabs on your performance, you can adjust your study strategies and ensure you’re fully prepared for upcoming challenges"

//      },
      
     
//       {
//         src: require("../../assets/images/Accurate Expense Tracking.webp"),

//         title: "Celebrate Milestones",
//         content:
// "Acknowledge your progress with certificates or badges for achieving goals, reinforcing your accomplishments and motivating you to continue learning. Celebrating milestones not only boosts your confidence but also creates a sense of achievement that fuels your motivation. These recognitions serve as tangible reminders of your hard work and dedication, encouraging you to set and pursue new goals"

//      },
//       {
//         src: require("../../assets/images/Personalized Feedback Loop.webp"),

//         title: "Personalised Study Recommendations",
//         content:
// "Get tailored suggestions for resources and activities based on your test results, helping you effectively focus on what you need to improve. Personalised recommendations ensure that your study efforts are targeted and efficient. By addressing specific areas for improvement, you can optimize your learning experience and make meaningful progress toward your educational objectives"

//      },
//     ]
//  },
 {
  title: "Track Your Progress",
  smallCards:[
    {
      src: require("../../assets/images/Comprehensive Performance Analytics.webp"),

      title: "Comprehensive Performance Analytics",
      content:
"Access detailed reports that highlight your strengths, weaknesses, and overall progress, providing clear insights into your learning journey. These analytics offer a thorough understanding of how you are performing in various subjects, enabling you to identify areas where you excel and those that need more attention. With this information, you can make informed decisions about your study strategies and focus on what matters most for your growth"

   },
    {
      src: require("../../assets/images/Goal Achivement Tracking.webp"),

      title: "Goal Achievement Tracking",
      content:
"Set specific learning goals and receive updates on your progress, keeping you motivated and accountable throughout your studies. This feature allows you to define clear objectives and track your advancement towards achieving them. Regular updates serve as reminders of your goals, helping you stay committed and motivated to reach new milestones in your educational journey"

   },
    {
      src: require("../../assets/images/Visual Pogress Charts.webp"),

      title: "Visual Progress Charts",
      content:
"Utilise easy-to-read graphs and charts to visualize your improvement over time, making it satisfying to see your hard work pay off. These visual representations make it easier to understand your progress at a glance. By seeing your growth displayed graphically, you can celebrate achievements and identify patterns in your learning, encouraging you to continue striving for excellence"

   },
   
  ]
 },
];

const StackingCardforStudent = ({ customStyles = {} }) => {
  const stackCardsRef = useRef(null);
  const smallCardRefs = useRef([]);
  const screen = useScreenSizes()
  const screenType = useSelector((state) => state.website.screenType);
  useEffect(() => {
    const stackCards = stackCardsRef.current;
    if (!stackCards) return;

    const items = stackCards.getElementsByClassName("js-stack-cards__item");
    let scrollingFn = null;
    let scrolling = false;

    const setStackCards = () => {
      const marginY = parseInt(
        getComputedStyle(stackCards).getPropertyValue("--stack-cards-gap"),
        10
      );
      stackCards.style.paddingBottom = `${marginY * (items.length - 1)}px`;

      for (let i = 0; i < items.length; i++) {
        // items[i].style.transform = `translateY(${marginY * i}px)`;
      }
    };

    const stackCardsCallback = (entries) => {
      if (entries[0].isIntersecting) {
        if (scrollingFn) return;
        stackCardsInitEvent();
      } else {
        if (!scrollingFn) return;
        window.removeEventListener("scroll", scrollingFn);
        scrollingFn = null;
      }
    };

    const stackCardsInitEvent = () => {
      scrollingFn = stackCardsScrolling;
      window.addEventListener("scroll", scrollingFn);
    };

    const stackCardsScrolling = () => {
      if (scrolling) return;
      scrolling = true;
      window.requestAnimationFrame(animateStackCards);
    };

    const animateStackCards = () => {
      const marginY = parseInt(
        getComputedStyle(stackCards).getPropertyValue("--stack-cards-gap"),
        10
      );
      const top = stackCards.getBoundingClientRect().top;
      const cardStyle = getComputedStyle(items[0]);
      const cardTop = Math.floor(parseFloat(cardStyle.getPropertyValue("top")));
      const cardHeight = Math.floor(
        parseFloat(cardStyle.getPropertyValue("height"))
      );
      const windowHeight = window.innerHeight;

      if (
        cardTop - top + windowHeight - cardHeight + marginY * items.length >
        0
      ) {
        scrolling = false;
        return;
      }

      for (let i = 0; i < items.length; i++) {
        const scrollingValue = cardTop - top - i * (cardHeight + marginY);
        if (scrollingValue > 0) {
          const scaling =
            i === items.length - 1
              ? 1
              : (cardHeight - scrollingValue * 0.05) / cardHeight;
          items[i].style.transform = `translateY(${
            marginY * i
          }px) scale(${scaling})`;
        } else {
          items[i].style.transform = `translateY(${marginY * i}px)`;
        }
      }

      scrolling = false;
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const cardIndex = Array.from(items).indexOf(entry.target);
        if (entry.isIntersecting) {
          setActiveButtonIndex(cardIndex); // Update active button index based on visible card
        }
      });
    });

    for (let i = 0; i < items.length; i++) {
      observer.observe(items[i]);
    }

    window.addEventListener("resize", setStackCards);
    return () => {
      window.removeEventListener("resize", setStackCards);
      observer.disconnect();
      window.removeEventListener("scroll", scrollingFn);
    };
  }, []);
  const [activeButtonIndex, setActiveButtonIndex] = useState(0);
 
  const [textScale, setTextScale] = useState(1);
 

const {t} = useTranslation()  
const increaseTextScale = () => {
  setTextScale((prevScale) => prevScale + 0.1);
};

const decreaseTextScale = () => {
  setTextScale((prevScale) => (prevScale > 1 ? prevScale - 0.1 : 1));
};

const defaultStyles = {
  container: {
    "--stack-cards-gap": "40px",
    "--stack-cards-item-ratio": "2/1",
  },
  stackCards: {
    paddingBottom: "calc(var(--stack-cards-gap) * 1)",
  },
  cardItem: {
    position: "relative",
    position: "sticky",
    top: "30px",
    paddingBottom: "calc(40vh / var(--stack-cards-item-ratio))",
    transformOrigin: "center top",
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    marginBottom: "110px",
    width: "95%",
    borderRadius: "10px",
    boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.3)",
    margin: "0 auto",
    padding: "15px",
  },
  cardContent: {
    position: "relative",
    top: 0,
    left: 0,
    height: "100%", // Default height
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    backgroundColor: "#ffffff",
    padding: "10px",
    borderRadius: "10px",
    justifyContent: "space-between",
    transition: "height 0.3s ease",
  },
  smallCardContainer: {
    display: "flex",
    width: "100%",
    overflowX: "scroll",
    overflowY: "auto",
    height: "100%",
    padding: "10px 11px",

  },
  smallCard: {
    height: "54vh",
    width: "20%",
    padding:"10px",
    borderRadius: "8px",
   
    marginRight: "10px",
    flex:
    screenType === 'smallPhone' || screenType === 'phone'
      ? '0 0 70%'
      : screenType === 'tablet'
      ? '0 0 30%'
      : screenType === 'tab'
      ? '0 0 32.5%'
      : '0 0 21%',
    display: "flex",
    flexDirection: "column",
    overflowX: "hidden",
    justifyContent: "flex-start",
    overflowY: "auto",
    position: "relative",
    transition: "height 0.3s ease",
    boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.3)",
  },
  smallCardImage: {
    height: "70%",
    borderRadius: "8px 8px 0 0",
    objectFit: "cover",
    transition: "height 0.3s ease",
  },
  smallCardContent: {
    height: "auto",
    display: "flex",
    flexDirection: "column",
    textOverflow: "ellipsis",
    fontSize: "1.1rem",
    overflowX: "hidden",
    overflowY: "scroll",
    whiteSpace: "normal",
    backgroundColor: "#ffffff",
    rowGap: "13px",
    borderRadius: "0 0 8px 8px",
    transition: "height 0.3s ease",
    position: "absolute",
    bottom: "0",
    width: "100%",
    padding: "5px",
    marginTop: "20px",
  },
  smallCardHover: {
    height: "80%", // Expanded height on hover
  },
  title: {
    padding: "10px", // Added padding for title
    fontSize: "1.5rem", // Adjust as needed
    color: "#333", // Title color
    flexShrink: 0, // Prevent shrinking
  },
  smallCardTitle: {
    display:"flex",
    width:"90%",
    flexWrap:"wrap",
    fontSize: "1.7rem", // Adjust size as needed
    color: "black", // Title color
    flexShrink: 0, // Prevent title from shrinking
  },
  navbar: {
    display: "flex",
    width: "100vw",
    overflowX: "scroll",
    justifyContent: "flexstart",
  },
  navItem: {
    marginRight: "10px",
    cursor: "pointer",
    color: "grey",
    borderRadius: "4px",
    fontSize: "1rem",
  },
  activeNavItem: {
    textDecoration: "underline",
    fontWeight: "bold",
    color: "black",
  },
  cardtitle: {
    margin: "0px",
    fontSize: "2rem",
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  button: {
    padding: '10px 20px',
    margin: '0 10px',
    borderRadius: '5px',
    border: 'none',
    backgroundColor: '#007bff',
    color: '#fff',
    cursor: 'pointer',
  },
};
const [scroll , setScroll]= useState(false)
return (
  <>
    {/* <div style={defaultStyles.buttonContainer}>
      <button style={defaultStyles.button} onClick={increaseTextScale}>Increase Text Size</button>
      <button style={defaultStyles.button} onClick={decreaseTextScale}>Decrease Text Size</button>
    </div> */}

    <div
      style={{ ...defaultStyles.container, ...customStyles.container }}
      ref={stackCardsRef}
    >
      <div style={defaultStyles.stackCards}>
        {cardData.map((card, largeCardIndex) => (
          <div
            style={{ ...defaultStyles.cardItem, ...card.largeCardStyle }}
            key={largeCardIndex}
            className="js-stack-cards__item"
          >
            <div style={defaultStyles.cardContent}>
              <p style={{ ...defaultStyles.cardtitle, fontSize: `${2 * textScale}rem` }}>{t(card.title)}</p>
              <div style={defaultStyles.smallCardContainer}>
                  {card.smallCards.map((smallCard, smallCardIndex) => (
                    <div
                      key={smallCardIndex}
                      ref={(el) => {
                        if (!smallCardRefs.current[largeCardIndex]) {
                          smallCardRefs.current[largeCardIndex] = [];
                        }
                        smallCardRefs.current[largeCardIndex][smallCardIndex] = el;
                      }}
                      style={defaultStyles.smallCard}
                      className="small-card"
                      data-index={smallCardIndex}
                      onMouseEnter={(e) => {
                        const content = e.currentTarget.querySelector('.small-card-content');
                        content.style.height = '90%'; // Expand content height on hover
                        setScroll(true)
                      }}
                      onMouseLeave={(e) => {
                        const content = e.currentTarget.querySelector('.small-card-content');
                        content.style.height = '30%'; // Reset to original height
                          setScroll(false)
                          content.scrollTop = 0;
                       
                      }} 
                      onClick={(e) => {
                        if (screenType === "smallPhone" || screenType === "phone"||screenType==="tab"||screenType==="tablet") {
                          const content = e.currentTarget.querySelector(".small-card-content");
                          // Toggle the height between 90% and 30%
                          if (content.style.height === "90%") {
                            content.style.height = "30%";
                            setScroll(false);
                            content.scrollTop = 0;
                          } else {
                            content.style.height = "90%";
                            setScroll(true);
                          }
                        }
                      }}
                    >
                      <img
                        src={smallCard.src}
                        alt={smallCard.alt}
                        style={defaultStyles.smallCardImage}
                        className="small-card-image"
                        loading="lazy" 
                      />
                      <div
                        style={{
                          ...defaultStyles.smallCardContent,
                          height: '30%', // Initial height
                        }}
                        className="small-card-content"
                      >
                        <div style={defaultStyles.smallCardTitle}>
                          <p style={{ display: 'flex', marginLeft: '20px' }}>{t(smallCard.title)}</p>
                        </div>
                        <div
                          style={{
                            height: '100%',
                            height: 'auto', // Set to auto to allow it to expand
                            flex: '1',
                            padding: '20px',
                          }}
                        >
                          {t(smallCard.content)}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </>
);
};

export default StackingCardforStudent;