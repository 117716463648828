import imageCompression from "browser-image-compression";

/**
 * Resizes an image file (or multiple files) based on the specified size parameter.
 *
 * @param {File|File[]} images - A single File object or an array of File objects to be resized.
 * @param {string|null} size - Optional size parameter: 
 *                             If 'thumb', the image(s) will be resized to a thumbnail size (200x240).
 *                             Otherwise, it defaults to a larger size (1980x1080).
 * @returns {Promise<File[]>} A promise that resolves to an array of resized image Files.
 */
export const resizeImage = async (images, size = null) => {
  const resizedImages = [];

  // Define the resizing parameters
  const params = {
    large: {
      maxWidthOrHeight: 1980,
      maxSizeMB: 1, // Maximum file size in MB
    },
    thumb: {
      maxWidthOrHeight: 240,
      maxSizeMB: 0.2, // Reduce size for thumbnails
    },
  };

  // Select the correct parameters
  const resizeParams = size ? params[size] : params.large;

  // Ensure the input is an array
  const imagesArray = Array.isArray(images) ? images : [images];

  // Resize each image
  for (const image of imagesArray) {
    try {
      const compressedFile = await imageCompression(image, resizeParams);
      resizedImages.push(compressedFile);
    } catch (error) {
      console.error("Error resizing image:", error);
    }
  }

  return resizedImages;
};
