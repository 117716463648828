import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';



// How to use

//  1-----> copy these state and paste in file you want to use 

//     const [alertModalType,setAlertModalType]=useState(null)
//     const [alertModalMessage,setAlertModalMessage]=useState(null)
//     const [alertModalHeader,setAlertModalHeader]=useState(null)
//     const [alertModalButtons,setAlertModalButtons]=useState(null)
//     const [isAlertModalVisisble,setIsAlertModalVisisble]=useState(false)
//     const toggleAlertModal=()=>{
//       setIsAlertModalVisisble(!isAlertModalVisisble)
//     }


//  2----> Render the Alert Modal in the component
        {/* <AlertModal 
        type={alertModalType} // 'Error' | 'Informative'|'Success'|'Warning' (type of Error)
        message={alertModalMessage} // string (message to display)
        headerText={alertModalHeader} // string (text for the header of the Error)
        buttons={alertModalButtons}  // Array of objects: { name: string, onClick: function | 'toggle' } (buttons array)
        isVisible={isAlertModalVisisble} // boolean (whether the modal is visible)
        toggleAlertModal={toggleAlertModal} // function to toggle modal visibility
        closeModalOnTappingOutside={true} // boolean for enabling/disabling closing modal on tapping outside by default false
        varient={'middle'}  //middle
         backgroundOpacity, //will work when blur is false
    blur, // boolean for enabling/disabling blur effect
    blurAmount, // blur amount 
        /> */}


// 3---> use these where you want to show modal
    // setAlertModalMessage("something Went Wrong Please try again later")
    // setAlertModalButtons([{name:'ok',onClick:'toggle'}])
    // setAlertModalHeader('Error')
    // setAlertModalType('Error')
    // setIsAlertModalVisisble(true)


// Above commented code is  use case example

const AlertModal = ({
    type, // 'Error' | 'Informative'|'Success'|'Warning'
    message, // string (message to display)
    headerText, // string (header text for the alert)
    buttons,  // Array of objects: { name: string, onClick: function | 'toggle' }
    isVisible, // boolean (whether the modal is visible)
    toggleAlertModal, // function to toggle modal visibility
    // 'middle' or 'slide'
    varient, 
    backgroundOpacity, //will work when blur is false between 0 to 1
    blur, // boolean for enabling/disabling blur effect
    blurAmount, // blur amount 
    initialCover,
    closeModalOnTappingOutside=false // boolean for enabling/disabling closing modal on tapping outside by default false
}) => {
    const { t } = useTranslation();
    console.log('headerText: ', headerText);

    const handleButtonPress = (button) => {
        if (button.onClick === 'toggle') {
            toggleAlertModal();
        } else {
            button.onClick();
        }
    };
console.log(type)
    const imageSource =
        type === 'Warning'
            ? require('../../assets/images/warning1.webp')
            : type === 'Error'
            ? require('../../assets/images/warning.webp')
            : type === 'Success'
            ? require('../../assets/images/success.webp')
            : null;

    const styles = {
        mainContainer: {
            backgroundColor: `rgba(0,0,0,${backgroundOpacity||0.5})`,
            flex: 1,
            justifyContent:  varient === 'middle' ? 'center' : 'flex-end',
            alignItems: 'center',
             display:"flex"
        },
        blurContainer: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            display:"flex"
        },
        mainContainer1: {
            backgroundColor:
                type === 'Warning'
                    ? '#fad372'
                    : type === 'Error'
                    ? 'red'
                    : type === 'Success'
                    ? 'green'
                    : 'grey',
            flexDirection: 'column',
            width: varient === 'middle' ? '75%' : '100%',
            borderRadius: 10,
        },
        container: {
            backgroundColor: 'white',
            borderRadius: 10,
            padding: 0,
            overflow:'hidden'
        },
        header: {
            width: '100%',
            backgroundColor: 'skyblue',
            padding: 10,
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            alignItems: 'center',
        },
        headerText: {
            color: 'white',
            fontSize: 20,
            fontWeight: 'bold',
        },
        alertText: {
            fontSize: 18,
            color: 'gray',
            textAlign: 'center',
        },
        buttonsContainer: {
            flexDirection: 'row',
            justifyContent: 'flex-end',
            margin: 20,
             display:"flex"
        },
        button: {
            marginHorizontal: 10,
            cursor:"pointer"

        },
        buttonText: {
            color: 'skyblue',
            fontSize: 16,
            cursor:"pointer"
        },
    };
    
    console.log('imageSource: ', imageSource);
    return (
        <div className="modal-overlay" style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: blur ? 'transparent' : `rgba(0, 0, 0, ${backgroundOpacity || 0.5})`,
            display: 'flex',
            justifyContent:  varient === 'middle' ? 'center' : 'flex-end',
            alignItems: 'center',
            backdropFilter: blur ? `blur(${blurAmount}px)` : 'none',
        }} onClick={() => closeModalOnTappingOutside && toggleAlertModal()}>            {/* Conditionally render the BlurView based on the blur prop */}
            {blur && (
             <motion.div
             style={{
               position: 'absolute',
               width: '100%',
               height: '100%',
               backdropFilter: `blur(${blurAmount}px)`,
               backgroundColor: 'rgba(255, 255, 255, 0.2)',
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center'
             }}
           >
             <div style={styles.mainContainer}>
               <div style={styles.mainContainer1}>
                 <div style={styles.container}>
                   <div style={{ justifyContent: 'center', alignItems: 'center', marginTop: 10 ,  display:"flex"}}>
                     {type !== 'Informative' && (
                       <div
                         style={
                           type === 'Warning'
                             ? { width: 80, height: 80 }
                             : type === 'Success'
                             ? { width: 65, height: 65, marginTop: 10 }
                             : { width: 60, height: 60 }
                         }
                       >
                         <img
                           src={imageSource}
                           alt="Alert Icon"
                           style={{ height: '100%', width: '100%', objectFit: 'contain' }}
                         />
                       </div>
                     )}
                   </div>
       
                   <div style={{ justifyContent: 'center', alignItems: 'center', marginTop: type === 'Informative' ? 20 : null ,  display:"flex" }}>
                     <p style={{ fontSize: 20, fontWeight: 'bold', color: 'black' }}>{t(headerText)}</p>
                   </div>
       
                   <div style={{ margin: '0 30px', marginTop: 5 }}>
                     <p style={styles.alertText}>{t(message)}</p>
                   </div>
       
                   <div style={styles.buttonsContainer}>
                     {buttons &&
                       buttons.map((button, index) => (
                         <button key={index} style={styles.button} onClick={() => handleButtonPress(button)}>
                           {t(button.name)}
                         </button>
                       ))}
                   </div>
                 </div>
               </div>
             </div>
           </motion.div>
            )}
            {/* If blur is false, render the modal without blur */}
            {!blur && (
                <div style={styles.mainContainer} activeOpacity={1} onClick={()=>{if(closeModalOnTappingOutside){toggleAlertModal()}}}>
                    <div style={styles.mainContainer1}>
                        <div style={styles.container}>
                        {initialCover && <div style={{height:7,backgroundColor:type=='Warning'?'orange':type=='Error'?'red':type=='Success'?'green':'#ac7ffa'}}></div>}
                            <div style={{ justifyContent: 'center', alignItems: 'center', marginTop: 10 ,  display:"flex" }}>
                                {type !== 'Informative' && (
                                    <div style={type === 'Warning' ? { width: 80, height: 80 } : type === 'Success' ? { width: 75, height: 75, marginTop: 10 } : { width: 60, height: 60 }}>
                                        <img src={imageSource} style={{ height: '100%', width: '100%', resizeMode: 'contain' }} />
                                    </div>
                                )}
                            </div>

                            <div style={{ justifyContent: 'center', alignItems: 'center', marginTop: type === 'Informative' ? 20 : null ,  display:"flex" }}>
                                <p style={{ fontSize: 20, fontFamily: 'Lora-Bold', color: 'black' }}>{t(headerText)}</p>
                            </div>

                            <div style={{ marginHorizontal: 30, marginTop: 5 }}>
                                <p style={styles.alertText}>{t(message)}</p>
                            </div>

                            <div style={styles.buttonsContainer}>
                                {buttons &&
                                    buttons.map((button, index) => (
                                        <div key={index} style={styles.button} onClick={() => handleButtonPress(button)}>
                                            <p style={styles.buttonText}>{t(button.name)}</p>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AlertModal;