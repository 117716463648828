import React, { useState } from "react";
import { Send, Phone, Mail, MapPin } from "lucide-react";
import { firestore } from "../../firebase";
import { addDoc, collection } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import Alert from "../../Components/Alert";

function App() {
  const [focusedField, setFocusedField] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [ showSuccess ,setShowSucces] = useState(false)
  const { t } = useTranslation();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission

    try {
      // Add the form data to Firestore
      await addDoc(collection(firestore, "userInfo"), {
        name: formData.name,
        email: formData.email,
        subject: formData.subject,
        message: formData.message,
        timestamp: new Date(), // Add a timestamp
      });

      setShowSucces(true);
      setFormData({ name: "", email: "", subject: "", message: "" }); // Clear the form
    } catch (error) {
     
    }
  };

  const styles = {
    container: {
      minHeight: "60vh",
      // background: 'linear-gradient(120deg, #E2E8F0 0%, #EEF2FF 100%)',
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
     
      padding: "40px 20px",
      fontFamily: "system-ui, sans-serif",
    },
    card: {
      display: "flex",
      flexDirection: window.innerWidth <= 768 ? "column" : "row",
      backgroundColor: "rgba(255, 255, 255, 0.7)",
      backdropFilter: "blur(20px)",
      borderRadius: "32px",
      boxShadow: isHovered
        ? "0 30px 60px rgba(0, 0, 0, 0.12), inset 0 0 0 1px rgba(255, 255, 255, 0.5)"
        : "0 20px 40px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.3)",
      width: "90%",
      // maxWidth: "1200px",
      overflow: "hidden",
      transition: "all 0.5s cubic-bezier(0.4, 0, 0.2, 1)",
      position: "relative",
    },
    contactInfo: {
      flex: "1",
      background:
        "linear-gradient(135deg, rgb(26, 27, 30) 0%, rgb(158, 168, 200) 100%)",
      padding: "60px 40px",
      display: "flex",
      flexDirection: "column",
      gap: "40px",
      position: "relative",
      overflow: "hidden",
      borderRadius: "24px",
      margin: "12px",
    },
    pattern: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      opacity: 0.1,
      background: `radial-gradient(circle at 100% 0%, rgba(255,255,255,0.2) 0%, transparent 25%),
                  radial-gradient(circle at 0% 100%, rgba(255,255,255,0.2) 0%, transparent 25%)`,
      zIndex: 0,
    },
    contactTitle: {
      fontSize: "36px",
      fontWeight: "800",
      color: "white",
      marginBottom: "30px",
      position: "relative",
      zIndex: 1,
      letterSpacing: "-0.03em",
    },
    contactItem: {
      display: "flex",
     flexWrap:"wrap",
      alignItems: "center",
      gap: "20px",
      padding: "20px",
      background: "rgba(255, 255, 255, 0.1)",
      borderRadius: "20px",
      backdropFilter: "blur(10px)",
      transition: "all 0.3s ease",
      cursor: "pointer",
      zIndex: 1,
      border: "1px solid rgba(255, 255, 255, 0.15)",
    },
    contactText: {
      fontSize: "16px",
      color: "white",
      fontWeight: "500",
      letterSpacing: "0.01em",
    },
    iconWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "48px",
      height: "48px",
      borderRadius: "16px",
      background: "rgba(255, 255, 255, 0.15)",
      backdropFilter: "blur(5px)",
      transition: "all 0.3s ease",
    },
    formSection: {
      flex: "1.5",
      padding: "60px 50px",
      background: "rgba(255, 255, 255, 0.5)",
      backdropFilter: "blur(10px)",
      position: "relative",
    },
    formTitle: {
      fontSize: "36px",
      fontWeight: "800",
      color: "#1E293B",
      marginBottom: "40px",
      letterSpacing: "-0.03em",
    },
    form: {
      display: "flex",
      flexDirection: "column",
      gap: "28px",
    },
    inputGroup: {
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    },
    label: {
      fontSize: "14px",
      fontWeight: "600",
      color: "#475569",
      textTransform: "uppercase",
      letterSpacing: "0.05em",
      transition: "all 0.2s ease",
    },
    input: (isFocused) => ({
      padding: "18px 24px",
      borderRadius: "20px",
      border: "2px solid",
      borderColor: isFocused ? "#2563EB" : "rgba(0, 0, 0, 0.1)",
      fontSize: "16px",
      transition: "all 0.3s ease",
      background: isFocused ? "white" : "rgba(255, 255, 255, 0.8)",
      outline: "none",
      boxShadow: isFocused
        ? "0 0 0 4px rgba(37, 99, 235, 0.1), 0 4px 12px rgba(0, 0, 0, 0.05)"
        : "0 2px 4px rgba(0, 0, 0, 0.02)",
    }),
    textarea: (isFocused) => ({
      padding: "18px 24px",
      borderRadius: "20px",
      border: "2px solid",
      borderColor: isFocused ? "#2563EB" : "rgba(0, 0, 0, 0.1)",
      fontSize: "16px",
      minHeight: "180px",
      resize: "vertical",
      transition: "all 0.3s ease",
      background: isFocused ? "white" : "rgba(255, 255, 255, 0.8)",
      outline: "none",
      boxShadow: isFocused
        ? "0 0 0 4px rgba(37, 99, 235, 0.1), 0 4px 12px rgba(0, 0, 0, 0.05)"
        : "0 2px 4px rgba(0, 0, 0, 0.02)",
    }),
    button: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "12px",
      background: "linear-gradient(135deg, #2563EB 0%, #1E40AF 100%)",
      color: "white",
      padding: "20px 40px",
      borderRadius: "20px",
      border: "none",
      fontSize: "18px",
      fontWeight: "600",
      cursor: "pointer",
      transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
      marginTop: "20px",
      position: "relative",
      overflow: "hidden",
      width:"50%",
      boxShadow: "0 4px 12px rgba(37, 99, 235, 0.2)",
    },
    buttonContent: {
      display: "flex",
      alignItems: "center",
    
      gap: "12px",
      transform: "translateY(0)",
      transition: "transform 0.3s ease",
    },
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   console.log('Form submitted:', formData);
  // };

  // const handleChange = (e) => {
  //   setFormData({
  //     ...formData,
  //     [e.target.name]: e.target.value
  //   });
  // };

  return (
    <div style={styles.container}>
      <div
        style={styles.card}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div style={styles.contactInfo}>
          <div style={styles.pattern} />
          <h2 style={styles.contactTitle}>{t("Let's get in touch")}</h2>
         
          <div
            style={styles.contactItem}
            onMouseEnter={(e) => {
              e.currentTarget.style.transform = "translateY(-4px)";
              e.currentTarget.style.background = "rgba(255, 255, 255, 0.15)";
              e.currentTarget.style.boxShadow =
                "0 10px 20px rgba(0, 0, 0, 0.1)";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.transform = "none";
              e.currentTarget.style.background = "rgba(255, 255, 255, 0.1)";
              e.currentTarget.style.boxShadow = "none";
            }}
          >
            <div style={styles.iconWrapper}>
              <Mail size={24} color="white" />
            </div>
            <span style={styles.contactText}>{t("support@studybuddy.education")}</span>
          </div>
          <div
            style={styles.contactItem}
            onMouseEnter={(e) => {
              e.currentTarget.style.transform = "translateY(-4px)";
              e.currentTarget.style.background = "rgba(255, 255, 255, 0.15)";
              e.currentTarget.style.boxShadow =
                "0 10px 20px rgba(0, 0, 0, 0.1)";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.transform = "none";
              e.currentTarget.style.background = "rgba(255, 255, 255, 0.1)";
              e.currentTarget.style.boxShadow = "none";
            }}
          > 
            <div style={styles.iconWrapper}>
              <MapPin size={24} color="white" />
            </div>
            <span style={styles.contactText}>
              {t(" Dhan Mandi, HMO, Rajasthan, India")}
            </span>
          </div> 
          <div
            style={styles.contactItem}
            onMouseEnter={(e) => {
              e.currentTarget.style.transform = "translateY(-4px)";
              e.currentTarget.style.background = "rgba(255, 255, 255, 0.15)";
              e.currentTarget.style.boxShadow =
                "0 10px 20px rgba(0, 0, 0, 0.1)";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.transform = "none";
              e.currentTarget.style.background = "rgba(255, 255, 255, 0.1)";
              e.currentTarget.style.boxShadow = "none";
            }}
          >
            <div style={styles.iconWrapper}>
              <Phone size={24} color="white" />
            </div>
            <span style={styles.contactText}>+91 8442002022</span>
          </div>
        </div>

        <div style={styles.formSection}> 
          <div style={{}}>

          <h1 style={styles.formTitle}>{t("Send a message")}</h1>
          </div>
          <form onSubmit={handleSubmit} style={styles.form}>
            <div style={styles.inputGroup}>
              <label
                style={{
                  ...styles.label,
                  color: focusedField === "name" ? "#2563EB" : "#475569",
                }}
              >
                Name
              </label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                style={styles.input(focusedField === "name")}
                placeholder="Your name"
                required
                onFocus={() => setFocusedField("name")}
                onBlur={() => setFocusedField("")}
              />
            </div>

            <div style={styles.inputGroup}>
              <label
                style={{
                  ...styles.label,
                  color: focusedField === "email" ? "#2563EB" : "#475569",
                }}
              >
               {t(" Email")}
              </label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                style={styles.input(focusedField === "email")}
                placeholder="Your email"
                required
                onFocus={() => setFocusedField("email")}
                onBlur={() => setFocusedField("")}
              />
            </div>

            <div style={styles.inputGroup}>
              <label
                style={{
                  ...styles.label,
                  color: focusedField === "subject" ? "#2563EB" : "#475569",
                }}
              >
               {t(" Subject")}
              </label>
              <input
                type="text"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                style={styles.input(focusedField === "subject")}
                placeholder="What's this about?"
                required
                onFocus={() => setFocusedField("subject")}
                onBlur={() => setFocusedField("")}
              />
            </div>

            <div style={styles.inputGroup}>
              <label
                style={{
                  ...styles.label,
                  color: focusedField === "message" ? "#2563EB" : "#475569",
                }}
              >
                Message
              </label>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                style={styles.textarea(focusedField === "message")}
                placeholder="Your message here..."
                required
                onFocus={() => setFocusedField("message")}
                onBlur={() => setFocusedField("")}
              />
            </div>
<div style={{width:"100%", display:"flex", justifyContent:"center"}}>

            <button
              type="submit"
              style={styles.button}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = "translateY(-4px)";
                e.currentTarget.style.boxShadow =
                  "0 15px 30px rgba(37, 99, 235, 0.3)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = "translateY(0)";
                e.currentTarget.style.boxShadow =
                  "0 4px 12px rgba(37, 99, 235, 0.2)";
              }}
            >
              <div style={styles.buttonContent}>
                Send Message
                <Send size={20} />
              </div> 
            </button>
            {showSuccess && (
          <Alert
          type="success" 
            message="Message sent successfully!"
            show={showSuccess}
            onClose={() => setShowSucces(false)}
          />
        )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default App;
