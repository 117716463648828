import { collection, getDocs } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { firestore } from "../../firebase";

const defaultStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '20px',
    width: '96%',
    backgroundColor: '#fff',
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    margin: '5vw auto',
  },
  jobTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  jobDetails: {
    display: 'flex',
    alignItems: 'center',
    color: '#757575',
    marginBottom: '20px',
  },
  detailItem: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '20px',
  },
  locationIcon: {
    height: '20px',
    marginRight: '5px',
  },
  jobDescription: {
    fontSize: '16px',
    color: '#424242',
    marginBottom: '20px',
  },
  applyButton: {
    backgroundColor: '#5A67D8',
    color: '#fff',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    textDecoration: 'none',
    fontWeight: 'bold',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
};

const Careerscard = ({
 
  containerStyle,
  jobTitleStyle,
  jobDetailsStyle,
  jobDescriptionStyle,
  applyButtonStyle,
  locationIconStyle,
  buttonContainerStyle,
}) => {
  const navigate = useNavigate();
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, "jobs"));
        const jobsArray = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setJobs(jobsArray);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchJobs();
  }, []);

  const handleApplyClick = (job) => {
    navigate("/jobApplication", { state: { jobTitle: job.jobTitle } });
  };
 

 
if (loading) {
  return (
    <div style={defaultStyles.loader}>
      <div style={defaultStyles.spinner}></div>
    </div>
  );
}

console.log(jobs)
  return (
    <div style={{ padding: "20px" }}>
      {jobs.length === 0 ? (
        <div>No job listings available</div>
      ) : (
        jobs.map((job) =>  (
     
          <div key={job.id} style={{ ...defaultStyles.container, ...containerStyle }}>
            <div style={{ ...defaultStyles.jobTitle, ...jobTitleStyle }}>
              {job.jobTitle || "Subject Matter Expert"}
            </div>

            <div style={{ ...defaultStyles.jobDetails, ...jobDetailsStyle }}>
              <div style={defaultStyles.detailItem}>
                <img
                  src={require("../../assets/images/location.webp")}
                  style={{ ...defaultStyles.locationIcon, ...locationIconStyle }}
                  alt="location"
                  loading="lazy" 
                />
                <p>{job.location || "Anywhere India"}</p>
              </div>
              <div style={defaultStyles.detailItem}>
                        <img
              src={job.jobTime === "Full Time"
                ? require("../../assets/Icons/fulday.png")
                : require("../../assets/Icons/halftime.png")}
              style={{ ...defaultStyles.locationIcon, ...locationIconStyle }}
              alt="jobtime"
              loading="lazy" 
            />

                <p>{job.jobTime || "Full Time"}</p>
              </div>
            </div>

            <div style={{ ...defaultStyles.jobDescription, ...jobDescriptionStyle }}>
              {job.jobInfo || "Job description not available."}
            </div>

            <div style={{ ...defaultStyles.buttonContainer, ...buttonContainerStyle }}>
              <button
                style={{ ...defaultStyles.applyButton, ...applyButtonStyle }}
                onClick={() => handleApplyClick(job)}
              >
                Apply Now
              </button>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default Careerscard;
