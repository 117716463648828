import React, { useEffect, useRef, useState, useCallback } from 'react';
import UserSearch from '../../searching/UserSearch';
import ReusableModal from '../ReusableModal';
import { AnimatePresence , motion } from 'framer-motion';
import { display } from '@mui/system';

const UserCardDropdown = ({ data, selectedCard, setSelectedCard, title, singleUser, disabled, SBuser, onlyModal, setOnlyModal, outerFunc, share, onShare }) => {



    const [selected, setSelected] = useState(onlyModal || false);
    const [userObject, setUserObject] = useState(selectedCard || {});
    const [backupObject, setBackupObject] = useState(userObject);


    const styles = {
        container: {
            margin: 5,
            padding: 10,
            backgroundColor: "red",
            borderRadius: 10,
        },
        row: {
            flexDirection: "row",
            flexWrap: "wrap",
            rowGap: 0,
            justifyContent: "space-between",
            alignItems: 'center',
            display : "flex"
        },
        title: {
            fontFamily: "MarkerFelt",
            fontSize: 20,
            color: "#3B486F",
            marginTop: 5,
            marginLeft: 5
        },
        dropdown: {
            margin: 0,
            flex: 1,
            height: 50,
            borderRadius: 10,
            paddingHorizontal: 10,
            backgroundColors:"red",
            minWidth: 180
        },
        placeholderStyle: {
            color: '#aaa',
        },
        selectedTextStyle: {
            color: '#333',
        },
        dropdownContainer: {
            borderRadius: 10,
            padding: 10,
        },
        inputSearchStyle: {
            height: 40,
            fontSize: 16,
            borderRadius: 10,
            color: "black"
        },
        card: {
            flexDirection: 'row',
            alignItems: 'center',
            padding: 10,
            backgroundColor: 'grey',
            borderRadius: 10,
            marginVertical: 5,
            display: 'flex',

        },
        imageContainer: {
            width: 50,
            height: 50,
            borderRadius: 25,
            overflow: 'hidden',
            marginRight: 10,
        },
        image: {
            width: '100%',
            height: '100%',
        },
        infoContainer: {
            flex: 1,
        },
        name: {
            fontSize: 16,
            fontWeight: 'bold',
            color: '#333',
        },
        role: {
            fontSize: 14,
            color: '#666',
        },
        selector: {
            width: '40%',
            backgroundColor: 'red',
            padding: 8,
            borderRadius: 10,
            margin: 5,

        },
        overlay: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Use transparent background for modal
        },
        modalSelector: {
            height: '90%',
            backgroundColor: 'red',
            borderRadius: 10,
            padding: 10,
            paddingBottom: 0,
            elevation: 5
        },
        modalContainer: {
            flex: 1, // Take up the full screen
            justifyContent: 'center', // Center vertically
            alignItems: 'center', // Center horizontally
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
            display : "flex"
        },
        namePill: {
            backgroundColor: '#D1C4E9',
            paddingHorizontal: 10,
            paddingVertical: 5,
            borderRadius: 10,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            columnGap: 5,
            display: 'flex',
        },
        lowerCard: {
            backgroundColor: '#fff',
            height: '10%',
            // width: '100%',
            marginVertical: 5,
            borderRadius: 10,
            elevation: 5,
            padding: 10,
            justifyContent: 'space-between',
            flexDirection: 'row',
            alignItems: 'center',
            display: 'flex',
        },
        selector: {
            padding: "10px",
            backgroundColor: "red",
            borderRadius: "10px",
            cursor: "pointer",
            userSelect: "none",
        },
        text: {
            color: "black",
            fontSize: "20px",
            textAlign: "right",
            fontFamily: "MarkerFelt",
        },
        overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "red",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 999,
            display     : "flex",   
        },
        modalSelector: {
            background: "red",
            padding: "20px",
            borderRadius: "15px",
            boxShadow: "0 10px 25px rgba(0, 0, 0, 0.3)",
            width: "400px",
            maxWidth: "90%",
        },
        modalFooter: {
            marginTop: "20px",
            backgroundColor:"red"
        },
        footerActions: {
            display: "flex",
            justifyContent: "space-between",
            marginTop: "15px",
        },
        closeButton: {
            background: "orange",
            padding: "10px 20px",
            borderRadius: "10px",
            border: "none",
            cursor: "pointer",
        },
        doneButton: {
            background: "#50C878",
            padding: "10px 20px",
            borderRadius: "10px",
            border: "none",
            color: "white",
            cursor: "pointer",
        },
    };
    const handleDropDown = () => {
        setSelected((prev) => !prev);
        setBackupObject({ ...userObject });
    };

    const handleRemove = (value) => {
        // Optional: Add a brief animation effect instead of vibration
        const element = document.getElementById(`user-${value}`);
        if (element) {
            element.style.transition = "opacity 0.2s ease-out";
            element.style.opacity = "0"; // Fades out before removing
        }
    
        setTimeout(() => {
            setUserObject((prev) => {
                if (prev[value]) {
                    const updatedObject = { ...prev };
                    delete updatedObject[value];
    
                    if (setSelectedCard) {
                        setSelectedCard(updatedObject);
                    }
                    return updatedObject;
                }
                return prev;
            });
        }, 200); // Delay to match the animation time
    };
    const handleClose = () => {
        setSelected(false);
        if (singleUser) {
            if (setSelectedCard) {
                setSelectedCard(userObject); // Save the final selected state
            }
            return;
        }
        setUserObject(backupObject); // Restore the backup state
        if (setOnlyModal) {
            setOnlyModal(false)
        }
    };


    const handleDone = () => {
        setSelected(false);
        if (setSelectedCard) {
            setSelectedCard(userObject); // Save the final selected state
        }
        if (setOnlyModal) {
            setOnlyModal(false)
        }
        if (outerFunc) {
            outerFunc()
        }
        
    };
    const backgroundColors = [
        '#B3E5FC',
        '#FFCCBC',
        '#DCEDC8',
        '#FFECB3',
        '#D1C4E9',
        '#F8BBD0',
    ];
    const objectIDsToFilter = ['LMS004', 'LMS009', 'LMS001', 'LMS002', 'LMS003', 'LMS005', 'LMS006', 'LMS007', 'LMS008',]

    return (
        <>
            {!onlyModal &&
                <div style={styles.container}>
                    {/* Title and Dropdown in a Row */}
                    <div style={styles.row}>
                        <p style={styles.title} numberOfLines={1}>{title || 'Select a User:'}</p>

                        <div style={styles.selector} onClick={disabled ? null : handleDropDown}>
            <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row-reverse" }}>
                {singleUser && Object.keys(userObject).length > 0 ? (
                    Object.keys(userObject).map((item, index) => (
                        <p key={index} style={styles.text}>
                            {selected ? "Close" : `${userObject[item].firstName} ${userObject[item].lastName}`}
                        </p>
                    ))
                ) : (
                    <p style={styles.text}>
                        {selected ? "Close" : "...Select User"}
                    </p>
                )}
            </div>

            {/* Modal Animation */}
            <AnimatePresence>
                {selected && (
                    <motion.div
                        className="modal-overlay"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                        style={styles.overlay}
                    >
                        <motion.div
                            initial={{ scale: 0.8 }}
                            animate={{ scale: 1 }}
                            exit={{ scale: 0.8 }}
                            transition={{ duration: 0.3 }}
                            style={styles.modalSelector}
                        >
                            <UserSearch
                                userObject={userObject}
                                setUserObject={setUserObject}
                                handleDropDown={handleDropDown}
                                idsArray={data}
                                singleUser={singleUser}
                                SBUser={SBuser}
                            />
                            <div style={styles.modalFooter}>
                                {singleUser &&
                                    Object.keys(userObject).length > 0 &&
                                    Object.keys(userObject).map((item, index) => (
                                        <p key={index} style={styles.text}>
                                            {userObject[item].firstName} {userObject[item].lastName}
                                        </p>
                                    ))}

                                <div style={styles.footerActions}>
                                    <button onClick={handleClose} style={styles.closeButton}>
                                        Close
                                    </button>
                                    {!singleUser && (
                                        <p style={styles.text}>
                                            Selected: {userObject ? Object.keys(userObject).length : "0"}
                                        </p>
                                    )}
                                    {!singleUser && (
                                        <button onClick={handleDone} style={styles.doneButton}>
                                            Done
                                        </button>
                                    )}
                                </div>
                            </div>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
                    </div>

                    {
                        Object.keys(userObject).length > 0 && !singleUser && !onlyModal &&
                        <div style={{ flexDirection: 'row', flexWrap: 'wrap', columnGap: 5, rowGap: 8, marginVertical: 10 }}>
                            {
                                Object.keys(userObject).map((item, index) => {

                                    return (
                                        <div style={styles.namePill} key={index}>
                                            <p style={{ color: 'black', fontSize: 16, fontFamily: 'MarkerFelt' }}>
                                                {userObject[item].firstName} {userObject[item].lastName}
                                            </p>
                                            <div style={{ width: 15, height: 15 }} onClick={() => handleRemove(item)}>
                                                {/* <p style={{ textAlign: 'center', position: 'absolute', top: -5, left: 1.8, color: 'black', fontSize: 13 }}>x</p>
                                 */}
                                                <img src={require('../../../assets/images/closeSmall.png')} style={{ width: '100%', height: '100%' }} />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }
                </div>
            }
            {
                onlyModal &&
                <ReusableModal visible={selected}
                    onClose={handleClose}
                    
                >


                            <div style={styles.modalSelector}>
                                <UserSearch userObject={userObject} setUserObject={setUserObject} handleDropDown={handleDropDown} idsArray={data} singleUser={singleUser} SBUser={SBuser} />
                                <div style={{ flexDirection: 'row', justifyContent: 'space-between', margin: 5, marginVertical: 15, alignItems: 'center' , display:"flex" }}  >
                                    {
                                        singleUser && Object.keys(userObject).length > 0 && selected &&
                                        Object.keys(userObject).map((item, index) => {
                                            return (
                                                <p key={index} style={{ color: selected ? 'black' : 'grey', fontSize: 20, textAlign: 'left', fontFamily: 'MarkerFelt' }} numberOfLines={1}>{userObject[item].firstName} {userObject[item].lastName}</p>
                                            )
                                        })
                                    }
                                    {
                                        <>
                                            <div onClick={() => handleClose()} style={{ backgroundColor: 'orange', padding: 5, paddingHorizontal: 10, borderRadius: 10, elevation: 5 }}>
                                                <p style={{ color: selected ? 'black' : 'grey', fontSize: 20, textAlign: 'right', fontFamily: 'MarkerFelt' }}>Close</p>
                                            </div>
                                            {
                                                !singleUser &&
                                                <p style={{ color: 'black', fontSize: 20, textAlign: 'center', fontFamily: 'MarkerFelt'  }}>
                                                    Selected : {userObject ? Object.keys(userObject).length : '0'}
                                                </p>
                                            }
                                            {!singleUser &&
                                                <div onClick={() => handleDone()} style={{ backgroundColor: '#50C878', padding: 5, paddingHorizontal: 10, borderRadius: 10, elevation: 5 }}>
                                                    <p style={{ color: selected ? 'white' : 'grey', fontSize: 20, textAlign: 'right', fontFamily: 'MarkerFelt' }}>Done</p>
                                                </div>}
                                        </>
                                    }
                                </div>
                            </div>
                            {
                                share &&
                                <div style={styles.lowerCard}>
                                    <div>
                                        <p style={{ fontSize: 24, color: '#000', fontFamily: 'Marker Felt' }}>
                                            Spread the word...
                                        </p>
                                    </div>
                                    <div activeOpacity={0.8} onClick={() => onShare()} style={{
                                        backgroundColor: '#D1C4E9', height: '100%', width: '25%', borderRadius: 10,
                                        justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', paddingHorizontal: 10 , display:"flex", display:"flex" 
                                    }}>
                                        <p style={{ fontSize: 18, color: '#000', fontFamily: 'Marker Felt' }}>
                                            Share
                                        </p>
                                        <img src={require('../../../assets/images/extShare.png')} style={{ height: 30, width: 30 }} resizeMode='contain' />

                                    </div>
                                </div>
                            }

                </ReusableModal>
            }
        </>
    );
};



export default UserCardDropdown;