import React, { useState, useEffect } from 'react';
import studentImage from '../../assets/images/student.webp';

import { auth, storage, messaging, functions, firestore, signInWithEmailAndPassword, getToken, signOut, } from '../../firebase';
import { signInWithCustomToken } from "firebase/auth";

import { doc, getDoc, writeBatch, setDoc, updateDoc, where, query , limit , collection , getDocs } from "firebase/firestore";
import { ref, getDownloadURL } from "firebase/storage";

import { useDispatch, useSelector } from 'react-redux';
import {
  setUserData,
  setActiveSBUser,
  updateUserField,
  clearUserData,
  setConversationDataFromStorageBucket,
} from '../../reduxStore/slices/UsersSlice.js';
import {
  addSBUser
} from "../../reduxStore/slices/sbUserSlice"
import UserVerificatonFlow from '../forgetPassFlow/index';
import {
  convertToJSON,
  getFCMTOKEN,
} from '../../backgroundServices/notificationService';

import { httpsCallable } from "firebase/functions";
import { useNavigate } from 'react-router-dom';
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { v4 as uuidv4 } from "uuid";
import { FCMTOKENKEY, IV } from '../../environmentVariables';
import decryptData from '../../utils/decrypt';
import encryptData from '../../utils/encrypt';
import PinCodeModal from '../forgetPinFlow/pinEnterModal';
import PinMainComponent from '../forgetPinFlow/pinMainComponent';
import { storeFileInStorageBucket } from '../../backgroundServices/backgroundService';
import { useTranslation } from 'react-i18next';
import { width } from '@mui/system';
import { setInstitute } from '../../reduxStore/slices/institute.js';
export const StoreFcmToken = async ({ userId, fcmToken, deviceData, pass, calledBy }) => {
  const secretKey = FCMTOKENKEY;

  if (!secretKey || secretKey.length !== 32) {
    console.error("Invalid encryption key");
    return { status: "false", message: "Invalid encryption key" };
  }

  try {
    const userDocRef = doc(firestore, "users", userId);
    const userDocSnap = await getDoc(userDocRef);
    const existingData = userDocSnap.exists() ? userDocSnap.data() : {};


    const encryptedToken = encryptData(fcmToken, secretKey);
    if (!encryptedToken) throw new Error("Encryption failed");

    let encryptedPassword;
    if (calledBy !== 'onTokenRefresh') {
      encryptedPassword = encryptData(pass, secretKey);
    } 
    const existingFcmTokenObject = existingData.fcmTokenObject || {};
    for (const [tokenKey, existingDeviceData] of Object.entries(existingFcmTokenObject)) {
      if (existingDeviceData === deviceData) {
        delete existingFcmTokenObject[tokenKey];
      }
    }
    existingFcmTokenObject[encryptedToken] = deviceData;

    const batch = writeBatch(firestore);
    const userDataToStore = { fcmTokenObject: existingFcmTokenObject };
    if (calledBy !== 'onTokenRefresh') {
      userDataToStore.password = encryptedPassword;
    }
    batch.update(userDocRef, userDataToStore);

    const conversationIds = existingData.conversations || [];
    for (const conversationId of conversationIds) {
      const conversationDocRef = doc(firestore, "conversations", conversationId);
      batch.update(conversationDocRef, { [`participants.${userId}`]: { ...existingFcmTokenObject } });
    }

    await batch.commit();
    return { status: 'success', message: 'FCM token stored successfully.' };
  } catch (error) {
    console.error("Error storing encrypted data:", error);
    return { status: "false", message: "Error storing encrypted data" };
  }
};


// const registerUser = async (username, password, userData, fcmTokens) => {
//   try {
//     // Call the Firebase Cloud Function
//     const createUser = httpsCallable(functions, "createUser");
//     const response = await createUser({ username, password, userData, fcmTokens });

//     if (response.data.status === "success" && response.data.token) {
//       // Sign in the user with the received custom token
//       await signInWithCustomToken(auth, response.data.token);
//       console.log("User registered successfully:", response.data);
//     } else {
//       console.log("Registration failed:", response.data.message);
//     }
//   } catch (error) {
//     console.error("Error creating user:", error);
//   }
// };





function AuthenticationTesting() {
  const dispatch = useDispatch();

  const [userEmail, setUserEmail] = useState('');
  const [userName, setUserName] = useState('');
  const [userPass, setUserPass] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isPinModalVisible, setIsPinModalVisible] = useState(false);
  const [passValue, setPassValue] = useState('');
  const date = new Date();
  // const storeFcmToken=functions().httpsCallable('storeFcmToken')
  const [user, setUser] = useState(null); // State to store the user object
  const navigation = useNavigate();
  const userData = useSelector(state => state.user.users);

  const activeUser = useSelector(state => state.user.activeUser);
  const [deviceData, setDeviceData] = useState("");
const handleSignUp =()=>{
  navigation("/signUp")
}
 
  useEffect(() => {
    const fetchDeviceId = async () => {
      // Check if the device ID already exists in localStorage
      let storedId = localStorage.getItem("device_uuid");

      // If no ID is stored, generate a new one
      if (!storedId) {
        const fp = await FingerprintJS.load();
        const result = await fp.get();

        // Generate a new device ID based on fingerprint and store it in localStorage
        storedId = uuidv4() + "-" + result.visitorId;
        localStorage.setItem("device_uuid", storedId);
      }

      setDeviceData(storedId); // Set the device ID in state
    };

    fetchDeviceId();
  }, []);
  const HandleSignIn = async (email, pass) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, pass);
      const { uid, email: userEmail } = userCredential.user;
      

      // Fetch user data from Firestore
      const userDocRef = doc(firestore, "SBusers", uid);
      const userSnapshot = await getDoc(userDocRef);

      if (!userSnapshot.exists()) {
        console.error("User data not found in Firestore");
        return;
      }

      const userData = userSnapshot.data();

      subscribeToAllTopics({ ...userData });

      // Dispatch Redux actions
      dispatch(setUserData({...userData }));
      dispatch(setActiveSBUser(uid));

      console.log("Signed in successfully!", userEmail);

      // Get FCM Token
      const fcmToken = await getToken(messaging, {
        vapidKey: "BOaQ482qDKa3uJWM5SQUVpRAOAh0-MSmmKYUdugqArdyluZe1vzoO8789D1kRFCtXxo8sVm72Weqr9drJpi8EYU"
      });
      
      console.log('fcmToken: ', fcmToken);
      
      // Store FCM Token in Firestore
      const res = await StoreFcmToken({
        userId: uid,
        fcmToken: fcmToken,
        deviceData,
        pass,
      });

      if (res.status === "success") {
        console.log("FCM token stored successfully");
      } else {
        console.log("FCM token storage failure");
      } 


    const { instituteID } = userData || {};

if (Array.isArray(instituteID) && instituteID.length > 0) {
    const instituteDocs = await Promise.all(
        instituteID.map(async (inst) => {
            const instituteDocRef = doc(firestore, "Institutes", inst);
            const instituteSnapshot = await getDoc(instituteDocRef);

            if (!instituteSnapshot.exists()) {
                console.error(`Institute data not found for ID: ${inst}`);
                return null;
            }

            return { id: inst, ...instituteSnapshot.data() }; // Include ID for reference
        })
    );

    // Filter out null values (if any institutes were not found)
    const validInstitutes = instituteDocs.filter(Boolean);

    console.log('Institutes Data:', validInstitutes);

    // Store conversation data (assuming setInstitute is a Redux action)
    dispatch(setInstitute(validInstitutes));
      storeConversationData(uid);
  }
    } catch (error) {
      console.error("Sign-in error:", error.message);
    }
  };





  const handleLogin = async (username, password) => {


    try {
      if (!username || !password) {
        console.error("Please enter both username and password");
        return;
      }

      // Call Firebase Cloud Function to authenticate user
      const loginCallable = httpsCallable(functions, "signIn");
      const signInResponse = await loginCallable({ username: username.toLowerCase().trim(), password });

      if (signInResponse.data.status === "success") {
        if (!signInResponse.data.token) {
          console.error("Invalid Username or Password");
          return;
        }

        const response = await signInWithCustomToken(auth, signInResponse.data.token);

        if (response.user) {
          const fUserDoc = doc(firestore, "SBusers", response.user.uid);
          const fUserData = (await getDoc(fUserDoc)).data();

          // Update FCM tokens (not necessary in React.js, but keeping for Firestore consistency)
          const fcmToken = await getToken(messaging, {
            vapidKey: "BOaQ482qDKa3uJWM5SQUVpRAOAh0-MSmmKYUdugqArdyluZe1vzoO8789D1kRFCtXxo8sVm72Weqr9drJpi8EYU"
          });
          const fcmTokens = fUserData.fcmTokens || {};
          fcmTokens[fcmToken] = { deviceID: "", lastUpdatedTimestamp: new Date().getTime() };

          await updateDoc(fUserDoc, { fcmTokens });

          // Update Redux
          dispatch(addSBUser({ uid: response.user.uid, userData: fUserData }));
          dispatch(setActiveSBUser(response.user.uid));
          subscribeToAllTopics({ ...fUserData });

          // Get FCM Token
       
    
          // Store FCM Token in Firestore
          const res = await StoreFcmToken({
            userId: response.user.uid,
            fcmToken: fcmToken,
            deviceData:fUserData,
            pass: password,
          });
    
          if (res.status === "success") {
            console.log("FCM token stored successfully");
          } else {
            console.log("FCM token storage failure");
          }
  
          // Store conversation data
          storeConversationData(response.user.uid);
        }
      } else {
        console.error("Login failed:", signInResponse.data.message);
      }
    } catch (error) {
      console.error("Error logging in:", error);
    }
  };


  const subscribeToAllTopics = async (activeUserData) => {
    try {
      const subscribeFCMTokenWithTopic = httpsCallable(functions, "subscribeFCMTokenWithTopic");

      // Your FCM token
      const fcmToken = await getToken(messaging, {
        vapidKey: "BOaQ482qDKa3uJWM5SQUVpRAOAh0-MSmmKYUdugqArdyluZe1vzoO8789D1kRFCtXxo8sVm72Weqr9drJpi8EYU"
      });

      // Call Cloud Function with token and topic array
      const response = await subscribeFCMTokenWithTopic({
        fcmToken: fcmToken,
        topicIdsArray: activeUserData.topicIdsArray,
      });
    } catch (error) {
      console.error("Error subscribing to topics:", error);
    }
  };
  const storeConversationData = (uid) => {
    readFileFromStorageBucketAndWriteInLocal(uid)

  }


  // Function to read file from Firebase Storage
  const readFileFromStorageBucketAndWriteInLocal = async (fileName) => {
    try {
      // ✅ Correct way to reference the file in Firebase Storage
      const storageRef = ref(storage, `conversationData/${fileName}.txt`);

      // ✅ Get the download URL
      const downloadUrl = await getDownloadURL(storageRef);

      // ✅ Fetch the file content
      const response = await fetch(downloadUrl);
      const fileContent = await response.text();
      console.log("File Content:", fileContent);

      // ✅ Create and download the file
      const blob = new Blob([fileContent], { type: "text/plain;charset=utf-8" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${fileName}.txt`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Clean up
      await storeFileInStorageBucket([fileName]);

    } catch (error) {
      console.error("Error reading file from storage bucket:", error);
    }
  }

  const removeFcmToken = async ({ userId, fcmToken, activeUser }) => {
    try {
      // Get the existing user document
      const userDocRef = doc(firestore, "users", userId);
      const userDocSnap = await getDoc(userDocRef);
      const existingData = userDocSnap.exists() ? userDocSnap.data() : {};

      // Encrypt the fcmToken
      const encryptedToken = await encryptData(fcmToken, FCMTOKENKEY);

      // Remove the encrypted token from the existing fcmTokenObject
      const existingFcmTokenObject = { ...existingData.fcmTokenObject } || {};
      delete existingFcmTokenObject[encryptedToken];

      // Create a batch operation
      const batch = writeBatch(firestore);

      // Update the user document in Firestore
      batch.update(userDocRef, {
        fcmTokenObject: existingFcmTokenObject,
      });

      // Retrieve the array of conversation IDs from the user document
      const conversationIds = existingData.conversations || [];

      // Update each conversation with the updated participants object
      for (const conversationId of conversationIds) {
        const conversationDocRef = doc(firestore, "conversations", conversationId);
        batch.update(conversationDocRef, {
          [`participants.${activeUser}`]: { ...existingFcmTokenObject },
        });
      }

      // Store the updated existingFcmTokenObject keys in the institute document's FCM token collection

      // If you need to store these in an institute document, call your function
      // await storeFcmTokenInInstitutesDocuments({
      //   LMSIDSObject: existingData.LMSIDSObject,
      //   fcmTokensArray: decryptedFcmTokenArray,
      // });

      // Commit the batch operation
      await batch.commit();

      return { status: "success", message: "FCM token removed successfully" };
    } catch (error) {
      console.error("Error removing encrypted data:", error);
      return { status: "false", message: "Error removing encrypted data" };
    }
  };

  const handleSignOut = () => {
    dispatch(clearUserData(activeUser));
    signOut(auth)
      .then(async e => {
        const uid = activeUser;

        const fcmToken = await getToken(messaging, {
          vapidKey: "BOaQ482qDKa3uJWM5SQUVpRAOAh0-MSmmKYUdugqArdyluZe1vzoO8789D1kRFCtXxo8sVm72Weqr9drJpi8EYU"
        }); const res = await removeFcmToken({ userId: uid, fcmToken: fcmToken });
        if (res.status == 'success') {
          console.log('fcm token removed successfully');
        } else {
          console.log('fcm token remove failure');
        }
        console.log('User signed out!');
      })
      .catch(error => {
        console.error(error);
      });
  };

  const switchProfile = (userUid) => {
    const selectedUser = userData[userUid];

    if (selectedUser) {
      setUser(selectedUser); // Set the user state
      if (selectedUser.profilePinCode) {
        setModalVisible(false);
         setIsPinModalVisible(true);
      } else {

        dispatch(setActiveSBUser(userUid));
      }
    }
  };

  const handlePinSubmit = () => {

    setIsPinModalVisible(false);
    setModalVisible(false);
    dispatch(setActiveSBUser(user.userUid));
    // signInUser(user, pass); // Use the user state
  };

  const getActiveUserData = async () => {

    const asyncStorageData = localStorage.getItem('persist:input');

    // Check if data exists and then parse it
    if (asyncStorageData) {
      const newdata = JSON.parse(asyncStorageData);

      // Assuming convertToJSON is a function that processes the data
      const hello = convertToJSON(newdata);

      // Log the specific data
      console.log(
        'input slice details updated successfully',
        hello['pendingTaskTracker'],
      );
    } else {
      console.log('No data found in localStorage.');
    }

    // const asyncStorageData = await AsyncStorage.getItem('persist:user');
    // const jsonData = JSON.parse(asyncStorageData);
    // const newdata = JSON.parse(jsonData.users)
    // console.log('newdata: ', newdata[activeUser]);
    // console.log("================",newdata[activeUser].pendingRequest);
  };

  const changeUserField = () => {
    dispatch(
      updateUserField({ userUid: activeUser, updates: { userBranch: 'MBA' } }),
    );
    console.log('User data changed successfully');
  };

  const renderUserItem = ({ item }) => (
    (
      <div
        style={styles.userItem}
        onClick={() => switchProfile(item.userUid)}>
        {item.profilePinCode ? (
          <img
            src={require('../../assets/images/user.webp')}
            style={{ width: 25, height: 25, cursor: "pointer" }}
          />
        ) : null}
        <p style={styles.userItemText}>{item.firstName}</p>
      </div>
    )
  );
const { t} = useTranslation()
  return (
    <div style={styles.container}>
 <div style={styles.leftPanel}>
        <div style={styles.graphicOverlay} />
        <div style={styles.leftContent}>
          <h1 style={styles.title}>{t("Welcome to Our Platform")}</h1>
          <p style={styles.subtitle}>
            {t("Join thousands of users who trust our platform for their daily needs.   Experience seamless integration and powerful features.")}
          </p>
        </div>
      </div>

      <div style={styles.rightPanel}>
        <div>

      
      <p style={styles.title}>Authentication Testing</p>

      <input
        style={styles.input}
        placeholder="Enter username"
        placeholderTextColor={'darkblue'}
        onChange={e => setUserEmail(e.target.value)}
        value={userEmail}
        inputMode="username"
      />
      <input
        style={styles.input}
        placeholder="Enter password"
        placeholderTextColor={'darkblue'}
        onChange={e => setUserPass(e.target.value)}
        value={userPass}
        secureTextEntry
      />
      <div
        style={styles.buttonGreen}
        onClick={() => HandleSignIn(userEmail, userPass)}>
        <p style={styles.buttonText}>Sign In</p>
      </div>

      <div style={styles.buttonRed} onClick={handleSignOut}>
        <p style={styles.buttonText}>Sign Out</p>
      </div>
      <div style={styles.buttonBlue} onClick={handleSignUp}>
 <p style={styles.buttonText}>Sign up</p>
 </div>
      <div
        style={styles.buttonBlue}
        onClick={() => setModalVisible(true)}>
        <p style={styles.buttonText}>Switch Profile</p>
      </div>
      <div style={styles.buttonBlue} onClick={getActiveUserData}>
        <p style={styles.buttonText}>Get Active User Data</p>
      </div>
      <div style={styles.buttonBlue} onClick={changeUserField}>
        <p style={styles.buttonText}>Change User Branch</p>
      </div>
      <div>

      <input
        style={styles.input}
        placeholder="Enter username"
        placeholderTextColor={'darkblue'}
        onChange={e => setUserName(e.target.value)}
        value={userName}
        inputMode="username"
      />
      <input
        style={styles.input}
        placeholder="Enter password"
        placeholderTextColor={'darkblue'}
        onChange={e => setUserPass(e.target.value)}
        value={userPass}
        secureTextEntry
      />
       <div
        style={styles.buttonGreen}
        onClick={() => handleLogin(userName, userPass)}>
        <p style={styles.buttonText}>Sign In</p>
      </div>  
      </div>
      <PinMainComponent userUid={activeUser} purpose={'setPin'} />
      <PinMainComponent userUid={activeUser} purpose={'removePin'} />
      <PinMainComponent userUid={activeUser} purpose={'forgotPin'} />
      <PinMainComponent userUid={activeUser} purpose={'updateSecurityQuestions'} />
      {modalVisible && (
        <div style={styles.modalOverlay} onClick={() => setModalVisible(false)}>
          <div style={styles.modalContent} onClick={(e) => e.stopPropagation()}>
            {/* Logging outside JSX */}

            {Object.values(userData).map((item) => (
              <div key={item.userUid}>{renderUserItem({ item })}</div>
            ))}

            <button
              onClick={() => setModalVisible(false)}
              style={styles.closeButton}
            >
              Close
            </button>
          </div>
        </div>
      )}

      {isPinModalVisible && (
        <PinCodeModal
          pinLength={4}
          onSubmit={handlePinSubmit}
          isPinModalVisible={isPinModalVisible}
          actualPin={user ? user.profilePinCode : null}
          onClose={() => {
            setIsPinModalVisible(false);
          }}
        />
      )}
      <UserVerificatonFlow
        otpMetaData={{
          otpLength: 5,
          otpType: 'numerical',
          otpValidationTime: 10,
          otpIsFor: 'forgotPassword',
          initialScreen: 'AuthenticationTesting',
        }}
      />
  </div>
      {/*otpIsFor can be forgotPin or forgotPassword and also initialScreen prop is compulsory*/}
</div>
    </div>
    
  );
}

const styles = {
  container: {

    display: "flex",
    padding: 20,
    flexDirection: window.innerWidth<=768? "column": "row",
    justifyContent: 'center',
    boxShadow: '0 10px 15px rgba(0, 0, 0, 0.5)',

  },

      leftPanel: {
        flex: '1',
        background: 'linear-gradient(135deg, rgb(32, 32, 32) 0%, rgb(5, 1, 9) 100%)',
        display: 'flex',
        flexDirection: 'column' ,
        justifyContent: 'center',
        overflow:"hidden",
        alignItems: 'center',
        padding: window.innerWidth <= 768 ? '20px' : '40px',
        color: 'white',
        position: 'relative' ,
        borderRadius:"10px 0px 0px 10px",
        minHeight: window.innerWidth <= 768 ? '300px' : 'auto',
      },
      rightPanel: {
        flex: '1',
        display: 'flex',
        borderRadius:"0px 10px 10px 0px",
        overflow:"hidden",
        justifyContent: 'center',
        flexDirection:"column",
        alignItems: 'center',
        padding: window.innerWidth <= 768 ? '20px' : '40px',
        background: 'white',
      },
      graphicOverlay: {
        position: 'absolute' ,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundImage: `url(${studentImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        opacity: 0.2,
      },
      leftContent: {
        position: 'relative' ,
        zIndex: 1,
        textAlign: 'center' ,
        maxWidth: '400px',
      },
      rightPanel: {
        flex: '1',
        display: 'flex',
        borderRadius:"0px 10px 10px 0px",
        overflow:"hidden",
        justifyContent: 'center',
        flexDirection:"column",
        alignItems: 'center',
        padding: window.innerWidth <= 768 ? '20px' : '40px',
        background: 'white',
      },
      form: {
        width: '100%',
        maxWidth: '400px',
      },
      title: {
        fontSize: '2.5rem',
        color: 'white',
        marginBottom: '20px',
        fontWeight: '700',
        textShadow: '0 2px 4px rgba(0,0,0,0.1)',
      },
      rightTitle: {
        fontSize: '2rem',
        color: '#1a1a1a',
        marginBottom: '10px',
        fontWeight: '700',
      },
      subtitle: {
        fontSize: '1.1rem',
        color: 'rgba(255,255,255,0.9)',
        lineHeight: '1.6',
      },
  title: {
    color: 'darkblue',
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
    textAlign: 'center',
  },
  input: {
    color: 'darkblue',
    height: 50,
    borderColor: '#007bff',
    borderWidth: 1,
    borderRadius: 10,
    paddingHorizontal: 15,
    marginBottom: 15,
  },
  buttonGreen: {
    backgroundColor: 'green',
    padding: 15,
    borderRadius: 15,
    cursor: "pointer",
    marginBottom: 15,
  },
  buttonRed: {
    backgroundColor: 'red',
    padding: 15,
    borderRadius: 10,
    marginBottom: 15,
    cursor: "pointer"
  },
  buttonBlue: {
    backgroundColor: '#007bff',
    padding: 15,
    borderRadius: 10,
    marginBottom: 15,
    cursor: "pointer"
  },
  buttonText: {
    color: '#fff',
    fontSize: 16,
    textAlign: 'center',

    cursor: "pointer"
  },
  modalOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modalContent: {
    width: "400px",
    backgroundColor: "red",
    padding: "20px",
    borderRadius: "10px",
    textAlign: "center",
    overflowY: "auto",
    maxHeight: "80vh",
    height: "300px",
    position: "relative",
  },
  closeButton: {
    backgroundColor: "#007bff",
    color: "white",
    padding: "10px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    marginTop: "10px",
  },
  modalContainer: {
    flex: 1,
    display: "flex",
    justifyContent: 'center',
    flexDirection: "column",
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    width: '80%',
    padding: 20,
    backgroundColor: '#fff',
    borderRadius: 10,
  },
  userItem: {
    padding: 10,
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: 'black',
    alignItems:"center",
    columnGap:"5px",
    margin: 5,
    borderRadius: 50,
    flexDirection: 'row',
    display: "flex"
  },
  userItemText: {
    fontSize: 16,
    cursor: "pointer"
  },
  otpTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 15,
    textAlign: 'center',
  },
  otpContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  otpInput: {
    width: 50,
    height: 50,
    borderWidth: 1,
    borderColor: '#007bff',
    borderRadius: 10,
    textAlign: 'center',
    fontSize: 18,
    marginHorizontal: 5,
  },
};

export default AuthenticationTesting;