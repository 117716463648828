import { useRef, useEffect } from 'react';

const useSound = (fileName) => {
    const audioRef = useRef(null);

    useEffect(() => {
        const loadSound = () => {
            const audio = new Audio(fileName);
            audio.volume = 0.5;
            audioRef.current = audio;

            audio.addEventListener("canplaythrough", () => {
                console.log("Sound loaded successfully: " + fileName);
            });

            audio.addEventListener("error", (error) => {
                console.error("Failed to load the sound:", fileName, error);
            });
        };

        loadSound();

        return () => {
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.src = "";
                audioRef.current = null;
            }
        };
    }, [fileName]);

    const playSound = () => {
        if (audioRef.current) {
            audioRef.current.play().catch((error) => {
                console.error("Sound playback failed", error);
            });
        } else {
            console.log("Sound not yet loaded");
        }
    };

    return playSound;
};

export default useSound;
