import React, { useRef, useState } from 'react';
import { useSearchBox } from 'react-instantsearch-core';

export function SearchBox({ onChange, ...props }) {
    const { query, refine } = useSearchBox(props);
    const [inputValue, setInputValue] = useState(query);
    const inputRef = useRef(null);

    function setQuery(newQuery) {
        setInputValue(newQuery);
        refine(newQuery);
    }

    if (query !== inputValue && document.activeElement !== inputRef.current) {
        setInputValue(query);
    }

    return (
        <div style={styles.container}>
            <input
                type="text"
                onChange={(e) => {
                    const newValue = e.target.value;
                    setQuery(newValue);
                    onChange?.(newValue);
                }}
                ref={inputRef}
                style={styles.input}
                value={inputValue}
                autoComplete="off"
                placeholder="Search here..."
            />
        </div>
    );
}

const styles = {
    container: {
        backgroundColor: '#252b33',
        padding: '18px',
    },
    input: {
        height: '48px',
        padding: '12px',
        fontSize: '16px',
        backgroundColor: '#fff',
        borderRadius: '4px',
        borderWidth: '1px',
        borderColor: '#ddd',
        color: 'black',
        width: '100%',
        boxSizing: 'border-box',
    },
};
