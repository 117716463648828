import React, { useEffect, useRef, useState } from "react";
import useScreenSizes from "../../utils/useScreenSize";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const cardData = [
  {
    title: "Customizable and Scalable Platform",
    smallCards: [
      {
        src: require("../../assets/images/Tailored Learning experiences.webp"),

        title: "Tailored Learning Experiences",
        content:
          "Study Buddy allows educators to customize courses and learning pathways based on individual student needs, providing a personalized learning experience",
      },
      {
        src: require("../../assets/images/Flexible Design Options.webp"),

        title: "Flexible Design Options:",
        content:
          "Our platform offers flexible design features that allow administrators to adjust the LMS layout, structure, and content delivery to match the institution’s specific requirements",
      },
      {
        src: require("../../assets/images/Effortless user Expansion.webp"),

        title: "Effortless User Expansion",
        content:
          " As your institution grows, Study Buddy easily scales to accommodate more users without compromising performance, allowing for seamless expansion of student and staff accounts",
      },
      {
        src: require("../../assets/images/Future-Proof Scalability.webp"),

        title: "Future-Proof Scalability",
        content:
          "Designed to grow with your institution, Study Buddy ensures that your LMS stays up-to-date and adapts to future advancements, making it a sustainable, long-term solution",
      },
 ],

 },

 {
  title: "Collaborative Learning Environment",
  smallCards:[
    {
      src: require("../../assets/images/engaging Group Projects.webp"),

      title: "Engaging Group Projects",
      content:
        "Study Buddy encourages collaborative learning through engaging group projects that promote teamwork and critical thinking among students",
    },
    {
      src: require("../../assets/images/Seamless Resource Shareing.webp"),

      title: "Seamless Resource Sharing",
      content:
        "Our platform facilitates easy sharing of resources, allowing educators and students to access and distribute materials effortlessly, enhancing collaboration",
    },
    {
      src: require("../../assets/images/Active Participation In Activities.webp"),

      title: "Active Participation in Activities",
      content:
        "With interactive features, Study Buddy fosters active participation in class activities, motivating students to contribute and engage with their peers",
    },
    {
      src: require("../../assets/images/Real-timre communication Tools.webp"),

      title: "Real-Time Communication Tools",
      content:
        "The platform includes real-time communication tools, such as chat and discussion forums, enabling instant interaction between students and teachers for a more connected learning experience",
    },
  ]
 },
 {
  title: "Seamless Communication",
  smallCards:[
    {
      src: require("../../assets/images/Instant Notification.webp"),

      title: "Instant Notifications",
      content:
        "Study Buddy provides instant notifications to keep students, parents, and educators informed about important updates, assignments, and announcements",
    },
    {
      src: require("../../assets/images/Parents-Teacher Interaction.webp"),

      title: "Parent-Teacher Interaction",
      content:
"Our platform facilitates easy communication between parents and teachers, fostering collaboration and ensuring that parents stay engaged in their child’s education"
   },
    {
      src: require("../../assets/images/Student Collabration.webp"),

      title: "Student Collaboration",
      content:
"Students can easily communicate and collaborate with peers on projects and assignments, enhancing teamwork and collective learning"

   },
    {
      src: require("../../assets/images/Centralised Messaging.webp"),

      title: "Centralized Messaging",
      content:
"With a centralized messaging system, users can access all communications in one place, making it easy to track conversations and stay organized"

   },
    {
      src: require("../../assets/images/Progress Tracking Alerts.webp"),

      title: "Progress Tracking Alerts:",
      content:
"The platform sends alerts to keep students and parents informed about academic progress, helping them stay on track and address any issues promptly"

   },
    {
      src: require("../../assets/images/Event Reminders.webp"),

      title: "Event Reminders",
      content:
"Study Buddy offers reminders for important events such as exams, meetings, and deadlines, ensuring that users are well-prepared and informed"

   },
    {
      src: require("../../assets/images/Cussomizable Notification.webp"),

      title: "Customizable Notifications",
      content:
"Users can customize their notification preferences to receive alerts that are most relevant to them, enhancing the overall communication experience"

   },
  ]
 },

//  {
//   title: "Comprehensive Administrative Tools",
//   smallCards:[
//     {
//       src: require("../../assets/images/Attendance Tracking.webp"),

//       title: "Automated Attendance Tracking",
//       content:
// "Our platform simplifies attendance management by automating the tracking process, allowing educators to quickly record and monitor student attendance without manual effort"

//    } ,
//     {
//       src: require("../../assets/images/Assignment Tracker.webp"),

//       title: "Class Scheduling Made Easy",
//       content:
// "Study Buddy provides intuitive scheduling tools that enable administrators to create, modify, and manage class schedules effortlessly, ensuring optimal use of resources and time"

//    },
   
//     {
//       src: require("../../assets/images/Assignment Tracker.webp"),

//       title: "Resource Management",
//       content:
// "The platform offers effective resource management capabilities, allowing educators to allocate and track teaching materials, ensuring that resources are used efficiently"

//    },
//     {
//       src: require("../../assets/images/Assignment Tracker.webp"),

//       title: "Performance Analytics",
//       content:
// "Study Buddy delivers in-depth performance analytics, helping educators and administrators analyze student data to identify trends, improve teaching strategies, and enhance overall academic outcomes"

//    },
   
    
//   ]
//  },

 {
  title: "Smart Financial Operations",
  smallCards:[
    {
      src: require("../../assets/images/Automated fee reminders.webp"),

      title: "Automated Fee Reminders",
      content:
"Study Buddy automatically sends reminders to parents and students about upcoming or overdue fees, helping them stay on top of payments"

   },
    {
      src: require("../../assets/images/source online payment.webp"),

      title: "Secure Online Payments",
      content:
"The platform offers secure online payment options, making it easy and safe for parents to pay fees without the need for physical transactions"

   },
    {
      src: require("../../assets/images/details finance reports.webp"),

      title: "Detailed Financial Reports",
      content:
"Administrators can generate detailed financial reports to track payments, outstanding balances, and overall financial health with ease"

   },
    {
      src: require("../../assets/images/customizable fee structures.webp"),

      title: "Customizable Fee Structures",
      content:
"Study Buddy allows institutions to set up flexible, customizable fee structures, catering to different student categories or payment plans"

   },
   
  ]
 },

 {
  title: "Smart Financial Operations",
  smallCards:[
    {
      src: require("../../assets/images/seamless Budget Management.webp"),

      title: "Seamless Budget Management",
      content:
"Study Buddy helps administrators easily manage and allocate budgets, ensuring financial resources are efficiently distributed across departments"

   },
    {
      src: require("../../assets/images/Accurate Expense Tracking.webp"),

      title: "Accurate Expense Tracking",
      content:
"The platform provides tools for tracking all expenses in real-time, giving clear visibility into where and how funds are being spent"

   },
    {
      src: require("../../assets/images/custom Financial Reports.webp"),

      title: "Custom Financial Reports",
      content:
"Administrators can generate custom financial reports to analyze specific financial data, making it easier to review and manage finances"

   },
    {
      src: require("../../assets/images/Data Driven Forcecasting.webp"),

      title: "Data-Driven Forecasting",
      content:
"Study Buddy uses financial data to offer insights and forecasts, helping institutions plan for future expenses and financial needs effectively"

   },
  ]
 },
 

 {
  title:  "Inventory Management",
  smallCards:[
    {
      src: require("../../assets/images/instant communication channals.webp"),

      title: "Enhanced Communication Tools",
      content:
"The platform provides effective communication tools, enabling parents and teachers to engage in meaningful discussions about student progress and concerns"

   },
    {
      src: require("../../assets/images/Comprehensive Student Insights.webp"),

      title: "Comprehensive Student Insights",
      content:
"Educators can share detailed insights about student performance and behavior during the meeting, helping parents understand their child's progress"

   },
    {
      src: require("../../assets/images/customizable Templetes.webp"),

      title: "Customizable Templates",
      content:
"Study Buddy offers customizable report card templates, allowing schools to design report cards that fit their specific grading criteria and branding"

   },
    {
      src: require("../../assets/images/Real-time Performance Updatss.webp"),

      title: "Real-Time Performance Updates",
      content:
"Educators can include real-time performance updates on report cards, providing parents and students with the most current information on academic progress"

   },
  ]
 },
 {
  title: "Report Card Generator",
  smallCards:[
    {
      src: require("../../assets/images/customizable Templetes.webp"),

      title: "Customizable Templates",
      content:
"Study Buddy offers customizable report card templates, allowing schools to design report cards that fit their specific grading criteria and branding"

   },
    {
      src: require("../../assets/images/Automated Data Collection.webp"),

      title: "Automated Data Collection",
      content:
"The platform automatically collects and compiles student performance data, saving educators time and ensuring accuracy in report generation"

   },
    {
      src: require("../../assets/images/Real-time Performance Updatss.webp"),

      title: "Real-Time Performance Updates",
      content:
"Educators can include real-time performance updates on report cards, providing parents and students with the most current information on academic progress"

   },
   
    {
      src: require("../../assets/images/customizable Templetes.webp"),

      title: "Effortless Distribution",
      content:
"The system simplifies the distribution of report cards, allowing teachers to easily share them with students and parents via email or through the LMS"

   },
   
  ]
 },
 {
  title: "Staff management",
  smallCards:[
 
    {
      src: require("../../assets/images/Role& Responsibility Assignment.webp"),

      title: "Role & Responsibility Assignment",
      content:
"The platform allows administrators to clearly define and assign roles and responsibilities to staff members, ensuring everyone knows their tasks"

   },
    {
      src: require("../../assets/images/Streamlined Payroll Management.webp"),

      title: "Streamlined Payroll Management",
      content:
"Study Buddy simplifies payroll processing, automating calculations and payments to ensure timely and accurate compensation for staff"

   },
    {
      src: require("../../assets/images/Performance Evalution Tools.webp"),

      title: "Performance Evaluation Tools",
      content:
"The system provides tools for conducting performance evaluations, helping administrators assess staff effectiveness and identify areas for professional development"

   },
   
  ]
 },
 {
  title: "Admission Management",
  smallCards:[
    {
      src: require("../../assets/images/Streamlined Application Handling.webp"),

      title: "Streamlined Application Handling",
      content:
"Study Buddy simplifies the process of managing student applications, making it easier for administrators to track and review submissions efficiently"

   },
    {
      src: require("../../assets/images/Customizable Applicationo Forms.webp"),

      title: "Customizable Application Forms",
      content:
"The platform allows institutions to create and modify application forms to meet specific requirements, ensuring they gather all necessary information from prospective students"

   },
  
    {
      src: require("../../assets/images/Real time Status Updates.webp"),

      title: "Real-Time Status Updates",
      content:
"Applicants can receive real-time updates on the status of their applications, keeping them informed throughout the admission process"

   },
   
  ]
 },

 {
  title:"Hostel Management",
  smallCards:[
    {
      src: require("../../assets/images/effortless room assignmesnt.webp"),

      title: "Effortless Room Assignments",
      content:
"Study Buddy streamlines the process of assigning rooms to students, making it quick and easy for administrators to manage accommodations"

   },
    {
      src: require("../../assets/images/real time occupancy monitoring.webp"),

      title: "Real-Time Occupancy Monitoring",
      content:
"The platform allows for real-time tracking of room occupancy, helping staff know which rooms are available and which are occupied"

   },
    
  
    
    {
      src: require("../../assets/images/instant communication channals.webp"),

      title: "Instant Communication Channels",
      content:
"Study Buddy includes communication tools that allow hostel staff and students to connect instantly, ensuring quick responses to any questions or issues"

   },
   
  ]
 },
 {
  title: "Curricular Planner",
  smallCards:[
    {
      src: require("../../assets/images/Streamlined Curriculum Design.webp"),

      title: "Streamlined Curriculum Design",
      content:
"Study Buddy helps educators create and organize curriculum plans efficiently, ensuring a clear structure for teaching and learning"

   },
    {
      src: require("../../assets/images/Alignment With EducationalStandards _.webp"),

      title: "Alignment with Educational Standards",
      content:
"The platform ensures that all curriculum designs meet relevant educational standards, helping schools provide quality education and comply with regulations"

   },
   
    {
      src: require("../../assets/images/Dynamic Assessment Methods.webp"),

      title: "Dynamic Assessment Methods",
      content:
"Study Buddy supports various assessment methods that can be adjusted based on student progress, allowing for more effective evaluation of learning outcomes"

   },
   
  ]
 },
 {
  title: "Data-Driven Insights",
  smallCards:[
    {
      src: require("../../assets/images/RealTIme Performance Tracking ;Early intervantion Alerts.webp"),

      title: "Real-Time Performance Tracking:",
      content:
        "Our platform provides real-time tracking of student and staff performance, enabling educators to monitor progress and engagement instantly.",
    },
    {
      src: require("../../assets/images/Customizable reports.webp"),

      title: "Customizable Reports:",
      content:
        "Administrators can create tailored reports that focus on specific metrics, helping to analyze data effectively and gain deeper insights into academic performance and trends",
    },

   
    {
      src: require("../../assets/images/Inform decision-making.webp"),

      title: "Informed Decision-Making",
      content:
        "With access to comprehensive data analytics, educators and administrators can make well-informed decisions to enhance learning outcomes and optimize resources",
    },
  ]
 },
//  {
//   title:"Community Support",
//   smallCards:[
//     {
//       src: require("../../assets/images/Discussion Fourms.webp"),

//       title: "Discussion Forums",
//       content:
// "Study Buddy includes discussion forums where students and teachers can ask questions, share ideas, and discuss topics, creating a supportive online community"

//    },
   
//   ]
// },
{
title: "Streamlining Fees Management",
smallCards:[
  {
    src: require("../../assets/images/Automated fee reminders.webp"),

    title: "Automated Fee Reminders",
    content:
"Study Buddy automatically sends reminders to parents and students about upcoming or overdue fees, helping them stay on top of payments"

 },
 
]
},

];

const StackingCardforInstitute = ({ customStyles = {} }) => {
  const stackCardsRef = useRef(null);
  const smallCardRefs = useRef([]);
const {t} = useTranslation();
  const screenType = useSelector((state) => state.website.screenType);
 const screen = useScreenSizes()
  useEffect(() => {
    const stackCards = stackCardsRef.current;
    if (!stackCards) return;

    const items = stackCards.getElementsByClassName("js-stack-cards__item");
    let scrollingFn = null;
    let scrolling = false;

    const setStackCards = () => {
      const marginY = parseInt(
        getComputedStyle(stackCards).getPropertyValue("--stack-cards-gap"),
        10
      );
      stackCards.style.paddingBottom = `${marginY * (items.length - 1)}px`;

      for (let i = 0; i < items.length; i++) {
        // items[i].style.transform = `translateY(${marginY * i}px)`;
      }
    };

    const stackCardsCallback = (entries) => {
      if (entries[0].isIntersecting) {
        if (scrollingFn) return;
        stackCardsInitEvent();
      } else {
        if (!scrollingFn) return;
        window.removeEventListener("scroll", scrollingFn);
        scrollingFn = null;
      }
    };

    const stackCardsInitEvent = () => {
      scrollingFn = stackCardsScrolling;
      window.addEventListener("scroll", scrollingFn);
    };

    const stackCardsScrolling = () => {
      if (scrolling) return;
      scrolling = true;
      window.requestAnimationFrame(animateStackCards);
    };

    const animateStackCards = () => {
      const marginY = parseInt(
        getComputedStyle(stackCards).getPropertyValue("--stack-cards-gap"),
        10
      );
      const top = stackCards.getBoundingClientRect().top;
      const cardStyle = getComputedStyle(items[0]);
      const cardTop = Math.floor(parseFloat(cardStyle.getPropertyValue("top")));
      const cardHeight = Math.floor(
        parseFloat(cardStyle.getPropertyValue("height"))
      );
      const windowHeight = window.innerHeight;

      if (
        cardTop - top + windowHeight - cardHeight + marginY * items.length >
        0
      ) {
        scrolling = false;
        return;
      }

      for (let i = 0; i < items.length; i++) {
        const scrollingValue = cardTop - top - i * (cardHeight + marginY);
        if (scrollingValue > 0) {
          const scaling =
            i === items.length - 1
              ? 1
              : (cardHeight - scrollingValue * 0.05) / cardHeight;
          items[i].style.transform = `translateY(${
            marginY * i
          }px) scale(${scaling})`;
        } else {
          items[i].style.transform = `translateY(${marginY * i}px)`;
        }
      }

      scrolling = false;
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const cardIndex = Array.from(items).indexOf(entry.target);
        if (entry.isIntersecting) {
          setActiveButtonIndex(cardIndex); // Update active button index based on visible card
        }
      });
    });

    for (let i = 0; i < items.length; i++) {
      observer.observe(items[i]);
    }

    window.addEventListener("resize", setStackCards);
    return () => {
      window.removeEventListener("resize", setStackCards);
      observer.disconnect();
      window.removeEventListener("scroll", scrollingFn);
    };
  }, []);
  const [activeButtonIndex, setActiveButtonIndex] = useState(0);
 
 
    const [textScale, setTextScale] = useState(1);
   
   const [ scroll , setScroll] = useState(false)
   
  const increaseTextScale = () => {
    setTextScale((prevScale) => prevScale + 0.1);
  };
  
  const decreaseTextScale = () => {
    setTextScale((prevScale) => (prevScale > 1 ? prevScale - 0.1 : 1));
  };
  
  const defaultStyles = {
    container: {
      "--stack-cards-gap": "40px",
      "--stack-cards-item-ratio": "2/1",
    },
    stackCards: {
      paddingBottom: "calc(var(--stack-cards-gap) * 1)",
    },
    cardItem: {
      position: "relative",
      position: "sticky",
      top: "30px",
      paddingBottom: "calc(40vh / var(--stack-cards-item-ratio))",
      transformOrigin: "center top",
      backgroundColor: "#ffffff",
      borderRadius: "8px",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      marginBottom: "110px",
      width: "95%",
      borderRadius: "10px",
      boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.3)",
      margin: "0 auto",
      padding: "15px",
    },
    cardContent: {
      position: "relative",
      top: 0,
      left: 0,
      height: "100%", // Default height
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
      backgroundColor: "#ffffff",
      padding: "10px",
      borderRadius: "10px",
      justifyContent: "space-between",
      transition: "height 0.3s ease",
    },
    smallCardContainer: {
      display: "flex",
      width: "100%",
      overflowX: "scroll",
      overflowY: "auto",
      height: "100%",
      padding: "10px 11px",
      // justifyContent: "space-evenly",
    },
    smallCard: {
      height: "54vh",
      width: "20%",
      padding:"10px",
      borderRadius: "8px",
     
      marginRight: "10px",
      flex:
      screenType === 'smallPhone' || screenType === 'phone'
        ? '0 0 70%'
        : screenType === 'tablet'
        ? '0 0 30%'
        : screenType === 'tab'
        ? '0 0 32.5%'
        : '0 0 21%',
      display: "flex",
      flexDirection: "column",
      overflowX: "hidden",
      justifyContent: "flex-start",
      overflowY: "auto",
      position: "relative",
      transition: "height 0.3s ease",
      boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.3)",
    },
    smallCardImage: {
      height: "70%",
      borderRadius: "8px 8px 0 0",
      objectFit: "cover",
      transition: "height 0.3s ease",
    },
    smallCardContent: {
      height: "auto",
      display: "flex",
      flexDirection: "column",
      textOverflow: "ellipsis",
      fontSize: "1.1rem",
      overflowX: "hidden",
      overflowY: "scroll",
      whiteSpace: "normal",
      backgroundColor: "#ffffff",
      rowGap: "13px",
      borderRadius: "0 0 8px 8px",
      transition: "height 0.3s ease",
      position: "absolute",
      bottom: "0",
      width: "100%",
      padding: "5px",
      marginTop: "20px",
    },
    smallCardHover: {
      height: "80%", // Expanded height on hover
    },
    title: {
      padding: "10px", // Added padding for title
      fontSize: "1.5rem", // Adjust as needed
      color: "#333", // Title color
      flexShrink: 0, // Prevent shrinking
    },
    smallCardTitle: {
      display:"flex",
      width:"90%",
      flexWrap:"wrap",
      fontSize: "1.7rem", // Adjust size as needed
      color: "black", // Title color
      flexShrink: 0, // Prevent title from shrinking
    },
    navbar: {
      display: "flex",
      width: "100vw",
      overflowX: "scroll",
      justifyContent: "flexstart",
    },
    navItem: {
      marginRight: "10px",
      cursor: "pointer",
      color: "grey",
      borderRadius: "4px",
      fontSize: "1rem",
    },
    activeNavItem: {
      textDecoration: "underline",
      fontWeight: "bold",
      color: "black",
    },
    cardtitle: {
      margin: "0px",
      fontSize: "2rem",
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '20px',
    },
    button: {
      padding: '10px 20px',
      margin: '0 10px',
      borderRadius: '5px',
      border: 'none',
      backgroundColor: '#007bff',
      color: '#fff',
      cursor: 'pointer',
    },
  };
  
  return (
    <>
      {/* <div style={defaultStyles.buttonContainer}>
        <button style={defaultStyles.button} onClick={increaseTextScale}>Increase Text Size</button>
        <button style={defaultStyles.button} onClick={decreaseTextScale}>Decrease Text Size</button>
      </div> */}
  
      <div
        style={{ ...defaultStyles.container, ...customStyles.container }}
        ref={stackCardsRef}
      >
        <div style={defaultStyles.stackCards}>
          {cardData.map((card, largeCardIndex) => (
            <div
              style={{ ...defaultStyles.cardItem, ...card.largeCardStyle }}
              key={largeCardIndex}
              className="js-stack-cards__item"
            >
              <div style={defaultStyles.cardContent}>
                <p style={{ ...defaultStyles.cardtitle, fontSize: `${2 * textScale}rem` }}>{t(card.title)}</p>
                <div style={defaultStyles.smallCardContainer}>
                  {card.smallCards.map((smallCard, smallCardIndex) => (
                    <div
                      key={smallCardIndex}
                      ref={(el) => {
                        if (!smallCardRefs.current[largeCardIndex]) {
                          smallCardRefs.current[largeCardIndex] = [];
                        }
                        smallCardRefs.current[largeCardIndex][smallCardIndex] = el;
                      }}
                      style={defaultStyles.smallCard}
                      className="small-card"
                      data-index={smallCardIndex}
                      onMouseEnter={(e) => {
                        const content = e.currentTarget.querySelector('.small-card-content');
                        content.style.height = '90%'; // Expand content height on hover
                        setScroll(true)
                      }}
                      onMouseLeave={(e) => {
                        const content = e.currentTarget.querySelector('.small-card-content');
                        content.style.height = '30%'; // Reset to original height
                          setScroll(false)
                          content.scrollTop = 0;
                       
                      }} 
                      onClick={(e) => {
                        if (screenType === "smallPhone" || screenType === "phone"||screenType==="tab"||screenType==="tablet") {
                          const content = e.currentTarget.querySelector(".small-card-content");
                          // Toggle the height between 90% and 30%
                          if (content.style.height === "90%") {
                            content.style.height = "30%";
                            setScroll(false);
                            content.scrollTop = 0;
                          } else {
                            content.style.height = "90%";
                            setScroll(true);
                          }
                        }
                      }}
                    >
                      <img
                        src={smallCard.src}
                        alt={smallCard.alt}
                        style={defaultStyles.smallCardImage}
                        className="small-card-image"
                        loading="lazy" 
                      />
                      <div
                        style={{
                          ...defaultStyles.smallCardContent,
                          height: '30%', // Initial height
                        }}
                        className="small-card-content"
                      >
                        <div style={defaultStyles.smallCardTitle}>
                          <p style={{ display: 'flex', marginLeft: '20px' }}>{t(smallCard.title)}</p>
                        </div>
                        <div
                          style={{
                            height: '100%',
                            height: 'auto', // Set to auto to allow it to expand
                            flex: '1',
                            padding: '20px',
                          }}
                        >
                          {t(smallCard.content)}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
  };
  

export default StackingCardforInstitute;