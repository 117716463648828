import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useScreenSizes from '../../utils/useScreenSize';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const initialProducts = [
  {
    id: 1,
    name: "StudyBuddy For Student",
    img: require("../../assets/images/student3D.webp"),
  },
  {
    id: 2,
    name: "StudyBuddy For Parent",
    img: require("../../assets/images/parents.webp"),
  },
  {
    id: 3,
    name: "StudyBuddy For Teacher",
    img: require("../../assets/images/teacher3D.webp"),
  },
  {
    id: 4,
    name: "StudyBuddy For Institute",
    img: require("../../assets/images/school.webp"),
  },
];

const Card = () => {
  const [products] = useState(initialProducts);
  const [hoveredCard, setHoveredCard] = useState(null); // State to track which card is hovered
  const [textScale, setTextScale] = useState(1); // State to control text scale
  const navigate = useNavigate();
  const { t } = useTranslation();
  const screen = useScreenSizes();
  const location = useLocation();

  // Mapping route to card ID for background color
  const routeToIdMap = {
    '/forStudent': 1,
    '/forParent': 2,
    '/forTeacher': 3,
    '/forInstitute': 4,
  };
  const screenType = useSelector((state) => state.website.screenType);

  // Custom click functions for each card
  const handleCardClick = (id) => {
    switch (id) {
      case 1:
        navigate('/forStudent');
        break;
      case 2:
        navigate('/forParent');
        break;
      case 3:
        navigate('/forTeacher');
        break;
      case 4:
        navigate('/forInstitute');
        break;
      default:
        break;
    }
  };

  // Determine the active card ID based on the route
  const activeCardId = routeToIdMap[location.pathname] || null;

  const increaseTextScale = () => {
    setTextScale((prevScale) => prevScale + 0.1);
  };

  const decreaseTextScale = () => {
    setTextScale((prevScale) => (prevScale > 1 ? prevScale - 0.1 : 1));
  };

  return (
    <div>
      <div style={{ display: 'flex', flexWrap: screenType === "phone" || screenType === "smallPhone" || screenType === "tablet" ? "wrap" : "", justifyContent: 'space-around', width: "100vw", fontFamily: "raleway" }}>
        {products.map((product) => ( 
          
          <div
            key={product.id} 
            style={{
              border: '1px solid #ccc',
              borderRadius: '10px',
              margin: '10px',
            
              padding: '10px',
              width: `${textScale * (screenType === "phone" ? 10 : screenType === "smallPhone" ? 8 : 12)}em`,
              textAlign: 'center',
              boxShadow: "10px 10px 15px rgba(0, 0, 0, 0.3)",
              transition: 'transform 0.2s, box-shadow 0.2s',
              backgroundColor: activeCardId === product.id ? 'lightblue' : 'white',
            }}
            onClick={() => handleCardClick(product.id)} // Set unique onClick for each card
            onMouseEnter={() => setHoveredCard(product.id)} // Set the hovered card ID
            onMouseLeave={() => setHoveredCard(null)} // Reset on mouse leave
          >
            <div style={{ height: screenType==="phone"||screenType=="smallPhone"? "10rem" :screenType==="tab"? "10rem" : '15em', marginBottom: '0.5em' }}>
              <img
                src={product.img}
                alt={product.name}
                style={{
                  maxHeight: product.id ==1?  "80%":'100%',
                  
                  maxWidth: '100%',
                  transform: hoveredCard === product.id ? 'scale(1.4)' : 'scale(1)',
                  transition: "transform 1s ease"
                }}
                loading="lazy" 
              />
            </div>
            <div style={{ fontSize: `${textScale * 1}em` }}>
              <p style={{
                fontFamily: "raleway",
                transition: "transform 1s ease",
                color: "black",
                fontSize:"20px",
                fontWeight:"bold",
                transform: hoveredCard === product.id ? "translateY(0.5em)" : "translateY(0)",
              }}>{t(product.name)}</p>
            </div>
          </div>
        ))}
      </div>
      {/* <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1em' }}>
        <button onClick={increaseTextScale} style={{ marginRight: '0.5em' }}>Increase Text Size</button>
        <button onClick={decreaseTextScale}>Decrease Text Size</button>
      </div> */}
    </div>
  );
};

export default Card;