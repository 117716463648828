import { useEffect, useState } from 'react';
import {   ActivityIndicator, Platform } from 'react-native';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../firebase";
const DocViewer = ({ item, onCancel, id, asFile, uploading }) => {
        const [loading, setLoading] = useState('0%');
        const [isDownloading, setIsDownloading] = useState(false);
        const [fileUrl, setFileUrl] = useState('');
        const [fileExists, setFileExists] = useState(false);

        // Check if file exists when the component is mounted
        useEffect(() => {
            const checkFileExists = async () => {
                if (!item?.name) return;
    
                const basePath = "https://your-server.com/uploads"; // Replace with your actual file path
                const filePath1 = `${basePath}/sent/${item.name}`;
                const filePath2 = `${basePath}/${item.name}`;
    
                try {
                    const response1 = await fetch(filePath1, { method: "HEAD", mode: "no-cors" });
                    if (response1.ok) {
                        setFileUrl(filePath1);
                        setLoading("100%");
                        setFileExists(true);
                        return;
                    }
    
                    const response2 = await fetch(filePath2, { method: "HEAD", mode: "no-cors" });
                    if (response2.ok) {
                        setFileUrl(filePath2);
                        setLoading("100%");
                        setFileExists(true);
                        return;
                    }
    
                    setFileExists(false);
                } catch (error) {
                    console.error("Error checking file existence:", error);
                    setFileExists(false);
                }
            };
    
            if (asFile) {
                checkFileExists();
            }
        }, [item?.name, uploading]);
    
        // Function to download the file
        const downloadFile = async (filePath, filename) => {
            if (isDownloading) return; // Prevent multiple downloads
    
            setIsDownloading(true);
            setLoading("0%");
    
            try {
            
    
                const fileRef = ref(storage,filePath );
    const url = await getDownloadURL(fileRef);
    window.open(url, "_blank"); // Opens in a new tab
    
                setLoading("100%");
            } catch (error) {
                console.error("Error downloading file:", error);
                alert("Error downloading file.");
            } finally {
                setIsDownloading(false);
            }
        };
    
        try {
        // Function to open the downloaded file
        const openFile = (url) => {
            if (url) {
                try {
                    window.open(url, "_blank"); // Opens in a new tab
                } catch (error) {
                    console.error("Error opening file:", error);
                    alert("Error opening document.");
                }
            }
        };
        

        const convertKBtoMB = (sizeInBytes) => {
            const sizeInKB = sizeInBytes / 1024;
            if (sizeInKB < 100) {
                return `${sizeInKB.toFixed(2)} KB`;
            } else {
                const sizeInMB = sizeInKB / 1024;
                return `${sizeInMB.toFixed(2)} MB`;
            }
        };

        return (
            <div style={{
                width: asFile ? 220 : 160,
                padding: 10,
                backgroundColor: 'white',
                margin: 10,
                borderRadius: 10,
                paddingRight: 5,
                ...(Platform.OS === 'ios'
                    ? {
                        shadowColor: '#000',
                        shadowOffset: { width: 0, height: 2 },
                        shadowOpacity: 0.25,
                        shadowRadius: 3.84,
                    }
                    : { elevation: 2 }),
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                 display:"flex"
            }}>
                <div style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                     display:"flex"
                }} activeOpacity={0.6}
                    onClick={() => (asFile && openFile(fileUrl))}
                    disabled={!!onCancel}
                >
                    <img src={
                        item.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                            ? require('../../assets/images/excel.png')
                            : item.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                                ? require('../../assets/images/google-docs.png')
                                : item.type == 'application/pdf'
                                    ? require('../../assets/images/pdf.png')
                                    : item.type == 'video/mp4'
                                        ? require('../../assets/images/video.png')
                                        : require('../../assets/images/galleryImg.png')
                    } style={{
                        height: 30,
                        width: 30,
                        marginRight: 3
                    }} />
                    <div style={{ paddingRight: 0 }}>
                        <p numberOfLines={2} style={{ color: 'black', width: onCancel ? 65 : 80 }}>
                            {item.name ? item.name : 'file'}
                        </p>
                    </div>
                </div>

                {asFile && <p style={{ color: 'black', fontSize: 10, marginRight: 5 }}>{convertKBtoMB(item.size)}</p>}

                {(!fileExists && asFile && !uploading) &&
                    <div style={{
                        width: 28,
                        height: 28,
                        justifyContent: 'center',
                        backgroundColor: 'white',
                        alignItems: 'center',
                        borderRadius: 20,
                        display:"flex"
                    }} activeOpacity={0.6}
                        onClick={() => downloadFile(item.url)}
                        disabled={isDownloading}
                    >
                        {!isDownloading ?
                            <img src={require('../../assets/images/download.webp')} style={{ width: '80%', height: '80%' , cursor:"pointer" }} />
                            :
                            <p style={{ color: 'black', fontSize: 10 }}>{loading}</p>}
                    </div>
                }
                {uploading && <ActivityIndicator size={'large'} color={'black'} />}

                {onCancel && <div style={{ width: 25, height: 25, justifyContent: 'center' ,  display:"flex" }} activeOpacity={0.6} onClick={() => onCancel(id)}>
                    <img src={require('../../assets/images/cross.png')} style={{ width: '70%', height: '70%' }} />
                </div>}
            </div>
        );
    }
    catch (err) {
        console.log(err);
    }
};

export default DocViewer;