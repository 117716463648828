export const peopleimage= {
        'people':require('../assets/images/profile.webp'),
        'freedamfighterback':require('../assets/images/freedomfighter.webp'),
        'freedamfighter':require('../assets/fallbackimages/freedamfighter.jpg'),
        'hostalimage' :require('../assets/images/hostel.webp'),
        'libraryimage':require('../assets/images/library.webp'),
        'clubimage':require('../assets/images/club.webp'),
        'courseimage':require('../assets/images/course.webp'),
        'screenbackimage':require('../assets/fallbackimages/screenbackground.jpg'),
        'buildingback':require('../assets/fallbackimages/buildingimage.jpg'),
        'classimage':require('../assets/fallbackimages/classimage.jpg'),
        'busimage':require('../assets/images/bus.webp'),
        'messimage':require('../assets/images/cooking.webp'),
        'roomimage':require('../assets/images/bedroom_1476010.webp'),
        'blogbackimage':require('../assets/fallbackimages/blogimage.jpg'),
        'bookimage':require('../assets/images/books.webp'),
        'subjectlogo':require('../assets/images/dna.webp'),
        'quizimage':require('../assets/images/quizes.webp')
        

    }
    
