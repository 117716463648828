
import springtree from "../assets/images/spriningseasontree.webp"

const themes = {
  default: {
    logo:  require('../assets/images/logo.webp'), // Set the path to your image here
    wordMark:  require('../assets/images/wordmark.webp'),
    soilColor: '#c7985f', // Brown
    Grass: require('../assets/images/grass.webp'),
     imagesConfig : [
      {
        imagePath: require("../assets/Animations/redBird.gif"),
        style: {
          width: (location, TitlebarHeight, screenType) =>
            location.pathname === "/LMS" && TitlebarHeight === "10vh"
              ? 17
           
              : screenType === "phone"
              ? 20
              : screenType === "smallPhone"
              ? 20
              : screenType === "tab"
              ? 30
              : screenType === "tablet"
              ? 40    : TitlebarHeight === "16vh"
              ? 50
              : 60,
          height: "auto",
        },
        originEnd: "left",
        verticalAlign: "top",
        duration: 10000,
        zIndex: -1000,
      },
      {
        imagePath: require("../assets/Animations/flockBirds.gif"),
        style: {
          width: (location, TitlebarHeight, screenType) =>
            location.pathname === "/LMS" && TitlebarHeight === "10vh"
              ? 17
           
              : screenType === "phone"
              ? 20
              : screenType === "smallPhone"
              ? 20
              : screenType === "tab"
              ? 30
              : screenType === "tablet"
              ? 40    : TitlebarHeight === "16vh"
              ? 50
              : 60,
          height: "auto",
        },
        originEnd: "right",
        verticalAlign: "top",
        duration: 5000,
      },
      {
        imagePath: require("../assets/Animations/ikgai-bird.gif"),
        style: {
          width: (location, TitlebarHeight, screenType) =>
            location.pathname === "/LMS" && TitlebarHeight === "10vh"
              ? 17
           
              : screenType === "phone"
              ? 20
              : screenType === "smallPhone"
              ? 20
              : screenType === "tab"
              ? 30
              : screenType === "tablet"
              ? 40    : TitlebarHeight === "16vh"
              ? 50
              : 60,
          height: "auto",
        },
        originEnd: "right",
        verticalAlign: "top",
        duration: 5000,
      },
      {
        imagePath: require("../assets/Animations/wiseBird.gif"),
        style: {
          width: (location, TitlebarHeight, screenType) =>
            location.pathname === "/LMS" && TitlebarHeight === "10vh"
              ? 17
           
              : screenType === "phone"
              ? 20
              : screenType === "smallPhone"
              ? 20
              : screenType === "tab"
              ? 30
              : screenType === "tablet"
              ? 40    : TitlebarHeight === "16vh"
              ? 50
              : 60,
          height: "auto",
        },
        originEnd: "left",
        verticalAlign: "top",
        duration: 5000,
      },
      {
        imagePath: require("../assets/Animations/blackBird.gif"),
        style: {
          width: (location, TitlebarHeight, screenType) =>
            location.pathname === "/LMS" && TitlebarHeight === "10vh"
              ? 17
           
              : screenType === "phone"
              ? 20
              : screenType === "smallPhone"
              ? 20
              : screenType === "tab"
              ? 30
              : screenType === "tablet"
              ? 40    : TitlebarHeight === "16vh"
              ? 50
              : 60,
          height: "auto",
        },
        originEnd: "left",
        verticalAlign: "top",
        duration: 5000,
      },
    ],
  },
  dark: {
    logo: springtree||'url(../../../../assets/images/logo.webp)', // Set the path to your image here
    soilColor: '#8B4513', // Brown
    treeColor: '#16A085', // Teal green
    textColor: '#FFFFFF', // White
  },
  forest: {
    backgroundImage: null, // You can set an image if needed
    soilColor: '#3B2C20', // Dark brown
    treeColor: '#A3C13A', // Light green
    textColor: '#1C1C1C', // Dark gray
  },
  
};

export default themes;
