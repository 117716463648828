import React from 'react';
import { useEffect, useState } from 'react';
import useScreenSizes from '../../utils/useScreenSize';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
const ERPContainer = () => {
  // Data array for all management systems
  const Data = [
    {
      title: 'Transport Management',
      explanation:
        'The transport management system ensures that parents and school administrators can monitor school buses in real-time, improving safety and communication. With optimized routes and automated alerts, transportation becomes smoother, faster, and more efficient. This helps reduce delays and offers peace of mind to both schools and parents.',
      features: [
        {
          title: 'Real-Time Tracking',
          content: 'Keep track of school buses and student commutes with real-time GPS tracking for parents and schools.',
          image: require("../../assets/images/Real-time-Tracking.webp"), // Image for Real-Time Tracking
        },
        {
          title: 'Optimised Routes',
          content: 'Ensure fuel-efficient and time-saving bus routes for both students and the institution.',
          image: require("../../assets/images/optimisedRoutes (1).webp"),        },
        {
          title: 'Automated Alerts',
          content: 'Receive instant notifications for pick-up and drop-off times, delays, or emergencies.',
          image: require("../../assets/images/automatedAlerts.webp"),        },
      ],
    },
    {
      title: 'Finance Management',
      explanation:
        'Managing school finances can be complex, but with ERP, tasks like fee collection, budget allocation, and financial reporting are simplified. Schools can automate billing processes, monitor expenses, and provide transparent financial records, making the system efficient and trustworthy.',
      features: [
        {
          title: 'Easy Fee Collection',
          content: 'Simplify fee collection with automated billing, receipts, and reminders for parents.',
          image: require("../../assets/images/easyFeeCollection.webp"),        },
        {
          title: 'Budget Management',
          content: 'Schools can efficiently track expenses, allocate budgets, and reduce financial errors.',
          image: require("../../assets/images/budgetManagement.webp"),        },
        {
          title: 'Transparency',
          content: 'Provide detailed reports on income and expenditures to maintain transparency with stakeholders.',
          image: require("../../assets/images/transperancy.webp"),        },
      ],
    },
    {
      title: 'Assets Management',
      explanation:
        'Schools have valuable assets like equipment, books, and technology, which can be difficult to manage manually. ERP helps track inventory, schedule maintenance, and ensure proper allocation of resources.',
      features: [
        {
          title: 'Track School Property',
          content: 'Keep an inventory of school property such as computers, lab equipment, and furniture to avoid loss or misuse.',
          image: require("../../assets/images/trackSchoolProperty.webp"),        },
        {
          title: 'Maintenance Alerts',
          content: 'Schedule timely maintenance for assets, reducing downtime and repair costs.',
          image: require("../../assets/images/maintanceaAlerts.webp"),        },
        {
          title: 'Asset Allocation',
          content: 'Assign and track usage of assets, such as projectors or lab tools, to specific departments or staff.',
          image: require("../../assets/images/assetAllocation.webp"),        },
      ],
    },
  ];

  const screenType = useSelector((state) => state.website.screenType);
  
  const [hoveredCardIndex, setHoveredCardIndex] = useState(null);
  const styles = {
    mainContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingtop:  screenType==="smallPhone"||screenType==="phone"?"7vh" : '10vh',
        },
    cardContainer: {
      display: 'flex',
      justifyContent: screenType==="smallPhone" || screenType==="phone" ? "center" : 'space-between',
      width: screenType==="smallPhone" || screenType==="phone" ? "100vw" : '95vw',
      flexDirection: screenType==="smallPhone" || screenType==="phone" ? "column" : "",
      height: screenType==="smallPhone" || screenType==="phone" ? "100vh" : '50vh',
      marginBottom: "25px"
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: screenType==="smallPhone" || screenType==="phone" ? "90vw" : '30%',
      borderRadius: '10px',
      overflowY: 'scroll',
      boxShadow: "10px 10px 15px rgba(0, 0, 0, 0.3)",
      marginBottom: "25px",
      transition: 'transform 0.3s ease', // Smooth transition for scaling
    },
    cardHover: {
      transform: 'scale(1.05)', // Scale up the card on hover
    },
    cardImage: {
      height: '70%',
      overflow: 'hidden',
      backgroundColor: '#f0f0f0',

    },
    cardContent: {
      height: '30%',
      padding: '10px',
      backgroundColor: '#fff',
      textAlign: 'center',
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
    dataContainer: {
      marginTop: screenType==="smallPhone" ? "25vw":screenType==="phone"?"25vw" : screenType==="tab"? "15vw":screenType==="tablet"?"12vw": '2vw',  
      width: screenType==="smallPhone" || screenType==="phone" ? "98vw" : '95vw',
      padding: '15px',
      backgroundColor: '#fff',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      borderRadius: '10px',
    },
  };
  
const {t} = useTranslation(); 
const [textScale, setTextScale] = useState(1); // State for text scale
const increaseTextScale = () => {
  setTextScale((prevScale) => prevScale + 0.1);
};

const decreaseTextScale = () => {
  setTextScale((prevScale) => (prevScale > 1 ? prevScale - 0.1 : 1));
};
return (
  <div style={styles.mainContainer}>
   
    {Data.map((system, systemIndex) => (
      <div key={systemIndex} style={styles.dataContainer}>
        <p style={{ fontSize: `${25 * textScale}px`, fontWeight: "bold" }}>{t(system.title)}</p>
        <div style={styles.cardContainer}>
          {system.features.map((feature, index) => (
            <div
              key={index}
              style={{
                ...styles.card,
                ...(hoveredCardIndex === index ? styles.cardHover : {}),
              }}
              onMouseEnter={() => setHoveredCardIndex(index)}
              onMouseLeave={() => setHoveredCardIndex(null)}
            >
              <div style={styles.cardImage}>
                <img
                  src={feature.image} // Dynamic image loading
                  alt={feature.title}
                  style={styles.image}
                  loading="lazy"  />
              </div>
              <div style={styles.cardContent}>
                <p style={{ fontSize: `${20 * textScale}px`, margin: "0px" }}>{t(feature.title)}</p>
                <p style={{ fontSize: `${16 * textScale}px` }}>{t(feature.content)}</p>
              </div>
            </div>
          ))}
        </div>
        <p style={{ fontSize: `${16 * textScale}px` }}>{t(system.explanation)}</p>
      </div>
    ))}
  </div>
);
};

// Styling object


export default ERPContainer;
