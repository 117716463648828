import React, { useEffect, useState } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import useScreenSizes from '../../utils/useScreenSize'; // Adjust the path as necessary
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { fontSize, fontWeight } from '@mui/system';
import { firestore } from '../../firebase';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';

const ImageCarousel = () => {
    const screen = useScreenSizes();
    const [isPhone, setIsPhone] = useState(false);
    const [isTablet, setIsTablet] = useState(false);
    const [hovered, setHovered] = useState(false); // Track hover state
    const { t } = useTranslation();
    const [carouselItems, setCarouselItems] = useState([]);
    console.log('carouselItems: ', carouselItems);
    const navigate = useNavigate();
    const screenType = useSelector((state) => state.website.screenType);

    useEffect(() => {
        if (screen >= 1050) {
            setIsTablet(false);
            setIsPhone(false);
        } else if (screen > 650) {
            setIsTablet(true);
            setIsPhone(false);
        } else {
            setIsPhone(true);
            setIsTablet(false);
        }
    }, [screen]);
  
    useEffect(() => {
        const fetchCarouselItems = async () => {
          try {
            // Reference to the specific document
            const docRef = doc(firestore, 'HeaderCarousel', 'carouselData');
            const docSnap = await getDoc(docRef);
    
            if (docSnap.exists()) {
              const data = docSnap.data();
              if (data.items && Array.isArray(data.items)) {
                setCarouselItems(data.items);
              } else {
                console.error('No items array found in document');
              }
            } else {
              console.error('Document does not exist');
            }
          } catch (error) {
            console.error('Error fetching carousel items:', error);
          }
        };
    
        fetchCarouselItems();
      }, []);
    
      const handleItemClick = (actionPath) => {
        navigate( actionPath);
      };
    //   const carouselItems = [
    //     // {
    //     //     imageUrl: require('../../assets/images/Registration.jpeg'),
         
    //     //     action: () => {

    //     //         navigate("/festInputForm"); // Navigate to the quiz
    //     //     },
    //     // },
    //     {
    //         imageUrl: require('../../assets/images/WSBanner_batcheditor_fotor.webp'),
   
    //         buttonText: "Get Started",
    //         action: () => {

    //             navigate("/getStarted"); // Navigate to the quiz
    //         },
    //     },
        
    // ];

    // Sample data for the carousel with button-specific routes
  
    const buttonContainerStyles = {
        position: "absolute",
        top: "67%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 2,
    };
const buttonContainerStyle ={
    position: "absolute",
    top: "80%",
  
    left: "50%",
    transform: "translate(-50%, -68%)",
    zIndex: 2,
}
    const buttonStyles = {
        background: "#162d49",
        border: "none",
        color: "#da8d06",
      
        padding:
            screenType === "phone" || screenType === "smallPhone"
                ? "5px 10px"
                : "10px 20px",
        fontSize:
            screenType === "phone" || screenType === "smallPhone"
                ? "0.8rem"
                : "1.2rem",
        fontWeight: "bold",
        borderRadius: "10px",
        cursor: "pointer",
        transform: hovered ? "scale(1.1)" : "scale(1)", // Scale up on hover
        transition: "transform 0.3s ease-in-out", // Smooth animation
    };
const buttonstyle ={
background:"rgb(22 46 74)",
color:"#ff9c00",
width: screenType==="desktop"? "229px" :screenType==="smallPhone"? "67px":screenType==="phone" ? "70px"  : screenType==="tab" ? "130px" :screenType==="tablet" ? "165px" : "140px",
height: screenType==="smallPhone"? "13px": screenType==="phone" ? "15px" : "40px",
  border:"none",
  fontWeight:700,
  fontSize :screenType==="phone"||screenType==="smallPhone"? "7px" : "",
    borderRadius: "10px",
    cursor: "pointer",
    transform: hovered ? "scale(1.1)" : "scale(1)", // Scale up on hover
    transition: "transform 0.3s ease-in-out", // Smooth animation
}
    return (

        <div style={{ width: '100%' }}>
            <Carousel
                autoFocus={true}
                showThumbs={false}
                showStatus={false}
                showIndicators={false}
                infiniteLoop
                autoPlay={true}
                interval={5000}
                showArrows={false}
            >

                {carouselItems.map((item, index) => (
                    <div
                        key={index}
                        style={{
                            height: "35vw",
                            width: "87vw",
                            margin: "2vw auto",
                            position: "relative",
                            borderRadius: "10px",
                          
                        }}
                    >
                        
                        <div 
                                style={buttonContainerStyle}
                            onMouseEnter={() => setHovered(true)}
                            onMouseLeave={() => setHovered(false)}
                            onClick={() => navigate(item.actionPath)}   // Navigate based on slide's route
                        >
                          <button style={buttonstyle}>
                                {t(item.buttonText)}
                            </button>
                        </div>

                        {/* Carousel Image */}
                 
                          <div
                            style={{
                                width: '100%',
                                height:"100%",
                                aspectRatio: index===1? "6937/2781":"6937/2781",
                                backgroundImage: `url(${item.imageUrl})`,
                                backgroundSize: 'contain',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                borderRadius: '8px',
                                boxSizing: 'border-box',
                                  marginbutton: index ===1 ? "20px" : "",
                                overflow: 'hidden',
                            }}
                        ></div>
                    </div>
                ))}
            </Carousel>
        </div>
    );
};

export default ImageCarousel;
