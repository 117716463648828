import React, { useEffect, useState } from "react";
import { BrowserRouter, useLocation } from "react-router-dom";
import { Provider, useSelector } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./reduxStore/store";
import AppContent from "./navigation/AppContent";
// import AppC from "./Components/quizModule.js/QuizModuleApp";
import "./App.css";
import { addNotification } from "./reduxStore/slices/Notification";
import themes from "./config/theme";
import QuizApp from "./Components/quizModule.js/InstructionComponent";
import QuizData from "./Components/quizModule.js/data/quizData";
import BlogUploader from "./FireStore/AddData/BlogData";
import BlogCategoryList from "./Screens/Blog/FetechBlogData";
const Loader = () => {
  const loading1 = useSelector((state) => state.website.loading1);
  const loading2 = useSelector((state) => state.website.loading2);
  const loading = useSelector((state) => state.website.loading);
const notification = useSelector((state) => state.website.notification);
  const screenType = useSelector((state) => state.website.screenType);
 
  if (loading1 && loading2&&loading) {

    return (
      <div
        style={{
          height: "100vh",
          width: "100vw",
          position: "absolute",
          zIndex: 100,
          display: "flex",
          backgroundColor: "white",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={require("./assets/Animations/loader.gif")} alt="Loading..." />
      </div>
    );
  } else {
    return null; // Hide the loader when both are false
  }
};

// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker
//     .register('/firebase-messaging-sw.js')
//     .then((registration) => {
      

     
//       navigator.serviceWorker.addEventListener('message', (event) => {
       
//         if (event.data && event.data.type === 'NOTIFICATION_RECEIVED') {
      
//           store.dispatch(addNotification(event.data.payload.notification));
        
//         }
//       });
//     })
//     .catch((error) => {
      
//     });
// }
const App = () => { 
  // useEffect(() => {
  //   // Disable right-click globally
  //   const disableRightClick = (event) => {
  //     event.preventDefault();
  //   };

  //   // Disable copy event
  //   const disableCopy = (event) => {
  //     event.preventDefault();
  //   };

  //   document.addEventListener("contextmenu", disableRightClick);
  //   document.addEventListener("copy", disableCopy);
  //   const checkDevTools = () => {
  //     const devToolsOpen = window.outerWidth - window.innerWidth > 100 || window.outerHeight - window.innerHeight > 100;
  //     if (devToolsOpen) {

  //     }
  //   }; 
  //   document.addEventListener("dragstart", (e) => {
  //     e.preventDefault();
  //   });
    
  //   document.addEventListener('beforeunload', () => {
  //     const images = document.querySelectorAll('img');
  //     images.forEach((img) => {
  //       img.src = 'data:image/png;base64,...'; // Replace the src with a data URL or remove the src altogether
  //     });
  //   });
    
  //   setInterval(checkDevTools, 1000); // Check every second

  //   return () => {
  //     document.removeEventListener("contextmenu", disableRightClick);
  //     document.removeEventListener("copy", disableCopy);
  //   };
  // }, []); 

  // useEffect(() => {
  //   // Function to block all key combinations to open dev tools
  //   const blockDevToolsShortcuts = (event) => {
  //     // Block common developer tools shortcuts
  //     const devToolsKeys = [
  //       event.key === 'F12', // F12
  //       (event.ctrlKey && event.shiftKey && event.key === 'I'), // Ctrl + Shift + I
  //       (event.ctrlKey && event.shiftKey && event.key === 'J'), // Ctrl + Shift + J
  //       (event.ctrlKey && event.shiftKey && event.key === 'C'), 
  //       (event.ctrlKey && event.key === 'U'), // Ctrl + U (view source)
  //       (event.key === 'F11'), // F11 (for fullscreen, sometimes opens dev tools)
  //     ];

  //     if (devToolsKeys.some(Boolean)) {
  //       event.preventDefault(); // Block the default behavior
  //     }
  //   };

  //   // Add the event listener for keydown events
  //   window.addEventListener("keydown", blockDevToolsShortcuts);

  //   // Function to detect when the developer tools are opened (by checking window size changes)
  //   const detectDevTools = () => {
  //     const threshold = 100; // threshold for window resizing
  //     const devToolsOpen = window.outerWidth - window.innerWidth > threshold || window.outerHeight - window.innerHeight > threshold;

  //     if (devToolsOpen) {
  //     }
  //   };

  //   // Interval to detect dev tools
  //   const devToolsInterval = setInterval(detectDevTools, 1000);

  //   // Cleanup the event listener and interval on unmount
  //   return () => {
  //     window.removeEventListener("keydown", blockDevToolsShortcuts);
  //     clearInterval(devToolsInterval);
  //   };
  // }, []);
   useEffect(() => {
        const handleKeydown = (event) => {
            console.log("Key pressed:", event.key);
    
            if (event.key === "Escape") {
              event.preventDefault();
                console.log("Escape key was pressed!");
              
            }
        };
    
        window.addEventListener("keydown", handleKeydown);
    
        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("keydown", handleKeydown);
        };
    }, []);
    
  return (
    <Provider store={store}>  
  
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Loader />
          <AppContent/>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

export default App;
