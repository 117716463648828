import React, { useEffect } from 'react';
import { AlertCircle, CheckCircle, Info, XCircle, X } from 'lucide-react';

const Alert = ({ type, message, onClose, show = true }) => {
  if (!show) return null;

  const styles = {
    container: {
      position: 'fixed',
      top: '150px',
      right: '20px',
      padding: '16px',
      borderRadius: '8px',
      display: 'flex',
      alignItems: 'center',
      gap: '12px',
      maxWidth: '400px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      animation: 'slideIn 0.3s ease-out',
    },
    message: {
      margin: 0,
      fontSize: '14px',
      flex: 1,
    },
    closeButton: {
      background: 'none',
      border: 'none',
      padding: '4px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      opacity: 0.7,
      transition: 'opacity 0.2s',
    }
  };

  const typeStyles = {
    success: {
      background: '#ecfdf5',
      color: '#065f46',
      border: '1px solid #a7f3d0',
    },
    error: {
      background: '#fef2f2',
      color: '#991b1b',
      border: '1px solid #fecaca',
    },
    warning: {
      background: '#fffbeb',
      color: '#92400e',
      border: '1px solid #fde68a',
    },
    info: {
      background: '#eff6ff',
      color: '#1e40af',
      border: '1px solid #bfdbfe',
    },
  };

  const IconComponent = {
    success: CheckCircle,
    error: XCircle,
    warning: AlertCircle,
    info: Info,
  }[type];

setTimeout (()=>{
onClose(true)
},[3000])

  return (
    <div style={{ ...styles.container, ...typeStyles[type] }}>
      <IconComponent size={20} />
      <p style={styles.message}>{message}</p>
      {onClose && (
        <button
          onClick={onClose}
          style={styles.closeButton}
          aria-label="Close alert"
        >
          <X size={16} />
        </button>
      )}
    </div>
  );
};

export default Alert;
