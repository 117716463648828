
import { margin } from "@mui/system";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const PostCard = ({ post, textScale }) => {

  const [isHovered, setIsHovered] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate()

  const screenType = useSelector((state) => state.website.screenType);

  const styles = (textScale, screenType) => ({
    container: {
      width: "100%",
    display: "flex",  // Ensure the parent container is a flexbox
    // flexWrap: "wrap", // Allow items to wrap if necessary (optional)
    justifyContent: "center", // Center content horizontally
    alignItems: "center", // Align items vertically
    gap: "20px", // Space between cards
  
    },
    blogsHeader: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    
      margin: "20vh",
    },
    blogsText: {
      fontSize: "2rem",
      fontWeight: "bold",
      color: "#333",
      textAlign: "center",
      letterSpacing: "2px",
    },
    post: {
    
    
      height: `${550 * textScale}px`,
      margin: "0 10px",
      // display: "flex",  
      flexDirection: "column",
      borderRadius: "10px",
      // overflow: "hidden",
      backgroundColor: "white",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    },
    headerPost: {
      width: "100%",
      background: "#ddd",
      position: "absolute",
      top: 0,
      transition: "margin-top 0.2s",
    },
    headerPostImage: {
      width: "100%",
      // height: "100%",
      transition: "transform 0.6s",
    },
    bodyPost: {
      width: "100%",
      height: `${textScale * 400}px`,
      background: "#fff",
      position: "absolute",
      bottom: 0,
      display: "flex",
      justifyContent: "center",
      // alignItems: "center",
      transition: "height 0.2s",
      cursor: "pointer",
    },
    postContent: {
      width: "80%",
      // width:"100%",
      margin: "2vw",
      height: "80%",
      background: "#fff",
      position: "relative",
    },
    title: {
      fontSize: "15px",
      fontWeight: "bold",
      margin: 0
    },
    paragraph: {
      fontSize: "14px",
      fontWeight: "normal",
    },
    containerInfos: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      position: "absolute",
      borderTop: "1px solid rgba(0, 0, 0, 0.2)",
      // paddingTop:  "25px",
    },
    postedBy: {
      display: "flex",
      flexDirection: "column",
      textTransform: "uppercase",
      letterSpacing: "1px",
      fontSize: "12px",
    },
    containerTags: {
      display: "flex",
      flexDirection: "column",
    },
    span: {
      fontSize: "12px",
      textTransform: "uppercase",
      opacity: 0.5,
      letterSpacing: "1px",
      fontWeight: "bold",
    },
    tagsList: {
      display: "flex",
    },
    tagItem: {
      fontSize: "12px",
      letterSpacing: "2px",
      listStyle: "none",
      marginLeft: "8px",
      textTransform: "uppercase",
      position: "relative",
      zIndex: 1,
      display: "flex",
      justifyContent: "center",
      cursor: "pointer",
    },
    detailContainer: {
      maxWidth: "800px",
      padding: "20px",
      backgroundColor: "#fff",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px",
      fontFamily: "Arial, sans-serif",
    },
    backButton: {
      fontSize: "16px",
      color: "#007BFF",
      border: "none",
      backgroundColor: "transparent",
      cursor: "pointer",
      marginBottom: "20px",
      display: "inline-flex",
      alignItems: "center",
    },
    detailImage: {
      width: "100%",
      height: "auto",
      borderRadius: "8px",
      marginBottom: "20px",
    },
    detailTitle: {
      fontSize: "2rem",
      fontWeight: "bold",
      color: "#333",
      marginBottom: "20px",

    },
    detailContent: {
      fontSize: "1rem",
      lineHeight: "1.6",
      color: "#555",
    },
    categoryContainer: {
      marginTop: "20px",
      fontSize: "1.1rem",
    },
    categoryLabel: {
      fontWeight: "bold",
      color: "#333",
    },
    categoryText: {
      fontStyle: "italic",
      color: "#007BFF",
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '20px',
    },
    button: {
      padding: '10px 20px',
      margin: '0 10px',
      borderRadius: '5px',
      border: 'none',
      backgroundColor: '#007bff',
      color: '#fff',
      cursor: 'pointer',
    },
    errorCard: {
      width: "40%",
      height: "300px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "white",
      borderRadius: "10px",
      color: "#d32f2f",
      fontWeight: "bold",
      fontSize: "1.5rem",
    },
    skeletonLoader: {
      width: "400px",
      height: "600px",
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#f0f0f0",
      borderRadius: "10px",
      position: "relative",
      overflow: "hidden",
    },
    skeletonImage: {
      width: "100%",
      height: "300px",
      backgroundColor: "#e0e0e0",
    },
    skeletonText: {
      width: "80%",
      height: "20px",
      backgroundColor: "#e0e0e0",
      marginBottom: "10px",
    },
    button: {
      padding: '10px 20px',
      margin: '0 10px',
      borderRadius: '5px',
      border: 'none',
      backgroundColor: '#007bff',
      color: '#fff',
      cursor: 'pointer',
    },
  });

  const handlePostClick = (post) => {
    navigate(`/blogs/BlogDetailsScreen/${post.objectID}`, { state: { post , documentId: post.objectID}});
  };
  return (




    <div
      style={{ ...styles(textScale).post, height: `${620 * textScale}px`,  minWidth: screenType == "smallPhone" ? "90%" :screenType=="phone" ? "300px" : "400px", overflow:"scroll" }}

    onClick={() => handlePostClick(post)}
    >
   
      <div style={{...styles(textScale).postContent,   margin: screenType==="smallPhone"? "5vw" : "2vw auto",} }>
        <p style={{ ...styles(textScale).title, fontSize: `${1.4 * textScale}rem` }}>{t(post.blogtopic)}</p>
        <p
          style={{
            ...styles(textScale).paragraph,
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            // marginBottom:"0px",
            WebkitLineClamp: isExpanded ? "unset" : 9,
            fontSize: `${1.3 * textScale}rem`,
          }}
        >
          {t(`${post.blogDescription}.... `)}
        </p>
        <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>

          <button
            style={{
              color: "#FF6347",
              background: "none",
              border: "none",
              cursor: "pointer",
              opacity: isHovered ? 1 : 0.5, // Show the button only on hover
              transition: "opacity 0.3s ease-in-out",
            }}
          onClick={() => handlePostClick(post)}
          >
            {t('See More')}
          </button>
        </div>

        {/* {!isExpanded 
             && post.BlogContent && post.BlogContent.length > 200  && ( // Check if the content is longer
            )} */}

        <div style={styles(textScale).containerInfos}>
          <div style={styles(textScale).containerTags}>
            <span style={styles(textScale).span}>{t('Blog Category')}</span>
            <div style={styles(textScale).tagsList}>
              <ul>
                <li style={{ ...styles(textScale).tagItem, fontSize: `${1.2 * textScale}rem` }}>{post.blogcategory}</li>
              </ul>
            </div>
          </div>
        </div>

      </div>
    </div>
 
  );
};
export default PostCard;