import React, { useEffect, useRef, useState } from "react";

import useScreenSizes from "../../utils/useScreenSize";
import { useSelector } from "react-redux";
import { use } from "react";
import { useTranslation } from "react-i18next";

const cardData = [
  {
    title: "Why StudyBuddy is the best choice for Teachers ?",
    smallCards: [
      {
        src: require("../../assets/images/Streamlined scheduling.webp"),
        title: "Streamlined Scheduling",
        content:
          "Effortlessly organise and update class timetables with just a few clicks. The intuitive interface allows teachers to create and modify schedules quickly, reducing the time spent on administrative tasks. This means more time can be dedicated to teaching and engaging with students, leading to a smoother learning experience",
      },
      {
        src: require("../../assets/images/Easy Lesson plannig.webp"),
        title: "Easy Lesson Planning",
        content:
          "Create, adjust, and share lesson plans quickly, keeping everything in one place. The platform offers a user-friendly template for lesson planning that makes it simple to tailor content to different learning needs. Teachers can collaborate and share plans with colleagues, ensuring a cohesive approach to education while maintaining flexibility.",
      },
      {
        src: require("../../assets/images/Assignment Tracker.webp"),
        title: "Assignment Tracking:",
        content:
          "Monitor student progress on assignments in real-time, ensuring timely feedback. This feature allows teachers to easily see which assignments have been submitted and which students may need additional support. By providing immediate feedback, educators can enhance student learning and engagement, ultimately driving better academic outcomes.",
      },
      {
        src: require("../../assets/images/Centralized Resources.webp"),
        title: "Centralised Resources",
        content:
          "Store all teaching materials in one platform for easy access during lessons. With a centralised repository, teachers can quickly find and utilise resources such as videos, worksheets, and presentations. This saves time and effort during lesson preparation and allows for a more dynamic and interactive classroom experience.",
      },
      {
        src: require("../../assets/images/Autimated Reminder.webp"),
        title: "Automated Reminders",
        content:
          "Set automatic notifications for deadlines, tests, and class activities, keeping students on track. This feature reduces the chances of missed assignments or important dates by sending timely reminders to both students and parents. By keeping everyone informed, it fosters accountability and ensures that students remain engaged in their learning journey.",
      },
      {
        src: require("../../assets/images/Attendance Mangement.webp"),
        title: "Attendance Management",
        content:
          "Keep track of attendance records digitally, minimising paperwork. This streamlined approach simplifies the attendance process, allowing for quick updates and easy reporting. Digital attendance records enhance accuracy and save teachers valuable time that can be better spent on student interactions and instructional planning.",
      },
      {
        src: require("../../assets/images/Seamless communication.webp"),
        title: "Seamless Communication",
        content:
          "Instantly communicate with students and parents about updates or concerns within the platform. This feature fosters an open line of communication, allowing educators to share important announcements, answer questions, and address any issues promptly. Improved communication strengthens relationships and keeps everyone connected in the educational process.",
      },
      {
        src: require("../../assets/images/Data Driven insights(1).webp"),
        title: "Data-Driven Insights",
        content:
          "Analyse student performance data to improve lesson effectiveness and personalise learning strategies. By leveraging data analytics, teachers can identify trends and areas for improvement, allowing them to tailor instruction to meet individual student needs. This focus on data-driven decisions enhances the overall quality of education and supports student success.",
      },
      {
        src: require("../../assets/images/Instant Grade Calculation.webp"),
        title: "Instant Grade Calculation",
        content:
          "Automate grading processes, saving time while maintaining accuracy. With instant grade calculation, teachers can quickly assess student work without manual calculations. This efficiency not only reduces the burden of grading but also ensures that scores are accurate and reflective of student performance. By streamlining this process, educators can focus more on instruction and support rather than administrative tasks.",
      },
      {
        src: require("../../assets/images/Customizable Feedback Templets.webp"),
        title: "Customizable Feedback Templates",
        content:
          "Provide personalised comments using pre-set templates tailored to each student's needs. These customizable feedback templates allow teachers to offer specific and constructive comments quickly. By selecting from pre-designed templates, educators can address individual strengths and areas for improvement, making feedback more relevant and impactful. This personalised approach fosters a growth mindset and encourages student development",
      },
      {
        src: require("../../assets/images/digital rubrics for clarity.webp"),
        title: "Digital Rubrics for Clarity",
        content:
          "Use clear, digital rubrics to make grading more transparent and consistent for both teachers and students. Digital rubrics provide a structured framework for evaluating student work, ensuring clarity in expectations and assessment criteria. This transparency helps students understand how they will be graded and what areas they need to improve. For teachers, it standardises the grading process, making evaluations fairer and more objective",
      },
      {
        src: require("../../assets/images/Insdividual Progress Reports.webp"),
        title: "Individual Progress Reports",
        content:
          "Access detailed reports for each student that spotlight their unique strengths and pinpoint areas for improvement. These personalised insights allow educators to celebrate achievements and tailor their teaching strategies, ensuring each student receives the attention they need to thrive academically.",
      },

      {
        src: require("../../assets/images/Class wide Trends.webp"),
        title: "Class-Wide Trends",
        content:
          "Analyse overall class performance to uncover common challenges that may be hindering student success. By identifying trends, teachers can adjust lesson plans proactively, targeting specific areas where students struggle, and enhancing the overall learning experience for the entire class.",
      },
      {
        src: require("../../assets/images/Targeted supports suggestion.webp"),
        title: "Targeted Support Suggestions",
        content:
          "Receive data-backed recommendations designed to offer personalised support to students who are struggling. This targeted approach ensures that interventions are timely and effective, fostering a supportive learning environment that empowers every student to overcome obstacles and achieve their potential",
      },
      {
        src: require("../../assets/images/track learning milestones.webp"),
        title: "Track Learning Milestones",
        content:
          "Monitor students’ progress over time to ensure they meet key learning objectives efficiently. By tracking these milestones, educators can assess the effectiveness of their teaching methods, make informed adjustments, and keep students motivated as they advance toward their academic goals.",
      },

      {
        src: require("../../assets/images/Multi format support.webp"),
        title: "Multi-Format Support",
        content:
          "Easily share a variety of educational materials, including videos, presentations, PDFs, and more, to cater to diverse learning preferences. This flexibility ensures that all students can engage with content in a way that resonates with them, fostering a more inclusive learning environment",
      },
      {
        src: require("../../assets/images/interactaive Learning.webp"),
        title: "Interactive Learning",
        content:
          "Ensure no message is missed with automatic alerts for new communications, keeping everyone informed. These notification alerts help maintain a continuous flow of information, ensuring that students and parents are always updated on important developments, deadlines, or feedback, thereby enhancing collaboration and support.",
      },
      {
        src: require("../../assets/images/on demand access.webp"),
        title: "On-Demand Access",
        content:
          "Students can access uploaded materials anytime, ensuring continuous learning beyond the classroom. This convenience empowers learners to revisit lessons at their own pace, review challenging concepts, and reinforce their understanding whenever they need, promoting lifelong learning habits.",
      },
      {
        src: require("../../assets/images/pesonalised content.webp"),
        title: "Personalised Content",
        content:
          "Tailor resources to meet the specific needs of individual students or groups for more effective learning. By customising materials based on students’ strengths and challenges, educators can create a more targeted approach that enhances comprehension and supports academic growth.",
      },
      {
        src: require("../../assets/images/Group Announvements.webp"),
        title: "Group Announcements",
        content:
          "Share important class-wide messages or announcements without needing separate communication tools. This streamlined approach keeps everyone in the loop, ensuring that all students and parents receive the same information simultaneously, thereby reducing confusion and improving engagement with class activities.",
      },
      {
        src: require("../../assets/images/Notificatin Alert.webp"),
        title: "Notification Alerts",
        content:
          "Ensure no message is missed with automatic alerts for new communications, keeping everyone informed. These notification alerts help maintain a continuous flow of information, ensuring that students and parents are always updated on important developments, deadlines, or feedback, thereby enhancing collaboration and support.",
      },
      {
        src: require("../../assets/images/Instant Messageing.webp"),
        title: "Instant Messaging",
        content:
          "Quickly send updates or feedback to students and parents in real time, ensuring prompt communication. This feature fosters a strong connection between educators and families, allowing for immediate responses to questions, reminders about assignments, or important news, ultimately enhancing the learning experience.",
      },
      {
        src: require("../../assets/images/Group Announvements.webp"),
        title: "Group Announcements",
        content:
          "Share important class-wide messages or announcements without needing separate communication tools. This streamlined approach keeps everyone in the loop, ensuring that all students and parents receive the same information simultaneously, thereby reducing confusion and improving engagement with class activities.",
      },
      {
        src: require("../../assets/images/Private Conversations.webp"),
        title: "Private Conversations",
        content:
          "Maintain secure, private chats for discussing individual student progress with parents or students. This confidentiality encourages open dialogue about academic performance and personal challenges, allowing educators to provide tailored support and guidance while respecting students' privacy.",
      },
      {
        src: require("../../assets/images/tailored assignments.webp"),
        title: "Tailored Assignments",
        content:
          "Assign tasks and resources based on each student’s learning level and pace. This personalized approach ensures that every student receives assignments that are appropriately challenging, promoting confidence and motivation while enhancing their understanding of the material.",
      },
      {
        src: require("../../assets/images/adaptive progression.webp"),
        title: "Adaptive Progression",
        content:
          "Adjust learning paths dynamically as students improve or require more support. This flexibility allows educators to respond to each student's evolving needs, ensuring they remain on track to meet their learning objectives. By modifying challenges and resources, teachers can provide the right level of support for every learner.",
      },
      {
        src: require("../../assets/images/goal setting and tracking.webp"),
        title: "Goal Setting and Tracking",
        content:
          "Set personalized learning goals for students and track their progress toward achievement. By establishing clear objectives, educators can motivate students to take ownership of their learning journeys. Regularly monitoring progress helps identify successes and areas needing attention, facilitating timely interventions and support.",
      },
      {
        src: require("../../assets/images/differentiated content delivery.webp"),
        title: "Differentiated Content Delivery",
        content:
          "Offer varied content formats—videos, quizzes, or readings—based on individual learning preferences. This approach caters to diverse learning styles, allowing students to engage with the material in ways that resonate most with them. By providing multiple formats, educators can enhance comprehension and retention, making learning more effective and enjoyable.",
      },
      {
        src: require("../../assets/images/Easy Quiz Creation.webp"),
        title: "Easy Quiz Creation",
        content:
          "Design and customize quizzes in minutes with a variety of question formats. This user-friendly feature allows educators to create engaging assessments tailored to their lesson objectives. Whether using multiple-choice, true/false, or open-ended questions, teachers can quickly develop quizzes that effectively evaluate student understanding.",
      },
      {
        src: require("../../assets/images/Auto-Grading Featue.webp"),
        title: "Auto-Grading Feature",
        content:
          "Automatically grade multiple-choice and objective questions, saving time on manual checking. This efficient tool eliminates the tedious task of grading, allowing educators to focus on teaching rather than paperwork. Instant grading not only streamlines the process but also provides students with quick insights into their performance.",
      },
      {
        src: require("../../assets/images/Customizable Exam Templates.webp"),
        title: "Customizable Exam Templates",
        content:
          "Use pre-built templates for assignments and exams, making the setup process quick and simple. These templates save educators time and effort, enabling them to create assessments that adhere to their curriculum standards. With customizable options, teachers can easily modify content to fit their specific needs",
      },

      {
        src: require("../../assets/images/Resource Sharing Hub.webp"),
        title: "Resource Sharing Hub",
        content:
          "Easily exchange lesson plans, presentations, and teaching materials with other educators. This collaborative platform fosters a supportive community where teachers can share their best practices and innovative resources. By accessing a diverse array of materials, educators can enhance their lesson quality and save valuable preparation time.",
      },
      {
        src: require("../../assets/images/Collaborative Projects.webp"),
        title: "Collaborative Projects",
        content:
          "Work together on cross-class or cross-subject projects, enriching student learning experiences. These collaborative initiatives encourage creativity and critical thinking by allowing students to explore topics from multiple perspectives. Teaming up with other classes or subjects not only broadens knowledge but also fosters teamwork and communication skills among students.",
      },
      {
        src: require("../../assets/images/peer Feedback.webp"),
        title: "Peer Feedback",
        content:
          "Receive valuable insights and suggestions from colleagues to refine teaching strategies. Engaging in peer feedback promotes professional growth and encourages educators to reflect on their practices. Constructive critiques and shared experiences help teachers identify areas for improvement, ultimately enhancing their effectiveness in the classroom.",
      },
      {
        src: require("../../assets/images/professional learning networks.webp"),
        title: "Professional Learning Networks",
        content:
          "Join discussion forums and groups to stay updated on best practices and new teaching methods. These networks provide educators with opportunities to connect, collaborate, and learn from each other. By participating in these communities, teachers can exchange ideas, access resources, and stay informed about the latest educational trends, ensuring their teaching remains relevant and impactful.",
      },
    ],
    largeCardStyle: { backgroundColor: "#ffffff", title: "cardo;wjrfdkjgbfh" },
    buttonConfig: [
      { name: "Comprehensive class management", index: 0 },
      { name: "Efficient grading and feedback", index: 8 },
      { name: "Data driven insights ", index: 12 },
      { name: "Flexible content delivery ", index: 16 },
      { name: "Easy communication with students and parents ", index: 20 },
      { name: "Customizable learning paths ", index: 24 },
      { name: "Organised assessment tools", index: 28 },
      { name: "Enhanced collaboration ", index: 31 },
    ],
  },

  {
    title: "Why StudyBuddy is the best choice for Parent ?",
    smallCards: [
      {
        src: require("../../assets/images/instant grade updates.webp"),

        title: "Instant Grade Updates",
        content:
          "With instant grade updates, parents can access their child’s test scores and assignment results as soon as they are uploaded by the teacher. No more waiting until report cards are issued. This feature ensures that parents are always informed about their child's academic performance in real-time, allowing them to celebrate successes or address concerns immediately.",
      },
      {
        src: require("../../assets/images/daily performance insights.webp"),

        title: "Daily Performance Insights",
        content:
          "This feature offers parents a clear view of their child's daily academic activities. Parents can track how well their child is participating in class, staying on top of homework, and progressing in real-time. These insights provide a daily snapshot of a student’s engagement and learning, empowering parents to offer timely support or encouragement when needed.",
      },
      {
        src: require("../../assets/images/Attendance Tracking.webp"),

        title: "Attendance Tracking",
        content:
          "Stay informed about your child's attendance with this feature, which provides real-time records of classroom presence. Parents are instantly notified of any absences, tardiness, or missed activities, ensuring they can take quick action to keep their child on track and maintain strong communication with the school.",
      },
      {
        src: require("../../assets/images/Costom notifications.webp"),

        title: "Custom Notifications",
        content:
          "Parents can receive personalised alerts for important updates like test results, upcoming project deadlines, or significant academic milestones. These custom notifications ensure parents remain engaged and proactive in supporting their child's education by never missing key events or performance updates.",
      },
      {
        src: require("../../assets/images/Direct Messaging.webp"),

        title: "Direct Messaging",
        content:
          "Facilitate quick, secure conversations between parents and teachers, allowing for instant feedback and addressing concerns promptly. This feature ensures seamless communication, helping to resolve issues or provide updates without delay.",
      },
      {
        src: require("../../assets/images/automated updates.webp"),

        title: "Automated Updates",
        content:
          "Keep parents informed effortlessly with automated notifications about important events, grades, and announcements. This feature ensures parents never miss crucial information, keeping them continuously updated on their child’s academic life.",
      },
      {
        src: require("../../assets/images/Parents-teacher conferences.webp"),

        title: "Parent-Teacher Conferences",
        content:
          "Simplify the process of scheduling and managing meetings with teachers directly through the app. This feature promotes open dialogue about student progress, ensuring productive conversations for student development.",
      },
      {
        src: require("../../assets/images/Share resources.webp"),

        title: "Shared Resources",
        content:
          "Give parents access to teaching materials and resources used in the classroom. This feature empowers parents to better support their child’s learning at home, reinforcing concepts and contributing to academic success",
      },
      {
        src: require("../../assets/images/Timely Reminders.webp"),

        title: "Timely Reminders",
        content:
          "Parents receive alerts for critical deadlines and events, such as exams or project submissions, ensuring they never miss important dates in their child’s academic calendar. This feature keeps parents on track with their child’s progress and school activities.",
      },
      {
        src: require("../../assets/images/Cutmiezable Alerts.webp"),

        title: "Customizable Alerts",
        content:
          "Parents can choose which notifications they prefer to receive, personalizing their experience. Whether it's grades, attendance, or event reminders, this feature allows parents to stay informed in a way that suits their needs",
      },
      {
        src: require("../../assets/images/Event Calendars.webp"),

        title: "Event Calendars",
        content:
          "A shared calendar provides an overview of upcoming exams, school events, and parent-teacher meetings, making it easier for parents to plan and stay organized.",
      },
      {
        src: require("../../assets/images/Real Time Updates.webp"),

        title: "Real-Time Updates",
        content:
          "Any changes to schedules or events are communicated instantly, ensuring parents are always up-to-date with the latest information. This real-time feature prevents missed opportunities or last-minute surprises",
      },
      {
        src: require("../../assets/images/customReports.webp"),

        title: "Customizable Reporting Options",
        content:
          "Reports can be customized to focus on particular subjects or skills, providing parents with insights that are directly relevant to their child's specific needs, making targeted support more efficient",
      },
      {
        src: require("../../assets/images/Comprehensive Performance Overview.webp"),

        title: "Comprehensive Performance Overview",
        content:
          "Parents can access detailed reports that highlight their child’s academic strengths and areas for growth. This helps in making well-informed decisions to support their child’s educational progress effectively",
      },
      {
        src: require("../../assets/images/Progress Tracking.webp"),

        title: "Progress Tracking",
        content:
          "Parents can monitor their child’s academic progress over time, observing both improvements and challenges. This feature enables timely interventions and celebrations of achievements.",
      },
      {
        src: require("../../assets/images/Actionable Insights.webp"),

        title: "Actionable Insights",
        content:
          "Reports include practical suggestions for resources and strategies that can help students improve in identified areas. Parents receive clear, actionable steps to enhance their child’s learning experience",
      },
      {
        src: require("../../assets/images/User-Friendly Interface (1).webp"),

        title: "User-Friendly Interface",
        content:
          "The app’s intuitive design allows parents to easily navigate and access their child’s educational data without dealing with any technical complexities. This ensures a smooth, hassle-free experience for all users",
      },
      {
        src: require("../../assets/images/Encryption For Privacy (1).webp"),

        title: "Encryption for Privacy",
        content:
          "Advanced encryption safeguards all sensitive student data, ensuring that information remains secure and protected from unauthorized access, giving parents peace of mind about their child’s privacy",
      },
      {
        src: require("../../assets/images/Cantrolled Access Leval (1).webp"),

        title: "Controlled Access Levels",
        content:
          "Only authorized individuals can access specific student data, ensuring that privacy is maintained at all times. This controlled access protects the integrity of personal information",
      },
      {
        src: require("../../assets/images/Regular Security Updates (1).webp"),

        title: "Regular Security Updates",
        content:
          "The app regularly receives updates to strengthen security, safeguarding against potential threats. Parents can trust that the app remains up-to-date with the latest protective measures.",
      },
      {
        src: require("../../assets/images/Homework Tracking (1).webp"),

        title: "Homework Tracking",
        content:
          "Parents can easily monitor their child’s assignments and deadlines, helping to foster accountability and maintain consistent study habits. This feature ensures that parents stay engaged in their child's academic responsibilities",
      },
      {
        src: require("../../assets/images/Resource Access.webp"),

        title: "Resource Access: ",
        content:
          "Parents can access educational materials and tools, enabling them to support their child's learning at home. These resources empower parents to reinforce lessons and provide additional help when needed",
      },
      {
        src: require("../../assets/images/Progress Discussions.webp"),

        title: "Progress Discussions",
        content:
          "Parents can engage in productive conversations with teachers about their child’s performance, ensuring collaborative efforts to support academic success. This open dialogue helps address challenges early and build on strengths",
      },
      {
        src: require("../../assets/images/Encryption For Privacy (1).webp"),

        title: "Encouragement Tools",
        content:
          "Built-in features allow parents to set academic goals and celebrate their child’s achievements, motivating students to develop strong study habits and stay engaged in their education",
      },

      {
        src: require("../../assets/images/Centralised Storage.webp"),

        title: "Centralised Storage",
        content:
          "All educational records, grades, and reports are stored on a single secure platform, eliminating the hassle of managing physical files. Parents can access everything they need from one place with ease",
      },
      {
        src: require("../../assets/images/Easy Retrieval.webp"),

        title: "Easy Retrieval",
        content:
          "Important documents and reports can be quickly searched and retrieved, saving parents time and reducing the stress of locating paperwork. This feature streamlines the process of accessing essential information.",
      },
      {
        src: require("../../assets/images/Enviornmentally Friendly.webp"),

        title: "Environmentally Friendly",
        content:
          "By reducing the need for printed materials, this digital solution helps contribute to environmental sustainability, minimising paper use and promoting eco-friendly practices",
      },
      {
        src: require("../../assets/images/Automatic Backups.webp"),

        title: "Automatic Backups",
        content:
          "All records are automatically backed up, ensuring that data is always safe and accessible, even in case of technical issues. Parents can rest assured their child’s information is secure.",
      },
    ],
    largeCardStyle: { backgroundColor: "#ffffff" },
    buttonConfig: [
      { name: "Real-Time Progress Monitoring:", index: 0 },
      { name: "Enhanced Communication", index: 4 },
      { name: "Instant Notifications", index: 8 },
      { name: "Personalised Reports", index: 12 },
      { name: "Secure Access to Student Data", index: 16 },
      { name: "Active Involvement", index: 20 },
      { name: "Paperless and Organized", index: 22 },
    ],
  },

  {
    title: "Why StudyBuddy is the best choice for Student ?",
    smallCards: [
      {
        src: require("../../assets/images/Integrated Learning Tools.webp"),

        title: "Integrated Learning Tools",
        content:
          "Enjoy a suite of features, including flashcards, note-taking, and interactive quizzes, all designed to enhance your study experience. These tools provide a comprehensive approach to learning, making it easier to grasp complex concepts and retain information. By integrating various methods, you can tailor your study sessions to suit your preferences and maximize your understanding.",
      },
      {
        src: require("../../assets/images/Progress Tracking Dashboard.webp"),

        title: "Progress Tracking Dashboard",
        content:
          "Monitor your learning journey with real-time insights into your performance, helping you identify strengths and areas for improvement. This dashboard provides a clear overview of your progress, allowing you to celebrate achievements and address challenges effectively. By visualizing your learning trajectory, you can set achievable goals and stay motivated throughout your studies.",
      },
      {
        src: require("../../assets/images/Flexible Study Plans.webp"),

        title: "Flexible Study Plans",
        content:
          "Create customized study schedules that fit your lifestyle, ensuring you can balance learning with other commitments. This flexibility allows you to design a study routine that aligns with your personal and professional responsibilities. By having control over your study time, you can optimize your learning experience without feeling overwhelmed.",
      },
      {
        src: require("../../assets/images/Community Support.webp"),

        title: "Community Support",
        content:
          "Connect with peers for group studies, discussions, and sharing resources, making learning collaborative and engaging. This supportive community fosters a sense of belonging and encourages knowledge sharing among students. By collaborating with others, you can deepen your understanding of subjects, gain different perspectives, and enhance your overall learning experience.",
      },
      {
        src: require("../../assets/images/Adaptive Learning Paths.webp"),

        title: "Adaptive Learning Paths",
        content:
          "Benefit from customized lesson plans that adjust to your unique strengths and weaknesses, ensuring effective learning. This personalized approach tailors the curriculum to your individual needs, helping you focus on areas that require more attention while reinforcing your existing knowledge. By adapting to your learning style, it maximizes your potential for success.",
      },
      {
        src: require("../../assets/images/Pace Your Progress.webp"),

        title: "Pace Your Progress",
        content:
          "Take control of your study schedule, allowing you to spend more time on challenging topics and breeze through areas of confidence. This flexibility empowers you to learn at your own pace, ensuring that you fully understand difficult concepts before moving on. By managing your time effectively, you can enhance retention and mastery of the material.",
      },

      {
        src: require("../../assets/images/Dynamic Quiz Formats.webp"),

        title: "Dynamic Quiz Formats",
        content:
          "Engage with quizzes that adapt in difficulty based on your performance, keeping you challenged and motivated. This interactive feature ensures that you are consistently tested at the right level, promoting growth without overwhelming you. By providing immediate feedback and adjusting to your skill level, these quizzes enhance your learning experience and keep you engaged.",
      },
      {
        src: require("../../assets/images/goal setting and tracking.webp"),

        title: "Goal Setting and Tracking",
        content:
          "Set personal learning goals and receive feedback on your progress, empowering you to stay focused and achieve success. By defining clear objectives, you can measure your progress and adjust your study strategies as needed. Regular feedback helps you identify areas of strength and opportunities for improvement, keeping you motivated and accountable throughout your learning journey.",
      },
      {
        src: require("../../assets/images/Gamified Learning Experiences.webp"),

        title: "Gamified Learning Experiences",
        content:
          "Transform studying into an exciting adventure with game-like challenges and rewards that keep you motivated. By incorporating elements of gaming, such as points, levels, and badges, learning becomes more engaging and enjoyable. This approach encourages you to actively participate in your education, making even the most challenging topics feel like a fun challenge to conquer",
      },
      {
        src: require("../../assets/images/Real-Time Feedback.webp"),

        title: "Real-Time Feedback",
        content:
          "Receive instant feedback on quizzes and activities, allowing you to learn from mistakes and reinforce understanding on the spot. This immediate response helps identify areas for improvement right away, enabling you to make necessary adjustments in your learning approach. Real-time feedback not only enhances comprehension but also boosts confidence as you see your progress",
      },
      {
        src: require("../../assets/images/Collaborative Activities.webp"),

        title: "Collaborative Activities",
        content:
          "Participate in group challenges and interactive discussions, fostering teamwork and making learning a social experience. Collaborative activities encourage communication and cooperation among peers, enriching the learning process. By working together, you can share diverse perspectives and solutions, making the learning experience more comprehensive and enjoyable.",
      },
      {
        src: require("../../assets/images/Multimedia Engagement.webp"),

        title: "Multimedia Engagement",
        content:
          "Enjoy a variety of learning formats, including animated videos and interactive simulations, to cater to different preferences and enhance retention. This multimedia approach addresses various learning styles, ensuring that content is accessible and engaging for everyone. By using diverse formats, you can better understand complex concepts and retain information more effectively.",
      },
      {
        src: require("../../assets/images/Instant Messaging Features.webp"),

        title: "Instant Messaging Features",
        content:
          "Easily reach out to teachers for quick clarifications or guidance, ensuring you never feel stuck on a problem. This immediate access allows you to get timely support on challenging topics, helping you overcome obstacles without delay. By eliminating communication barriers, instant messaging fosters a supportive learning environment where questions can be addressed promptly.",
      },
      {
        src: require("../../assets/images/Peer Colaboration.webp"),

        title: "Peer Collaboration",
        content:
          "Form study groups with classmates to share insights, resources, and tackle challenging subjects together. Collaborative learning encourages teamwork and helps you gain different perspectives on complex topics. By working with peers, you can deepen your understanding, enhance retention, and make studying a more enjoyable experience.",
      },
      {
        src: require("../../assets/images/Discussion Fourms.webp"),

        title: "Discussion Forums",
        content:
          "Engage in topic-based discussions with peers and educators, fostering a collaborative learning environment where ideas thrive. These forums provide a space for exchanging thoughts, asking questions, and sharing resources. By participating in discussions, you can explore diverse viewpoints and clarify concepts, enriching your learning experience.",
      },
      {
        src: require("../../assets/images/Regular Q&A Sessions.webp"),

        title: "Regular Q&A Sessions",
        content:
          "Participate in scheduled Q&A sessions with teachers, allowing for in-depth exploration of topics and personalized support. These dedicated sessions offer an opportunity to dive deeper into subjects, clarify doubts, and engage in meaningful conversations with educators. Regular interaction helps reinforce learning and builds a strong connection between students and teachers.",
      },
      {
        src: require("../../assets/images/Comprehensive Performance Analytics.webp"),

        title: "Comprehensive Performance Analytics",
        content:
          "Access detailed reports that highlight your strengths, weaknesses, and overall progress, providing clear insights into your learning journey. These analytics offer a thorough understanding of how you are performing in various subjects, enabling you to identify areas where you excel and those that need more attention. With this information, you can make informed decisions about your study strategies and focus on what matters most for your growth",
      },
      {
        src: require("../../assets/images/Goal Achivement Tracking.webp"),

        title: "Goal Achievement Tracking",
        content:
          "Set specific learning goals and receive updates on your progress, keeping you motivated and accountable throughout your studies. This feature allows you to define clear objectives and track your advancement towards achieving them. Regular updates serve as reminders of your goals, helping you stay committed and motivated to reach new milestones in your educational journey",
      },
      {
        src: require("../../assets/images/Visual Pogress Charts.webp"),

        title: "Visual Progress Charts",
        content:
          "Utilise easy-to-read graphs and charts to visualize your improvement over time, making it satisfying to see your hard work pay off. These visual representations make it easier to understand your progress at a glance. By seeing your growth displayed graphically, you can celebrate achievements and identify patterns in your learning, encouraging you to continue striving for excellence",
      },
      {
        src: require("../../assets/images/Offline Mode.webp"),

        title: "Offline Mode",
        content:
          "Download lessons and resources to study without an internet connection, making it easy to learn during commutes or in remote areas. This feature is perfect for busy lifestyles, allowing you to take your studies anywhere. Whether you’re on a train, waiting in line, or in a place with limited connectivity, you can continue your education seamlessly without interruption",
      },
      {
        src: require("../../assets/images/Mobile Accessibility.webp"),

        title: "Mobile Accessibility",
        content:
          "Access all your study materials and lessons from any device, ensuring you can learn on your phone, tablet, or laptop—whenever you want. This flexibility allows you to study at your convenience, whether you’re at home, in class, or on the go. With everything available at your fingertips, you can maximize your learning opportunities and never miss a chance to reinforce your knowledge.",
      },
      {
        src: require("../../assets/images/Flexible Scheduling.webp"),

        title: "Flexible Scheduling",
        content:
          "Fit learning into your busy lifestyle by choosing when and where you study, whether it’s during lunch breaks, while traveling, or at home. This adaptability empowers you to create a study routine that suits your personal schedule. By integrating learning into your daily life, you can make the most of every moment and enhance your productivity without feeling overwhelmed",
      },

      {
        src: require("../../assets/images/Instant Resouce Availability.webp"),

        title: "Instant Resource Availability",
        content:
          "Find and access resources quickly without the need for physical textbooks, allowing for spontaneous study sessions whenever inspiration strikes. This instant availability eliminates the hassle of searching for materials, enabling you to dive into learning whenever the opportunity arises. With just a few taps, you can engage with a wealth of information and resources, making study time efficient and effectively",
      },
      {
        src: require("../../assets/images/Regular Assements (1).webp"),

        title: "Regular Assessments",
        content:
          "Take frequent quizzes and tests to monitor your understanding, ensuring you stay aware of your academic progress and knowledge gaps. Regular assessments help reinforce what you’ve learned and provide insights into how well you grasp the material. By keeping tabs on your performance, you can adjust your study strategies and ensure you’re fully prepared for upcoming challenges",
      },

      {
        src: require("../../assets/images/Cellibrate Milestone.webp"),

        title: "Celebrate Milestones",
        content:
          "Acknowledge your progress with certificates or badges for achieving goals, reinforcing your accomplishments and motivating you to continue learning. Celebrating milestones not only boosts your confidence but also creates a sense of achievement that fuels your motivation. These recognitions serve as tangible reminders of your hard work and dedication, encouraging you to set and pursue new goals",
      },
      {
        src: require("../../assets/images/Personalized Feedback Loop.webp"),

        title: "Personalised Study Recommendations",
        content:
          "Get tailored suggestions for resources and activities based on your test results, helping you effectively focus on what you need to improve. Personalised recommendations ensure that your study efforts are targeted and efficient. By addressing specific areas for improvement, you can optimize your learning experience and make meaningful progress toward your educational objectives",
      },
    ],
    largeCardStyle: { backgroundColor: "#ffffff", title: "cardo;wjrfdkjgbfh" },
    buttonConfig: [
      { name: "All-in-One Learning Solution", index: 0 },
      { name: "Personalised Learning Experience", index: 4 },
      { name: "Interactive Learning", index: 8 },
      { name: "Connect with Teachers and Peers", index: 12 },
      { name: "Track Your Progress", index: 16 },
      { name: "Learn Anytime, Anywhere", index: 19 },
      //  { name: "Boost Your Confidence", index: 23 },
    ],
  },
  {
    title: "Why StudyBuddy is the best choice for Institute ?",
    smallCards: [
      {
        src: require("../../assets/images/Tailored Learning experiences.webp"),

        title: "Tailored Learning Experiences",
        content:
          "Our platform offers flexible design features that allow administrators to adjust the LMS layout, structure, and content delivery to match the institution’s specific requirements",
      },
      {
        src: require("../../assets/images/Flexible Design Options.webp"),

        title: "Flexible Design Options:",
        content:
          "Monitor your learning journey with real-time insights into your performance, helping you identify strengths and areas for improvement. This dashboard provides a clear overview of your progress, allowing you to celebrate achievements and address challenges effectively. By visualizing your learning trajectory, you can set achievable goals and stay motivated throughout your studies.",
      },
      {
        src: require("../../assets/images/Effortless user Expansion.webp"),

        title: "Effortless User Expansion",
        content:
          " As your institution grows, Study Buddy easily scales to accommodate more users without compromising performance, allowing for seamless expansion of student and staff accounts",
      },
      {
        src: require("../../assets/images/Future-Proof Scalability.webp"),

        title: "Future-Proof Scalability",
        content:
          "Designed to grow with your institution, Study Buddy ensures that your LMS stays up-to-date and adapts to future advancements, making it a sustainable, long-term solution",
      },
      {
        src: require("../../assets/images/RealTIme Performance Tracking ;Early intervantion Alerts.webp"),

        title: "Real-Time Performance Tracking:",
        content:
          "Our platform provides real-time tracking of student and staff performance, enabling educators to monitor progress and engagement instantly.",
      },
      {
        src: require("../../assets/images/Customizable reports.webp"),

        title: "Customizable Reports:",
        content:
          "Administrators can create tailored reports that focus on specific metrics, helping to analyze data effectively and gain deeper insights into academic performance and trends",
      },

      {
        src: require("../../assets/images/Inform decision-making.webp"),

        title: "Informed Decision-Making",
        content:
          "With access to comprehensive data analytics, educators and administrators can make well-informed decisions to enhance learning outcomes and optimize resources",
      },
      {
        src: require("../../assets/images/engaging Group Projects.webp"),

        title: "Engaging Group Projects",
        content:
          "Study Buddy encourages collaborative learning through engaging group projects that promote teamwork and critical thinking among students",
      },
      {
        src: require("../../assets/images/Seamless Resource Shareing.webp"),

        title: "Seamless Resource Sharing",
        content:
          "Our platform facilitates easy sharing of resources, allowing educators and students to access and distribute materials effortlessly, enhancing collaboration",
      },
      {
        src: require("../../assets/images/Active Participation In Activities.webp"),

        title: "Active Participation in Activities",
        content:
          "With interactive features, Study Buddy fosters active participation in class activities, motivating students to contribute and engage with their peers",
      },
      {
        src: require("../../assets/images/Real-timre communication Tools.webp"),

        title: "Real-Time Communication Tools",
        content:
          "The platform includes real-time communication tools, such as chat and discussion forums, enabling instant interaction between students and teachers for a more connected learning experience",
      },
      {
        src: require("../../assets/images/Instant Notification.webp"),

        title: "Instant Notifications",
        content:
          "Study Buddy provides instant notifications to keep students, parents, and educators informed about important updates, assignments, and announcements",
      },
      {
        src: require("../../assets/images/Parents-Teacher Interaction.webp"),

        title: "Parent-Teacher Interaction",
        content:
          "Our platform facilitates easy communication between parents and teachers, fostering collaboration and ensuring that parents stay engaged in their child’s education",
      },
      {
        src: require("../../assets/images/Student Collabration.webp"),

        title: "Student Collaboration",
        content:
          "Students can easily communicate and collaborate with peers on projects and assignments, enhancing teamwork and collective learning",
      },
      {
        src: require("../../assets/images/Centralised Messaging.webp"),

        title: "Centralized Messaging",
        content:
          "With a centralized messaging system, users can access all communications in one place, making it easy to track conversations and stay organized",
      },
      {
        src: require("../../assets/images/Progress Tracking Alerts.webp"),

        title: "Progress Tracking Alerts:",
        content:
          "The platform sends alerts to keep students and parents informed about academic progress, helping them stay on track and address any issues promptly",
      },
      {
        src: require("../../assets/images/Event Reminders.webp"),

        title: "Event Reminders",
        content:
          "Study Buddy offers reminders for important events such as exams, meetings, and deadlines, ensuring that users are well-prepared and informed",
      },
      {
        src: require("../../assets/images/Cussomizable Notification.webp"),

        title: "Customizable Notifications",
        content:
          "Users can customize their notification preferences to receive alerts that are most relevant to them, enhancing the overall communication experience",
      },
      {
        src: require("../../assets/images/Accurate Expense Tracking.webp"),

        title: "Automated Attendance Tracking",
        content:
          "Our platform simplifies attendance management by automating the tracking process, allowing educators to quickly record and monitor student attendance without manual effort",
      },

      {
        src: require("../../assets/images/Automated fee reminders.webp"),

        title: "Automated Fee Reminders",
        content:
          "Study Buddy automatically sends reminders to parents and students about upcoming or overdue fees, helping them stay on top of payments",
      },
      {
        src: require("../../assets/images/source online payment.webp"),

        title: "Secure Online Payments",
        content:
          "The platform offers secure online payment options, making it easy and safe for parents to pay fees without the need for physical transactions",
      },
      {
        src: require("../../assets/images/details finance reports.webp"),

        title: "Detailed Financial Reports",
        content:
          "Administrators can generate detailed financial reports to track payments, outstanding balances, and overall financial health with ease",
      },
      {
        src: require("../../assets/images/customizable fee structures.webp"),

        title: "Customizable Fee Structures",
        content:
          "Study Buddy allows institutions to set up flexible, customizable fee structures, catering to different student categories or payment plans",
      },
      {
        src: require("../../assets/images/Real time invertory tracking.webp"),

        title: "Real-Time Inventory Tracking:",
        content:
          "Study Buddy keeps track of all library materials in real-time, helping librarians know which books or resources are available or checked out",
      },
      {
        src: require("../../assets/images/automated Cataloging.webp"),

        title: "Automated Cataloging",
        content:
          "The platform automates the process of organizing and cataloging books, making it easy to manage and update the library’s collection",
      },
      {
        src: require("../../assets/images/Easy checkouts returns.webp"),

        title: "Easy Checkouts & Returns",
        content:
          "Students and staff can quickly check out and return books through the system, streamlining the process for everyone",
      },
      {
        src: require("../../assets/images/Advance Search Features.webp"),

        title: "Advanced Search Features",
        content:
          "The platform offers powerful search tools that help users easily find specific books or resources based on title, author, or keywords",
      },
      {
        src: require("../../assets/images/seamless Budget Management.webp"),

        title: "Seamless Budget Management",
        content:
          "Study Buddy helps administrators easily manage and allocate budgets, ensuring financial resources are efficiently distributed across departments",
      },
      {
        src: require("../../assets/images/Accurate Expense Tracking.webp"),

        title: "Accurate Expense Tracking",
        content:
          "The platform provides tools for tracking all expenses in real-time, giving clear visibility into where and how funds are being spent",
      },
      {
        src: require("../../assets/images/custom Financial Reports.webp"),

        title: "Custom Financial Reports",
        content:
          "Administrators can generate custom financial reports to analyze specific financial data, making it easier to review and manage finances",
      },
      {
        src: require("../../assets/images/Data Driven Forcecasting.webp"),

        title: "Data-Driven Forecasting",
        content:
          "Study Buddy uses financial data to offer insights and forecasts, helping institutions plan for future expenses and financial needs effectively",
      },
      {
        src: require("../../assets/images/effortless room assignmesnt.webp"),

        title: "Effortless Room Assignments",
        content:
          "Study Buddy streamlines the process of assigning rooms to students, making it quick and easy for administrators to manage accommodations",
      },
      {
        src: require("../../assets/images/real time occupancy monitoring.webp"),

        title: "Real-Time Occupancy Monitoring",
        content:
          "The platform allows for real-time tracking of room occupancy, helping staff know which rooms are available and which are occupied",
      },

      {
        src: require("../../assets/images/instant communication channals.webp"),

        title: "Instant Communication Channels",
        content:
          "Study Buddy includes communication tools that allow hostel staff and students to connect instantly, ensuring quick responses to any questions or issues",
      },

      {
        src: require("../../assets/images/instant communication channals.webp"),

        title: "Enhanced Communication Tools",
        content:
          "The platform provides effective communication tools, enabling parents and teachers to engage in meaningful discussions about student progress and concerns",
      },
      {
        src: require("../../assets/images/Comprehensive Student Insights.webp"),

        title: "Comprehensive Student Insights",
        content:
          "Educators can share detailed insights about student performance and behavior during the meeting, helping parents understand their child's progress",
      },
      {
        src: require("../../assets/images/customizable Templetes.webp"),

        title: "Customizable Templates",
        content:
          "Study Buddy offers customizable report card templates, allowing schools to design report cards that fit their specific grading criteria and branding",
      },
      {
        src: require("../../assets/images/Real-time Performance Updatss.webp"),

        title: "Real-Time Performance Updates",
        content:
          "Educators can include real-time performance updates on report cards, providing parents and students with the most current information on academic progress",
      },

      {
        src: require("../../assets/images/Role& Responsibility Assignment.webp"),

        title: "Role & Responsibility Assignment",
        content:
          "The platform allows administrators to clearly define and assign roles and responsibilities to staff members, ensuring everyone knows their tasks",
      },
      {
        src: require("../../assets/images/Streamlined Payroll Management.webp"),

        title: "Streamlined Payroll Management",
        content:
          "Study Buddy simplifies payroll processing, automating calculations and payments to ensure timely and accurate compensation for staff",
      },
      {
        src: require("../../assets/images/Performance Evalution Tools.webp"),

        title: "Performance Evaluation Tools",
        content:
          "The system provides tools for conducting performance evaluations, helping administrators assess staff effectiveness and identify areas for professional development",
      },
      {
        src: require("../../assets/images/Streamlined Application Handling.webp"),

        title: "Streamlined Application Handling",
        content:
          "Study Buddy simplifies the process of managing student applications, making it easier for administrators to track and review submissions efficiently",
      },
      {
        src: require("../../assets/images/Customizable Applicationo Forms.webp"),

        title: "Customizable Application Forms",
        content:
          "The platform allows institutions to create and modify application forms to meet specific requirements, ensuring they gather all necessary information from prospective students",
      },

      {
        src: require("../../assets/images/Real time Status Updates.webp"),

        title: "Real-Time Status Updates",
        content:
          "Applicants can receive real-time updates on the status of their applications, keeping them informed throughout the admission process",
      },

      {
        src: require("../../assets/images/customizable Templetes.webp"),

        title: "Customizable Templates",
        content:
          "Study Buddy offers customizable report card templates, allowing schools to design report cards that fit their specific grading criteria and branding",
      },
      {
        src: require("../../assets/images/Automated Data Collection.webp"),

        title: "Automated Data Collection",
        content:
          "The platform automatically collects and compiles student performance data, saving educators time and ensuring accuracy in report generation",
      },
      {
        src: require("../../assets/images/Real-time Performance Updatss.webp"),

        title: "Real-Time Performance Updates",
        content:
          "Educators can include real-time performance updates on report cards, providing parents and students with the most current information on academic progress",
      },

      {
        src: require("../../assets/images/customizable Templetes.webp"),

        title: "Effortless Distribution",
        content:
          "The system simplifies the distribution of report cards, allowing teachers to easily share them with students and parents via email or through the LMS",
      },
      {
        src: require("../../assets/images/Streamlined Curriculum Design.webp"),

        title: "Streamlined Curriculum Design",
        content:
          "Study Buddy helps educators create and organize curriculum plans efficiently, ensuring a clear structure for teaching and learning",
      },
      {
        src: require("../../assets/images/Alignment With EducationalStandards _.webp"),

        title: "Alignment with Educational Standards",
        content:
          "The platform ensures that all curriculum designs meet relevant educational standards, helping schools provide quality education and comply with regulations",
      },

      {
        src: require("../../assets/images/Dynamic Assessment Methods.webp"),

        title: "Dynamic Assessment Methods",
        content:
          "Study Buddy supports various assessment methods that can be adjusted based on student progress, allowing for more effective evaluation of learning outcomes",
      },
    ],
    largeCardStyle: { backgroundColor: "#ffffff", title: "cardo;wjrfdkjgbfh" },
    buttonConfig: [
      { name: "Customizable and Scalable Platform", index: 0 },
      { name: "Data-Driven Insights", index: 4 },
      { name: "Collaborative Learning Environment", index: 7 },
      { name: "Seamless Communication", index: 11 },
      { name: "Comprehensive Administrative Tools", index: 18 },

      { name: "Streamlining Fees Management", index: 19 },
      { name: " Efficient Library Management", index: 23 },
      { name: "Smart Financial Operations", index: 27 },
      { name: "Hostel Management", index: 31 },
      { name: "Inventory Management", index: 35 },

      { name: "Staff management ", index: 38 },

      { name: "Admission Management", index: 41 },

      { name: "Report Card Generator", index: 44 },
      { name: "Curricular Planner", index: 48 },
    ],
  },
];

const StackingCards = ({ customStyles = {} }) => {
  const { t } = useTranslation();
  const stackCardsRef = useRef(null);
  const smallCardRefs = useRef([]);
  const navButtonRefs = useRef([]);
  const buttonScrollRef = useRef({});
  const stackCardRef = useRef({});

  const StackCardIndex = useRef(0);
  const [textScale, setTextScale] = useState(1); // State to control text scale
  const [activeButtonIndex, setActiveButtonIndex] = useState({
    0: 0,
    1: 0,
    2: 0,
    3: 0,
  });
  const [hasScrolled, setHasScrolled] = useState(
    Array(cardData.length).fill(false)
  );
  const scrollToCard = (largeCardIndex, cardIndex) => {
    const card = smallCardRefs.current[largeCardIndex][cardIndex];
    if (card) {
      const parent = card.parentNode;
      parent.scrollTo({ left: card.offsetLeft, behavior: "smooth" });

      setActiveButtonIndex((prev) => {
        const prevData = { ...prev };
        prevData[StackCardIndex.current] = cardIndex;
        return prevData;
      });
      if (navButtonRefs.current[cardIndex]) {
        navButtonRefs.current[cardIndex].scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  useEffect(() => {
    const items = document.querySelectorAll(".small-card");
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const cardIndex = parseInt(entry.target.getAttribute("data-index"));
            const buttonData = cardData[StackCardIndex.current].buttonConfig;
            let buttonKeysArray = [];
            buttonData.map((item, index) => {
              buttonKeysArray.push(Object.values(item)[1]);
            });

            buttonKeysArray.some((item, index) => {
              if (cardIndex >= buttonKeysArray[buttonKeysArray.length - 1]) {
                setActiveButtonIndex((prev) => {
                  const prevData = { ...prev };
                  prevData[StackCardIndex.current] =
                    buttonKeysArray[buttonKeysArray.length - 1];
                  return prevData;
                });
                return true;
              } else if (cardIndex < item) {
                if (index > 0) {
                  setActiveButtonIndex((prev) => {
                    const prevData = { ...prev };
                    prevData[StackCardIndex.current] =
                      buttonKeysArray[index - 1];
                    return prevData;
                  });
                } else {
                }
                return true; // Stop iteration
              }
              return false; // Continue iteration
            });
          }
        });
      },
      { threshold: 1 }
    );

    items.forEach((item, index) => {
      observer.observe(item);
    });

    return () => observer.disconnect();
  }, []);

  const [firstTime, setFirstTime] = useState(true);

  useEffect(() => {
    const scrollToActiveButton = () => {
      if (
        buttonScrollRef.current &&
        buttonScrollRef.current[StackCardIndex.current] &&
        buttonScrollRef.current[StackCardIndex.current][
          activeButtonIndex[StackCardIndex.current]
        ]
      ) {
        stackCardRef.current[StackCardIndex.current].parentNode.scrollTo({
          top: stackCardRef.current[StackCardIndex.current].offsetLeft,
          behavior: "smooth",
        });
        buttonScrollRef.current[StackCardIndex.current][
          activeButtonIndex[StackCardIndex.current]
        ].parentNode.scrollTo({
          left:
            buttonScrollRef.current[StackCardIndex.current][
              activeButtonIndex[StackCardIndex.current]
            ].offsetLeft - 40,
          behavior: "smooth",
        });
      }
    };
    if (!firstTime) {
      scrollToActiveButton();
    } else {
      setFirstTime(false);
    }
  }, [activeButtonIndex]);
  const screenType = useSelector((state) => state.website.screenType);

  useEffect(() => {
    const stackCards = stackCardsRef.current;
    if (!stackCards) return;

    const items = stackCards.getElementsByClassName("js-stack-cards__item");
    let scrollingFn = null;
    let scrolling = false;
    const setStackCards = () => {
      const marginY = parseInt(
        getComputedStyle(stackCards).getPropertyValue("--stack-cards-gap"),
        10
      );
      stackCards.style.paddingBottom = `${marginY * (items.length - 1)}px`;

      for (let i = 0; i < items.length; i++) {
        // items[i].style.transform = `translateY(${marginY * i}px)`;
      }
    };

    const stackCardsCallback = (entries) => {
      if (entries[0].isIntersecting) {
        if (scrollingFn) return;
        stackCardsInitEvent();
      } else {
        if (!scrollingFn) return;
        window.removeEventListener("scroll", scrollingFn);
        scrollingFn = null;
      }
    };

    const stackCardsInitEvent = () => {
      scrollingFn = stackCardsScrolling;
      window.addEventListener("scroll", scrollingFn);
    };

    const stackCardsScrolling = () => {
      if (scrolling) return;
      scrolling = true;
      window.requestAnimationFrame(animateStackCards);
    };

    const animateStackCards = () => {
      const marginY = parseInt(
        getComputedStyle(stackCards).getPropertyValue("--stack-cards-gap"),
        10
      );
      const top = stackCards.getBoundingClientRect().top;
      const cardStyle = getComputedStyle(items[0]);
      const cardTop = Math.floor(parseFloat(cardStyle.getPropertyValue("top")));
      const cardHeight = Math.floor(
        parseFloat(cardStyle.getPropertyValue("height"))
      );
      const windowHeight = window.innerHeight;

      if (
        cardTop - top + windowHeight - cardHeight + marginY * items.length >
        0
      ) {
        scrolling = false;
        return;
      }

      for (let i = 0; i < items.length; i++) {
        const scrollingValue = cardTop - top - i * (cardHeight + marginY);
        if (scrollingValue > 0) {
          const scaling =
            i === items.length - 1
              ? 1
              : (cardHeight - scrollingValue * 0.05) / cardHeight;
          items[i].style.transform = `translateY(${
            marginY * i
          }px) scale(${scaling})`;
        } else {
          items[i].style.transform = `translateY(${marginY * i}px)`;
        }
      }

      scrolling = false;
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const cardIndex = Array.from(items).indexOf(entry.target);
        if (entry.isIntersecting) {
          if (navButtonRefs.current[cardIndex]) {
            navButtonRefs.current[cardIndex].scrollIntoView({
              behavior: "smooth",
              inline: "center",
            });
          }
        }
      });
    });

    for (let i = 0; i < items.length; i++) {
      observer.observe(items[i]);
    }

    window.addEventListener("resize", setStackCards);
    return () => {
      window.removeEventListener("resize", setStackCards);
      observer.disconnect();
      window.removeEventListener("scroll", scrollingFn);
    };
  }, []);

  const screen = useScreenSizes();

const [ scroll , setScroll] = useState(false)
  const defaultStyles = {
    container: {
      '--stack-cards-gap': '40px',
      '--stack-cards-item-ratio': '2/1',
      fontFamily: 'raleway',
    },
    stackCards: {
      paddingBottom: 'calc(var(--stack-cards-gap) * 1)',
    },
    cardItem: {
 
      position: 'sticky',
      top: '30px',
      paddingBottom:
        screenType === 'smallPhone'
          ? 'calc(35vh / var(--stack-cards-item-ratio))'
          : screenType === 'phone'
          ? 'calc(40vh / var(--stack-cards-item-ratio))'
          : screenType === 'tablet'
          ? 'calc(20vh / var(--stack-cards-item-ratio))'
          : screenType === 'tab'
          ? 'calc(20vh/ var(--stack-cards-item-ratio))'
          : screen >= 1200
          ? 'calc(40vh / var(--stack-cards-item-ratio))'
          : '',
      transformOrigin: 'center top',
      backgroundColor: '#ffffff',
      borderRadius: '8px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      marginBottom:
        screenType === 'smallPhone'
          ? '130px'
          : screenType === 'tab'
          ? '50px'
          : '110px',
      width: '95%',
      borderRadius: '10px',
      boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.3)',
      margin: '0 auto',
      padding: '15px',
    },
    cardContent: {
      position: 'relative',
      top: 0,
      left: 0,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      backgroundColor: '#ffffff',
      padding: '10px',
      borderRadius: '10px',
      justifyContent: 'space-between',
      transition: 'height 0.3s ease',
    },
    smallCardContainer: {
      display: 'flex',
      overflowX: 'scroll',
      overflowY: 'auto',
      height: '100%',
      padding: '10px 11px',
    },
    smallCard: {
      height:
        screenType === 'smallPhone' || screenType === 'phone'
          ? '40vh'
          : screenType === 'tablet'
          ? '30vw'
          : screenType === 'tab'
          ? '35vw'
          : '54vh',
      width: '20%',
      borderRadius: '8px',
      marginRight: '10px',
      flex:
        screenType === 'smallPhone' || screenType === 'phone'
          ? '0 0 70%'
          : screenType === 'tablet'
          ? '0 0 30%'
          : screenType === 'tab'
          ? '0 0 32.5%'
          : '0 0 21%',
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'hidden',
      justifyContent: 'flex-start',
      overflowY: 'auto',
      position: 'relative',
      transition: 'height 0.3s ease',
      boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.3)',
      marginRight: '2vw',
    },
    smallCardImage: {
      height: '70%',
      borderRadius: '8px 8px 0 0',
      objectFit: 'cover',
      transition: 'height 0.3s ease',
    },
    smallCardContent: {
      height: 'auto',
      display: 'flex',
      flexDirection: 'column',
      textOverflow: 'ellipsis',
      fontSize: `${textScale * (screenType === 'smallPhone' ? 0.8 : screenType === 'tablet' ? 1 : 1.1)}rem`,
      overflowX: 'hidden',
      overflowY: 'scroll',
      whiteSpace: 'normal',
      backgroundColor: '#ffffff',
      rowGap:
        screenType === 'smallPhone'
          ? '13px'
          : screenType === 'tab'
          ? '9px'
          : '13px',
      borderRadius: '0 0 8px 8px',
      transition: 'height 0.3s ease',
      position: 'absolute',
      bottom: '0',
      overflowY:scroll ? "scroll" :"hidden",
      width: '100%',
      padding: '5px',
      // marginTop: '20px',
    },
    smallCardHover: {
      height: '80%',
    },
    title: {
      padding: '10px',
      fontSize: `${textScale * 1.5}rem`,
      color: '#333',
      flexShrink: 0,
    },
    smallCardTitle: {
      fontSize: `${textScale * (screenType === 'smallPhone' || screenType === 'phone' ? 1.2 : screenType === 'tablet' ? 1.8 : 1.7)}rem`,
      color: 'black',
      flexShrink: 0,
    },
    navbar: {
      display: 'flex',
      width: '100vw',
      overflowX: 'scroll',
      justifyContent: 'flexstart',
    },
    navItem: {
      marginRight: '10px',
      cursor: 'pointer',
      color: 'grey',
      borderRadius: '4px',
      fontSize: `${textScale * (screenType === 'smallPhone' || screenType === 'phone' ? 0.9 : screenType === 'tablet' ? 1 : 1)}rem`,
    },
    activeNavItem: {
      textDecoration: 'underline',
      fontWeight: 'bold',
      color: 'black',
    },
    cardtitle: {
      margin: '0px',
      fontSize: `${textScale * (screenType === 'tablet' ? 1.7 : screenType === 'tab' ? 1.6 : screenType === 'smallPhone' || screenType === 'phone' ? 1.7 : 2)}rem`,
    },
  };

  const increaseTextScale = () => {
    setTextScale((prevScale) => prevScale + 0.1);
  };

  const decreaseTextScale = () => {
    setTextScale((prevScale) => (prevScale > 1 ? prevScale - 0.1 : 1));
  };

  useEffect(() => {}, [activeButtonIndex]);

  return (
    <div>
      <div
        style={{ ...defaultStyles.container, ...customStyles.container }}
        ref={stackCardsRef}
      >
        <div style={defaultStyles.stackCards}>
          {cardData.map((card, largeCardIndex) => (
            <div
              ref={(e) => {
                stackCardRef.current[largeCardIndex] = e;
              }}
              style={{ ...defaultStyles.cardItem, ...card.largeCardStyle }}
              key={largeCardIndex}
              onMouseEnter={() => {
                StackCardIndex.current = largeCardIndex;
              }}
              className="js-stack-cards__item"
            >
              <div style={defaultStyles.cardContent}>
                <p style={defaultStyles.cardtitle}>{t(card.title)}</p>
                <div style={defaultStyles.navbar}>
                  {card.buttonConfig.map((button, buttonIndex) => (
                    <div
                      ref={(e) => {
                        if (!buttonScrollRef.current[largeCardIndex]) {
                          buttonScrollRef.current[largeCardIndex] = {}; // Initialize nested object
                        }
                        buttonScrollRef.current[largeCardIndex][button.index] = e; // Assign ref
                      }}
                      key={buttonIndex}
                      style={{
                        ...defaultStyles.navItem,
                        ...(largeCardIndex === StackCardIndex.current &&
                        activeButtonIndex[largeCardIndex] === button.index
                          ? { ...defaultStyles.activeNavItem } // You can add more styles to differentiate the active button
                          : defaultStyles.navItem),
                      }}
                      onClick={() => {
                        scrollToCard(largeCardIndex, button.index);
                        setActiveButtonIndex((prev) => {
                          const prevData = { ...prev };
                          prevData[StackCardIndex.current] = button.index;
                          return prevData;
                        });
                      }}
                    >
                      <p style={{ width: '365px', fontFamily: '' }}>{t(button.name)}</p>
                    </div>
                  ))}
                </div>
                <div style={defaultStyles.smallCardContainer}>
                  {card.smallCards.map((smallCard, smallCardIndex) => (
                    <div
                      key={smallCardIndex}
                      ref={(el) => {
                        if (!smallCardRefs.current[largeCardIndex]) {
                          smallCardRefs.current[largeCardIndex] = [];
                        }
                        smallCardRefs.current[largeCardIndex][smallCardIndex] = el;
                      }}
                      style={defaultStyles.smallCard}
                      className="small-card"
                      data-index={smallCardIndex}
                      onMouseEnter={(e) => {
                        const content = e.currentTarget.querySelector('.small-card-content');
                        content.style.height = '90%'; // Expand content height on hover
                        setScroll(true)
                      }}
                      onMouseLeave={(e) => {
                        const content = e.currentTarget.querySelector('.small-card-content');
                        content.style.height = '30%'; // Reset to original height
                          setScroll(false)
                          content.scrollTop = 0;
                       
                      }} 
                      onClick={(e) => {
                        if (screenType === "smallPhone" || screenType === "phone"||screenType==="tab"||screenType==="tablet") {
                          const content = e.currentTarget.querySelector(".small-card-content");
                          // Toggle the height between 90% and 30%
                          if (content.style.height === "90%") {
                            content.style.height = "30%";
                            setScroll(false);
                            content.scrollTop = 0;
                          } else {
                            content.style.height = "90%";
                            setScroll(true);
                          }
                        }
                      }}
                    >
                      <img
                        src={smallCard.src}
                        alt={smallCard.alt}
                        style={defaultStyles.smallCardImage}
                        className="small-card-image"
                        loading="lazy" 
                      />
                      <div
                        style={{
                          ...defaultStyles.smallCardContent,
                          height: '30%', // Initial height
                        }}
                        className="small-card-content"
                      >
                        <div style={defaultStyles.smallCardTitle}>
                          <p style={{ display: 'flex', marginLeft: '20px' }}>{t(smallCard.title)}</p>
                        </div>
                        <div
                          style={{
                            height: '100%',
                            height: 'auto', // Set to auto to allow it to expand
                            flex: '1',
                            padding: '20px',
                          }}
                        >
                          {t(smallCard.content)}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
  <button onClick={increaseTextScale} style={{ marginRight: '10px' }}>Increase Text Size</button>
  <button onClick={decreaseTextScale}>Decrease Text Size</button>
</div> */}
    </div>
  );
};

export default StackingCards;
