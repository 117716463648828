import React from 'react';
import validIndicator from '../../assets/images/validIndicator.webp'; // Correct path to your valid indicator image
import invalidIndicator from '../../assets/images/invalidIndicator.webp'; // Correct path to your invalid indicator image

const App = (props) => {
  const { title, errorMessage, isValid, value, onChangeFunc, placeholder, secureTextEntry, maxLength, instructions } = props;

  return (
    <div style={{ width:"102%", padding: '5px', margin: '5px', borderRadius: '10px', backgroundColor: title ? '#E9F2FF' : undefined }}>
      {title && (
        <p style={{ fontFamily: "MarkerFelt, sans-serif", color: '#3B486F', fontSize: '20px', margin: '0 0 10px 0' }}>
          {title}
        </p>
      )}
      {errorMessage && isValid === 'invalid' && (
        <p style={styles.errorMessage}>{errorMessage}</p>
      )}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <input
          value={value}
          onChange={(e) => onChangeFunc(e.target.value)}
          placeholder={placeholder}
          type={secureTextEntry ? 'password' : 'text'}
          maxLength={maxLength || undefined}
          style={
            
            isValid === 'valid'
              ? styles.validContainer
              : isValid === 'invalid'
              ? styles.invalidContainer
              : styles.container
          }
        />
        {isValid === 'valid' && <img src={validIndicator} alt="Valid" style={styles.validityIndicator} />}
        {isValid === 'invalid' && <img src={invalidIndicator} alt="Invalid" style={styles.validityIndicator} />}
        {isValid === 'checking' && <div style={styles.activityIndicator}><span>Loading...</span></div>}
      </div>
      {instructions && (
        <p style={{ color: 'black', alignSelf: 'flex-start' }}>* {instructions}</p>
      )}
    </div>
  );
};

const styles = {
  container: {
    width: '100%',
    borderRadius: '10px',
    color: '#814F19',
    fontFamily: 'MarkerFelt',
    fontSize: '16px',
    backgroundColor: '#fff',
    margin: '5px',
    padding: '10px',
  },
  validContainer: {
    width: '90%',
    borderRadius: '10px',
    borderColor: '#098B0B',
    borderWidth: '3px',
    color: '#098B0B',
    fontFamily: 'MarkerFelt',
    fontSize: '22px',
    backgroundColor: '#CDFFB4',
    margin: '5px',
    padding: '10px',
  },
  invalidContainer: {
    width: '90%',
    borderRadius: '10px',
    borderColor: '#DF0203',
    borderWidth: '3px',
    color: '#DF0203',
    fontFamily: 'MarkerFelt',
    fontSize: '22px',
    backgroundColor: '#FFBFC6',
    margin: '5px',
    padding: '10px',
  },
  errorMessage: {
    color: 'darkred',
    fontFamily: 'MarkerFelt',
    fontSize: '16px',
    marginLeft: '10px',
    marginTop: '20px',
    maxWidth: '100%',
  },
  validityIndicator: {
    width: '20px', // Adjusted width to be more realistic
    height: '20px',
    borderRadius: '50%',
    marginLeft: '10px',
  },
  activityIndicator: {
    marginLeft: '10px',
  },
};

export default App;
