import React, { useEffect, useRef, useState } from "react";
import MainContentArea from "../Screens/MainContentArea/MainContentArea";
import TitleBarWebsite from "../Components/studyBuddyWA/TitleBarWebsite";
import TitleBar from "../Components/studyBuddyWA/TitleBar.js";
import SidebarMobile from "../Components/studyBuddyWA/SidebarMobile.js";
import backgroundImage from '../assets/images/background1_new.webp';

import Footer from "../Components/studyBuddyWA/Footer.js";
import { useSelector, useDispatch } from "react-redux";
import {
  setAnimate,
  setHeaderHeight,
  setHeaderSize,
  setLoading,
  setReach,
  setTitlebarHeight,
} from "../reduxStore/slices/WebSiteData.js";
import useScreenSizes from "../utils/useScreenSize.js";
import { useLocation } from "react-router-dom";
import SideBarScreen from "../Components/studyBuddyWA/sideBarNav";
import FooterWA from "../Components/studyBuddyWA/FooterWA.js";
import themes from "../config/theme.js";

const Apps = () => {
  const dispatch = useDispatch();
  const headerHeight = useSelector((state) => state.website.headerHeight);
  const sidebar = useSelector((state) => state.auth.sidebar);
  const reach = useSelector((state) => state.website.reach);
  const screen = useScreenSizes();
  const location = useLocation();
  const setFixed = !["/"].includes(location.pathname);
  const currentTheme = useSelector((state) => state.website.theme);
  const selectedTheme = themes[currentTheme] || themes.default; // Use selected theme

  const containersRef = useRef(null);

  const containerRef = useRef(null); // Ref for the outer container
  const [headerFixed, setHeaderFixed] = useState(false); // State for toggling header fixed position
  const [Tab, setTab] = useState(); // State for tablet
  const [phone, setPhone] = useState(); // State for phone
  const [enableScroll, setEnableScroll] = useState(false);

  useEffect(() => {
    if (setFixed) {
      let newHeaderHeight;

      if (screen >= 1200) {
        newHeaderHeight = "18vh";
      } else if (screen >= 1000 && screen <= 1200) {
        newHeaderHeight = "15vh";
      } else if (screen >= 650 && screen <= 1000) {
        newHeaderHeight = "14vh";
      } else {
        newHeaderHeight = "10vh";
      }

      dispatch(setHeaderHeight(newHeaderHeight));

      setHeaderFixed(true); // Set header to fixed when scrolling
      dispatch(setReach(true));
    }
  });
  useEffect(() => {
    let initialHeaderHeight;

    // Determine device type based on screen size
    if (screen > 1200) {
      initialHeaderHeight = "90vh";
      setTab(false);

      setPhone(false);
      dispatch(setReach(false));
    } else if (screen >= 800 && screen <= 1200) {
      initialHeaderHeight = "50vh";
      dispatch(setReach(false));

      setTab(true); // Set Tab to true if screen is tablet
      setPhone(false); // Set phone to false
    } else {
      initialHeaderHeight = "50vh";
      setTab(false); // Set Tab to false
      setPhone(true); // Set phone to true
      dispatch(setReach(false));
    }

    dispatch(setHeaderHeight(initialHeaderHeight));
  }, [screen, dispatch]);

  useEffect(() => {
    if (reach) {
      const timer = setTimeout(() => {
        setEnableScroll(true);
      }, 500);

      return () => clearTimeout(timer); // Cleanup the timeout on unmount or when `reach` changes
    } else {
      setEnableScroll(false);
    }
  }, [reach]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = containerRef.current.scrollTop;

      if (currentScrollY > 0) {
        dispatch(setReach(true));

        let newHeaderHeight;

        if (screen >= 1200) {
          newHeaderHeight = "18vh";
        } else if (screen >= 1000 && screen <= 1200) {
          newHeaderHeight = "15vh";
        } else if (screen >= 650 && screen <= 1000) {
          newHeaderHeight = "14vh";
        } else {
          newHeaderHeight = "10vh";
        }

        dispatch(setHeaderHeight(newHeaderHeight));

        setHeaderFixed(true); // Set header to fixed when scrolling
      } else {
        if (!setFixed) {
          let resetHeaderHeight;
          if (screen >= 1200) {
            resetHeaderHeight = "90vh";
          } else if (screen >= 650 && screen <= 1200) {
            resetHeaderHeight = "50vh";
          } else {
            resetHeaderHeight = "50vh";
          }

          dispatch(setHeaderHeight(resetHeaderHeight));
          dispatch(setReach(false));
          setHeaderFixed(false); // Reset header to relative when at the top
        }
      }
    };

    const container = containerRef.current;
    container.addEventListener("scroll", handleScroll);

    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [screen, dispatch, setFixed]);

  const routesWithoutSidebar = ["/LMS"];
  const routesWithoutTitleBar = ["/LMS"];

  const showTitleBar = routesWithoutTitleBar.includes(location.pathname);
  const showSidebar = routesWithoutSidebar.includes(location.pathname);
  useEffect(() => {
    if (containersRef.current) {
      containersRef.current.scrollTo(0, 0);
    }
  }, [location.pathname]);
  useEffect(() => {
    if (containersRef.current) {
      containersRef.current.scrollTo(0, 0);
    }
  }, [location.state]);

  const treeClick = useSelector((state) => state.website.treeClick);
  const headerSize = useSelector((state) => state.website.headerSize);
  const screenType = useSelector((state) => state.website.screenType);

  const [prevScrollY, setPrevScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {

      const currentScrollY = containersRef.current.scrollTop;
      if (currentScrollY - prevScrollY > 10) {
        if (screenType === "smallPhone" || screenType === "phone") {
          dispatch(setTitlebarHeight("6vh"));
        }
        if (screenType === "tab") {
          dispatch(setTitlebarHeight("10vh"));
        }
        if (screenType === "tablet") {
          dispatch(setTitlebarHeight("10vh"));
        }
        if (screenType === "desktop") {
          dispatch(setTitlebarHeight("10vh"));
        }
      } else if (prevScrollY - currentScrollY > 10) {
        if (screenType === "smallPhone" || screenType === "phone") {
          dispatch(setTitlebarHeight("10vh"));
        }
        dispatch(setTitlebarHeight("14vh"));
      }
      else if (currentScrollY === 0) {
        dispatch(setTitlebarHeight("14vh"))

      }
      // Update the previous scroll position
      setPrevScrollY(currentScrollY);
    };

    const container = containersRef.current;
    container.addEventListener("scroll", handleScroll);

    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollY, screenType]);

  useEffect(() => {
    if (treeClick) {
      dispatch(setAnimate(true));
      if (headerSize === "small") {
        dispatch(setTitlebarHeight("100dvh"));
        dispatch(setHeaderSize("large"));
      }
    } else {
      dispatch(setAnimate(true));
      if (headerSize === "large") {
        if (screenType === "smallPhone" || screenType === "phone") {
          dispatch(setTitlebarHeight("10vh"));
        } else if (screenType === "tab" || screenType === "tablet") {
          dispatch(setTitlebarHeight("16vh"));
        } else {
          dispatch(setTitlebarHeight("16vh"));
        }
        dispatch(setHeaderSize("small"));
      }
    }
  }, [treeClick]);

  const TitlebarHeight = useSelector((state) => state.website.TitlebarHeight);



  return (
    <div
      ref={containerRef}
      style={{
        height: "100vh",
        backgroundColor: selectedTheme.soilColor,
        overflowY: "scroll",
        overflowX: "hidden",
        scrollbarWidth: "none",

      }}
    >
        <div
          style={{
            width: "100vw",
            height: location.pathname == "/LMS" ? "0" : headerHeight,
            position: reach ? "fixed" : "relative",
            top: 0,
            left: 0,
            backgroundSize: "cover",
            backgroundColor: selectedTheme.soilColor,
            zIndex: 10,
            transition: "height 0.5s ease",
            scrollbarWidth: "none",
            backgroundRepeat: reach ? "repeat-y" : "repeat-x",
            backgroundImage:
              location.pathname == "/LMS"
                ? null
                : `url(${backgroundImage})`,
          }}
        >
          <img
            src={backgroundImage}
            alt="background"
            onLoad={() => dispatch(setLoading(false))}
            style={{ display: "none" }} // Hide the image element
          />



          {showTitleBar ? <TitleBar /> : <TitleBarWebsite setFixed={setFixed} />}
        </div>
  
      <div
        style={{
          maxHeight: location.pathname==="/LMS" ? "100dvh" :   screen >= 900 && screen <= 1000 ? "87%" :  screen >= 1000 && screen <= 1050  ?   "85.1%":  screen >= 1100 && screen <= 1200 ? "87%" :
            screen >= 650 && screen <= 820
              ? "86.1%"
               :
              Tab
                ? "85.1%"
                : phone
                  ? "92%"
                  : "83dvh",
          
                  flexDirection:"column",
          position: "relative",
          backgroundColor: selectedTheme.soilColor,
          zIndex: 1,
          marginTop: "",
          marginTop:
            location.pathname == "/LMS"
              ? TitlebarHeight
              : reach
                ? headerHeight
                : "",
          overflow: enableScroll ? "scroll" : "hidden",
      
          transition:
            location.pathname == "/LMS" ? "margin 0.5s ease-in-out" : "",
        }}
        ref={containersRef}
      >
        <div style={{ display: "flex" }} ref={containersRef}>
          {sidebar && location.pathname == "/LMS" ? <SideBarScreen /> : null}
          <MainContentArea />
        </div>
        {

          showSidebar && showTitleBar ? <FooterWA /> : <Footer />
        }
      

      </div>
    </div>
  );
};

export default Apps;
